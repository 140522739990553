import React from 'react';

const IconEdit = (
    <svg viewBox="0 0 100 100">
        {/* <defs> */}
        <path
            d="M93.542 4.682c-6.242-6.243-16.389-6.243-22.63 0L6.803 68.786c-.033.036-.05.071-.077.105-.034.044-.07.086-.105.13a1.82 1.82 0 00-.215.425c-.018.053-.045.087-.054.14l-.024.051L.044 96.035a1.663 1.663 0 00-.035.356c0 .026-.009.053-.009.068 0 .21.044.4.113.592l.051.13c.087.192.2.374.356.52.167.174.364.305.582.392.218.086.442.129.67.129.137 0 .277-.007.415-.043l26.397-6.284c.054-.009.087-.036.14-.053.06-.016.114-.043.174-.07.129-.06.242-.13.355-.217.045-.033.087-.06.131-.096.016-.015.034-.024.043-.042l64.115-64.106c6.24-6.24 6.24-16.398 0-22.63zM91.033 7.19c4.418 4.426 4.8 11.38 1.154 16.248L74.782 6.035c4.88-3.647 11.825-3.264 16.251 1.156zm-8.802 26.404L64.627 15.993l2.517-2.51L84.74 31.08l-2.509 2.516zM8.82 89.404a1.78 1.78 0 00-2.518 0l-1.346 1.344 4.54-19.07 7.864-.876-1.007 9.104c-.009.069.018.131.018.2s-.027.131-.018.191c0 .06.036.114.045.165.026.104.05.2.095.295.043.114.096.218.165.322.053.087.113.156.173.225.087.095.182.164.287.244.078.051.155.103.242.147.113.051.244.087.367.113.069.018.12.051.189.06.07.01.13.018.2.018h.008c.063 0 .123-.009.192-.018l9.106-1.006-.878 7.864-19.07 4.54L8.82 91.92a1.781 1.781 0 000-2.515zm22.309-9.74L70.91 39.882a1.78 1.78 0 000-2.518 1.77 1.77 0 00-2.509 0L28.62 77.146l-8.489.947.945-8.49L60.86 29.82a1.773 1.773 0 10-2.509-2.508L18.56 67.095l-5.66.624 49.218-49.21 17.595 17.595-49.209 49.22.625-5.66zM87.258 28.57L69.653 10.966l2.518-2.518 17.602 17.605-2.515 2.518z"
            fill="currentColor"
        />
        {/* </defs>
        <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
                <use xlink:href="#a" />
            </mask>
            <use fill="#000" xlink:href="#a" />
            <g mask="url(#b)" fill="#000">
                <path d="M-1-1H99V99H-1z" />
            </g>
        </g> */}
    </svg>
);

export default IconEdit;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CSVReader from 'react-csv-reader';
import styled from 'styled-components';
import { Group } from '@webfox-sc/core';
import FormNativeSelect from '../../../atoms/FormNativeSelect';
import TextM from '../../../atoms/TextM';
import { COLORS } from '../../../styles/common';

const options = [
    {
        value: 'UTF-8',
        label: 'UTF-8'
    },
    {
        value: 'iso-8859-1',
        label: 'ISO 8859-1 (Excel 2010)'
    }
];

const defaultValue = 'UTF-8';

const parserOptions = { delimiter: ';' };

const Comp = styled.div({
    display: 'flex',
    '.csv-reader-input': {
        border: '1px solid white',
        outline: 0,
        background: 'transparent',
        padding: '0 22px',
        minHeight: '56px',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',

        '&:hover': {
            background: 'rgba(255, 255, 255, 0.14)'
        },

        '&:active': {
            background: COLORS.WHITE
        },

        '&--active': {
            background: COLORS.WHITE,
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.14)'
            }
        }
    },
    '.csv-reader-input input': {
        border: 0,
        clip: 'rect(0, 0, 0, 0)',
        height: '1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px'
    },
    '.csv-reader-input label': {
        fontFamily: '"Gudea", sans-serif',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: '700',
        color: COLORS.WHITE,
        textTransform: 'uppercase',
        cursor: 'pointer'
    }
});

const CSVUploader = ({ onUpload, inputId }) => {
    const [selectedEncoding, setEncoding] = useState(defaultValue);

    return (
        <Comp>
            <Group spacing="10px" alignItems="center">
                <TextM>Zeichensatz</TextM>
                <FormNativeSelect
                    options={options}
                    value={selectedEncoding}
                    onChange={(value) => {
                        setEncoding(value);
                    }}
                />
                <CSVReader
                    cssClass="csv-reader-input"
                    label="CSV Upload"
                    inputId={inputId}
                    fileEncoding={selectedEncoding}
                    parserOptions={parserOptions}
                    onFileLoaded={(data) => {
                        document.getElementById(inputId).value = '';
                        onUpload(data);
                    }}
                />
            </Group>
        </Comp>
    );
};

CSVUploader.propTypes = {
    /**
     * Id of input field, make sure to set one if more than one CSVUploader is used per page.
     * @prop className
     * @type string
     * @values ''
     */
    inputId: PropTypes.string,
    /**
     * @prop onUpload
     * @type function (callback)
     */
    onUpload: PropTypes.func
};

CSVUploader.defaultProps = {
    inputId: 'csv-input',
    onUpload: () => null
};

export default CSVUploader;

/* xeslint-disable */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledClickWrapper = styled.div(({ cursor }) => {
    return {
        cursor
    };
});

const ClickWrapper = ({ cursor, children, onClick, onMouseUp, onClickOutside, onMouseDownOutside }) => {
    const refComponent = useRef();

    useEffect(() => {
        const handleOnClickOutside = (e) => {
            if (!refComponent.current.contains(e.target)) {
                onClickOutside();
            }
        };

        const handleOnMouseDownOutside = (e) => {
            if (!refComponent.current.contains(e.target)) {
                onMouseDownOutside();
            }
        };

        if (typeof onClickOutside === 'function') {
            document.addEventListener('click', handleOnClickOutside);
        }

        if (typeof onMouseDownOutside === 'function') {
            document.addEventListener('mousedown', handleOnMouseDownOutside);
        }

        return () => {
            document.removeEventListener('click', handleOnClickOutside);
            document.removeEventListener('mousedown', handleOnMouseDownOutside);
        };
    }, [onClickOutside, onMouseDownOutside]);

    return (
        <StyledClickWrapper ref={refComponent} cursor={cursor} onClick={onClick} onMouseUp={onMouseUp}>
            {children}
        </StyledClickWrapper>
    );
};

ClickWrapper.propTypes = {
    cursor: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    onMouseUp: PropTypes.func,
    onClickOutside: PropTypes.func,
    onMouseDownOutside: PropTypes.func
};

ClickWrapper.defaultProps = {
    cursor: null,
    children: null,
    onClick: () => {},
    onMouseUp: () => {},
    onClickOutside: null,
    onMouseDownOutside: null
};

export default ClickWrapper;

import React from 'react';

const IconPermanent = (
    <svg viewBox="0 0 100 100">
        <path
            d="M3.337 46c0 7.113 2.775 13.819 7.828 18.891a1.679 1.679 0 010 2.37 1.666 1.666 0 01-2.359 0C3.125 61.557 0 54.003 0 46c0-16.604 13.46-30.105 29.996-30.105h35.979L53.825 3.693a1.679 1.679 0 010-2.37c.65-.654 1.7-.654 2.352 0l14.998 15.053c.154.155.277.343.367.548.162.408.162.874 0 1.282-.09.204-.213.393-.367.539L56.177 33.797a1.657 1.657 0 01-2.352 0 1.669 1.669 0 010-2.36l12.15-12.203h-35.98C15.3 19.234 3.338 31.24 3.338 46zm89.486-19.487A30.109 30.109 0 01100 46c0 16.604-13.46 30.105-29.996 30.105H30.687l12.159 12.202a1.679 1.679 0 010 2.37 1.669 1.669 0 01-1.18.49c-.422 0-.853-.164-1.178-.49l-15-15.061a1.673 1.673 0 010-2.361l15-15.052a1.669 1.669 0 012.358 2.36L30.688 72.766h39.316C84.7 72.766 96.662 60.76 96.662 46c0-6.33-2.262-12.48-6.379-17.315a1.683 1.683 0 01.188-2.36 1.661 1.661 0 012.352.188z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconPermanent;

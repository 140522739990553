import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import FormButton from '../../atoms/FormButton';
import OrderListDropdown from '../../atoms/OrderListDropdown';
import TextM from '../../atoms/TextM';
import Table from '../../atoms/Table';
import TableBody from '../../atoms/TableBody';
import TableHeader from '../../atoms/TableHeader';
import TableRow from '../../atoms/TableRow';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import TableBodyCell from '../../atoms/TableBodyCell';
import TableBodyCellSelect from '../../atoms/TableBodyCellSelect';
import OrdersCostcenterDialog from '../OrdersCostcenterDialog';
import OrderListFilters from './OrderListFilters';
import { fetchOrders, updateCostcenters } from '../../redux/actions/ordersActions';
import { useOrderList } from './OrderList.hooks';
import { useUser } from '../../hooks/user';
import DateUtil from '../../utils/DateUtil';
import { ORDER_STATUS_LABELS, USER_ROLES } from '../../global/constants';
import CSVDownloader from '../../components/CSVDownloader';

const OrderList = () => {
    const { userIsLoggedIn, userRoleName } = useUser();
    const dispatch = useDispatch();
    const history = useHistory();

    const [showCostcenterDialog, setShowCostcenterDialog] = useState(false);

    const {
        isOrdersInitialRequest,
        ordersSortingKey,
        ordersSortingOrder,
        ordersFiltered,
        ordersDisplayMax,
        selectedOrderIds,
        handleSetOrdersSortingKey,
        toggleOrderSelection,
        displayFurtherOrders
    } = useOrderList();

    useEffect(() => {
        if (userIsLoggedIn) {
            dispatch(fetchOrders());
        }
    }, [userIsLoggedIn, dispatch]);

    if (!userIsLoggedIn) {
        return null;
    }

    if (isOrdersInitialRequest) {
        return null;
    }

    return (
        <>
            <PageContentBlock>
                <OrderListFilters />

                {ordersFiltered.length === 0 && (
                    <Block paddingTop="50px" justify="center">
                        <TextM>
                            <strong>Es wurden keine Ad-hoc-Aufträge gefunden.</strong>
                        </TextM>
                    </Block>
                )}

                {ordersFiltered.length > 0 && (
                    <>
                        <Block paddingTop="50px">
                            <Grid columns="3" alignItems="center">
                                <GridItem>
                                    <Block>
                                        <OrderListDropdown
                                            disabled={!selectedOrderIds.length}
                                            onClickChangeCostcenter={() => setShowCostcenterDialog(true)}
                                        />
                                    </Block>
                                </GridItem>
                                <GridItem>
                                    <Block justify="center">
                                        <TextM>
                                            <strong>
                                                {ordersFiltered.length}{' '}
                                                {ordersFiltered.length === 1 ? 'Ad-hoc-Auftrag' : 'Ad-hoc-Aufträge'}
                                            </strong>
                                        </TextM>
                                    </Block>
                                </GridItem>
                                {(userRoleName === USER_ROLES.ADMIN ||
                                    userRoleName === USER_ROLES.LOCATION_MANAGER ||
                                    userRoleName === USER_ROLES.SERVICE_MANAGER) && (
                                    <GridItem>
                                        <Block justify="flex-end">
                                            <CSVDownloader data={ordersFiltered} type="orders" />
                                        </Block>
                                    </GridItem>
                                )}
                            </Grid>
                        </Block>

                        <Block paddingTop="50px">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell width="1%" />
                                        <TableHeaderCell
                                            width="10%"
                                            label="ID"
                                            order={ordersSortingKey === 'entryId' ? ordersSortingOrder : null}
                                            onClick={() => handleSetOrdersSortingKey('entryId')}
                                        />
                                        <TableHeaderCell
                                            label="Leistung"
                                            order={
                                                ordersSortingKey === 'listServiceSorting' ? ordersSortingOrder : null
                                            }
                                            onClick={() => handleSetOrdersSortingKey('listServiceSorting')}
                                        />
                                        <TableHeaderCell
                                            width="10%"
                                            label="Erstellt am"
                                            order={ordersSortingKey === 'createdAt' ? ordersSortingOrder : null}
                                            onClick={() => handleSetOrdersSortingKey('createdAt')}
                                        />
                                        <TableHeaderCell
                                            width="10%"
                                            label="Wunschtermin"
                                            order={ordersSortingKey === 'datePlanned' ? ordersSortingOrder : null}
                                            onClick={() => handleSetOrdersSortingKey('datePlanned')}
                                        />
                                        <TableHeaderCell
                                            width="18%"
                                            label="Bearbeitung"
                                            order={ordersSortingKey === 'assigneeName' ? ordersSortingOrder : null}
                                            onClick={() => handleSetOrdersSortingKey('assigneeName')}
                                        />
                                        <TableHeaderCell
                                            width="12%"
                                            label="Status"
                                            order={ordersSortingKey === 'status' ? ordersSortingOrder : null}
                                            onClick={() => handleSetOrdersSortingKey('status')}
                                        />
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {ordersFiltered.slice(0, ordersDisplayMax).map((order) => {
                                        return (
                                            <TableRow
                                                key={order.id}
                                                href={`/orders/${order.id}`}
                                                onClick={() => history.push(`/orders/${order.id}`)}
                                            >
                                                <TableBodyCell showBorderRight>
                                                    <TableBodyCellSelect
                                                        selected={selectedOrderIds.includes(order.id)}
                                                        onChangeSelection={() => toggleOrderSelection(order.id)}
                                                    />
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">{order.entryId}</TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    <TextM>
                                                        <strong>{order.type}</strong>
                                                    </TextM>
                                                    {order.listService && (
                                                        <TextM>
                                                            <em>{order.listService}</em>
                                                        </TextM>
                                                    )}
                                                    {order.isOverhead && <TextM>Über Betriebskosten abrechnen</TextM>}
                                                    {!order.isOverhead && order.billingDetailsFormatted && (
                                                        <TextM>{order.billingDetailsFormatted}</TextM>
                                                    )}
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {DateUtil.getDateFormatted(order.createdAt)}
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {DateUtil.getDateFormatted(order.datePlanned) || '–'}
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">{order.assigneeName}</TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {ORDER_STATUS_LABELS[order.status]}
                                                </TableBodyCell>
                                                <TableBodyCell layout="info-icon" />
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Block>

                        {ordersDisplayMax < ordersFiltered.length && (
                            <Block paddingTop="10px" justify="center">
                                <FormButton label="Mehr anzeigen" onClick={displayFurtherOrders} />
                            </Block>
                        )}
                    </>
                )}
            </PageContentBlock>
            <OrdersCostcenterDialog
                show={showCostcenterDialog}
                onClickConfirm={(costcenter) => {
                    dispatch(updateCostcenters({ c: costcenter, orders: selectedOrderIds }));
                    setShowCostcenterDialog(false);
                }}
                onClickCancel={() => setShowCostcenterDialog(false)}
                onClickOutside={() => setShowCostcenterDialog(false)}
            />
        </>
    );
};

OrderList.propTypes = {};
OrderList.defaultProps = {};

export default OrderList;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import DateUtil from '../utils/DateUtil';
import { ORDER_STATUS, ORDER_STATUS_LABELS } from '../global/constants';

export function useOrderHistory(orderId) {
    const order = useSelector(({ dataState }) => dataState.orders[orderId]);
    const histories = useSelector(({ dataState }) => dataState.histories);
    const users = useSelector(({ dataState }) => dataState.users);
    const roles = useSelector(({ dataState }) => dataState.roles);

    const [orderHistory, setOrderHistory] = useState([]);
    const [lastRejectionComment, setLastRejectionComment] = useState('');
    const [orderComments, setOrderComments] = useState([]);

    useEffect(() => {
        if (order) {
            const _orderHistory = [];
            const _orderComments = [];
            const historyIds = order.historyIds || [];

            historyIds.forEach((historyId) => {
                const history = histories[historyId];
                const { previousStatus, nextStatus, comment, creatorId, diff } = history;

                const creator = users[creatorId];
                const creatorUsername = creator?.username || '?';
                const role = creator ? roles[creator.roleId] : null;
                const roleName = role ? `(${role.name})` : '';
                const creatorDescription = `${creatorUsername} ${roleName}`;

                /**
                 * history
                 */
                let text = '';

                if (previousStatus !== nextStatus) {
                    if (!previousStatus && nextStatus === ORDER_STATUS.OFFEN) {
                        text = `Erstellt von ${creatorDescription}`;
                    } else if (nextStatus === ORDER_STATUS.ABGELEHNT) {
                        text = `Abgelehnt von ${creatorDescription}`;
                        if (comment) {
                            text += `<br /><br />Grund der Ablehnung:<br /><em>${comment}</em>`;
                            setLastRejectionComment(comment);
                        }
                    } else if (nextStatus === ORDER_STATUS.FREIGEGEBEN) {
                        text = `Freigegeben von ${creatorDescription}`;
                    } else {
                        text = `Status geändert zu <em>${ORDER_STATUS_LABELS[nextStatus]}</em> von ${creatorDescription}`;
                    }
                } else if (diff?.assignee) {
                    text = `<em>Bearbeitung</em> geändert von ${creatorDescription}`;
                    if (comment) {
                        text += `<br /><br />Grund der Änderung:<br /><em>${comment}</em>`;
                    }
                } else if (diff?.billing) {
                    text = `<em>Verrechnungsdaten</em> geändert von ${creatorDescription}`;
                }

                if (text) {
                    _orderHistory.push({
                        createdAt: history.createdAt,
                        dateFormatted: DateUtil.getDateTimeFormattedExtended(history.createdAt),
                        text
                    });
                }

                /**
                 * comments
                 */
                if (!previousStatus && !nextStatus && comment && Object.keys(diff).length === 0) {
                    _orderComments.push({
                        createdAt: history.createdAt,
                        dateFormatted: DateUtil.getDateTimeFormattedExtended(history.createdAt),
                        creatorDescription,
                        comment
                    });
                }
            });

            setOrderHistory(_orderBy(_orderHistory, ['createdAt'], ['asc']));
            setOrderComments(_orderBy(_orderComments, ['createdAt'], ['asc']));
        }
    }, [order, histories, users, roles]);

    return {
        orderHistory,
        lastRejectionComment,
        orderComments
    };
}

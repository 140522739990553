import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Block, Collapse, Grid, GridItem } from '@webfox-sc/core';
import IconCreate from '../../assets/icons/IconCreate';
import IconDelete from '../../assets/icons/IconDelete';
import Table from '../../atoms/Table';
import TableBody from '../../atoms/TableBody';
import TableHeader from '../../atoms/TableHeader';
import TableRow from '../../atoms/TableRow';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import TableBodyCell from '../../atoms/TableBodyCell';
import TextM from '../../atoms/TextM';
import FormButton from '../../atoms/FormButton';
import ConfirmDialog from '../../atoms/ConfirmDialog';
import SettingsUsersEdit from '../SettingsUsersEdit';
import SettingsUsersCreate from '../SettingsUsersCreate';
import { fetchUsers, deleteUser } from '../../redux/actions/usersActions';
import { fetchRoles } from '../../redux/actions/rolesActions';
import { useSettingsUsers } from './SettingsUsers.hooks';
import { useUser } from '../../hooks/user';
import { USER_ROLES } from '../../global/constants';
import CSVDownloader from '../../components/CSVDownloader';

const SettingsUsers = () => {
    const dispatch = useDispatch();
    const { userRoleName } = useUser();
    const [activeUserId, setActiveUserId] = useState('');
    const [showCreatePanel, setShowCreatePanel] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const {
        isUsersInitialRequest,
        usersSorted,
        usersSortingKey,
        usersSortingOrder,
        usersDisplayMax,
        handleSetUsersSortingKey,
        displayFurtherUsers
    } = useSettingsUsers();

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchRoles());
    }, [dispatch]);

    if (userRoleName !== USER_ROLES.ADMIN && userRoleName !== USER_ROLES.LOCATION_MANAGER) {
        return null;
    }
    const canEdit = userRoleName === USER_ROLES.ADMIN;

    if (isUsersInitialRequest) {
        return null;
    }

    const handleOnClickShowCreatePanel = () => {
        setShowCreatePanel(!showCreatePanel);
        setActiveUserId('');
    };

    const handleOnClickTableRow = (userId) => {
        setActiveUserId(userId);
        setShowCreatePanel(false);
    };

    const handleOnClickDelete = async () => {
        dispatch(deleteUser(activeUserId));
    };
    return (
        <>
            <Block justify="center">
                <TextM>
                    <strong>
                        {usersSorted.length} {usersSorted.length === 1 ? 'Benutzer' : 'Benutzer'}
                    </strong>
                </TextM>
            </Block>
            <Block paddingTop="30px">
                <Grid columns="2" alignItems="center">
                    <GridItem>
                        {canEdit && (
                            <Block>
                                <FormButton
                                    label="Neuer Benutzer"
                                    icon={IconCreate}
                                    iconSize="small"
                                    active={showCreatePanel}
                                    onClick={handleOnClickShowCreatePanel}
                                />
                            </Block>
                        )}
                    </GridItem>
                    <GridItem>
                        <Block justify="flex-end">
                            <CSVDownloader data={usersSorted} type="users" />
                        </Block>
                    </GridItem>
                </Grid>
            </Block>

            <Collapse show={showCreatePanel} keepChildren={false}>
                <Block paddingTop="10px">
                    <SettingsUsersCreate
                        onClickCancel={() => setShowCreatePanel(false)}
                        onCreated={() => setShowCreatePanel(false)}
                    />
                </Block>
            </Collapse>

            <Block paddingTop="50px">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell
                                width="33%"
                                label="Name"
                                order={usersSortingKey === 'username' ? usersSortingOrder : null}
                                onClick={() => handleSetUsersSortingKey('username')}
                            />
                            <TableHeaderCell
                                label="E-Mail"
                                order={usersSortingKey === 'email' ? usersSortingOrder : null}
                                onClick={() => handleSetUsersSortingKey('email')}
                            />
                            <TableHeaderCell
                                width="20%"
                                label="Rolle"
                                order={usersSortingKey === 'roleName' ? usersSortingOrder : null}
                                onClick={() => handleSetUsersSortingKey('roleName')}
                            />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {usersSorted.slice(0, usersDisplayMax).map((user) => {
                            return (
                                <TableRow
                                    key={user.id}
                                    onClick={
                                        !canEdit || activeUserId === user.id
                                            ? null
                                            : () => handleOnClickTableRow(user.id)
                                    }
                                >
                                    {activeUserId === user.id ? (
                                        <TableBodyCell colSpan="3">
                                            <SettingsUsersEdit
                                                userId={user.id}
                                                onClickCancel={() => setActiveUserId('')}
                                                onUpdated={() => setActiveUserId('')}
                                                onClickDelete={() => {
                                                    setShowDeleteDialog(true);
                                                }}
                                            />
                                        </TableBodyCell>
                                    ) : (
                                        <>
                                            <TableBodyCell layout="text">{user.username}</TableBodyCell>
                                            <TableBodyCell layout="text">{user.email}</TableBodyCell>
                                            <TableBodyCell layout="text">{user.roleName}</TableBodyCell>
                                        </>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Block>

            {usersDisplayMax < usersSorted.length && (
                <Block paddingTop="10px" justify="center">
                    <FormButton label="Mehr anzeigen" onClick={displayFurtherUsers} />
                </Block>
            )}

            <ConfirmDialog
                show={showDeleteDialog}
                heading="Möchten Sie diesen Eintrag wirklich löschen?"
                text="Diese Aktion kann nicht rückgängig gemacht werden."
                confirmButtonLabel="Löschen"
                confirmButtonIcon={IconDelete}
                onClickConfirm={() => {
                    handleOnClickDelete();
                    setShowDeleteDialog(false);
                }}
                onClickCancel={() => setShowDeleteDialog(false)}
                onClickOutside={() => setShowDeleteDialog(false)}
            />
        </>
    );
};

SettingsUsers.propTypes = {};
SettingsUsers.defaultProps = {};

export default SettingsUsers;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Group, GroupItem } from '@webfox-sc/core';
import FormLabel from '../FormLabel';
import FormInput from '../FormInput';
import FormButton from '../FormButton';
import FormStaticField from '../FormStaticField';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import IconEdit from '../../assets/icons/IconEdit';

const FormInputExtended = ({
    label,
    type,
    value,
    staticFallbackValue,
    isRequired,
    error,
    isEditMode,
    isInlineEditing,
    onChange,
    onClickSubmit
}) => {
    const [valueLocal, setValueLocal] = useState(value);
    const [errorLocal, setErrorLocal] = useState(error);
    const [isEditModeLocal, setIsEditModeLocal] = useState(isEditMode);
    const [isInlineEditingLocal, setIsInlineEditingLocal] = useState(isInlineEditing);

    useEffect(() => {
        setValueLocal(value);
    }, [value]);

    useEffect(() => {
        setErrorLocal(error);
    }, [error]);

    useEffect(() => {
        setIsEditModeLocal(isEditMode);
    }, [isEditMode]);

    useEffect(() => {
        setIsInlineEditingLocal(isInlineEditing);
    }, [isInlineEditing]);

    const handleOnInputChange = (targetValue) => {
        setValueLocal(targetValue);
        setErrorLocal(false);
        onChange(targetValue);
    };

    const handleOnClickCancel = () => {
        setIsEditModeLocal(false);
        setValueLocal(value);
    };

    const handleOnClickSubmit = () => {
        if (isRequired && !valueLocal) {
            setErrorLocal(true);
        } else {
            onClickSubmit(valueLocal);
            setIsEditModeLocal(false);
        }
    };

    const formInput = (
        <FormInput
            type={type}
            ariaLabel={label}
            value={valueLocal}
            block
            error={errorLocal}
            onChange={(e) => handleOnInputChange(e.target.value)}
        />
    );

    const formStaticField = <FormStaticField value={valueLocal || staticFallbackValue} />;

    return (
        <>
            {label && (
                <FormLabel top bold>
                    {label}
                    {isRequired && isEditModeLocal && ' *'}
                </FormLabel>
            )}
            {isInlineEditingLocal ? (
                <>
                    {isEditModeLocal ? (
                        <Group nowrap spacing="5px">
                            <GroupItem grow>{formInput}</GroupItem>
                            <FormButton icon={IconCheck} iconSize="small" onClick={handleOnClickSubmit} />
                            <FormButton icon={IconClose} iconSize="small" onClick={handleOnClickCancel} />
                        </Group>
                    ) : (
                        <Group nowrap spacing="5px">
                            <GroupItem grow>{formStaticField}</GroupItem>
                            <FormButton icon={IconEdit} iconSize="small" onClick={() => setIsEditModeLocal(true)} />
                        </Group>
                    )}
                </>
            ) : (
                <>{isEditModeLocal ? formInput : formStaticField}</>
            )}
        </>
    );
};

FormInputExtended.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    staticFallbackValue: PropTypes.any,
    isRequired: PropTypes.bool,
    error: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isInlineEditing: PropTypes.bool,
    onChange: PropTypes.func,
    onClickSubmit: PropTypes.func
};

FormInputExtended.defaultProps = {
    label: '',
    type: 'text',
    value: '',
    staticFallbackValue: '',
    isRequired: false,
    error: false,
    isEditMode: false,
    isInlineEditing: false,
    onChange: () => {},
    onClickSubmit: () => {}
};

export default FormInputExtended;

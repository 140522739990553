/* xeslint-disable */
import axios from 'axios';
import auth from '../../utils/auth';
import api from '../../api';
import { REDUX_ACTIONS } from '../../global/constants';

export function fetchUsers() {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_USERS
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/users`,
            params: {
                _limit: 100000
            },
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const users = res.data || [];
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_USERS,
                    users
                });
                return users;
            })
            .catch((error) => {
                console.log('#fetchUsers error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_USERS_ERROR
                });
            });
    };
}

export function createUser(data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CREATE_USER
        });

        return axios({
            method: 'post',
            url: `${api.apiUrl}/users`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const user = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CREATE_USER,
                    user
                });
                return user;
            })
            .catch((error) => {
                console.log('#createUser error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CREATE_USER_ERROR
                });
            });
    };
}

export function fetchUser(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_USER
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/users/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const user = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_USER,
                    user
                });
                return user;
            })
            .catch((error) => {
                console.log('#fetchUser error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_USER_ERROR
                });
            });
    };
}

export function fetchMe(jwtToken) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_ME
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/users/me`,
            headers: {
                Authorization: `Bearer ${jwtToken || auth.getToken()}`
            }
        })
            .then((res) => {
                const user = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_ME,
                    user
                });
                return user;
            })
            .catch((error) => {
                console.log('#fetchMe error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_ME_ERROR
                });
                throw error;
            });
    };
}

export function updateUser(id, data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_UPDATE_USER
        });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/users/${id}`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const user = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_UPDATE_USER,
                    user
                });
                return user;
            })
            .catch((error) => {
                console.log('#updateUser error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_UPDATE_USER_ERROR
                });
            });
    };
}

export function deleteUser(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_DELETE_USER
        });

        return axios({
            method: 'delete',
            url: `${api.apiUrl}/users/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const user = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_DELETE_USER,
                    user
                });
                return user;
            })
            .catch((error) => {
                console.log('#deleteUser error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_DELETE_USER_ERROR
                });
            });
    };
}

export function setUsersSorting(usersSortingKey, usersSortingOrder) {
    return {
        type: REDUX_ACTIONS.SET_USERS_SORTING,
        usersSortingKey,
        usersSortingOrder
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../styles/common';
import { STYLE_TEXT } from '../../styles/type';

const StyledButton = styled.button(() => {
    const style = {
        display: 'flex',
        flexFlow: 'row nowrap',
        border: 'none',
        background: 'transparent',
        boxSizing: 'border-box',
        width: '100%',
        margin: 0,
        padding: 0,
        outline: 0,
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.14)'
        }
    };

    return style;
});

const StyledIcon = styled.span(() => {
    const style = {
        flex: '0 0 97px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '97px',
        height: '97px',
        background: 'white',
        svg: {
            width: '50px',
            height: '50px',
            color: COLORS.DARK_BLUE
        }
    };
    return style;
});

const StyledLabel = styled.span(() => {
    const style = {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        height: '97px',
        padding: '0 20px',
        borderTop: '1px solid white',
        borderRight: '1px solid white',
        borderBottom: '1px solid white',
        transition: 'background-color 0.2s ease',

        ...STYLE_TEXT,
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: '28px',

        '&[data-active="true"]': {
            border: 'none',
            backgroundColor: COLORS.DARK_BLUE
        }
    };
    return style;
});

const TypeButton = ({ label, icon, active, onClick }) => {
    const dataAttributes = {
        'data-active': active
    };

    return (
        <StyledButton type="button" onClick={onClick}>
            <StyledIcon>{icon}</StyledIcon>
            <StyledLabel {...dataAttributes}>{label}</StyledLabel>
        </StyledButton>
    );
};

TypeButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.any,
    active: PropTypes.bool,
    onClick: PropTypes.func
};

TypeButton.defaultProps = {
    label: '',
    icon: null,
    active: false,
    onClick: () => {}
};

export default TypeButton;

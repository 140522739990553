/* eslint-disable */
/**
 *
 * CockpitView
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Stars from '../../MicroComps/Stars';
import { NavLink } from 'react-router-dom';

class PerformanceItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}

    render() {
        let orderType;
        if (this.props.order_type == 'claim') {
            orderType = 'Reklamation';
        } else if (this.props.order_type == 'order') {
            orderType = 'Ad-hoc-Auftrag';
        } else if (this.props.order_type == 'permanent') {
            orderType = 'Dauerauftrag';
        }

        return (
            <NavLink to={'/orders/' + this.props.origid}>
                <Container className="performance-item grid-reset">
                    <Row>
                        <Col sm={3} md={3} lg={3} xl={3}>
                            <h5 className="performance-regular">{this.props.id}</h5>
                        </Col>
                        <Col sm={5} md={5} lg={5} xl={5}>
                            <h5 className="performance-regular">{orderType}</h5>
                        </Col>
                        <Col sm={4} md={4} lg={4} xl={4}>
                            {this.props.datePlanned && (
                                <h5 className="performance-regular">{this.props.datePlanned}</h5>
                            )}
                            {this.props.dateDone && <h5 className="performance-regular">{this.props.dateDone}</h5>}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8} md={8} lg={8} xl={8}>
                            <h5 className="performance-bold">{this.props.type}</h5>
                        </Col>
                        <Col sm={4} md={4} lg={4} xl={4}>
                            {this.props.rating !== 0 && <Stars rating={this.props.rating} />}
                        </Col>
                    </Row>
                </Container>
            </NavLink>
        );
    }
}

export default PerformanceItem;

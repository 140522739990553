import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, GridItem, Group } from '@webfox-sc/core';
import TextM from '../../atoms/TextM';
import FormPanelGroup from '../../atoms/FormPanelGroup';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormStaticField from '../../atoms/FormStaticField';
import FormCheckbox from '../../atoms/FormCheckbox';
import FormPanelBilling from '../../components/FormPanelBilling';
import { fetchMe, updateUser } from '../../redux/actions/usersActions';
import { useUser } from '../../hooks/user';
import { USER_ROLES } from '../../global/constants';

const SettingsProfile = () => {
    const dispatch = useDispatch();
    const { user, userId, username, userEmail, userRoleName } = useUser();

    useEffect(() => {
        dispatch(fetchMe());
    }, [dispatch, userId]);

    if (!user) {
        return null;
    }

    return (
        <FormPanelGroup>
            <FormPanel title="Benutzer">
                <Grid spacing="20px">
                    <GridItem cols="6">
                        <FormLabel top bold>
                            Name
                        </FormLabel>
                        <FormStaticField value={username} />
                    </GridItem>
                    <GridItem cols="6">
                        <FormLabel top bold>
                            E-Mail
                        </FormLabel>
                        <FormStaticField value={userEmail} />
                    </GridItem>
                    <GridItem cols="12">
                        <FormLabel top bold>
                            Rolle
                        </FormLabel>
                        <FormStaticField value={userRoleName} />
                    </GridItem>
                </Grid>
            </FormPanel>

            <FormPanel title="E-Mail-Benachrichtigungen">
                <Group paddingTop="5px" spacing="15px" alignItems="center">
                    <FormCheckbox
                        onChange={(e) =>
                            dispatch(
                                updateUser(user.id, {
                                    receive_notifications: e.target.checked
                                })
                            )
                        }
                        checked={user.receiveNotifications}
                    />
                    <TextM>E-Mail-Benachrichtigungen erhalten</TextM>
                </Group>
            </FormPanel>

            {(userRoleName === USER_ROLES.KEY_USER ||
                userRoleName === USER_ROLES.LOCATION_MANAGER ||
                userRoleName === USER_ROLES.ADMIN) && (
                <FormPanelBilling
                    billing={user.billing}
                    isInlineEditing
                    onSubmitBilling={(billingDraft) =>
                        dispatch(
                            updateUser(user.id, {
                                billing: billingDraft
                            })
                        )
                    }
                />
            )}
        </FormPanelGroup>
    );
};

SettingsProfile.propTypes = {};
SettingsProfile.defaultProps = {};

export default SettingsProfile;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import FilterCheckbox from '../../atoms/FilterCheckbox';
import FormButton from '../../atoms/FormButton';
import TextM from '../../atoms/TextM';
import Table from '../../atoms/Table';
import TableBody from '../../atoms/TableBody';
import TableHeader from '../../atoms/TableHeader';
import TableRow from '../../atoms/TableRow';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import TableBodyCell from '../../atoms/TableBodyCell';
import { fetchClaims } from '../../redux/actions/claimsActions';
import { useClaimList } from './ClaimList.hooks';
import { useUser } from '../../hooks/user';
import DateUtil from '../../utils/DateUtil';
import { CLAIM_STATUS, CLAIM_STATUS_LABELS } from '../../global/constants';

const ClaimList = () => {
    const { userIsLoggedIn } = useUser();
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        isClaimsInitialRequest,
        claimsFilters,
        claimsSortingKey,
        claimsSortingOrder,
        claimsFiltered,
        claimsDisplayMax,
        toggleClaimsFilter,
        handleSetClaimsSortingKey,
        displayFurtherClaims
    } = useClaimList();

    useEffect(() => {
        if (userIsLoggedIn) {
            dispatch(fetchClaims());
        }
    }, [userIsLoggedIn, dispatch]);

    if (!userIsLoggedIn) {
        return null;
    }

    if (isClaimsInitialRequest) {
        return null;
    }

    return (
        <PageContentBlock>
            <Group spacing="40px" justify="center" alignItems="center">
                <TextM>
                    <strong>Status</strong>
                </TextM>
                <FilterCheckbox
                    label="Offen"
                    checked={claimsFilters.includes(CLAIM_STATUS.OFFEN)}
                    onChange={() => toggleClaimsFilter(CLAIM_STATUS.OFFEN)}
                />
                <FilterCheckbox
                    label="Unberechtigt"
                    checked={claimsFilters.includes(CLAIM_STATUS.UNBERECHTIGT)}
                    onChange={() => toggleClaimsFilter(CLAIM_STATUS.UNBERECHTIGT)}
                />
                <FilterCheckbox
                    label="In Arbeit"
                    checked={claimsFilters.includes(CLAIM_STATUS.IN_ARBEIT)}
                    onChange={() => toggleClaimsFilter(CLAIM_STATUS.IN_ARBEIT)}
                />
                <FilterCheckbox
                    label="Erledigt"
                    checked={claimsFilters.includes(CLAIM_STATUS.ERLEDIGT)}
                    onChange={() => toggleClaimsFilter(CLAIM_STATUS.ERLEDIGT)}
                />
            </Group>

            {claimsFiltered.length === 0 && (
                <Block paddingTop="50px" justify="center">
                    <TextM>
                        <strong>Es wurden keine Reklamationen gefunden.</strong>
                    </TextM>
                </Block>
            )}

            {claimsFiltered.length > 0 && (
                <>
                    <Block paddingTop="50px" justify="center">
                        <TextM>
                            <strong>
                                {claimsFiltered.length} {claimsFiltered.length === 1 ? 'Reklamation' : 'Reklamationen'}
                            </strong>
                        </TextM>
                    </Block>

                    <Block paddingTop="50px">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell
                                        width="15%"
                                        label="ID"
                                        order={claimsSortingKey === 'entryId' ? claimsSortingOrder : null}
                                        onClick={() => handleSetClaimsSortingKey('entryId')}
                                    />
                                    <TableHeaderCell
                                        width="18%"
                                        label="Erstellt am"
                                        order={claimsSortingKey === 'createdAt' ? claimsSortingOrder : null}
                                        onClick={() => handleSetClaimsSortingKey('createdAt')}
                                    />
                                    <TableHeaderCell
                                        label="Auftragsersteller"
                                        order={claimsSortingKey === 'creator.username' ? claimsSortingOrder : null}
                                        onClick={() => handleSetClaimsSortingKey('creator.username')}
                                    />
                                    <TableHeaderCell
                                        width="20%"
                                        label="Auftragstyp"
                                        order={claimsSortingKey === 'type' ? claimsSortingOrder : null}
                                        onClick={() => handleSetClaimsSortingKey('type')}
                                    />
                                    <TableHeaderCell
                                        width="20%"
                                        label="Status"
                                        order={claimsSortingKey === 'status' ? claimsSortingOrder : null}
                                        onClick={() => handleSetClaimsSortingKey('status')}
                                    />
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {claimsFiltered.slice(0, claimsDisplayMax).map((claim) => {
                                    return (
                                        <TableRow
                                            key={claim.id}
                                            href={`/claims/${claim.id}`}
                                            onClick={() => history.push(`/claims/${claim.id}`)}
                                        >
                                            <TableBodyCell layout="text">{claim.entryId}</TableBodyCell>
                                            <TableBodyCell layout="text">
                                                {DateUtil.getDateFormatted(claim.createdAt)}
                                            </TableBodyCell>
                                            <TableBodyCell layout="text">
                                                {claim.creator ? claim.creator.username : ''}
                                            </TableBodyCell>
                                            <TableBodyCell layout="text">{claim.type}</TableBodyCell>
                                            <TableBodyCell layout="text">
                                                {CLAIM_STATUS_LABELS[claim.status]}
                                            </TableBodyCell>
                                            <TableBodyCell layout="info-icon" />
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Block>

                    {claimsDisplayMax < claimsFiltered.length && (
                        <Block paddingTop="10px" justify="center">
                            <FormButton label="Mehr anzeigen" onClick={displayFurtherClaims} />
                        </Block>
                    )}
                </>
            )}
        </PageContentBlock>
    );
};

ClaimList.propTypes = {};
ClaimList.defaultProps = {};

export default ClaimList;

/* xeslint-disable */
import axios from 'axios';
import auth from '../../utils/auth';
import api from '../../api';
import { REDUX_ACTIONS } from '../../global/constants';

export function fetchPermanents() {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_PERMANENTS
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/permanent`,
            params: {
                _limit: 100000
            },
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const permanents = res.data || {};

                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_PERMANENTS,
                    permanents
                });

                return permanents;
            })
            .catch((error) => {
                console.log('#fetchPermanents error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_PERMANENTS_ERROR
                });
            });
    };
}

export function createPermanent(data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CREATE_PERMANENT
        });

        return axios({
            method: 'post',
            url: `${api.apiUrl}/permanent`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const permanent = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CREATE_PERMANENT,
                    permanent
                });
                return permanent;
            })
            .catch((error) => {
                console.log('#createPermanent error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CREATE_PERMANENT_ERROR
                });
            });
    };
}

export function fetchPermanent(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_PERMANENT
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/permanent/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const permanent = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_PERMANENT,
                    permanent
                });
                return permanent;
            })
            .catch((error) => {
                console.log('#fetchPermanent error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_PERMANENT_ERROR
                });
            });
    };
}

export function updatePermanent(id, data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_UPDATE_PERMANENT
        });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/permanent/${id}`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const permanent = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_UPDATE_PERMANENT,
                    permanent
                });
                return permanent;
            })
            .catch((error) => {
                console.log('#updatePermanent error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_UPDATE_PERMANENT_ERROR
                });
            });
    };
}

export function deletePermanent(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_DELETE_PERMANENT
        });

        return axios({
            method: 'delete',
            url: `${api.apiUrl}/permanent/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const permanent = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_DELETE_PERMANENT,
                    permanent
                });
                return permanent;
            })
            .catch((error) => {
                console.log('#deletePermanent error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_DELETE_PERMANENT_ERROR
                });
            });
    };
}

export function resetPermanentDraft() {
    return {
        type: REDUX_ACTIONS.RESET_PERMANENT_DRAFT
    };
}

export function setPermanentDraft(permanentDraft) {
    return {
        type: REDUX_ACTIONS.SET_PERMANENT_DRAFT,
        permanentDraft
    };
}

export function setPermanentsSearchText(permanentsSearchText) {
    return {
        type: REDUX_ACTIONS.SET_PERMANENTS_SEARCH_TEXT,
        permanentsSearchText
    };
}

export function setPermanentsFilters(permanentsFilters) {
    return {
        type: REDUX_ACTIONS.SET_PERMANENTS_FILTERS,
        permanentsFilters
    };
}

export function setPermanentsSorting(permanentsSortingKey, permanentsSortingOrder) {
    return {
        type: REDUX_ACTIONS.SET_PERMANENTS_SORTING,
        permanentsSortingKey,
        permanentsSortingOrder
    };
}

export function setPermanentsDisplayMax(permanentsDisplayMax) {
    return {
        type: REDUX_ACTIONS.SET_PERMANENTS_DISPLAY_MAX,
        permanentsDisplayMax
    };
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormStaticField from '../../atoms/FormStaticField';
import FormTextarea from '../../atoms/FormTextarea';
import FormButton from '../../atoms/FormButton';
import { updatePermanent } from '../../redux/actions/permanentsActions';
import { useOrderAssignees } from '../../hooks/orders';
import { usePermanent } from '../../hooks/permanents';

const FormPanelPermanentAssignee = ({ permanentId, isInlineEditing }) => {
    const dispatch = useDispatch();
    const { permanent, permanentAssignee } = usePermanent(permanentId);
    const { assigneeDropdownOptions } = useOrderAssignees();
    const [assigneeDropdownOptionsWithCurrent, setAssigneeDropdownOptionsWithCurrent] = useState([]);

    const [isEditMode, setIsEditMode] = useState(false);
    const [assigneeIdDraft, setAssigneeIdDraft] = useState(permanent ? permanent.assigneeId : null);
    const [commentDraft, setCommentDraft] = useState('');
    const [isButtonSubmitClicked, setIsButtonSubmitClicked] = useState(false);

    useEffect(() => {
        if (permanent) {
            setAssigneeIdDraft(permanent.assigneeId);
        }
    }, [permanent]);

    useEffect(() => {
        if (assigneeDropdownOptions && assigneeDropdownOptions.length > 0) {
            if (permanent && permanent.assigneeId) {
                const options = assigneeDropdownOptions;
                const isCurrentAssigneeIncluded = assigneeDropdownOptions.find(
                    ({ value }) => value === permanent.assigneeId
                );
                if (!isCurrentAssigneeIncluded) {
                    const currentAssigneeOption = {
                        value: permanent.assigneeId,
                        label: permanent.assigneeName
                    };
                    options.unshift(currentAssigneeOption);
                }
                setAssigneeDropdownOptionsWithCurrent(options);
            }
        }
    }, [permanent, assigneeDropdownOptions]);

    useEffect(() => {
        if (!isEditMode) {
            setAssigneeIdDraft(permanent?.assigneeId);
            setCommentDraft('');
        }
    }, [isEditMode, permanent]);

    const handleOnClickSubmit = async () => {
        setIsButtonSubmitClicked(true);

        if (commentDraft) {
            dispatch(
                updatePermanent(permanentId, {
                    assignee: assigneeIdDraft,
                    comment: commentDraft
                })
            );

            setIsEditMode(false);
            setIsButtonSubmitClicked(false);
            setCommentDraft('');
        }
    };

    return (
        <FormPanel
            title="Bearbeitung"
            showEditButton={isInlineEditing}
            onClickEditButton={() => {
                if (isEditMode) {
                    setIsEditMode(false);
                    setIsButtonSubmitClicked(false);
                } else {
                    setIsEditMode(true);
                }
            }}
        >
            <Group nowrap spacing="5px">
                <GroupItem grow>
                    {isEditMode ? (
                        <Grid spacing="5px">
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    Name
                                </FormLabel>
                                <FormNativeSelect
                                    options={assigneeDropdownOptionsWithCurrent}
                                    value={assigneeIdDraft}
                                    onChange={(e) => setAssigneeIdDraft(e.target.value)}
                                />
                            </GridItem>
                            <GridItem cols="8">
                                <FormLabel top bold>
                                    Grund der Änderung *
                                </FormLabel>
                                <FormTextarea
                                    value={commentDraft}
                                    height="50px"
                                    error={isButtonSubmitClicked && !commentDraft}
                                    onChange={(e) => setCommentDraft(e.target.value)}
                                />
                            </GridItem>
                        </Grid>
                    ) : (
                        <Grid spacing="5px">
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    Name
                                </FormLabel>
                                <FormStaticField value={permanentAssignee ? permanentAssignee.username : '–'} />
                            </GridItem>
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    E-Mail
                                </FormLabel>
                                <FormStaticField value={permanentAssignee ? permanentAssignee.email : '–'} />
                            </GridItem>
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    Rolle
                                </FormLabel>
                                <FormStaticField value={permanentAssignee ? permanentAssignee.roleName : '–'} />
                            </GridItem>
                        </Grid>
                    )}
                </GroupItem>
                {isInlineEditing && isEditMode && (
                    <GroupItem>
                        <Group nowrap spacing="5px" paddingTop="29px">
                            <FormButton
                                icon={IconCheck}
                                iconSize="small"
                                onClick={() => {
                                    handleOnClickSubmit();
                                }}
                            />
                            <FormButton
                                icon={IconClose}
                                iconSize="small"
                                onClick={() => {
                                    setIsEditMode(false);
                                    setIsButtonSubmitClicked(false);
                                }}
                            />
                        </Group>
                    </GroupItem>
                )}
            </Group>
        </FormPanel>
    );
};

FormPanelPermanentAssignee.propTypes = {
    permanentId: PropTypes.any,
    isInlineEditing: PropTypes.bool
};

FormPanelPermanentAssignee.defaultProps = {
    permanentId: null,
    isInlineEditing: false
};

export default FormPanelPermanentAssignee;

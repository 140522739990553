import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import orderInitial from '../data/orderInitial';
import { createOrder, updateOrder, setOrderDraft, resetOrderDraft } from '../redux/actions/ordersActions';
import { useUser } from './user';
import { useBilling } from './billing';
import { USER_ROLES } from '../global/constants';

export function useOrder(orderId) {
    const order = useSelector(({ dataState }) => dataState.orders[orderId]);
    const orderService = useSelector(({ dataState }) => dataState.services[order?.serviceId]);
    const orderBuilding = useSelector(({ dataState }) => dataState.buildings[order?.buildingId]);
    const orderAssignee = useSelector(({ dataState }) => dataState.users[order?.assigneeId]);
    const orderMonthlyPrice = (orderService?.price || 0) * (order?.quantity || 0);
    const histories = useSelector(({ dataState }) => dataState.histories);
    const [orderHistories, setOrderHistories] = useState([]);

    useEffect(() => {
        if (order) {
            const historyIds = order.historyIds || [];
            const _orderHistories = historyIds.map((historyId) => histories[historyId]);
            setOrderHistories(_orderHistories);
        }
    }, [order, histories]);

    return {
        order,
        orderService,
        orderBuilding,
        orderAssignee,
        orderHistories,
        orderMonthlyPrice
    };
}

export function useOrderActions() {
    const dispatch = useDispatch();
    const orderDraft = useSelector(({ ordersState }) => ordersState.orderDraft);

    const handleCreateOrderFromDraft = async () => {
        const createData = {
            type: orderDraft.type,
            date_planned: orderDraft.datePlanned,
            date_done: orderDraft.dateDone,
            location: orderDraft.location,
            message: orderDraft.message,
            is_overhead: orderDraft.isOverhead,
            billing: orderDraft.billing,
            contact_person_list: orderDraft.contactPersonList,
            service: orderDraft.serviceId,
            quantity: orderDraft.quantity,
            building: orderDraft.buildingId,
            assignee: orderDraft.assigneeId
        };

        return dispatch(createOrder(createData));
    };

    const handleUpdateOrderFromDraft = async (newStatus) => {
        if (orderDraft && orderDraft.id) {
            const data = {
                date_planned: orderDraft.datePlanned,
                location: orderDraft.location,
                message: orderDraft.message,
                is_overhead: orderDraft.isOverhead,
                billing: orderDraft.billing,
                contact_person_list: orderDraft.contactPersonList,
                service: orderDraft.serviceId,
                quantity: orderDraft.quantity,
                building: orderDraft.buildingId
            };

            if (newStatus) {
                data.status = newStatus;
            }

            dispatch(updateOrder(orderDraft.id, data));
        }
    };

    return {
        handleCreateOrderFromDraft,
        handleUpdateOrderFromDraft
    };
}

export function useOrderAssignees() {
    const { userId, username } = useUser();
    const users = useSelector(({ dataState }) => dataState.users);
    const [assigneeDropdownOptions, setAssigneeDropdownOptions] = useState([]);

    useEffect(() => {
        const _assigneeDropdownOptions = [
            {
                value: userId,
                label: username
            }
        ];

        const assignees = Object.values(users).filter((user) => user.roleName === USER_ROLES.KEY_USER);
        const assigneesSorted = _orderBy(assignees, ['username'], ['asc']);

        assigneesSorted.forEach((user) => {
            _assigneeDropdownOptions.push({
                value: user.id,
                label: user.username
            });
        });

        setAssigneeDropdownOptions(_assigneeDropdownOptions);
    }, [userId, username, users]);

    return {
        assigneeDropdownOptions
    };
}

export function useOrderServices() {
    const services = useSelector(({ dataState }) => dataState.services);
    const orderDraft = useSelector(({ ordersState }) => ordersState.orderDraft);
    const { type } = orderDraft;

    const [orderServiceDropdownOptions, setOrderServiceDropdownOptions] = useState([]);

    useEffect(() => {
        if (type) {
            /**
             * services by type
             */
            const servicesByType = Object.values(services).filter((service) => service.type === type);

            /**
             * set service dropdown options
             */
            let _serviceDropdownOptions = servicesByType.map((service) => {
                return {
                    value: service.id,
                    label: `${service.number} ${service.name}`
                };
            });

            /**
             * sort
             */
            _serviceDropdownOptions = _orderBy(_serviceDropdownOptions, ['label'], ['asc']);

            /**
             * add default value
             */
            _serviceDropdownOptions = [
                {
                    value: '',
                    label: 'Auswählen',
                    disabled: true
                },
                ..._serviceDropdownOptions
            ];

            setOrderServiceDropdownOptions(_serviceDropdownOptions);
        }
    }, [type, services]);

    return {
        orderServiceDropdownOptions
    };
}

export function useOrderDraft() {
    const dispatch = useDispatch();
    const { user } = useUser();
    const { isBillingValid } = useBilling();

    const orderDraft = useSelector(({ ordersState }) => ordersState.orderDraft);
    const orderDraftService = useSelector(({ dataState }) => dataState.services[orderDraft.serviceId]);
    const orderDraftBuilding = useSelector(({ dataState }) => dataState.buildings[orderDraft.buildingId]);
    const orderDraftAssignee = useSelector(({ dataState }) => dataState.users[orderDraft.assigneeId]);
    const orderDraftMonthlyPrice = (orderDraftService?.price || 0) * (orderDraft?.quantity || 0);

    const isOrderDraftServiceDataValid = () => {
        const { quantity, location, serviceId, buildingId } = orderDraft;

        if (!quantity || !location || !serviceId || !buildingId) {
            return false;
        }

        return true;
    };

    const isOrderDraftDetailsDataValid = () => {
        const { isOverhead, billing, contactPersonList } = orderDraft;

        if (!isOverhead && !isBillingValid(billing)) {
            return false;
        }

        let isContactPersonListValid = true;
        contactPersonList.forEach((contactPerson) => {
            if (!contactPerson.first_name || !contactPerson.last_name || !contactPerson.email) {
                isContactPersonListValid = false;
            }
        });

        if (!isContactPersonListValid) {
            return false;
        }

        return true;
    };

    const isOrderDraftDataValid = () => {
        const { quantity, location, isOverhead, billing, contactPersonList, serviceId, buildingId } = orderDraft;

        if (!isOverhead && !isBillingValid(billing)) {
            return false;
        }

        let isContactPersonListValid = true;
        contactPersonList.forEach((contactPerson) => {
            if (!contactPerson.first_name || !contactPerson.last_name || !contactPerson.email) {
                isContactPersonListValid = false;
            }
        });

        if (!quantity || !location || !isContactPersonListValid || !serviceId || !buildingId) {
            return false;
        }

        return true;
    };

    const updateOrderDraft = (data) => {
        const orderDraftNew = { ...orderDraft, ...data };
        return dispatch(setOrderDraft(orderDraftNew));
    };

    const updateOrderDraftType = (type) => {
        if (type !== orderDraft.type) {
            const orderDraftNew = {
                ...orderInitial,
                type,
                billing: user ? user.billing : {}
            };
            dispatch(setOrderDraft(orderDraftNew));
        }
    };

    const reset = () => {
        dispatch(resetOrderDraft());
    };

    const getOrderDraftPostData = () => {
        return {
            type: orderDraft.type
        };
    };

    return {
        orderDraft,
        orderDraftService,
        orderDraftBuilding,
        orderDraftAssignee,
        orderDraftMonthlyPrice,
        isOrderDraftServiceDataValid,
        isOrderDraftDetailsDataValid,
        isOrderDraftDataValid,
        updateOrderDraft,
        stableUpdateOrderDraft: useCallback(updateOrderDraft, []),
        updateOrderDraftType,
        stableResetOrderDraft: useCallback(reset, []),
        getOrderDraftPostData
    };
}

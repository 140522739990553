import React from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import FormButton from '../../atoms/FormButton';
import SettingsProfile from '../SettingsProfile';
import SettingsAccount from '../SettingsAccount';
import SettingsBuildings from '../SettingsBuildings';
import SettingsServices from '../SettingsServices';
import SettingsUsers from '../SettingsUsers';
import { useUser } from '../../hooks/user';
import { USER_ROLES } from '../../global/constants';

const Settings = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { userRoleName } = useUser();

    return (
        <PageContentBlock>
            <Block paddingTop="180px">
                <Group spacing="10px" justify="center">
                    <FormButton
                        label="Profil"
                        active={pathname === '/settings/profile'}
                        onClick={() => history.push('/settings/profile')}
                    />
                    <FormButton
                        label="Account"
                        active={pathname === '/settings/account'}
                        onClick={() => history.push('/settings/account')}
                    />
                    {userRoleName === USER_ROLES.ADMIN && (
                        <FormButton
                            label="Standorte"
                            active={pathname === '/settings/buildings'}
                            onClick={() => history.push('/settings/buildings')}
                        />
                    )}
                    {userRoleName === USER_ROLES.ADMIN && (
                        <FormButton
                            label="Leistungen"
                            active={pathname === '/settings/services'}
                            onClick={() => history.push('/settings/services')}
                        />
                    )}
                    {(userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.LOCATION_MANAGER) && (
                        <FormButton
                            label="Benutzer"
                            active={pathname === '/settings/users'}
                            onClick={() => history.push('/settings/users')}
                        />
                    )}
                </Group>
            </Block>
            <Block padding="50px 0">
                <Switch>
                    <Route path="/settings/profile" component={SettingsProfile} />
                    <Route path="/settings/account" component={SettingsAccount} />
                    <Route path="/settings/buildings" component={SettingsBuildings} />
                    <Route path="/settings/services" component={SettingsServices} />
                    <Route path="/settings/users" component={SettingsUsers} />
                </Switch>
            </Block>
        </PageContentBlock>
    );
};

Settings.propTypes = {};
Settings.defaultProps = {};

export default Settings;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Group, GroupItem, Collapse } from '@webfox-sc/core';
import { STYLE_TEXT } from '../../styles/type';

const StyledHeader = styled.div(({ disabled }) => {
    const style = {
        padding: '0 25px',
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? 0.5 : 1
    };
    return style;
});

const StyledTitle = styled.div(({ isExpanded }) => {
    const style = {
        ...STYLE_TEXT,
        fontWeight: 700,
        fontSize: isExpanded ? '40px' : '22px',
        lineHeight: '40px',
        transition: 'font-size 0.3s ease-out'
    };
    return style;
});

const AccordionSection = ({ title, expanded, disabled, children, onToggleExpanded }) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded]);

    const handleOnClickHeader = () => {
        if (!disabled) {
            setIsExpanded(!isExpanded);
            onToggleExpanded(!isExpanded);
        }
    };

    return (
        <>
            <StyledHeader disabled={disabled} onClick={handleOnClickHeader}>
                <Group nowrap spacing="20px" alignItems="center">
                    <GroupItem grow>
                        <Block borderTop="1px solid white" />
                    </GroupItem>
                    <GroupItem>
                        <StyledTitle isExpanded={isExpanded}>{title}</StyledTitle>
                    </GroupItem>
                    <GroupItem grow>
                        <Block borderTop="1px solid white" />
                    </GroupItem>
                </Group>
            </StyledHeader>
            <Collapse show={isExpanded} duration={300} animationType="ease-out">
                {children}
            </Collapse>
        </>
    );
};

AccordionSection.propTypes = {
    title: PropTypes.string,
    expanded: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    onToggleExpanded: PropTypes.func
};

AccordionSection.defaultProps = {
    title: '',
    expanded: false,
    disabled: false,
    children: '',
    onToggleExpanded: () => {}
};

export default AccordionSection;

/* eslint-disable */
/**
 *
 * UploadSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import api from '../../../../api';

class UploadSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadObj: null,
            uploadName: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    // handleChange(selectorFiles: FileList) {
    handleChange(selectorFiles) {
        this.setState({ uploadObj: selectorFiles[0], uploadName: selectorFiles[0].name });
    }

    sendPdf() {
        this.props.onClick(this.props.num, this.state.uploadObj, this.state.uploadName, this.props.year);
        this.setState({ uploadObj: null, uploadName: '' });
    }

    render() {
        const { data, year, month, num, api } = this.props;

        let renderList = <p></p>;

        if (data != null) {
            renderList = data.map((item, index) => {
                let url = api + '/uploads/' + item.hash + item.ext;
                let temp = year + '-' + num;

                let checkmonth = item.name.substring(5, 7);

                let checkYear = item.name.substring(0, 4);

                if (checkmonth == num && year == checkYear) {
                    return (
                        <Col sm={12} md={12} lg={6} xl={6} key={index} className="reset">
                            <div className="single-asset">
                                <a href={url} target="_blank">
                                    <span>{item.name}</span>
                                </a>
                            </div>
                        </Col>
                    );
                } else {
                    return null;
                }
            });
        }

        return (
            <div className="comp-upload-item">
                <Container className="grid-container grid-reset">
                    <Row>
                        <Col sm={12} md={12} lg={2} xl={2}>
                            <span className="title-month">{month}</span>

                            {this.state.uploadObj == null ? (
                                <React.Fragment>
                                    <div className="upload-btn">
                                        <span>PDF auswählen</span>
                                        <form>
                                            <input
                                                type="file"
                                                id="fileInputElement"
                                                onChange={(e) => this.handleChange(e.target.files)}
                                            />
                                        </form>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="upload-btn upload" onClick={() => this.sendPdf()}>
                                        <span>HOCHLADEN</span>
                                    </div>
                                </React.Fragment>
                            )}
                        </Col>

                        <Col sm={12} md={12} lg={10} xl={10}>
                            <span className="title-assets">Datei öffnen:</span>
                            <div className="list-assets">
                                <Container className="grid-container grid-reset">
                                    <Row>{renderList}</Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default UploadSection;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconClose from '../../assets/icons/IconClose';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormButton from '../../atoms/FormButton';
import { createBuilding } from '../../redux/actions/buildingsActions';

const SettingsBuildingsCreate = ({ onClickCancel, onCreated }) => {
    const dispatch = useDispatch();

    const [nameDraft, setNameDraft] = useState('');
    const [codeDraft, setCodeDraft] = useState('');
    const [isButtonCreateClicked, setIsButtonCreateClicked] = useState(false);

    const handleClickCreate = async () => {
        if (nameDraft && codeDraft) {
            await dispatch(
                createBuilding({
                    name: nameDraft,
                    code: codeDraft
                })
            );
            onCreated();
        } else {
            setIsButtonCreateClicked(true);
        }
    };

    return (
        <Block padding="20px" border="1px solid white">
            <Grid spacing="20px">
                <GridItem cols="6">
                    <FormLabel top>Name *</FormLabel>
                    <FormInput
                        ariaLabel="Name"
                        value={nameDraft}
                        error={isButtonCreateClicked && !nameDraft}
                        onChange={(e) => setNameDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="6">
                    <FormLabel top>Code *</FormLabel>
                    <FormInput
                        ariaLabel="Code"
                        value={codeDraft}
                        error={isButtonCreateClicked && !codeDraft}
                        onChange={(e) => setCodeDraft(e.target.value)}
                    />
                </GridItem>
            </Grid>

            <Group paddingTop="20px" spacing="20px">
                <FormButton label="Erstellen" icon={IconCheckbox} iconSize="small" onClick={handleClickCreate} />
                <FormButton label="Abbrechen" icon={IconClose} iconSize="small" onClick={onClickCancel} />
            </Group>
        </Block>
    );
};

SettingsBuildingsCreate.propTypes = {
    onClickCancel: PropTypes.func,
    onCreated: PropTypes.func
};
SettingsBuildingsCreate.defaultProps = {
    onClickCancel: () => {},
    onCreated: () => {}
};

export default SettingsBuildingsCreate;

import { REDUX_ACTIONS } from '../../global/constants';
import permanentInitial from '../../data/permanentInitial';

const initialState = {
    permanentDraft: permanentInitial,
    isPermanentsRequest: false,
    permanentsSearchText: '',
    permanentsFilters: [],
    permanentsSortingKey: 'entryId',
    permanentsSortingOrder: 'desc',
    permanentsDisplayMax: 10
};

function permanentsReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_PERMANENTS:
            return {
                ...state,
                isPermanentsRequest: true
            };

        case REDUX_ACTIONS.RECIEVE_PERMANENTS:
        case REDUX_ACTIONS.REQUEST_PERMANENTS_ERROR:
            return {
                ...state,
                isPermanentsRequest: false
            };

        case REDUX_ACTIONS.SET_PERMANENT_DRAFT:
            return {
                ...state,
                permanentDraft: action.permanentDraft
            };

        case REDUX_ACTIONS.RESET_PERMANENT_DRAFT:
            return {
                ...state,
                permanentDraft: permanentInitial
            };

        case REDUX_ACTIONS.SET_PERMANENTS_SEARCH_TEXT:
            return {
                ...state,
                permanentsSearchText: action.permanentsSearchText
            };

        case REDUX_ACTIONS.SET_PERMANENTS_FILTERS:
            return {
                ...state,
                permanentsFilters: action.permanentsFilters
            };

        case REDUX_ACTIONS.SET_PERMANENTS_SORTING:
            return {
                ...state,
                permanentsSortingKey: action.permanentsSortingKey,
                permanentsSortingOrder: action.permanentsSortingOrder
            };

        case REDUX_ACTIONS.SET_PERMANENTS_DISPLAY_MAX:
            return {
                ...state,
                permanentsDisplayMax: action.permanentsDisplayMax
            };

        /**
         * logout
         */
        case REDUX_ACTIONS.LOGOUT:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default permanentsReducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Block } from '@webfox-sc/core';
import TextL from '../../atoms/TextL';
import TextM from '../../atoms/TextM';
import FormButton from '../../atoms/FormButton';
import FormInput from '../../atoms/FormInput';
import api from '../../api';

const SettingsPassword = ({ isAuthForgotPage }) => {
    const [email, setEmail] = useState('');
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordForgotFailed, setIsPasswordForgotFailed] = useState(false);

    const sendForgotPassword = (e) => {
        e.preventDefault();

        api.strapiapi
            .forgotPassword(email)
            .then(() => {
                setIsPasswordReset(true);
                setIsPasswordForgotFailed(false);
            })
            .catch((err) => {
                console.log('error - forgotPassword: ', err);
                setIsPasswordForgotFailed(true);
            });
    };

    return (
        <Block border={!isAuthForgotPage ? '1px solid white' : 'none'} padding="30px">
            <Block justify="center">
                <TextL>
                    <strong>{isAuthForgotPage ? 'Passwort zurücksetzen' : 'Passwort ändern'}</strong>
                </TextL>
            </Block>
            <form onSubmit={(e) => sendForgotPassword(e)}>
                {isPasswordReset ? (
                    <Block justify="center" paddingTop="30px">
                        <TextM>Sie haben eine E-Mail erhalten. Bitte folgen Sie den Anweisungen in der E-Mail.</TextM>
                    </Block>
                ) : (
                    <Block paddingTop="30px">
                        <Block justify="center">
                            <TextM>Bitte geben Sie Ihre E-Mail ein:</TextM>
                        </Block>
                        <Block paddingTop="20px" justify="center">
                            <Block width="100%" maxWidth="600px">
                                <FormInput name="email" onChange={({ target }) => setEmail(target.value)} />
                            </Block>
                        </Block>
                        <Block paddingTop="20px" justify="center">
                            <FormButton type="submit" label="Passwort zurücksetzen" />
                        </Block>
                    </Block>
                )}

                {isPasswordForgotFailed && (
                    <Block justify="center" paddingTop="30px">
                        <TextM>Die E-Mail-Adresse ist ungültig!</TextM>
                    </Block>
                )}
            </form>
        </Block>
    );
};

SettingsPassword.propTypes = {
    isAuthForgotPage: PropTypes.bool
};

SettingsPassword.defaultProps = {
    isAuthForgotPage: false
};

export default SettingsPassword;

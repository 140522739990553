class AppUtil {
    static getEntryId(entryObjectOrId) {
        if (!entryObjectOrId) {
            return null;
        }

        if (typeof entryObjectOrId === 'object') {
            return entryObjectOrId.id;
        }

        if (typeof entryObjectOrId === 'string' || typeof entryObjectOrId === 'number') {
            return entryObjectOrId;
        }

        return null;
    }

    static isEmailValid(email) {
        const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reEmail.test(String(email).toLowerCase());
    }

    static isPasswordValid(password) {
        if (password.length < 8) {
            return false;
        }

        let score = 0;

        if (password.match(/[a-z]/)) {
            score += 1;
        }

        if (password.match(/[A-Z]/)) {
            score += 1;
        }

        if (password.match(/\d+/g)) {
            score += 1;
        }

        if (password.match(/[#?!@$%^&*\\-_]/)) {
            score += 1;
        }

        if (score < 3) {
            return false;
        }

        return true;
    }
}

export default AppUtil;

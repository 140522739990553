/* xeslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconReject from '../../assets/icons/IconReject';
import IconDelete from '../../assets/icons/IconDelete';
import ConfirmDialog from '../../atoms/ConfirmDialog';
import FormButton from '../../atoms/FormButton';
import FormTextarea from '../../atoms/FormTextarea';
import { updateClaim, deleteClaim } from '../../redux/actions/claimsActions';
import { useClaim } from '../../hooks/claims';
import { useUser } from '../../hooks/user';
import { CLAIM_STATUS, USER_ROLES } from '../../global/constants';

const ClaimDetailsOptions = ({ claimId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userRoleName } = useUser();
    const { claim } = useClaim(claimId);

    const [reasonDraft, setReasonDraft] = useState('');
    const [isButtonRejectClicked, setIsButtonRejectClicked] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    if (!claim) {
        return null;
    }

    const handleOnUpdateStatus = (newStatus) => {
        dispatch(
            updateClaim(claim.id, {
                status: newStatus
            })
        );
    };

    const handleOnClickReject = () => {
        setIsButtonRejectClicked(true);
        if (reasonDraft) {
            dispatch(
                updateClaim(claim.id, {
                    comment: reasonDraft,
                    status: CLAIM_STATUS.UNBERECHTIGT
                })
            );
        }
    };

    const handleOnClickDelete = async () => {
        await dispatch(deleteClaim(claim.id));
        history.push('/claims');
    };

    const handleOnClickDone = () => {
        dispatch(
            updateClaim(claim.id, {
                status: CLAIM_STATUS.ERLEDIGT,
                date_done: new Date()
            })
        );
    };

    const showOptionStatusToInArbeit =
        claim.status === CLAIM_STATUS.OFFEN &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.SERVICE_MANAGER);

    const showOptionStatusToUnberechtigt =
        claim.status === CLAIM_STATUS.OFFEN &&
        (userRoleName === USER_ROLES.ADMIN ||
            userRoleName === USER_ROLES.LOCATION_MANAGER ||
            userRoleName === USER_ROLES.SERVICE_MANAGER);

    const showOptionStatusToErledigt =
        claim.status === CLAIM_STATUS.IN_ARBEIT &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.SERVICE_MANAGER);

    const showOptionDelete = userRoleName === USER_ROLES.ADMIN;

    return (
        <>
            <Group vertical spacing="70px">
                {showOptionStatusToInArbeit && (
                    <Block justify="center">
                        <FormButton
                            layout="primary"
                            label={'Status ändern zu "In Arbeit"'}
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={() => handleOnUpdateStatus(CLAIM_STATUS.IN_ARBEIT)}
                        />
                    </Block>
                )}
                {showOptionStatusToUnberechtigt && (
                    <>
                        <Block justify="center">
                            <Block width="100%" maxWidth="600px">
                                <FormTextarea
                                    value={reasonDraft}
                                    placeholder="Begründung *"
                                    height="100px"
                                    error={isButtonRejectClicked && !reasonDraft}
                                    onChange={(e) => setReasonDraft(e.target.value)}
                                />
                            </Block>
                        </Block>
                        <Block paddingTop="10px" justify="center">
                            <FormButton
                                layout="primary"
                                label={'Status ändern zu "Unberechtigt"'}
                                icon={IconReject}
                                iconSize="small"
                                onClick={handleOnClickReject}
                            />
                        </Block>
                    </>
                )}
                {showOptionStatusToErledigt && (
                    <Block justify="center">
                        <FormButton
                            layout="primary"
                            label={'Status ändern zu "Erledigt"'}
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={handleOnClickDone}
                        />
                    </Block>
                )}
                {showOptionDelete && (
                    <Block justify="center">
                        <FormButton
                            label="Auftrag endgültig löschen"
                            icon={IconDelete}
                            iconSize="small"
                            onClick={() => setShowDeleteDialog(true)}
                        />
                    </Block>
                )}
            </Group>

            <ConfirmDialog
                show={showDeleteDialog}
                heading={`Möchten Sie die Reklamation<br />#${claim.entryId} wirklich löschen?`}
                text="Diese Aktion kann nicht rückgängig gemacht werden."
                confirmButtonLabel="Löschen"
                confirmButtonIcon={IconDelete}
                onClickConfirm={() => {
                    handleOnClickDelete();
                    setShowDeleteDialog(false);
                }}
                onClickCancel={() => setShowDeleteDialog(false)}
                onClickOutside={() => setShowDeleteDialog(false)}
            />
        </>
    );
};

ClaimDetailsOptions.propTypes = {
    claimId: PropTypes.any
};

ClaimDetailsOptions.defaultProps = {
    claimId: null
};

export default ClaimDetailsOptions;

export const STYLE_TEXT = {
    fontFamily: '"Gudea", sans-serif',
    color: 'white',
    '-webkit-font-smoothing': 'antialiased'
};

export const STYLE_TEXT_S = {
    ...STYLE_TEXT,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    strong: {
        fontWeight: 700
    }
};

export const STYLE_TEXT_S_BOLD = {
    ...STYLE_TEXT,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px'
};

export const STYLE_TEXT_M = {
    ...STYLE_TEXT,
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    strong: {
        fontWeight: 700
    }
};

export const STYLE_TEXT_M_BOLD = {
    ...STYLE_TEXT,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px'
};

export const STYLE_TEXT_L = {
    ...STYLE_TEXT,
    fontWeight: 400,
    fontSize: '23px',
    lineHeight: '28px',
    strong: {
        fontWeight: 700
    }
};

export const STYLE_TEXT_XL = {
    ...STYLE_TEXT,
    fontWeight: 400,
    fontSize: '26px',
    lineHeight: '32px',
    strong: {
        fontWeight: 700
    }
};

export const STYLE_HEADING_M = {
    ...STYLE_TEXT,
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '50px'
};

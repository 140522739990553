import React from 'react';
import PropTypes from 'prop-types';
import FormStaticField from '../../atoms/FormStaticField';

const Comment = ({ userName, dateFormatted, text }) => {
    return (
        <FormStaticField>
            {userName}, {dateFormatted}:<br />
            <br />
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </FormStaticField>
    );
};

Comment.propTypes = {
    userName: PropTypes.string,
    dateFormatted: PropTypes.string,
    text: PropTypes.string
};

Comment.defaultProps = {
    userName: '',
    dateFormatted: '',
    text: ''
};

export default Comment;

import React from 'react';
import { Block } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import SettingsPassword from '../SettingsPassword';

const AuthForgotPage = () => {
    return (
        <PageContentBlock>
            <Block paddingTop="180px">
                <SettingsPassword isAuthForgotPage />
            </Block>
        </PageContentBlock>
    );
};

export default AuthForgotPage;

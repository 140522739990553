import React from 'react';

const IconInfo = (
    <svg viewBox="0 0 100 100">
        <path
            d="M48.017 28.36c-3.196 0-5.798-2.69-5.798-5.996 0-3.306 2.602-5.996 5.798-5.996s5.797 2.69 5.797 5.996c0 3.306-2.601 5.996-5.797 5.996zm.205-15.838c-5.431 0-9.849 4.418-9.849 9.848 0 5.43 4.418 9.848 9.849 9.848 5.43 0 9.848-4.418 9.848-9.848 0-5.43-4.418-9.848-9.848-9.848zm16.204 69.11H35.161v-7.25h6.636c1.06 0 1.922-.862 1.922-1.923V47.998c0-1.06-.862-1.923-1.922-1.923h-8.222v-5.297h22.149v31.681c0 1.061.863 1.923 1.924 1.923h6.778v7.25zM67.708 71.1a1.905 1.905 0 00-1.359-.564H59.57V38.855c0-1.06-.863-1.923-1.923-1.923H31.652a1.925 1.925 0 00-1.923 1.923v9.143c0 1.06.862 1.922 1.923 1.922h8.221v20.616h-6.636a1.925 1.925 0 00-1.923 1.923v11.096c0 1.06.862 1.924 1.923 1.924h33.112c1.06 0 1.922-.864 1.922-1.924V72.459c0-.514-.199-.997-.563-1.359zM49 94.153C24.102 94.153 3.846 73.898 3.846 49S24.102 3.845 49 3.845c24.899 0 45.155 20.257 45.155 45.155S73.899 94.153 49 94.153zM49 0C21.982 0 0 21.981 0 49s21.982 48.999 49 48.999c27.019 0 49-21.98 49-48.999C98 21.981 76.019 0 49 0z"
            fill="currentColor"
        />
        {/* <defs>
            <path
                d="M48.017 28.36c-3.196 0-5.798-2.69-5.798-5.996 0-3.306 2.602-5.996 5.798-5.996s5.797 2.69 5.797 5.996c0 3.306-2.601 5.996-5.797 5.996zm.205-15.838c-5.431 0-9.849 4.418-9.849 9.848 0 5.43 4.418 9.848 9.849 9.848 5.43 0 9.848-4.418 9.848-9.848 0-5.43-4.418-9.848-9.848-9.848zm16.204 69.11H35.161v-7.25h6.636c1.06 0 1.922-.862 1.922-1.923V47.998c0-1.06-.862-1.923-1.922-1.923h-8.222v-5.297h22.149v31.681c0 1.061.863 1.923 1.924 1.923h6.778v7.25zM67.708 71.1a1.905 1.905 0 00-1.359-.564H59.57V38.855c0-1.06-.863-1.923-1.923-1.923H31.652a1.925 1.925 0 00-1.923 1.923v9.143c0 1.06.862 1.922 1.923 1.922h8.221v20.616h-6.636a1.925 1.925 0 00-1.923 1.923v11.096c0 1.06.862 1.924 1.923 1.924h33.112c1.06 0 1.922-.864 1.922-1.924V72.459c0-.514-.199-.997-.563-1.359zM49 94.153C24.102 94.153 3.846 73.898 3.846 49S24.102 3.845 49 3.845c24.899 0 45.155 20.257 45.155 45.155S73.899 94.153 49 94.153zM49 0C21.982 0 0 21.981 0 49s21.982 48.999 49 48.999c27.019 0 49-21.98 49-48.999C98 21.981 76.019 0 49 0z"
                id="a"
                fill="currentColor"
            />
        </defs> */}
        {/* <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
                <use xlink:href="#a" />
            </mask>
            <use fill="#1D1D1B" xlink:href="#a" />
            <g mask="url(#b)" fill="#000">
                <path d="M-1-1H99V99H-1z" />
            </g>
        </g> */}
    </svg>
);

export default IconInfo;

import React from 'react';

const IconDownload = (
    <svg viewBox="0 0 100 100">
        <path
            d="M34.21,48a1.67,1.67,0,0,0-2.39,0,1.69,1.69,0,0,0,0,2.39L48.73,67.3a1.7,1.7,0,0,0,1.2.5H50a1.61,1.61,0,0,0,1.33-.72L68.11,50.32a1.69,1.69,0,0,0-2.39-2.4l-14,14V1.7a1.69,1.69,0,1,0-3.38,0V62.08Zm0,0M61.86,27.12a1.69,1.69,0,1,0,0,3.38h22V96.62H16.11V30.5h22a1.69,1.69,0,1,0,0-3.38H12.71V100H87.29V27.12Zm0,0"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconDownload;

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import buildingsReducer from './buildingsReducer';
import claimsReducer from './claimsReducer';
import dataReducer from './dataReducer';
import ordersReducer from './ordersReducer';
import permanentsReducer from './permanentsReducer';
import servicesReducer from './servicesReducer';
import usersReducer from './usersReducer';

const rootReducer = combineReducers({
    authState: authReducer,
    buildingsState: buildingsReducer,
    claimsState: claimsReducer,
    dataState: dataReducer,
    ordersState: ordersReducer,
    permanentsState: permanentsReducer,
    servicesState: servicesReducer,
    usersState: usersReducer
});

export default rootReducer;

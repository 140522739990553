import React from 'react';
import PropTypes from 'prop-types';
import { Block, Group } from '@webfox-sc/core';
import IconEdit from '../../assets/icons/IconEdit';
import TextXL from '../TextXL';
import IconButton from '../IconButton';
import { COLORS } from '../../styles/common';

const FormPanel = ({ title, showEditButton, onClickEditButton, children }) => {
    return (
        <Block background={COLORS.DARK_BLUE} padding="50px" paddingBottom="60px">
            {(title || showEditButton) && (
                <Group justify="space-between" paddingBottom="20px">
                    <TextXL>
                        <strong>{title}</strong>
                    </TextXL>
                    {showEditButton && <IconButton icon={IconEdit} onClick={onClickEditButton} />}
                </Group>
            )}
            {children}
        </Block>
    );
};

FormPanel.propTypes = {
    title: PropTypes.string,
    showEditButton: PropTypes.bool,
    onClickEditButton: PropTypes.func,
    children: PropTypes.any
};

FormPanel.defaultProps = {
    title: '',
    showEditButton: false,
    onClickEditButton: () => {},
    children: null
};

export default FormPanel;

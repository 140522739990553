/* xeslint-disable */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import IconDownload from '../../assets/icons/IconDownload';
import TextM from '../../atoms/TextM';
import FormButton from '../../atoms/FormButton';
import FormDatePicker from '../../atoms/FormDatePicker';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import { STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';
import DateUtil from '../../utils/DateUtil';
import exportUtil from '../../utils/exportUtil';

const StyledShader = styled.div({
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const StyledDialog = styled.div({
    position: 'absolute',
    zIndex: 11,
    width: 'calc(100vw - 60px)',
    maxWidth: '800px',
    boxSizing: 'border-box',
    background: 'white'
});

const StyledHeading = styled.div({
    ...STYLE_TEXT_M,
    fontSize: '40px',
    lineHeight: 'normal',
    textAlign: 'center',
    color: COLORS.DARK_BLUE
});

const PermanentsDownloadDialog = ({ show, onClickDownload, onClickCancel }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [format, setFormat] = useState(exportUtil.formatOptions[0].value);
    const [csvData, setCSVData] = useState(null);

    const { permanents, services, buildings } = useSelector(({ dataState }) => dataState);

    useEffect(() => {
        if (show) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [show]);

    if (!show) {
        return null;
    }

    /**
     * Download Logic
     */
    const handleOnClickDownload = () => {
        /**
         * logic ...
         */
        if (permanents && services && buildings) {
            const orderData = [];
            const allMonthDates = exportUtil.getAllMonthDates(startDate, endDate);
            Object.values(permanents).forEach((order) => {
                allMonthDates.forEach((monthDates) => {
                    // skip entries that expired before the 16th of the month
                    if (order.expiryDate) {
                        const expiryDate = new Date(order.expiryDate);
                        if (expiryDate >= monthDates[0] && expiryDate < monthDates[1] && expiryDate.getDate() < 16) {
                            return;
                        }
                    }
                    const factor = exportUtil.getPermanentQuantity(order, monthDates);
                    if (factor > 0) {
                        const exportData = exportUtil.getDownloadDataEntry(
                            order,
                            { services, buildings, factor, monthDates },
                            'permanents'
                        );
                        orderData.push(exportData);
                    }
                });
            });
            const dataArray = exportUtil.formatDownloadData(orderData, 'permanents', format);
            setCSVData(dataArray);
        }
        /**
         * callback for closing dialog
         */
    };

    const handleOnClickDownloadLink = () => {
        /**
         * callback for closing dialog
         */
        setCSVData(null);
        onClickDownload();
    };

    return (
        <StyledShader>
            <StyledDialog>
                <Block padding="40px">
                    <Block justify="center">
                        <StyledHeading>CSV Download</StyledHeading>
                    </Block>

                    <Block justify="center" paddingTop="30px">
                        <TextM color={COLORS.DARK_GREY}>
                            <strong>Zeitraum</strong>
                        </TextM>
                    </Block>

                    <Block paddingTop="10px" position="relative" zIndex={10}>
                        <Group spacing="10px" alignItems="center">
                            <GroupItem>
                                <TextM color={COLORS.DARK_GREY}>von</TextM>
                            </GroupItem>
                            <GroupItem grow>
                                <FormDatePicker
                                    layout="white"
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                />
                            </GroupItem>
                            <TextM color={COLORS.DARK_GREY}>bis</TextM>
                            <GroupItem grow>
                                <FormDatePicker
                                    layout="white"
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    minDate={startDate}
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                />
                            </GroupItem>
                        </Group>
                    </Block>

                    <Block justify="center" paddingTop="30px">
                        <TextM color={COLORS.DARK_GREY}>
                            <strong>Exportformat</strong>
                        </TextM>
                    </Block>

                    <Block paddingTop="10px" justify="center">
                        <FormNativeSelect
                            layout="white"
                            options={exportUtil.formatOptions}
                            value={format}
                            onChange={(e) => {
                                setFormat(e.target.value);
                            }}
                        />
                    </Block>

                    <Group paddingTop="40px" nowrap spacing="10px" justify="center">
                        <FormButton
                            layout="white"
                            label="Abbrechen"
                            icon={IconClose}
                            iconSize="small"
                            onClick={onClickCancel}
                        />
                        {csvData ? (
                            <CSVLink
                                data={csvData}
                                separator={format === 'semi_utf-8' ? ';' : ','}
                                filename={`permanents_${DateUtil.getFileTimeFormatted()}_${format}.csv`}
                                className="hidden"
                                target="_blank"
                            >
                                <FormButton
                                    layout="white"
                                    label="Datei speichern"
                                    icon={IconDownload}
                                    iconSize="small"
                                    onClick={handleOnClickDownloadLink}
                                />
                            </CSVLink>
                        ) : (
                            <FormButton
                                layout="white"
                                label="Download"
                                icon={IconCheck}
                                iconSize="small"
                                disabled={!startDate || !endDate}
                                onClick={handleOnClickDownload}
                            />
                        )}
                    </Group>
                </Block>
            </StyledDialog>
        </StyledShader>
    );
};

PermanentsDownloadDialog.propTypes = {
    show: PropTypes.bool,
    onClickDownload: PropTypes.func,
    onClickCancel: PropTypes.func
};

PermanentsDownloadDialog.defaultProps = {
    show: false,
    onClickDownload: () => {},
    onClickCancel: () => {}
};

export default PermanentsDownloadDialog;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { Provider as ReduxProvider } from 'react-redux';
import rootReducer from './redux/reducers/rootReducer';
import './scss/app.scss';
import App from './App';

const middleware = [thunk];
if (process.env.NODE_ENV === 'development') {
    const loggerMiddleware = createLogger({
        collapsed: true
    });
    middleware.push(loggerMiddleware);
}

const store = createStore(rootReducer, {}, applyMiddleware(...middleware));

ReactDOM.render(
    <ReduxProvider store={store}>
        <App />
    </ReduxProvider>,
    document.getElementById('root')
);

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';
import { COLORS } from '../../styles/common';
import { STYLE_TEXT_M_BOLD } from '../../styles/type';

const theme = ({ layout, iconSize, width, minWidth, maxWidth }) => {
    let rootStyle = {
        width,
        minWidth: minWidth || '30px',
        maxWidth,
        minHeight: '54px',
        border: '1px solid white',
        background: 'transparent',
        padding: '0 22px',
        outline: 0,
        transition: 'background-color 0.2s ease, border-color 0.2s ease',
        svg: {
            width: iconSize === 'small' ? '24px' : '30px',
            height: iconSize === 'small' ? '24px' : '30px'
        },
        span: {
            display: 'inline-block',
            minWidth: '30px',
            marginLeft: iconSize === 'small' ? '5px' : '10px',
            ...STYLE_TEXT_M_BOLD,
            color: layout === 'primary' || layout === 'white' ? COLORS.DARK_BLUE : 'white',
            textTransform: 'uppercase'
        },
        '&[data-with-icon]': {
            paddingLeft: '12px'
        },
        '&[data-with-icon-only]': {
            width: '54px',
            height: '54px',
            padding: 0
        },
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.14)'
        },
        '&:active': {
            background: COLORS.WHITE,
            span: {
                color: COLORS.DARK_BLUE
            }
        },
        '&[data-active]': {
            background: COLORS.DARK_BLUE,
            borderColor: COLORS.DARK_BLUE,
            span: {
                color: COLORS.WHITE
            }
        }
    };

    if (layout === 'primary') {
        rootStyle = {
            ...rootStyle,
            border: 'none',
            background: 'white',
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.85)'
            }
        };
    }

    if (layout === 'white') {
        rootStyle = {
            ...rootStyle,
            border: `1px solid ${COLORS.DARK_BLUE}`,
            background: 'white',
            '&:hover': {
                background: 'rgba(3, 100, 161, 0.14)'
            },
            '&:active': {
                background: COLORS.DARK_BLUE,
                span: {
                    color: COLORS.WHITE
                }
            }
        };
    }

    return {
        root: rootStyle
    };
};

const FormButton = (props) => {
    return <Button {...props} theme={theme(props)} />;
};

FormButton.propTypes = {
    ...Button.propTypes,
    layout: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.any,
    iconSize: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string
};

FormButton.defaultProps = {
    ...Button.defaultProps,
    layout: null,
    label: '',
    icon: null,
    iconSize: '',
    active: false,
    disabled: false,
    block: false,
    width: null,
    minWidth: null,
    maxWidth: null
};

export default FormButton;

const permanentIntervals = [
    {
        value: 'day',
        label: 'täglich'
    },
    {
        value: 'week',
        label: 'wöchentlich'
    },
    {
        value: 'month',
        label: 'monatlich'
    },
    {
        value: 'quarter',
        label: 'je Quartal'
    },
    {
        value: 'year',
        label: 'jährlich'
    }
];

export default permanentIntervals;

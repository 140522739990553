/* xeslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import IconArrowRight from '../../assets/icons/IconArrowRight';
import TextS from '../../atoms/TextS';
import FormPanelGroup from '../../atoms/FormPanelGroup';
import FormPanel from '../../atoms/FormPanel';
import FormButton from '../../atoms/FormButton';
import FormLabel from '../../atoms/FormLabel';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormInput from '../../atoms/FormInput';
import FormTextarea from '../../atoms/FormTextarea';
import FormStaticField from '../../atoms/FormStaticField';
import FormDatePicker from '../../atoms/FormDatePicker';
import { useOrderDraft, useOrderServices, useOrderAssignees } from '../../hooks/orders';
import { useUser } from '../../hooks/user';
import { useBuildings } from '../../hooks/buildings';
import FormatUtil from '../../utils/FormatUtil';
import { USER_ROLES } from '../../global/constants';

const OrderCreateService = ({ onClickNext }) => {
    const { userRoleName } = useUser();
    const { orderDraft, orderDraftService, orderDraftMonthlyPrice, updateOrderDraft } = useOrderDraft();
    const { orderServiceDropdownOptions } = useOrderServices();
    const { assigneeDropdownOptions } = useOrderAssignees();
    const { buildingDropdownOptions } = useBuildings();

    const [isButtonNextClicked, setIsButtonNextClicked] = useState(false);

    return (
        <>
            <FormPanelGroup>
                <FormPanel title="Leistung">
                    <Grid spacing="20px">
                        <GridItem cols="6">
                            <FormLabel top bold>
                                Leistungsbeschreibung *
                            </FormLabel>
                            <FormNativeSelect
                                options={orderServiceDropdownOptions}
                                value={orderDraft.serviceId}
                                error={isButtonNextClicked && !orderDraft.serviceId}
                                onChange={(e) => {
                                    updateOrderDraft({
                                        serviceId: e.target.value
                                    });
                                }}
                            />
                        </GridItem>
                        <GridItem cols="2">
                            <FormLabel top bold>
                                Menge *
                            </FormLabel>
                            <FormInput
                                ariaLabel="Menge"
                                type="number"
                                value={orderDraft.quantity}
                                error={isButtonNextClicked && !orderDraft.quantity}
                                onChange={(e) => {
                                    updateOrderDraft({
                                        quantity: parseFloat(e.target.value)
                                    });
                                }}
                            />
                        </GridItem>
                        <GridItem cols="2">
                            <FormLabel top bold>
                                Einheit
                            </FormLabel>
                            <FormStaticField value={orderDraftService ? orderDraftService.unit : ''} />
                        </GridItem>
                        <GridItem cols="2">
                            <FormLabel top bold>
                                Gesamtpreis EUR
                            </FormLabel>
                            <FormStaticField
                                value={
                                    orderDraftMonthlyPrice ? FormatUtil.getDecimalFormatted(orderDraftMonthlyPrice) : ''
                                }
                            />
                            <Block paddingTop="7px">
                                <TextS>exklusive 7% SRE Aufschlag</TextS>
                            </Block>
                        </GridItem>

                        <GridItem cols="4">
                            <FormLabel top bold>
                                Ort *
                            </FormLabel>
                            <FormInput
                                ariaLabel="Ort"
                                placeholder="Gebäude- und Raumnummer"
                                value={orderDraft.location}
                                error={isButtonNextClicked && !orderDraft.location}
                                onChange={(e) => {
                                    updateOrderDraft({
                                        location: e.target.value
                                    });
                                }}
                            />
                        </GridItem>
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Standort *
                            </FormLabel>
                            <FormNativeSelect
                                options={buildingDropdownOptions}
                                value={orderDraft.buildingId}
                                error={isButtonNextClicked && !orderDraft.buildingId}
                                onChange={(e) => {
                                    updateOrderDraft({
                                        buildingId: e.target.value
                                    });
                                }}
                            />
                        </GridItem>
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Wunschtermin
                            </FormLabel>
                            <FormDatePicker
                                popperPlacement="top-start"
                                minDate={Date.now()}
                                dateFormat="dd.MM.yyyy"
                                selected={orderDraft.datePlanned ? new Date(orderDraft.datePlanned) : null}
                                onChange={(date) => {
                                    updateOrderDraft({
                                        datePlanned: date
                                    });
                                }}
                            />
                        </GridItem>
                    </Grid>
                    {(userRoleName === USER_ROLES.ADMIN ||
                        userRoleName === USER_ROLES.LOCATION_MANAGER ||
                        userRoleName === USER_ROLES.SERVICE_MANAGER) && (
                        <Block paddingTop="20px">
                            <Grid spacing="20px">
                                <GridItem cols="4">
                                    <Block>
                                        <FormLabel top bold>
                                            Bearbeitung
                                        </FormLabel>
                                        <FormNativeSelect
                                            options={assigneeDropdownOptions}
                                            value={orderDraft.assigneeId}
                                            onChange={(e) => {
                                                updateOrderDraft({
                                                    assigneeId: e.target.value
                                                });
                                            }}
                                        />
                                    </Block>
                                </GridItem>
                            </Grid>
                        </Block>
                    )}

                    <Block paddingTop="20px">
                        <Grid spacing="20px">
                            <GridItem cols="8">
                                <FormLabel top bold>
                                    Bemerkung
                                </FormLabel>
                                <Block>
                                    <FormTextarea
                                        value={orderDraft.message}
                                        placeholder="Bitte geben Sie eine detaillierte Leistungsbeschreibung und sonstige Wünsche an."
                                        height="157px"
                                        onChange={(e) => {
                                            updateOrderDraft({
                                                message: e.target.value
                                            });
                                        }}
                                    />
                                </Block>
                            </GridItem>
                        </Grid>
                    </Block>
                </FormPanel>
            </FormPanelGroup>

            <Block paddingTop="50px" justify="center">
                <FormButton
                    label="Weiter"
                    icon={IconArrowRight}
                    iconSize="small"
                    onClick={() => {
                        setIsButtonNextClicked(true);
                        onClickNext();
                    }}
                />
            </Block>
        </>
    );
};

OrderCreateService.propTypes = {
    onClickNext: PropTypes.func
};

OrderCreateService.defaultProps = {
    onClickNext: () => {}
};

export default OrderCreateService;

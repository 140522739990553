import React from 'react';
import PropTypes from 'prop-types';
import { Group } from '@webfox-sc/core';

const FormPanelGroup = ({ paddingTop, children }) => {
    return (
        <Group vertical spacing="20px" paddingTop={paddingTop}>
            {children}
        </Group>
    );
};

FormPanelGroup.propTypes = {
    paddingTop: PropTypes.string,
    children: PropTypes.any
};

FormPanelGroup.defaultProps = {
    paddingTop: null,
    children: null
};

export default FormPanelGroup;

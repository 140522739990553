import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Block } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import TextM from '../../atoms/TextM';
import TextL from '../../atoms/TextL';
import FormButton from '../../atoms/FormButton';
import FormInput from '../../atoms/FormInput';
import api from '../../api';

const AuthResetPage = () => {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isPasswordResetFailed, setIsPasswordResetFailed] = useState(false);

    const { search: locationSearch } = useLocation();
    const history = useHistory();

    const resetPassword = (e) => {
        e.preventDefault();
        const code = locationSearch.split('=')[1];
        if (!code) {
            return false;
        }
        console.log(code, password, password2);

        return api.strapiapi
            .resetPassword(code, password, password2)
            .then(() => {
                history.push('/');
            })
            .catch((err) => {
                console.log('error - resetPassword: ', err);
                setIsPasswordResetFailed(true);
            });
    };

    return (
        <PageContentBlock>
            <Block paddingTop="180px">
                <form onSubmit={(e) => resetPassword(e)}>
                    <Block width="100%">
                        <Block justify="center">
                            <TextL>
                                <strong>Passwort ändern</strong>
                            </TextL>
                        </Block>

                        <Block paddingTop="30px" justify="center">
                            <TextM>Bitte geben Sie Ihr neues Passwort ein:</TextM>
                        </Block>
                        <Block paddingTop="20px" justify="center">
                            <Block width="100%" maxWidth="600px">
                                <FormInput
                                    name="password"
                                    type="password"
                                    onChange={({ target }) => setPassword(target.value)}
                                />
                            </Block>
                        </Block>

                        <Block paddingTop="20px" justify="center">
                            <TextM>Bitte wiederholen Sie Ihr neues Passwort:</TextM>
                        </Block>
                        <Block paddingTop="20px" justify="center">
                            <Block width="100%" maxWidth="600px">
                                <FormInput
                                    name="password2"
                                    type="password"
                                    onChange={({ target }) => setPassword2(target.value)}
                                />
                            </Block>
                        </Block>

                        <Block paddingTop="20px" justify="center">
                            <FormButton type="submit" label="Passwort ändern" />
                        </Block>
                    </Block>

                    {isPasswordResetFailed && (
                        <Block justify="center" paddingTop="30px">
                            <TextM>Die Passworteingabe ist ungültig.</TextM>
                        </Block>
                    )}
                </form>
            </Block>
        </PageContentBlock>
    );
};

export default AuthResetPage;

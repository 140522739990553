import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useRoles() {
    const roles = useSelector(({ dataState }) => dataState.roles);
    const [roleDropdownOptions, setRoleDropdownOptions] = useState([]);

    useEffect(() => {
        if (roles) {
            const _roleDropdownOptions = [
                {
                    value: '',
                    label: 'Auswählen',
                    disabled: true
                }
            ];
            Object.values(roles).forEach((role) => {
                if (role.name !== 'Authenticated' && role.name !== 'Public') {
                    _roleDropdownOptions.push({
                        value: role.id,
                        label: role.name
                    });
                }
            });
            setRoleDropdownOptions(_roleDropdownOptions);
        }
    }, [roles]);

    return {
        roleDropdownOptions
    };
}

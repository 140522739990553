/* xeslint-disable */
const monthNamesDe = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
];

class DateUtil {
    /**
     * @return string 'DD.MM.YYYY'
     */
    static getDateFormatted(time) {
        if (!time) {
            return '';
        }
        const date = new Date(time);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const outputDay = day < 10 ? `0${String(day)}` : String(day);
        const outputMonth = month < 10 ? `0${String(month)}` : String(month);
        return `${outputDay}.${outputMonth}.${String(date.getFullYear())}`;
    }

    /**
     * @return string 'DD. [Monat] YYYY'
     */
    static getDateFormattedLong(time) {
        if (!time) {
            return '';
        }
        const date = new Date(time);
        const day = date.getDate();
        const month = date.getMonth();
        const outputDay = day < 10 ? `0${String(day)}` : String(day);
        const outputMonth = monthNamesDe[month];
        return `${outputDay}. ${outputMonth} ${String(date.getFullYear())}`;
    }

    /**
     * @return string 'HH:mm'
     */
    static getTimeFormatted(time) {
        if (!time) {
            return '';
        }
        const date = new Date(time);
        const outputHours = date.getHours() < 10 ? `0${String(date.getHours())}` : String(date.getHours());
        const outputMinutes = date.getMinutes() < 10 ? `0${String(date.getMinutes())}` : String(date.getMinutes());
        return `${outputHours}:${outputMinutes}`;
    }

    /**
     * @return string 'HH:mm:ss'
     */
    static getFullTimeFormatted(time) {
        if (!time) {
            return '';
        }
        const date = new Date(time);
        const outputSeconds = date.getSeconds() < 10 ? `0${String(date.getSeconds())}` : String(date.getSeconds());
        return `${DateUtil.getTimeFormatted(time)}:${outputSeconds}`;
    }

    /**
     * @return string 'DD.MM.YYYY HH:mm'
     */
    static getDateTimeFormatted(time) {
        if (!time) {
            return '';
        }
        return `${DateUtil.getDateFormatted(time)} ${DateUtil.getTimeFormatted(time)}`;
    }

    /**
     * @return string 'DD.MM.YYYY, HH:mm Uhr'
     */
    static getDateTimeFormattedExtended(time) {
        if (!time) {
            return '';
        }
        return `${DateUtil.getDateFormatted(time)}, ${DateUtil.getTimeFormatted(time)} Uhr`;
    }

    /**
     * @return string 'DD.MM.YYYY HH:mm:ss'
     */
    static getDateFullTimeFormatted(time) {
        if (!time) {
            return '';
        }
        return `${DateUtil.getDateFormatted(time)} ${DateUtil.getFullTimeFormatted(time)}`;
    }

    /**
     * @return string 'YYYY-MM-DD-HHmmss' of now
     */
    static getFileTimeFormatted() {
        return new Date().toISOString().split('.')[0].replace('T', '-').replace(/:/g, '');
    }

    /**
     * @return number
     */
    static getCurrentWeek() {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
    }
}

export default DateUtil;

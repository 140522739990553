import React from 'react';

const IconSort = (
    <svg viewBox="0 0 19 32">
        <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
            <path d="M9.5 3l6.5 9H3zM9.5 29l6.5-9H3z" />
        </g>
    </svg>
);

export default IconSort;

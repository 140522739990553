import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_TEXT_L } from '../../styles/type';

const StyledComponent = styled.div(() => {
    const style = {
        display: 'inline-block',
        ...STYLE_TEXT_L
    };
    return style;
});

const TextL = ({ children }) => {
    return <StyledComponent>{children}</StyledComponent>;
};

TextL.propTypes = {
    children: PropTypes.any
};

TextL.defaultProps = {
    children: ''
};

export default TextL;

/* xeslint-disable */
import React from 'react';
// import PropTypes from 'prop-types';
import { Checkbox } from '@webfox-sc/core';
import { COLORS } from '../../styles/common';

const theme = {
    checkbox: {
        height: '30px',
        width: '30px',
        background: 'transparent',
        border: `1px solid ${COLORS.WHITE}`,
        boxSizing: 'border-box',
        svg: {
            width: '20px',
            height: '20px',
            color: COLORS.WHITE
        },
        '&:hover': {
            background: COLORS.DARK_BLUE
        },
        '&[data-checked]': {
            background: COLORS.DARK_BLUE
        }
    }
};

const FormCheckbox = (props) => {
    return <Checkbox {...props} theme={theme} />;
};

FormCheckbox.propTypes = {
    ...Checkbox.propTypes
};

FormCheckbox.defaultProps = {
    ...Checkbox.defaultProps
};

export default FormCheckbox;

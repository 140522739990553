import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _at from 'lodash/at';
import _orderBy from 'lodash/orderBy';
import { setUsersSorting } from '../../redux/actions/usersActions';

export function useSettingsUsers() {
    const dispatch = useDispatch();
    const users = useSelector(({ dataState }) => dataState.users);
    const { isUsersRequest, usersSortingKey, usersSortingOrder } = useSelector(({ usersState }) => usersState);

    const usersDisplayStep = 50;
    const [usersDisplayMax, setUsersDisplayMax] = useState(usersDisplayStep);

    const [usersSorted, setUsersSorted] = useState([]);

    useEffect(() => {
        let _usersSorted = Object.values(users);

        /**
         * SORT
         */
        _usersSorted = _orderBy(_usersSorted, (object) => _at(object, [usersSortingKey]), [usersSortingOrder]);

        /**
         * SET
         */
        setUsersSorted(_usersSorted);
    }, [users, usersSortingKey, usersSortingOrder]);

    const handleSetUsersSortingKey = (sortingKey) => {
        let sortingOrder = 'asc';
        if (sortingKey === usersSortingKey) {
            sortingOrder = usersSortingOrder === 'asc' ? 'desc' : 'asc';
        }
        dispatch(setUsersSorting(sortingKey, sortingOrder));
    };

    const displayFurtherUsers = () => {
        setUsersDisplayMax(usersDisplayMax + usersDisplayStep);
    };

    return {
        users,
        isUsersInitialRequest: isUsersRequest && !Object.values(users).length,
        usersSorted,
        usersSortingKey,
        usersSortingOrder,
        usersDisplayMax,
        handleSetUsersSortingKey,
        displayFurtherUsers
    };
}

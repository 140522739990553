import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ children }) => {
    return <thead>{children}</thead>;
};

TableHeader.propTypes = {
    children: PropTypes.any
};

TableHeader.defaultProps = {
    children: null
};

export default TableHeader;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _at from 'lodash/at';
import _orderBy from 'lodash/orderBy';
import {
    setPermanentsSearchText,
    setPermanentsFilters,
    setPermanentsSorting,
    setPermanentsDisplayMax
} from '../../redux/actions/permanentsActions';
import { useUser } from '../../hooks/user';

export function usePermanentList() {
    const dispatch = useDispatch();
    const { userId, userRoleName } = useUser();
    const permanents = useSelector(({ dataState }) => dataState.permanents);

    const {
        isPermanentsRequest,
        permanentsSearchText,
        permanentsFilters,
        permanentsSortingKey,
        permanentsSortingOrder,
        permanentsDisplayMax
    } = useSelector(({ permanentsState }) => permanentsState);

    const permanentsDisplayStep = 10;

    const [permanentsFiltered, setPermanentsFiltered] = useState([]);

    useEffect(() => {
        /**
         * FILTER
         */
        let _permanentsFiltered = Object.values(permanents).filter((permanent) => {
            /**
             * NO FILTER STATUS
             */
            if (!permanentsFilters.length) {
                return true;
            }

            /**
             * FILTER STATUS
             */
            if (permanentsFilters.includes(permanent.filterStatus)) {
                return true;
            }

            return false;
        });

        /**
         * SEARCH TEXT
         */
        if (permanentsSearchText.trim()) {
            _permanentsFiltered = _permanentsFiltered.filter((permanent) => {
                return permanent.searchString.includes(permanentsSearchText.trim().toLowerCase());
            });
        }

        /**
         * SORT
         */
        _permanentsFiltered = _orderBy(_permanentsFiltered, (object) => _at(object, [permanentsSortingKey]), [
            permanentsSortingOrder
        ]);

        /**
         * SET
         */
        setPermanentsFiltered(_permanentsFiltered);
    }, [
        permanents,
        permanentsSearchText,
        permanentsFilters,
        permanentsSortingKey,
        permanentsSortingOrder,
        userId,
        userRoleName
    ]);

    /**
     * change search text
     * @param {String} searchText
     */
    const handleChangePermanentsSearchText = (searchText) => {
        dispatch(setPermanentsSearchText(searchText));

        /**
         * reset num of displayed items
         */
        dispatch(setPermanentsDisplayMax(permanentsDisplayStep));
    };

    const togglePermanentsFilter = (filter) => {
        let permanentListFiltersNew = [];
        if (permanentsFilters.includes(filter)) {
            permanentListFiltersNew = permanentsFilters.filter((f) => f !== filter);
        } else {
            permanentListFiltersNew = [...permanentsFilters, filter];
        }
        dispatch(setPermanentsFilters(permanentListFiltersNew));
    };

    const handleSetPermanentsSortingKey = (sortingKey) => {
        let sortingOrder = 'asc';
        if (sortingKey === permanentsSortingKey) {
            sortingOrder = permanentsSortingOrder === 'asc' ? 'desc' : 'asc';
        }
        dispatch(setPermanentsSorting(sortingKey, sortingOrder));
    };

    const displayFurtherPermanents = () => {
        dispatch(setPermanentsDisplayMax(permanentsDisplayMax + permanentsDisplayStep));
    };

    return {
        permanents,
        isPermanentsInitialRequest: isPermanentsRequest && !Object.values(permanents).length,
        permanentsSearchText,
        permanentsFiltered,
        permanentsFilters,
        permanentsSortingKey,
        permanentsSortingOrder,
        permanentsDisplayMax,
        handleChangePermanentsSearchText,
        togglePermanentsFilter,
        handleSetPermanentsSortingKey,
        displayFurtherPermanents
    };
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import FormButton from '../../atoms/FormButton';
import Table from '../../atoms/Table';
import TableBody from '../../atoms/TableBody';
import TableHeader from '../../atoms/TableHeader';
import TableRow from '../../atoms/TableRow';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import TableBodyCell from '../../atoms/TableBodyCell';
import TextM from '../../atoms/TextM';
import PermanentsDownloadDialog from '../PermanentsDownloadDialog';
import PermanentListFilters from './PermanentListFilters';
import { fetchPermanents } from '../../redux/actions/permanentsActions';
import { usePermanentList } from './PermanentList.hooks';
import { useUser } from '../../hooks/user';
import DateUtil from '../../utils/DateUtil';
import { PERMANENT_STATUS_LABELS, USER_ROLES } from '../../global/constants';

const PermanentList = () => {
    const { userIsLoggedIn, userRoleName } = useUser();
    const dispatch = useDispatch();
    const history = useHistory();

    const [showDownloadDialog, setShowDownloadDialog] = useState(false);

    const {
        isPermanentsInitialRequest,
        permanentsSortingKey,
        permanentsSortingOrder,
        permanentsFiltered,
        permanentsDisplayMax,
        handleSetPermanentsSortingKey,
        displayFurtherPermanents
    } = usePermanentList();

    useEffect(() => {
        if (userIsLoggedIn) {
            dispatch(fetchPermanents());
        }
    }, [userIsLoggedIn, dispatch]);

    if (!userIsLoggedIn) {
        return null;
    }

    if (isPermanentsInitialRequest) {
        return null;
    }

    return (
        <>
            <PageContentBlock>
                <PermanentListFilters />

                {permanentsFiltered.length === 0 && (
                    <Block paddingTop="50px" justify="center">
                        <TextM>
                            <strong>Es wurden keine Daueraufträge gefunden.</strong>
                        </TextM>
                    </Block>
                )}

                {permanentsFiltered.length > 0 && (
                    <>
                        <Block paddingTop="50px">
                            <Grid columns="3" alignItems="center">
                                <GridItem />
                                <GridItem>
                                    <Block justify="center">
                                        <TextM>
                                            <strong>
                                                {permanentsFiltered.length}{' '}
                                                {permanentsFiltered.length === 1 ? 'Dauerauftrag' : 'Daueraufträge'}
                                            </strong>
                                        </TextM>
                                    </Block>
                                </GridItem>
                                {(userRoleName === USER_ROLES.ADMIN ||
                                    userRoleName === USER_ROLES.LOCATION_MANAGER ||
                                    userRoleName === USER_ROLES.SERVICE_MANAGER) && (
                                    <GridItem>
                                        <Block justify="flex-end">
                                            <div>
                                                <FormButton
                                                    label="CSV Download"
                                                    onClick={() => setShowDownloadDialog(true)}
                                                />
                                            </div>
                                        </Block>
                                    </GridItem>
                                )}
                            </Grid>
                        </Block>

                        <Block paddingTop="50px">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell
                                            width="10%"
                                            label="ID"
                                            order={permanentsSortingKey === 'entryId' ? permanentsSortingOrder : null}
                                            onClick={() => handleSetPermanentsSortingKey('entryId')}
                                        />
                                        <TableHeaderCell
                                            width="12%"
                                            label="Erstellt am"
                                            order={permanentsSortingKey === 'createdAt' ? permanentsSortingOrder : null}
                                            onClick={() => handleSetPermanentsSortingKey('createdAt')}
                                        />
                                        <TableHeaderCell
                                            width="12%"
                                            label="Startdatum"
                                            order={permanentsSortingKey === 'startDate' ? permanentsSortingOrder : null}
                                            onClick={() => handleSetPermanentsSortingKey('startDate')}
                                        />
                                        <TableHeaderCell
                                            width="12%"
                                            label="Ablaufdatum"
                                            order={
                                                permanentsSortingKey === 'expiryDate' ? permanentsSortingOrder : null
                                            }
                                            onClick={() => handleSetPermanentsSortingKey('expiryDate')}
                                        />
                                        <TableHeaderCell
                                            label="Bearbeitung"
                                            order={
                                                permanentsSortingKey === 'assigneeName'
                                                    ? permanentsSortingOrder
                                                    : null
                                            }
                                            onClick={() => handleSetPermanentsSortingKey('assigneeName')}
                                        />
                                        <TableHeaderCell
                                            width="15%"
                                            label="Auftragstyp"
                                            order={permanentsSortingKey === 'type' ? permanentsSortingOrder : null}
                                            onClick={() => handleSetPermanentsSortingKey('type')}
                                        />
                                        <TableHeaderCell
                                            width="12%"
                                            label="Status"
                                            order={
                                                permanentsSortingKey === 'listStatus' ? permanentsSortingOrder : null
                                            }
                                            onClick={() => handleSetPermanentsSortingKey('listStatus')}
                                        />
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {permanentsFiltered.slice(0, permanentsDisplayMax).map((permanent) => {
                                        return (
                                            <TableRow
                                                key={permanent.id}
                                                href={`/permanents/${permanent.id}`}
                                                onClick={() => history.push(`/permanents/${permanent.id}`)}
                                            >
                                                <TableBodyCell layout="text">{permanent.entryId}</TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {DateUtil.getDateFormatted(permanent.createdAt)}
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {DateUtil.getDateFormatted(permanent.startDate)}
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {DateUtil.getDateFormatted(permanent.expiryDate)}
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {permanent.assigneeName || ''}
                                                </TableBodyCell>
                                                <TableBodyCell layout="text">{permanent.type}</TableBodyCell>
                                                <TableBodyCell layout="text">
                                                    {PERMANENT_STATUS_LABELS[permanent.listStatus]}
                                                </TableBodyCell>
                                                <TableBodyCell layout="info-icon" />
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Block>

                        {permanentsDisplayMax < permanentsFiltered.length && (
                            <Block paddingTop="10px" justify="center">
                                <FormButton label="Mehr anzeigen" onClick={displayFurtherPermanents} />
                            </Block>
                        )}
                    </>
                )}
            </PageContentBlock>
            <PermanentsDownloadDialog
                show={showDownloadDialog}
                onClickDownload={() => setShowDownloadDialog(false)}
                onClickCancel={() => setShowDownloadDialog(false)}
            />
        </>
    );
};

PermanentList.propTypes = {};
PermanentList.defaultProps = {};

export default PermanentList;

/* eslint-disable */
import { REDUX_ACTIONS } from '../../global/constants';

const initialState = {
    isUsersRequest: false,
    usersSortingKey: 'username',
    usersSortingOrder: 'asc'
};

function usersReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_USERS:
            return {
                ...state,
                isUsersRequest: true
            };

        case REDUX_ACTIONS.RECIEVE_USERS:
        case REDUX_ACTIONS.REQUEST_USERS_ERROR:
            return {
                ...state,
                isUsersRequest: false
            };

        case REDUX_ACTIONS.SET_USERS_SORTING:
            return {
                ...state,
                usersSortingKey: action.usersSortingKey,
                usersSortingOrder: action.usersSortingOrder
            };

        /**
         * logout
         */
        case REDUX_ACTIONS.LOGOUT:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default usersReducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Group } from '@webfox-sc/core';
import FormButton from '../../atoms/FormButton';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import TextM from '../../atoms/TextM';
import DateUtil from '../../utils/DateUtil';
import exportUtil from '../../utils/exportUtil';

const CSVDownloader = ({ data, type }) => {
    const { services, buildings } = useSelector(({ dataState }) => dataState);
    const [csvData, setCSVData] = useState(null);
    const [format, setFormat] = useState(exportUtil.formatOptions[0].value);

    if (data && services && buildings) {
        const generateDownload = () => {
            const downloadData = data.map((entry) =>
                exportUtil.getDownloadDataEntry(entry, { services, buildings }, type)
            );
            const dataArray = exportUtil.formatDownloadData(downloadData, type, format);
            setCSVData(dataArray);
        };

        return (
            <>
                {csvData ? (
                    <CSVLink
                        data={csvData}
                        separator={format === 'semi_utf-8' ? ';' : ','}
                        filename={`${type}_${DateUtil.getFileTimeFormatted()}_${format}.csv`}
                        className="hidden"
                        target="_blank"
                    >
                        <FormButton label="Datei speichern" onClick={() => setCSVData(null)} />
                    </CSVLink>
                ) : (
                    <Group spacing="12px" alignItems="center" justify="flex-end">
                        <TextM>Exportformat</TextM>
                        <FormNativeSelect
                            options={exportUtil.formatOptions}
                            value={format}
                            onChange={(e) => {
                                setFormat(e.target.value);
                            }}
                        />
                        <FormButton label="CSV Download" onClick={() => generateDownload()} />
                    </Group>
                )}
            </>
        );
    }
    return null;
};

CSVDownloader.propTypes = {
    data: PropTypes.any,
    type: PropTypes.string
};

CSVDownloader.defaultProps = {
    data: [],
    type: 'data'
};

export default CSVDownloader;

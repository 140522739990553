import React from 'react';

const IconTypeBodenreinigung = (
    <svg viewBox="0 0 100 100">
        <path
            d="M79.469 88.733V71.029h1.614v-1.61c0-4.437-3.62-8.046-8.07-8.046H60.099V6.652C60.1 3.1 57.204.214 53.643.214c-3.561 0-6.457 2.887-6.457 6.438v54.72H34.273c-4.449 0-8.07 3.61-8.07 8.048v1.61h1.614v17.703c0 6.213 5.07 11.266 11.299 11.266h25.826v-1.9c2.31 1.2 5.086 1.9 8.07 1.9H87.54V96.78c-4.451 0-8.071-3.61-8.071-8.047zM50.415 6.652a3.227 3.227 0 013.228-3.22 3.227 3.227 0 013.228 3.22v46.673h-6.456V6.652zm0 54.72h6.456v-4.828h-6.456v4.829zm-16.142 3.22h38.74a4.85 4.85 0 014.566 3.218H29.707a4.852 4.852 0 014.566-3.219zm4.843 32.188c-4.45 0-8.07-3.61-8.07-8.047V71.029h8.07v17.704c0 3.152 1.308 6 3.406 8.047h-3.406zm33.897 0c-6.23 0-11.3-3.61-11.3-8.047h-3.228c0 3.214.696 6.034 1.852 8.047h-9.922c-4.45 0-8.071-3.61-8.071-8.047V71.029H68.17v6.438h3.228v-6.438h4.843v17.704c0 3.15 1.303 6.002 3.398 8.047h-6.626zM68.17 83.905h3.228v-3.22H68.17v3.22zm-56.494-1.61c0 7.987 6.517 14.485 14.527 14.485V100C11.963 100 .377 88.445.377 74.247h3.228c0 6.287 2.594 11.977 6.77 16.07a17.57 17.57 0 01-1.928-8.023h3.229zM90.768 100V96.78h8.07V100h-8.07zM14.904 79.076c0-2.662 2.172-4.828 4.842-4.828 2.67 0 4.843 2.166 4.843 4.828 0 2.663-2.173 4.829-4.843 4.829s-4.842-2.166-4.842-4.829zm6.456 0a1.615 1.615 0 00-3.228 0 1.614 1.614 0 003.228 0zm-6.456-19.313c-3.561 0-6.457-2.887-6.457-6.438 0-3.55 2.896-6.437 6.457-6.437 3.56 0 6.456 2.887 6.456 6.437 0 3.551-2.895 6.438-6.456 6.438zm0-9.657a3.227 3.227 0 00-3.228 3.22 3.226 3.226 0 003.228 3.218 3.226 3.226 0 003.228-3.219 3.227 3.227 0 00-3.228-3.219zm-6.457 28.97v-3.218h3.229v3.218H8.447zM.377 71.03V67.81h3.228v3.22H.377zm0-6.438v-3.218h3.228v3.218H.377z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconTypeBodenreinigung;

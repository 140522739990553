import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_HEADING_M } from '../../styles/type';

const StyledComponent = styled.div({
    ...STYLE_HEADING_M
});

const HeadingM = ({ children }) => {
    return <StyledComponent>{children}</StyledComponent>;
};

HeadingM.propTypes = {
    children: PropTypes.any
};

HeadingM.defaultProps = {
    children: ''
};

export default HeadingM;

/* xeslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import IconArrowRight from '../../assets/icons/IconArrowRight';
import TextXL from '../../atoms/TextXL';
import FormPanel from '../../atoms/FormPanel';
import FormButton from '../../atoms/FormButton';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormPanelBilling from '../../components/FormPanelBilling';
import { usePermanentDraft } from '../../hooks/permanents';

const PermanentCreateDetails = ({ onClickNext }) => {
    const { permanentDraft, updatePermanentDraft, updatePermanentDraftContactPerson } = usePermanentDraft();
    const [isButtonNextClicked, setIsButtonNextClicked] = useState(false);

    const { contactPersonList } = permanentDraft;
    const contactPerson = contactPersonList[0] || {};

    return (
        <>
            <Group vertical spacing="20px">
                <FormPanelBilling
                    billing={permanentDraft.billing}
                    isEditMode
                    isValidationMode={isButtonNextClicked}
                    onChangeBilling={(billingDraft) =>
                        updatePermanentDraft({
                            billing: billingDraft
                        })
                    }
                />

                <FormPanel>
                    <TextXL>
                        <strong>Kfm. Ansprechpartner</strong>
                    </TextXL>

                    <Block paddingTop="20px">
                        <Grid spacing="20px">
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    Vorname *
                                </FormLabel>
                                <FormInput
                                    ariaLabel="Vorname"
                                    value={contactPerson.first_name}
                                    error={isButtonNextClicked && !contactPerson.first_name}
                                    onChange={(e) => {
                                        updatePermanentDraftContactPerson({
                                            first_name: e.target.value
                                        });
                                    }}
                                />
                            </GridItem>
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    Nachname *
                                </FormLabel>
                                <FormInput
                                    ariaLabel="Nachname"
                                    value={contactPerson.last_name}
                                    error={isButtonNextClicked && !contactPerson.last_name}
                                    onChange={(e) => {
                                        updatePermanentDraftContactPerson({
                                            last_name: e.target.value
                                        });
                                    }}
                                />
                            </GridItem>
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    E-Mail *
                                </FormLabel>
                                <FormInput
                                    ariaLabel="E-Mail"
                                    value={contactPerson.email}
                                    error={isButtonNextClicked && !contactPerson.email}
                                    onChange={(e) => {
                                        updatePermanentDraftContactPerson({
                                            email: e.target.value
                                        });
                                    }}
                                />
                            </GridItem>
                            <GridItem cols="4">
                                <FormLabel top bold>
                                    Abteilung *
                                </FormLabel>
                                <FormInput
                                    ariaLabel="Abteilung"
                                    value={contactPerson.department}
                                    error={isButtonNextClicked && !contactPerson.department}
                                    onChange={(e) => {
                                        updatePermanentDraftContactPerson({
                                            department: e.target.value
                                        });
                                    }}
                                />
                            </GridItem>
                        </Grid>
                    </Block>
                </FormPanel>
            </Group>

            <Block paddingTop="50px" justify="center">
                <FormButton
                    label="Weiter"
                    icon={IconArrowRight}
                    iconSize="small"
                    onClick={() => {
                        setIsButtonNextClicked(true);
                        onClickNext();
                    }}
                />
            </Block>
        </>
    );
};

PermanentCreateDetails.propTypes = {
    onClickNext: PropTypes.func
};

PermanentCreateDetails.defaultProps = {
    onClickNext: () => {}
};

export default PermanentCreateDetails;

import React from 'react';

const IconDelete = (
    <svg viewBox="0 0 100 100">
        <path
            d="M80.65 8.86a9.83 9.83 0 019.85 9.79v5a1.66 1.66 0 01-1.67 1.65H83L80.18 76a1.66 1.66 0 01-1.66 1.56h-.09a1.66 1.66 0 01-1.58-1.74l2.76-50.52H69.13a1.66 1.66 0 110-3.31h18v-3.34a6.5 6.5 0 00-6.51-6.47H19.35a6.5 6.5 0 00-6.52 6.47V22h49.63a1.66 1.66 0 110 3.31H20.39l.46 8.48a1.66 1.66 0 01-1.57 1.75h-.09A1.67 1.67 0 0117.52 34l-.47-8.66h-5.88a1.66 1.66 0 01-1.67-1.69v-5a9.83 9.83 0 019.84-9.79h16.74V5.37A5.39 5.39 0 0141.48 0h17a5.39 5.39 0 015.4 5.37v3.49zm-41.24 0h21.18V5.37a2.07 2.07 0 00-2.07-2.06h-17a2.07 2.07 0 00-2.07 2.06zm38.84 72a1.66 1.66 0 011.57 1.74L79 98.43a1.65 1.65 0 01-1.71 1.57H22.71a1.65 1.65 0 01-1.66-1.57l-3.16-57.84a1.66 1.66 0 113.32-.18l3.08 56.28h51.42l.78-14.28a1.65 1.65 0 011.76-1.56zm-12.7 6.74h-.16a6.1 6.1 0 01-6-6.23l1.12-41a6.05 6.05 0 016.1-5.91h.17a6.09 6.09 0 015.94 6.23l-1.11 41a6.06 6.06 0 01-6.06 5.9zm-2.77-6.15a2.76 2.76 0 002.7 2.83h.07a2.75 2.75 0 002.78-2.68l1.11-41a2.77 2.77 0 00-2.7-2.83h-.08a2.75 2.75 0 00-2.77 2.68zm-28.33 6.14a6.06 6.06 0 01-6.11-5.91l-1.11-41a6.09 6.09 0 015.94-6.23h.17a6.05 6.05 0 016.1 5.91l1.12 41a6.1 6.1 0 01-6 6.23zm-1.19-49.87a2.77 2.77 0 00-2.7 2.83l1.11 41a2.75 2.75 0 002.78 2.68h.07a2.76 2.76 0 002.7-2.83l-1.11-41a2.75 2.75 0 00-2.77-2.68zm22.85 43.79a6.11 6.11 0 01-12.22 0v-41a6.11 6.11 0 0112.22 0zm-8.89 0a2.78 2.78 0 005.56 0v-41a2.78 2.78 0 00-5.56 0z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default IconDelete;

import FormatUtil from '../utils/FormatUtil';

export function useBilling() {
    const isBillingOrgIdValid = (orgId) => {
        return String(orgId).length === 8;
    };

    const isBillingCValid = (c) => {
        return !!c;
    };

    const isBillingWValid = (w) => {
        return !!w;
    };

    const isBillingCWValid = (c, w) => {
        /**
         * either c or w must be set
         */
        return (!!c && !w) || (!c && !!w);
    };

    const isBillingGValid = (g) => {
        return String(g).length === 8;
    };

    const isBillingValid = (billing) => {
        if (!isBillingOrgIdValid(billing?.org_id)) {
            return false;
        }

        if (!isBillingCWValid(billing?.c, billing?.w)) {
            return false;
        }

        if (!isBillingGValid(billing?.g)) {
            return false;
        }

        return true;
    };

    const getBillingFormatted = (billing) => {
        return FormatUtil.getBillingDetailsFormatted(billing);
    };

    return {
        isBillingOrgIdValid,
        isBillingCValid,
        isBillingWValid,
        isBillingCWValid,
        isBillingGValid,
        isBillingValid,
        getBillingFormatted
    };
}

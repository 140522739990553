import React from 'react';
import { useDispatch } from 'react-redux';
import { Block, Collapse, Group, GroupItem } from '@webfox-sc/core';
import FilterCheckbox from '../../../atoms/FilterCheckbox';
import FormInput from '../../../atoms/FormInput';
import FormDatePicker from '../../../atoms/FormDatePicker';
import TextButton from '../../../atoms/TextButton';
import TextS from '../../../atoms/TextS';
import TextM from '../../../atoms/TextM';
import { useOrderList } from '../OrderList.hooks';
import { useUser } from '../../../hooks/user';
import { setOrdersStartDate, setOrdersEndDate } from '../../../redux/actions/ordersActions';
import { ORDER_STATUS } from '../../../global/constants';

const OrderListFilters = () => {
    const { userIsLoggedIn } = useUser();
    const dispatch = useDispatch();

    const {
        isOrdersInitialRequest,
        ordersSearchText,
        ordersStartDate,
        ordersEndDate,
        ordersFilters,
        handleChangeOrdersSearchText,
        toggleOrdersFilter
    } = useOrderList();

    if (!userIsLoggedIn) {
        return null;
    }

    if (isOrdersInitialRequest) {
        return null;
    }

    return (
        <>
            <Block>
                <Group spacing="26px">
                    <GroupItem grow>
                        <Group nowrap spacing="20px" alignItems="center">
                            <Block width="55px">
                                <TextM>
                                    <strong>Suche</strong>
                                </TextM>
                            </Block>
                            <GroupItem grow>
                                <FormInput
                                    size="L"
                                    ariaLabel="Suche"
                                    value={ordersSearchText}
                                    maxLength="128"
                                    onChange={(e) => handleChangeOrdersSearchText(e.target.value)}
                                />
                            </GroupItem>
                        </Group>
                    </GroupItem>
                    <Group nowrap spacing="20px" alignItems="center">
                        <TextM>
                            <strong>Zeitraum</strong>
                        </TextM>
                        <TextM>von</TextM>
                        <FormDatePicker
                            size="L"
                            hideButton
                            dateFormat="dd.MM.yyyy"
                            selected={ordersStartDate ? new Date(ordersStartDate) : null}
                            onChange={(date) => dispatch(setOrdersStartDate(date?.toISOString()))}
                        />
                        <TextM>bis</TextM>
                        <FormDatePicker
                            size="L"
                            hideButton
                            dateFormat="dd.MM.yyyy"
                            selected={ordersEndDate ? new Date(ordersEndDate) : null}
                            minDate={ordersStartDate ? new Date(ordersStartDate) : null}
                            onChange={(date) => dispatch(setOrdersEndDate(date?.toISOString()))}
                        />
                    </Group>
                </Group>
            </Block>

            <Group paddingTop="40px" spacing="40px" justify="center" alignItems="center">
                <TextM>
                    <strong>Status</strong>
                </TextM>
                <FilterCheckbox
                    label="Offen"
                    checked={ordersFilters.includes(ORDER_STATUS.OFFEN)}
                    onChange={() => toggleOrdersFilter(ORDER_STATUS.OFFEN)}
                />
                <FilterCheckbox
                    label="Abgelehnt"
                    checked={ordersFilters.includes(ORDER_STATUS.ABGELEHNT)}
                    onChange={() => toggleOrdersFilter(ORDER_STATUS.ABGELEHNT)}
                />
                <FilterCheckbox
                    label="Freigegeben"
                    checked={ordersFilters.includes(ORDER_STATUS.FREIGEGEBEN)}
                    onChange={() => toggleOrdersFilter(ORDER_STATUS.FREIGEGEBEN)}
                />
                <FilterCheckbox
                    label="In Arbeit"
                    checked={ordersFilters.includes(ORDER_STATUS.IN_ARBEIT)}
                    onChange={() => toggleOrdersFilter(ORDER_STATUS.IN_ARBEIT)}
                />
                <FilterCheckbox
                    label="Erledigt"
                    checked={ordersFilters.includes(ORDER_STATUS.ERLEDIGT)}
                    onChange={() => toggleOrdersFilter(ORDER_STATUS.ERLEDIGT)}
                />
                <FilterCheckbox
                    label="Storniert"
                    checked={ordersFilters.includes(ORDER_STATUS.STORNIERT)}
                    onChange={() => toggleOrdersFilter(ORDER_STATUS.STORNIERT)}
                />
            </Group>

            <Collapse show={!!ordersStartDate && !!ordersEndDate}>
                <Block paddingTop="50px" justify="center">
                    <Group spacing="10px">
                        <TextS>Der Zeitraumfilter ist momentan aktiv.</TextS>
                        <TextButton
                            label="Filter entfernen"
                            onClick={() => {
                                dispatch(setOrdersStartDate(null));
                                dispatch(setOrdersEndDate(null));
                            }}
                        />
                    </Group>
                </Block>
            </Collapse>
        </>
    );
};

OrderListFilters.propTypes = {};
OrderListFilters.defaultProps = {};

export default OrderListFilters;

const permanentInitial = {
    id: '',
    type: '',
    serviceId: '',
    quantity: '',
    frequency: '',
    interval: '',
    location: '',
    buildingId: '',
    startDate: null,
    message: '',
    billing: {
        org_id: '',
        c: '',
        w: '',
        g: ''
    },
    contactPersonList: [
        {
            first_name: '',
            last_name: '',
            email: '',
            department: ''
        }
    ]
};

export default permanentInitial;

/* xeslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconReject from '../../assets/icons/IconReject';
import IconDelete from '../../assets/icons/IconDelete';
import ConfirmDialog from '../../atoms/ConfirmDialog';
import TextXL from '../../atoms/TextXL';
import FormButton from '../../atoms/FormButton';
import FormTextarea from '../../atoms/FormTextarea';
import FormDatePicker from '../../atoms/FormDatePicker';
import FormOrderCosts from '../../components/FormOrderCosts';
import { updateOrder, deleteOrder } from '../../redux/actions/ordersActions';
import { useOrder } from '../../hooks/orders';
import { useOrderHistory } from '../../hooks/useOrderHistory';
import { useUser } from '../../hooks/user';
import { ORDER_STATUS, USER_ROLES } from '../../global/constants';
import FormStaticField from '../../atoms/FormStaticField';
import FormLabel from '../../atoms/FormLabel';

const OrderDetailsOptions = ({ orderId, onClickEdit }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userRoleName } = useUser();
    const { order, orderService } = useOrder(orderId);
    const { lastRejectionComment } = useOrderHistory(orderId);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCancellationDialog, setShowCancellationDialog] = useState(false);

    const [reasonDraft, setReasonDraft] = useState('');
    const [isButtonRejectClicked, setIsButtonRejectClicked] = useState(false);

    const [dateScheduledDraft, setDateScheduledDraft] = useState(null);
    const [quantityDraft, setQuantityDraft] = useState(null);
    const [isButtonDoneClicked, setIsButtonDoneClicked] = useState(false);

    useEffect(() => {
        if (order) {
            setDateScheduledDraft(order.dateScheduled);
            setQuantityDraft(order.quantity);
        }
    }, [order]);

    if (!order) {
        return null;
    }

    const handleOnUpdateStatus = (newStatus) => {
        dispatch(
            updateOrder(order.id, {
                status: newStatus
            })
        );
    };

    const handleOnClickDelete = async () => {
        await dispatch(deleteOrder(order.id));
        history.push('/orders');
    };

    const handleOnClickReject = () => {
        setIsButtonRejectClicked(true);
        if (reasonDraft) {
            dispatch(
                updateOrder(order.id, {
                    comment: reasonDraft,
                    status: ORDER_STATUS.ABGELEHNT
                })
            );
        }
    };

    const handleOnClickStartWork = () => {
        dispatch(
            updateOrder(order.id, {
                status: ORDER_STATUS.IN_ARBEIT
            })
        );
    };

    const handleOnClickDone = () => {
        setIsButtonDoneClicked(true);

        if (dateScheduledDraft && quantityDraft && quantityDraft > 0) {
            dispatch(
                updateOrder(order.id, {
                    status: ORDER_STATUS.ERLEDIGT,
                    date_scheduled: dateScheduledDraft,
                    quantity: parseFloat(quantityDraft)
                })
            );
        }
    };

    const showOptionFreigeben =
        order.status === ORDER_STATUS.OFFEN &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.LOCATION_MANAGER);

    const showOptionAblehnen =
        order.status === ORDER_STATUS.OFFEN &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.LOCATION_MANAGER);

    const showOptionStatusToInArbeit =
        order.status === ORDER_STATUS.FREIGEGEBEN &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.SERVICE_MANAGER);

    const showOptionsByStatusAbgelehnt =
        order.status === ORDER_STATUS.ABGELEHNT &&
        (userRoleName === USER_ROLES.ADMIN ||
            userRoleName === USER_ROLES.SERVICE_MANAGER ||
            userRoleName === USER_ROLES.KEY_USER);

    const showOptionStatusToErledigt =
        order.status === ORDER_STATUS.IN_ARBEIT &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.SERVICE_MANAGER);

    const showOptionStornieren =
        order.status === ORDER_STATUS.OFFEN ||
        order.status === ORDER_STATUS.FREIGEGEBEN ||
        order.status === ORDER_STATUS.ABGELEHNT ||
        (order.status === ORDER_STATUS.IN_ARBEIT &&
            (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.SERVICE_MANAGER));

    const showOptionDelete = userRoleName === USER_ROLES.ADMIN;

    return (
        <>
            <Group vertical spacing="70px">
                {showOptionFreigeben && (
                    <Block justify="center">
                        <FormButton
                            layout="primary"
                            label="Auftrag freigeben"
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={() => handleOnUpdateStatus(ORDER_STATUS.FREIGEGEBEN)}
                        />
                    </Block>
                )}
                {showOptionAblehnen && (
                    <>
                        <Block justify="center">
                            <Block width="100%" maxWidth="600px">
                                <FormTextarea
                                    value={reasonDraft}
                                    placeholder="Grund der Ablehnung *"
                                    height="100px"
                                    error={isButtonRejectClicked && !reasonDraft}
                                    onChange={(e) => setReasonDraft(e.target.value)}
                                />
                            </Block>
                        </Block>
                        <Block paddingTop="10px" justify="center">
                            <FormButton
                                layout="primary"
                                label="Auftrag ablehnen"
                                icon={IconReject}
                                iconSize="small"
                                onClick={handleOnClickReject}
                            />
                        </Block>
                    </>
                )}
                {showOptionStatusToInArbeit && (
                    <>
                        <Block justify="center">
                            <TextXL>
                                <strong>Status ändern</strong>
                            </TextXL>
                        </Block>
                        <Group paddingTop="30px" justify="center">
                            <FormButton
                                layout="primary"
                                label={'Status ändern zu "In Arbeit"'}
                                icon={IconCheckbox}
                                iconSize="small"
                                onClick={handleOnClickStartWork}
                            />
                        </Group>
                    </>
                )}
                {showOptionsByStatusAbgelehnt && (
                    <>
                        <Block justify="center">
                            <TextXL>
                                <strong>Grund der Ablehnung</strong>
                            </TextXL>
                        </Block>
                        <Block paddingTop="30px" justify="center">
                            <Block width="100%" maxWidth="600px">
                                <FormStaticField value={lastRejectionComment || '–'} />
                            </Block>
                        </Block>
                        <Block paddingTop="10px" justify="center">
                            <FormButton
                                layout="primary"
                                label="Auftrag bearbeiten"
                                icon={IconCheckbox}
                                iconSize="small"
                                onClick={onClickEdit}
                            />
                        </Block>
                    </>
                )}
                {showOptionStatusToErledigt && (
                    <>
                        <Block justify="center">
                            <TextXL>
                                <strong>Erledigungsdatum und Menge eingeben, Status ändern</strong>
                            </TextXL>
                        </Block>
                        <Block paddingTop="30px">
                            <FormOrderCosts
                                isEditMode
                                isValidationMode={isButtonDoneClicked}
                                quantity={quantityDraft}
                                unit={orderService ? orderService.unit : ''}
                                unitPrice={orderService ? orderService.price : order.unitPrice}
                                onChangeQuantity={(_quantity) => setQuantityDraft(_quantity)}
                            />
                        </Block>
                        <Block paddingTop="10px" justify="center">
                            <Grid>
                                <GridItem cols="3">
                                    <FormLabel top>
                                        <strong>Erledigungsdatum</strong>
                                    </FormLabel>
                                    <FormDatePicker
                                        isEditMode
                                        error={isButtonDoneClicked && !dateScheduledDraft}
                                        minWidth="350px"
                                        dateFormat="dd.MM.yyyy"
                                        selected={dateScheduledDraft ? new Date(dateScheduledDraft) : null}
                                        onChange={(date) => setDateScheduledDraft(date)}
                                    />
                                </GridItem>
                            </Grid>
                        </Block>
                        <Group paddingTop="10px" justify="center">
                            <FormButton
                                layout="primary"
                                label={'Status ändern zu "Erledigt"'}
                                icon={IconCheckbox}
                                iconSize="small"
                                onClick={handleOnClickDone}
                            />
                        </Group>
                    </>
                )}
                {showOptionStornieren && (
                    <Block justify="center">
                        <FormButton
                            label="Auftrag stornieren"
                            icon={IconReject}
                            iconSize="small"
                            onClick={() => setShowCancellationDialog(true)}
                        />
                    </Block>
                )}
                {showOptionDelete && (
                    <Block justify="center">
                        <FormButton
                            label="Auftrag endgültig löschen"
                            icon={IconDelete}
                            iconSize="small"
                            onClick={() => setShowDeleteDialog(true)}
                        />
                    </Block>
                )}
            </Group>

            <ConfirmDialog
                show={showCancellationDialog}
                heading={`Möchten Sie den Ad-hoc-Auftrag<br />#${order.entryId} wirklich stornieren?`}
                text="Diese Aktion kann nicht rückgängig gemacht werden."
                confirmButtonLabel="Stornieren"
                confirmButtonIcon={IconReject}
                onClickConfirm={() => {
                    handleOnUpdateStatus(ORDER_STATUS.STORNIERT);
                    setShowCancellationDialog(false);
                }}
                onClickCancel={() => setShowCancellationDialog(false)}
                onClickOutside={() => setShowCancellationDialog(false)}
            />

            <ConfirmDialog
                show={showDeleteDialog}
                heading={`Möchten Sie den Ad-hoc-Auftrag<br />#${order.entryId} wirklich löschen?`}
                text="Diese Aktion kann nicht rückgängig gemacht werden."
                confirmButtonLabel="Löschen"
                confirmButtonIcon={IconDelete}
                onClickConfirm={() => {
                    handleOnClickDelete();
                    setShowDeleteDialog(false);
                }}
                onClickCancel={() => setShowDeleteDialog(false)}
                onClickOutside={() => setShowDeleteDialog(false)}
            />
        </>
    );
};

OrderDetailsOptions.propTypes = {
    orderId: PropTypes.any,
    onClickEdit: PropTypes.func
};

OrderDetailsOptions.defaultProps = {
    orderId: null,
    onClickEdit: () => {}
};

export default OrderDetailsOptions;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CockpitAdHoc from './CockpitAdHoc';
import CockpitClaim from './CockpitClaim';
import CockpitPerformance from './CockpitPerformance';
import CockpitUpload from './CockpitUpload';
import 'react-datepicker/dist/react-datepicker.css';

class CockpitView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate()
        };
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount() {}

    handleChangeStart(startDate) {
        this.setState({ startDate });
    }

    handleChangeEnd(endDate) {
        this.setState({ endDate });
    }

    getCosts(data) {
        let totalCosts = 0;
        let i;
        for (i = 0; i < data.length; i++) {
            if (data[i].status === 'ERLEDIGT') {
                totalCosts += data[i].costs;
            }
        }
        return totalCosts.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    countOrders(data, status) {
        let count = 0;
        let i;
        for (i = 0; i < data.length; i++) {
            if (data[i].status === status) {
                count++;
            }
        }
        return count;
    }

    render() {
        const { startDate, endDate } = this.state;
        const { claims, orders, permanents } = this.props;
        const allOrders = orders.concat(permanents, claims);

        return (
            <div className="comp-cockpit">
                <Container className="grid-container grid-reset">
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <h2 className="cockpit-main-header">Real Time</h2>
                        </Col>
                    </Row>
                </Container>

                <div className="realtime-stats">
                    <CockpitPerformance
                        data={allOrders}
                        adhoc={orders}
                        claims={claims}
                        countOrders={(xdata, status) => this.countOrders(xdata, status)}
                    />
                </div>
                <Container className="grid-container grid-reset center-flex cockpit-dp-container">
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <span className="cockpit-dp-span">
                                <label className="cockpit-dp-header">
                                    {moment(startDate).format('DD.MM.YYYY')}-
                                    <DatePicker
                                        selected={startDate}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={this.handleChangeStart}
                                    />
                                </label>
                                <label className="cockpit-dp-header">
                                    {moment(endDate).format('DD.MM.YYYY')}
                                    <DatePicker
                                        selected={endDate}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={this.handleChangeEnd}
                                    />
                                </label>
                            </span>
                        </Col>
                    </Row>
                </Container>
                <div className="service-stats">
                    <div className="service-stats-container">
                        <CockpitAdHoc
                            data={orders}
                            countOrders={(xdata, status) => this.countOrders(xdata, status)}
                            getCosts={(xdata) => this.getCosts(xdata)}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                    <div className="service-stats-container">
                        <CockpitClaim
                            data={claims}
                            countOrders={(xdata, status) => this.countOrders(xdata, status)}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                </div>

                <Container className="grid-container grid-reset">
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <div className="cockpit-upload-container">
                                <CockpitUpload startDate={startDate} endDate={endDate} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

CockpitView.propTypes = {
    claims: PropTypes.array,
    orders: PropTypes.array,
    permanents: PropTypes.array
};

CockpitView.defaultProps = {
    claims: [],
    orders: [],
    permanents: []
};

export default CockpitView;

import { INTERVAL_FACTORS, PERMANENT_STATUS } from '../global/constants';

const headers = {
    orders: [
        'Empfänger ORG ID',
        'Leistungsart',
        'Auftragsmenge',
        'Mengeneinheit der Auftragsmenge',
        'Preis',
        'Währung',
        'Bestellnummer',
        'Profitcenter',
        'Leistungserstellungsdatum',
        'Erledigungsdatum',
        'Auftragsnummer',
        'Gebäude',
        'Raum',
        'Leistungsbeschreibung',
        'Bearbeiter',
        'Ansprechpartner',
        'Verrechnungsart'
    ],
    permanents: [
        'Empfänger ORG ID',
        'Leistungsart',
        'Auftragsmenge',
        'Mengeneinheit der Auftragsmenge',
        'Preis pro Monat',
        'Währung',
        'Bestellnummer',
        'Profitcenter',
        'Leistungserstellungsdatum',
        'Ablaufdatum',
        'Auftragsnummer',
        'Gebäude',
        'Raum',
        'Leistungsbeschreibung',
        'Bearbeiter',
        'Ansprechpartner',
        'Kategorie',
        'Verrechnungsart'
    ],
    users: ['Name', 'E-Mail', 'Rolle', 'ORG ID', 'Verrechnungsdaten']
};

const formatBillingData = (billing) =>
    billing && billing.g ? `${billing.c ? `c=${billing.c}` : `w=${billing.w}`}*g=${billing.g}` : '';

const helper = {
    getAllMonthDates(startDate, endDate) {
        const dates = [];
        if (startDate) {
            const date = new Date(startDate);
            if (endDate && date < endDate) {
                while (date <= endDate) {
                    const oldStartDate = new Date(date);
                    const month = date.getMonth() + 1;
                    date.setMonth(month);
                    dates.push([oldStartDate, new Date(date)]);
                }
            } else {
                const oldStartDate = new Date(date);
                const month = date.getMonth() + 1;
                date.setMonth(month);
                dates.push([oldStartDate, new Date(date)]);
            }
        }
        return dates;
    },
    getPermanentQuantity(permanent, monthDates) {
        const { expiryDate, startDate, interval, frequency, status } = permanent;

        if (status === PERMANENT_STATUS.ZUR_FREIGABE || status === PERMANENT_STATUS.ABGELEHNT) {
            // job never started
            return 0;
        }
        if (!frequency || !interval) {
            // invalid data
            return 0;
        }
        const start = new Date(startDate);
        if (Number.isNaN(start.getTime()) || start >= monthDates[1]) {
            // invalid start date or start date in the future
            return 0;
        }

        const end = expiryDate ? new Date(expiryDate) : null;
        const isEndValid = end && !Number.isNaN(end.getTime());

        if (isEndValid && end < monthDates[0]) {
            // job has expired or was cancelled
            return 0;
        }
        // jobs with invalid end date are treated as active
        return frequency * (INTERVAL_FACTORS[interval] || 0);
    },
    formatOptions: [
        {
            value: 'semi_utf-8',
            label: 'Semikolon, UTF-8 (Standard)'
        },
        {
            value: 'comma_utf-8',
            label: 'Komma, UTF-8'
        },
        {
            value: 'comma_iso',
            label: 'Komma, ISO 8859-1 (Excel 365)'
        },
        {
            value: 'comma_win',
            label: 'Komma, Windows-1251 (Excel 365)'
        }
    ],
    getDownloadDataEntry(rawData, additionalData, dataType) {
        if (dataType === 'users') {
            const { username, email, roleName, billing } = rawData;
            const exportData = [username, email, roleName, billing.org_id, formatBillingData(billing)];
            return exportData;
        }
        // orders
        const { services, buildings, factor = 1, monthDates } = additionalData;
        const isPermanent = dataType === 'permanents';

        const {
            entryId,
            billing,
            quantity,
            unitPrice,
            createdAt,
            expiryDate: expiryDateISOString,
            dateScheduled,
            location,
            isOverhead,
            serviceId,
            buildingId,
            listService,
            assigneeName,
            contactPersonList,
            message,
            type
        } = rawData;

        const service = services[serviceId];
        const building = buildings[buildingId];
        const price = ((service && service.price) || unitPrice || 0) * (quantity || 1) * factor;

        let createDate;
        let createDateString = '';
        let completeDateString = '';
        let expiryDateString = '';
        if (isPermanent) {
            createDate = new Date(monthDates[1]);
            createDate.setDate(0);
            // expiry date
            if (expiryDateISOString) {
                const expiryDate = new Date(expiryDateISOString);
                if (!Number.isNaN(expiryDate.getTime()) && expiryDate.getTime() !== 0) {
                    expiryDateString = expiryDate.toLocaleDateString('de-DE', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    });
                }
            }
        } else {
            createDate = new Date(createdAt);
            const completeDate = new Date(dateScheduled);
            if (!Number.isNaN(completeDate.getTime()) && completeDate.getTime() !== 0) {
                completeDateString = completeDate.toLocaleDateString('de-DE', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                });
            }
        }
        if (!Number.isNaN(createDate.getTime()) && createDate.getTime() !== 0) {
            createDateString = createDate.toLocaleDateString('de-DE', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
        }

        const exportData = [
            billing.org_id,
            'SR2:SREOPEX2',
            isPermanent ? '1' : quantity || '',
            isPermanent ? 'ST' : (service && service.unit) || '',
            price ? price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '',
            'EUR',
            formatBillingData(billing),
            building ? building.code : '',
            createDateString
        ];
        if (isPermanent) {
            exportData.push(expiryDateString);
        } else {
            exportData.push(completeDateString);
        }
        exportData.push(
            ...[
                entryId,
                building ? building.name : '',
                location || '',
                `${(serviceId && (isPermanent ? service.name : listService)) || message}`,
                assigneeName || '',
                (contactPersonList || [])
                    .filter(({ first_name, last_name, name }) => first_name || last_name || name)
                    .map(({ first_name, last_name, name }) => name || `${first_name} ${last_name}`)
                    .join(',')
            ]
        );
        if (isPermanent) {
            exportData.push(type);
        }
        exportData.push(isOverhead ? 'über Nebenkosten' : 'Verrechnung');
        return exportData;
    },
    formatDownloadData(orderData, orderType, format) {
        const dataArray = [headers[orderType], ...orderData];
        if (format === 'semi_utf-8' || format === 'comma_utf-8') {
            return dataArray;
        }
        const encoding = format === 'comma_win' ? 'windows-1251' : 'iso-8859-1';
        const utf8encoder = new TextEncoder();
        const textDecoder = new TextDecoder(encoding);
        const convertedArray = dataArray.map((arr) => arr.map((val) => textDecoder.decode(utf8encoder.encode(val))));
        return convertedArray;
    }
};

export default helper;

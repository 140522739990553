import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import DetailsHeader from '../../atoms/DetailsHeader';
import FormPanelGroup from '../../atoms/FormPanelGroup';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormStaticField from '../../atoms/FormStaticField';
import FormPanelBilling from '../../components/FormPanelBilling';
import PermanentDetailsOptions from '../PermanentDetailsOptions';
import PermanentDetailsHistory from '../PermanentDetailsHistory';
import { fetchPermanent, updatePermanent } from '../../redux/actions/permanentsActions';
import { fetchUsers } from '../../redux/actions/usersActions';
import { useUser } from '../../hooks/user';
import { usePermanent } from '../../hooks/permanents';
import { usePermanentHistory } from '../../hooks/usePermanentHistory';
import DateUtil from '../../utils/DateUtil';
import FormatUtil from '../../utils/FormatUtil';
import { PERMANENT_STATUS, USER_ROLES } from '../../global/constants';
import FormPanelContact from '../../components/FormPanelContact';
import FormPanelPermanentAssignee from '../../components/FormPanelPermanentAssignee';

const PermanentDetails = () => {
    const dispatch = useDispatch();
    const { userRoleName } = useUser();
    const history = useHistory();
    const { permanentId } = useParams();

    const {
        permanent,
        permanentBuilding,
        permanentService,
        permanentContactPerson,
        permanentMonthlyPrice,
        frequencyIntervalFormatted
    } = usePermanent(permanentId);

    const { permanentHistory } = usePermanentHistory(permanentId);

    useEffect(() => {
        dispatch(fetchPermanent(permanentId));
        dispatch(fetchUsers());
    }, [permanentId, dispatch]);

    if (!permanent) {
        return null;
    }

    const handleOnUpdateBilling = (billingDraft) => {
        dispatch(
            updatePermanent(permanent.id, {
                billing: billingDraft
            })
        );
    };

    const handleOnUpdateContact = (value) => {
        const contactPersonListNew = [{ ...permanentContactPerson, ...value }];
        dispatch(
            updatePermanent(permanent.id, {
                contact_person_list: contactPersonListNew
            })
        );
    };

    /**
     * permissions
     */
    let userCanEditBilling = false;
    if (
        permanent.listStatus === PERMANENT_STATUS.ZUR_FREIGABE &&
        [USER_ROLES.ADMIN, USER_ROLES.KEY_USER].includes(userRoleName)
    ) {
        userCanEditBilling = true;
    } else if (
        permanent.listStatus === PERMANENT_STATUS.AKTIV &&
        [USER_ROLES.ADMIN, USER_ROLES.KEY_USER, USER_ROLES.LOCATION_MANAGER].includes(userRoleName)
    ) {
        userCanEditBilling = true;
    }

    const userCanEditContact = [
        PERMANENT_STATUS.ZUR_FREIGABE,
        PERMANENT_STATUS.AKTIV,
        // PERMANENT_STATUS.ABGELEHNT,
        PERMANENT_STATUS.STORNIERUNG
    ].includes(permanent.listStatus);

    return (
        <PageContentBlock>
            <Block paddingTop="50px">
                <DetailsHeader
                    title={`Dauerauftrag #${permanent.entryId}`}
                    statusLabel={permanent.listStatus}
                    backLinkLabel="Zurück zur Übersicht"
                    backLinkHref="/permanents"
                    onClickBackLink={(e) => {
                        e.preventDefault();
                        history.push('/permanents');
                    }}
                />
            </Block>

            <FormPanelGroup paddingTop="40px">
                <FormPanelPermanentAssignee
                    permanentId={permanentId}
                    isInlineEditing={
                        (permanent.status === PERMANENT_STATUS.ZUR_FREIGABE ||
                            permanent.status === PERMANENT_STATUS.ABGELEHNT ||
                            permanent.status === PERMANENT_STATUS.AKTIV) &&
                        (userRoleName === USER_ROLES.ADMIN ||
                            userRoleName === USER_ROLES.LOCATION_MANAGER ||
                            userRoleName === USER_ROLES.SERVICE_MANAGER)
                    }
                />

                <FormPanel title="Leistung">
                    <Grid spacing="20px">
                        {/* <GridItem cols="6">
                            <FormLabel top bold>
                                Auftragsersteller
                            </FormLabel>
                            <FormStaticField value={permanent.creator ? permanent.creator.username : ''} />
                        </GridItem> */}
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Erstellt am
                            </FormLabel>
                            <FormStaticField value={DateUtil.getDateFormatted(permanent.createdAt)} />
                        </GridItem>
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Startdatum
                            </FormLabel>
                            <FormStaticField value={DateUtil.getDateFormatted(permanent.startDate) || '–'} />
                        </GridItem>
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Ablaufdatum
                            </FormLabel>
                            <FormStaticField value={DateUtil.getDateFormatted(permanent.expiryDate) || '–'} />
                        </GridItem>

                        <GridItem cols="3">
                            <FormLabel top bold>
                                Leistungstyp
                            </FormLabel>
                            <FormStaticField value={permanent.type} />
                        </GridItem>
                        <GridItem cols="9">
                            <FormLabel top bold>
                                Leistungsbeschreibung
                            </FormLabel>
                            <FormStaticField
                                value={permanentService ? `${permanentService.number} ${permanentService.name}` : '–'}
                            />
                        </GridItem>

                        <GridItem cols="2">
                            <FormLabel top bold>
                                Menge
                            </FormLabel>
                            <FormStaticField value={permanent.quantity || '–'} />
                        </GridItem>
                        <GridItem cols="3">
                            <FormLabel top bold>
                                Einheit
                            </FormLabel>
                            <FormStaticField value={permanentService ? permanentService.unit : '–'} />
                        </GridItem>
                        <GridItem cols="3">
                            <FormLabel top bold>
                                Intervall
                            </FormLabel>
                            <FormStaticField value={frequencyIntervalFormatted || '–'} />
                        </GridItem>

                        <GridItem cols="2">
                            <FormLabel top bold>
                                Einzelpreis EUR
                            </FormLabel>
                            <FormStaticField
                                value={
                                    permanentService?.price
                                        ? FormatUtil.getDecimalFormatted(permanentService?.price, 2)
                                        : '–'
                                }
                            />
                        </GridItem>
                        <GridItem cols="2">
                            <FormLabel top bold>
                                Monatspreis EUR
                            </FormLabel>
                            <FormStaticField
                                value={
                                    permanentMonthlyPrice
                                        ? FormatUtil.getDecimalFormatted(permanentMonthlyPrice, 2)
                                        : '–'
                                }
                            />
                        </GridItem>

                        <GridItem cols="3">
                            <FormLabel top bold>
                                Ort
                            </FormLabel>
                            <FormStaticField value={permanent.location || '–'} />
                        </GridItem>

                        <GridItem cols="3">
                            <FormLabel top>
                                <strong>Standort</strong>
                            </FormLabel>
                            <FormStaticField value={permanentBuilding ? permanentBuilding.nameCode : '–'} />
                        </GridItem>

                        <GridItem cols="6">
                            <FormLabel top bold>
                                Bemerkung
                            </FormLabel>
                            <FormStaticField value={FormatUtil.nl2br(permanent.message) || '–'} />
                        </GridItem>
                    </Grid>
                </FormPanel>

                <FormPanelBilling
                    billing={permanent.billing}
                    isInlineEditing={userCanEditBilling}
                    onSubmitBilling={(billingDraft) => handleOnUpdateBilling(billingDraft)}
                />

                <FormPanelContact
                    contact={permanentContactPerson}
                    isInlineEditing={userCanEditContact}
                    onSubmit={(contactDraft) => handleOnUpdateContact(contactDraft)}
                />
            </FormPanelGroup>

            <Block paddingTop="70px">
                <PermanentDetailsOptions permanentId={permanentId} />
            </Block>

            {permanentHistory.length > 0 && (
                <Block paddingTop="70px">
                    <PermanentDetailsHistory permanentId={permanentId} />
                </Block>
            )}
        </PageContentBlock>
    );
};

PermanentDetails.propTypes = {};
PermanentDetails.defaultProps = {};

export default PermanentDetails;

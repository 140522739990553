import React from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import TypeButton from '../../atoms/TypeButton';
import IconTypeAbfall from '../../assets/icons/IconTypeAbfall';
import IconTypeBodenreinigung from '../../assets/icons/IconTypeBodenreinigung';
import IconTypeOberflaechenreinigung from '../../assets/icons/IconTypeOberflaechenreinigung';
import IconTypeVerbrauchsmaterial from '../../assets/icons/IconTypeVerbrauchsmaterial';
import IconTypeGlasreinigung from '../../assets/icons/IconTypeGlasreinigung';
import IconTypeSonstiges from '../../assets/icons/IconTypeSonstiges';
import { useClaimDraft } from '../../hooks/claims';

const ClaimCreateType = ({ onClickTypeButton }) => {
    const { claimDraft, updateClaimDraftType } = useClaimDraft();

    return (
        <Block justify="center">
            <Block maxWidth="1030px">
                <Grid columns="3" hSpacing="25px" vSpacing="33px">
                    <GridItem>
                        <TypeButton
                            label="Abfall"
                            icon={IconTypeAbfall}
                            active={claimDraft.type === 'Abfall'}
                            onClick={() => {
                                updateClaimDraftType('Abfall');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Bodenreinigung"
                            icon={IconTypeBodenreinigung}
                            active={claimDraft.type === 'Bodenreinigung'}
                            onClick={() => {
                                updateClaimDraftType('Bodenreinigung');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Oberflächenreinigung"
                            icon={IconTypeOberflaechenreinigung}
                            active={claimDraft.type === 'Oberflächenreinigung'}
                            onClick={() => {
                                updateClaimDraftType('Oberflächenreinigung');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Verbrauchsmaterial"
                            icon={IconTypeVerbrauchsmaterial}
                            active={claimDraft.type === 'Verbrauchsmaterial'}
                            onClick={() => {
                                updateClaimDraftType('Verbrauchsmaterial');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Glasreinigung"
                            icon={IconTypeGlasreinigung}
                            active={claimDraft.type === 'Glasreinigung'}
                            onClick={() => {
                                updateClaimDraftType('Glasreinigung');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Sonstiges"
                            icon={IconTypeSonstiges}
                            active={claimDraft.type === 'Sonstiges'}
                            onClick={() => {
                                updateClaimDraftType('Sonstiges');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                </Grid>
            </Block>
        </Block>
    );
};

ClaimCreateType.propTypes = {
    onClickTypeButton: PropTypes.func
};

ClaimCreateType.defaultProps = {
    onClickTypeButton: () => {}
};

export default ClaimCreateType;

import { useSelector } from 'react-redux';

export function useAuth() {
    const { isLoggedIn, isRequestingLogin, loginErrorText, isRequestingAutoLogin } = useSelector(
        ({ authState }) => authState
    );

    return {
        isLoggedIn,
        isRequestingLogin,
        loginErrorText,
        isRequestingAutoLogin
    };
}

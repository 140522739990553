/* xeslint-disable */
class FormatUtil {
    static getDecimalFormatted(amount, decimalCount = 4, minDecimalCount = 2, locale = 'de-de') {
        return amount.toLocaleString(locale, {
            minimumFractionDigits: minDecimalCount,
            maximumFractionDigits: decimalCount,
            useGrouping: true
        });
    }

    static getBillingDetailsFormatted(billing) {
        if (!billing || typeof billing !== 'object') {
            return '';
        }

        const { org_id, c, w, g } = billing;
        if (!org_id && !c && !w && !g) {
            return '';
        }

        const aBillingFormatted = [`Org ID ${org_id},`];

        if (c) {
            aBillingFormatted.push(`c=${c}`);
        }

        if (w) {
            aBillingFormatted.push(`w=${w}`);
        }

        if (g) {
            aBillingFormatted.push(`*G=${g}`);
        }

        return aBillingFormatted.join(' ');
    }

    static nl2br(text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
}

export default FormatUtil;

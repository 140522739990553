import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconClose from '../../assets/icons/IconClose';
import TextM from '../../atoms/TextM';
import PageContentBlock from '../../atoms/PageContentBlock';
import DetailsHeader from '../../atoms/DetailsHeader';
import FormPanelGroup from '../../atoms/FormPanelGroup';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormInputExtended from '../../atoms/FormInputExtended';
import FormTextarea from '../../atoms/FormTextarea';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormDatePicker from '../../atoms/FormDatePicker';
import FormStaticField from '../../atoms/FormStaticField';
import FormButton from '../../atoms/FormButton';
import FormPanelBilling from '../../components/FormPanelBilling';
import FormPanelOrderAssignee from '../../components/FormPanelOrderAssignee';
import FormPanelContactPersonList from '../../components/FormPanelContactPersonList';
// import FormOrderCosts from '../../components/FormOrderCosts';
import OrderDetailsOptions from '../OrderDetailsOptions';
import OrderDetailsComments from '../OrderDetailsComments';
import OrderDetailsHistory from '../OrderDetailsHistory';
import { fetchOrder, updateOrder } from '../../redux/actions/ordersActions';
import { fetchServices } from '../../redux/actions/servicesActions';
import { fetchBuildings } from '../../redux/actions/buildingsActions';
import { fetchUsers } from '../../redux/actions/usersActions';
import { useUser } from '../../hooks/user';
import { useOrder, useOrderDraft, useOrderServices, useOrderActions } from '../../hooks/orders';
import { useOrderHistory } from '../../hooks/useOrderHistory';
import { useBuildings } from '../../hooks/buildings';
import DateUtil from '../../utils/DateUtil';
import FormatUtil from '../../utils/FormatUtil';
import { ORDER_STATUS, USER_ROLES } from '../../global/constants';

const OrderDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { orderId } = useParams();
    const { userRoleName } = useUser();
    const { order, orderService, orderBuilding, orderMonthlyPrice } = useOrder(orderId);
    const { orderHistory } = useOrderHistory(orderId);
    const {
        orderDraft,
        orderDraftService,
        orderDraftMonthlyPrice,
        isOrderDraftDataValid,
        updateOrderDraft,
        stableUpdateOrderDraft,
        stableResetOrderDraft
    } = useOrderDraft();
    const { orderServiceDropdownOptions } = useOrderServices();
    const { handleUpdateOrderFromDraft } = useOrderActions();
    const { buildingDropdownOptions } = useBuildings();

    const [isOrderEditMode, setIsOrderEditMode] = useState(false);
    const [isButtonUpdateClicked, setIsButtonUpdateClicked] = useState(false);

    useEffect(() => {
        dispatch(fetchOrder(orderId));
        dispatch(fetchServices());
        dispatch(fetchBuildings());
        dispatch(fetchUsers());
    }, [orderId, dispatch]);

    useEffect(() => {
        if (order) {
            stableUpdateOrderDraft(order);
        } else {
            stableResetOrderDraft();
        }
    }, [order, stableUpdateOrderDraft, stableResetOrderDraft]);

    if (!order || !orderDraft) {
        return null;
    }

    const handleOnUpdateBilling = (billingDraft) => {
        dispatch(
            updateOrder(order.id, {
                billing: billingDraft
            })
        );
    };

    const handleOnClickOrderEdit = () => {
        updateOrderDraft(order);
        setIsOrderEditMode(true);
    };

    const handleOnCancelOrderEdit = () => {
        updateOrderDraft(order);
        setIsOrderEditMode(false);
        setIsButtonUpdateClicked(false);
    };

    const handleOnClickOrderUpdate = async () => {
        if (isOrderDraftDataValid()) {
            await handleUpdateOrderFromDraft(ORDER_STATUS.OFFEN);
            setIsOrderEditMode(false);
            setIsButtonUpdateClicked(false);
        } else {
            setIsButtonUpdateClicked(true);
        }
    };

    const isOverheadEditable =
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.LOCATION_MANAGER) &&
        (order.status === ORDER_STATUS.OFFEN || order.status === ORDER_STATUS.IN_ARBEIT);

    return (
        <PageContentBlock>
            <Block paddingTop="50px">
                <DetailsHeader
                    title={`Ad-hoc-Auftrag #${order.entryId}`}
                    statusLabel={order.status}
                    backLinkLabel="Zurück zur Übersicht"
                    backLinkHref="/orders"
                    onClickBackLink={(e) => {
                        e.preventDefault();
                        history.push('/orders');
                    }}
                />
            </Block>

            <FormPanelGroup paddingTop="40px">
                <FormPanelOrderAssignee
                    orderId={orderId}
                    isInlineEditing={
                        (order.status === ORDER_STATUS.OFFEN ||
                            order.status === ORDER_STATUS.ABGELEHNT ||
                            order.status === ORDER_STATUS.FREIGEGEBEN ||
                            order.status === ORDER_STATUS.IN_ARBEIT) &&
                        (userRoleName === USER_ROLES.ADMIN ||
                            userRoleName === USER_ROLES.LOCATION_MANAGER ||
                            userRoleName === USER_ROLES.SERVICE_MANAGER)
                    }
                />

                <FormPanel title="Leistung">
                    <Grid spacing="20px">
                        <GridItem cols="3">
                            <FormLabel top>
                                <strong>Leistungstyp</strong>
                            </FormLabel>
                            <FormStaticField value={order.type} />
                        </GridItem>
                        <GridItem cols="9">
                            <FormLabel top>
                                <strong>Leistungsbeschreibung</strong>
                            </FormLabel>
                            {isOrderEditMode ? (
                                <FormNativeSelect
                                    options={orderServiceDropdownOptions}
                                    value={orderDraft.serviceId}
                                    error={isButtonUpdateClicked && !orderDraft.serviceId}
                                    onChange={(e) => {
                                        updateOrderDraft({
                                            serviceId: e.target.value
                                        });
                                    }}
                                />
                            ) : (
                                <FormStaticField
                                    value={
                                        orderService
                                            ? `${orderService.number} ${orderService.name}`
                                            : order.message || '–'
                                    }
                                />
                            )}
                        </GridItem>
                    </Grid>
                    <Block paddingTop="20px">
                        <Grid spacing="20px">
                            <GridItem cols="3">
                                {isOrderEditMode ? (
                                    <FormInputExtended
                                        label="Menge"
                                        type="number"
                                        value={order.quantity}
                                        staticFallbackValue="–"
                                        isEditMode={isOrderEditMode}
                                        onChange={(value) => {
                                            updateOrderDraft({
                                                quantity: parseFloat(value)
                                            });
                                        }}
                                    />
                                ) : (
                                    <>
                                        <FormLabel top bold>
                                            Menge
                                        </FormLabel>
                                        <FormStaticField
                                            value={
                                                order?.quantity
                                                    ? FormatUtil.getDecimalFormatted(order.quantity, 4, 0)
                                                    : '–'
                                            }
                                        />
                                    </>
                                )}
                            </GridItem>
                            <GridItem cols="3">
                                <FormLabel top bold>
                                    Einheit
                                </FormLabel>
                                <FormStaticField value={orderService?.unit || '–'} />
                            </GridItem>
                            <GridItem cols="3">
                                <FormLabel top bold>
                                    Einzelpreis EUR
                                </FormLabel>
                                {isOrderEditMode ? (
                                    <FormStaticField
                                        value={
                                            orderDraftService?.price
                                                ? FormatUtil.getDecimalFormatted(orderDraftService?.price)
                                                : '–'
                                        }
                                    />
                                ) : (
                                    <FormStaticField
                                        value={
                                            orderService?.price
                                                ? FormatUtil.getDecimalFormatted(orderService?.price)
                                                : '–'
                                        }
                                    />
                                )}
                            </GridItem>
                            <GridItem cols="3">
                                <FormLabel top bold>
                                    Gesamtpreis EUR
                                </FormLabel>
                                {isOrderEditMode ? (
                                    <FormStaticField
                                        value={
                                            orderDraftMonthlyPrice
                                                ? FormatUtil.getDecimalFormatted(orderDraftMonthlyPrice)
                                                : '–'
                                        }
                                    />
                                ) : (
                                    <FormStaticField
                                        value={
                                            orderMonthlyPrice ? FormatUtil.getDecimalFormatted(orderMonthlyPrice) : '–'
                                        }
                                    />
                                )}
                            </GridItem>
                        </Grid>
                    </Block>
                    <Block paddingTop="20px">
                        <Grid spacing="20px">
                            <GridItem cols="3">
                                <FormLabel top>
                                    <strong>Wunschtermin</strong>
                                </FormLabel>
                                {isOrderEditMode ? (
                                    <FormDatePicker
                                        popperPlacement="top-start"
                                        minDate={Date.now()}
                                        dateFormat="dd.MM.yyyy"
                                        selected={orderDraft.datePlanned ? new Date(orderDraft.datePlanned) : null}
                                        onChange={(date) => {
                                            updateOrderDraft({
                                                datePlanned: date
                                            });
                                        }}
                                    />
                                ) : (
                                    <FormStaticField value={DateUtil.getDateFormatted(order.datePlanned) || '–'} />
                                )}
                            </GridItem>
                            <GridItem cols="3">
                                <FormLabel top>
                                    <strong>Erledigungsdatum</strong>
                                </FormLabel>
                                <FormStaticField value={DateUtil.getDateFormatted(order.dateScheduled) || '–'} />
                            </GridItem>
                            <GridItem cols="3">
                                <FormInputExtended
                                    label="Ort"
                                    value={orderDraft.location}
                                    isRequired
                                    isEditMode={isOrderEditMode}
                                    isInlineEditing={
                                        !isOrderEditMode &&
                                        order.status !== ORDER_STATUS.ERLEDIGT &&
                                        order.status !== ORDER_STATUS.STORNIERT
                                    }
                                    onChange={(value) => {
                                        if (isOrderEditMode) {
                                            updateOrderDraft({
                                                location: value
                                            });
                                        }
                                    }}
                                    onClickSubmit={(value) => {
                                        dispatch(
                                            updateOrder(order.id, {
                                                location: value
                                            })
                                        );
                                    }}
                                />
                            </GridItem>
                            <GridItem cols="3">
                                <FormLabel top>
                                    <strong>Standort</strong>
                                </FormLabel>
                                {isOrderEditMode ? (
                                    <FormNativeSelect
                                        options={buildingDropdownOptions}
                                        value={orderDraft.buildingId}
                                        error={isButtonUpdateClicked && !orderDraft.buildingId}
                                        onChange={(e) => {
                                            updateOrderDraft({
                                                building: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <FormStaticField value={orderBuilding ? orderBuilding.nameCode : '–'} />
                                )}
                            </GridItem>
                            <GridItem cols="12">
                                <FormLabel top bold>
                                    Bemerkung
                                </FormLabel>

                                {isOrderEditMode ? (
                                    <FormTextarea
                                        value={orderDraft.message}
                                        placeholder="Bitte geben Sie eine detaillierte Leistungsbeschreibung und sonstige Wünsche an."
                                        height="157px"
                                        onChange={(e) => {
                                            updateOrderDraft({
                                                message: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <FormStaticField value={FormatUtil.nl2br(order.message) || '–'} />
                                )}
                            </GridItem>
                        </Grid>
                    </Block>
                </FormPanel>

                <FormPanelBilling
                    isOverhead={orderDraft.isOverhead}
                    isOverheadEditable={isOverheadEditable}
                    billing={isOrderEditMode ? orderDraft.billing : order.billing}
                    isEditMode={isOrderEditMode}
                    isInlineEditing={
                        (order.status === ORDER_STATUS.OFFEN ||
                            order.status === ORDER_STATUS.FREIGEGEBEN ||
                            order.status === ORDER_STATUS.IN_ARBEIT) &&
                        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.KEY_USER)
                    }
                    isValidationMode={isOrderEditMode && isButtonUpdateClicked}
                    onChangeOverhead={(isOverhead) => {
                        dispatch(
                            updateOrder(order.id, {
                                is_overhead: isOverhead
                            })
                        );
                    }}
                    onChangeBilling={(billingDraft) => {
                        if (isOrderEditMode) {
                            updateOrderDraft({
                                billing: billingDraft
                            });
                        }
                    }}
                    onSubmitBilling={(billingDraft) => handleOnUpdateBilling(billingDraft)}
                />

                {isOrderEditMode ? (
                    <FormPanelContactPersonList
                        contactPersonList={orderDraft.contactPersonList}
                        isEditMode
                        isValidationMode={isButtonUpdateClicked}
                        onChange={(contactPersonListDraft) =>
                            updateOrderDraft({
                                contactPersonList: contactPersonListDraft
                            })
                        }
                    />
                ) : (
                    <FormPanelContactPersonList contactPersonList={order.contactPersonList} />
                )}

                {/* <FormPanel title="Kosten">
                    {isOrderEditMode ? (
                        <FormOrderCosts
                            quantity={order.quantity}
                            unit={orderDraftService ? orderDraftService.unit : ''}
                            unitPrice={orderDraftService ? orderDraftService.price : order.unitPrice}
                        />
                    ) : (
                        <FormOrderCosts
                            quantity={order.quantity}
                            unit={orderService ? orderService.unit : ''}
                            unitPrice={orderService ? orderService.price : order.unitPrice}
                        />
                    )}
                </FormPanel> */}
            </FormPanelGroup>

            {isOrderEditMode ? (
                <>
                    <Block paddingTop="70px" justify="center">
                        <TextM>
                            <strong>Aktualisieren und Status ändern zu &quot;Offen&quot;</strong>
                        </TextM>
                    </Block>
                    <Block paddingTop="30px" justify="center">
                        <Group nowrap spacing="20px">
                            <FormButton
                                label="Abbrechen"
                                icon={IconClose}
                                iconSize="small"
                                onClick={handleOnCancelOrderEdit}
                            />
                            <FormButton
                                layout="primary"
                                label="Aktualisieren"
                                icon={IconCheckbox}
                                iconSize="small"
                                onClick={handleOnClickOrderUpdate}
                            />
                        </Group>
                    </Block>
                </>
            ) : (
                <Block paddingTop="70px">
                    <OrderDetailsOptions orderId={orderId} onClickEdit={handleOnClickOrderEdit} />
                </Block>
            )}

            <Block paddingTop="70px">
                <OrderDetailsComments orderId={orderId} />
            </Block>

            {orderHistory.length > 0 && (
                <Block paddingTop="70px">
                    <OrderDetailsHistory orderId={orderId} />
                </Block>
            )}
        </PageContentBlock>
    );
};

OrderDetails.propTypes = {};
OrderDetails.defaultProps = {};

export default OrderDetails;

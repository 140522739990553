import React from 'react';
import SettingsPassword from '../SettingsPassword';

const SettingsAccount = () => {
    return <SettingsPassword />;
};

SettingsAccount.propTypes = {};
SettingsAccount.defaultProps = {};

export default SettingsAccount;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInteractiveTableRow = styled.tr({
    cursor: 'pointer',
    transition: 'background-color 0.1s ease',
    '&:hover': {
        background: 'rgba(255,255,255,0.08)'
    }
});

const TableRow = ({ children, onClick }) => {
    if (onClick && typeof onClick === 'function') {
        return <StyledInteractiveTableRow onClick={onClick}>{children}</StyledInteractiveTableRow>;
    }
    return <tr>{children}</tr>;
};

TableRow.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func
};

TableRow.defaultProps = {
    children: null,
    onClick: null
};

export default TableRow;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _at from 'lodash/at';
import _orderBy from 'lodash/orderBy';
import {
    setOrdersSearchText,
    setOrdersFilters,
    setOrdersSorting,
    setSelectedOrderIds,
    setOrdersDisplayMax
} from '../../redux/actions/ordersActions';

import { useUser } from '../../hooks/user';
import { ORDER_STATUS } from '../../global/constants';

const getOrderStatusByTimespan = (orderHistories, order, ordersStartDate, ordersEndDate) => {
    const startDate = new Date(ordersStartDate);

    /**
     * add 1 day since end dates hour is set to 0h
     */
    const endDateFinal = new Date(ordersEndDate);
    endDateFinal.setDate(endDateFinal.getDate() + 1);

    // treat ERLEDIGT date differently, actual date is relevant, not date of history entry
    if (order && order.dateScheduled) {
        const dateScheduled = new Date(order.dateScheduled);
        if (dateScheduled && dateScheduled >= startDate && dateScheduled < endDateFinal) {
            return ORDER_STATUS.ERLEDIGT;
        }
    }

    // get last status change in time frame
    const lastStatusChange = _orderBy(orderHistories, ['createdAt'], ['desc']).find((history) => {
        const { createdAt, previousStatus, nextStatus } = history;
        const createDate = new Date(createdAt);
        if (
            previousStatus !== nextStatus &&
            nextStatus !== ORDER_STATUS.ERLEDIGT &&
            createDate >= startDate &&
            createDate < endDateFinal
        ) {
            return true;
        }
        return false;
    });

    return lastStatusChange?.nextStatus || '';
};

export function useOrderList() {
    const dispatch = useDispatch();
    const { userId, userRoleName } = useUser();
    const { orders, histories } = useSelector(({ dataState }) => dataState);
    const {
        isOrdersRequest,
        ordersSearchText,
        ordersStartDate,
        ordersEndDate,
        ordersFilters,
        ordersSortingKey,
        ordersSortingOrder,
        selectedOrderIds,
        ordersDisplayMax
    } = useSelector(({ ordersState }) => ordersState);

    const ordersDisplayStep = 10;

    const [ordersFiltered, setOrdersFiltered] = useState([]);

    /**
     * filter and sort orders
     */
    useEffect(() => {
        let _ordersFiltered = Object.values(orders);

        /**
         * FILTER
         */
        if (ordersStartDate && ordersEndDate) {
            _ordersFiltered = _ordersFiltered.filter((order) => {
                const orderHistories = order.historyIds.map((historyId) => histories[historyId]);
                const orderStatus = getOrderStatusByTimespan(orderHistories, order, ordersStartDate, ordersEndDate);

                if (!ordersFilters.length) {
                    return orderStatus !== '';
                }

                return ordersFilters.includes(orderStatus);
            });
        } else if (ordersFilters.length > 0) {
            _ordersFiltered = _ordersFiltered.filter((order) => ordersFilters.includes(order.status));
        }

        /**
         * SEARCH TEXT
         */
        if (ordersSearchText.trim()) {
            _ordersFiltered = _ordersFiltered.filter((order) => {
                return order.searchString.includes(ordersSearchText.trim().toLowerCase());
            });
        }

        /**
         * SORT
         */
        _ordersFiltered = _orderBy(_ordersFiltered, (object) => _at(object, [ordersSortingKey]), [ordersSortingOrder]);

        /**
         * SET
         */
        setOrdersFiltered(_ordersFiltered);
    }, [
        orders,
        ordersSearchText,
        ordersStartDate,
        ordersEndDate,
        ordersFilters,
        ordersSortingKey,
        ordersSortingOrder,
        histories,
        userId,
        userRoleName
    ]);

    /**
     * change search text
     * @param {String} searchText
     */
    const handleChangeOrdersSearchText = (searchText) => {
        dispatch(setOrdersSearchText(searchText));

        /**
         * reset selected orders
         */
        dispatch(setSelectedOrderIds([]));

        /**
         * reset num of displayed orders
         */
        dispatch(setOrdersDisplayMax(ordersDisplayStep));
    };

    /**
     * toggle status filter
     * @param {String} filter
     */
    const toggleOrdersFilter = (filter) => {
        let ordersFiltersNew = [];
        if (ordersFilters.includes(filter)) {
            ordersFiltersNew = ordersFilters.filter((f) => f !== filter);
        } else {
            ordersFiltersNew = [...ordersFilters, filter];
        }
        dispatch(setOrdersFilters(ordersFiltersNew));

        /**
         * reset selected orders
         */
        dispatch(setSelectedOrderIds([]));

        /**
         * reset num of displayed orders
         */
        dispatch(setOrdersDisplayMax(ordersDisplayStep));
    };

    /**
     * click list sorting
     * @param {String} sortingKey
     */
    const handleSetOrdersSortingKey = (sortingKey) => {
        let sortingOrder = 'asc';
        if (sortingKey === ordersSortingKey) {
            sortingOrder = ordersSortingOrder === 'asc' ? 'desc' : 'asc';
        }
        dispatch(setOrdersSorting(sortingKey, sortingOrder));
    };

    /**
     * toggle order selection
     * @param {string} orderId
     */
    const toggleOrderSelection = (orderId) => {
        let _selectedOrderIds;
        if (selectedOrderIds.includes(orderId)) {
            _selectedOrderIds = selectedOrderIds.filter((selectedOrderId) => selectedOrderId !== orderId);
        } else {
            _selectedOrderIds = [...selectedOrderIds];
            _selectedOrderIds.push(orderId);
        }
        dispatch(setSelectedOrderIds(_selectedOrderIds));
    };

    /**
     * show more orders within list
     */
    const displayFurtherOrders = () => {
        dispatch(setOrdersDisplayMax(ordersDisplayMax + ordersDisplayStep));
    };

    return {
        orders,
        isOrdersInitialRequest: isOrdersRequest && !Object.values(orders).length,
        ordersFiltered,
        ordersSearchText,
        ordersStartDate,
        ordersEndDate,
        ordersFilters,
        ordersSortingKey,
        ordersSortingOrder,
        selectedOrderIds,
        ordersDisplayMax,
        handleChangeOrdersSearchText,
        toggleOrdersFilter,
        handleSetOrdersSortingKey,
        displayFurtherOrders,
        toggleOrderSelection
    };
}

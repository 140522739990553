/* eslint-disable */
import { REDUX_ACTIONS } from '../../global/constants';

const initialState = {
    isLoggedIn: false,
    isRequestingLogin: false,
    loginErrorText: '',
    isRequestingAutoLogin: false,
    userId: 0,
    jwt: ''
};

function authReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_LOGIN:
            return Object.assign({}, state, {
                isRequestingLogin: true,
                loginErrorText: ''
            });

        case REDUX_ACTIONS.REQUEST_LOGIN_ERROR:
            return Object.assign({}, state, {
                isRequestingLogin: false,
                loginErrorText: action.loginErrorText
            });

        case REDUX_ACTIONS.RECIEVE_LOGIN:
            return Object.assign({}, state, {
                isRequestingLogin: false,
                loginErrorText: ''
            });

        case REDUX_ACTIONS.REQUEST_AUTO_LOGIN:
            return Object.assign({}, state, {
                isRequestingAutoLogin: true
            });

        case REDUX_ACTIONS.REQUEST_AUTO_LOGIN_ERROR:
            return Object.assign({}, state, {
                isRequestingAutoLogin: false
            });

        case REDUX_ACTIONS.RECIEVE_AUTO_LOGIN:
            return Object.assign({}, state, {
                isRequestingAutoLogin: false
            });

        case REDUX_ACTIONS.LOGIN:
            return Object.assign({}, state, {
                isLoggedIn: true,
                userId: action.userId,
                jwt: action.jwt
            });

        case REDUX_ACTIONS.LOGOUT:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default authReducer;

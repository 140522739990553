/* xeslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import TypeButton from '../../atoms/TypeButton';
import IconTypeBodenreinigung from '../../assets/icons/IconTypeBodenreinigung';
import IconTypeMoebel from '../../assets/icons/IconTypeMoebel';
import IconTypeGlasreinigung from '../../assets/icons/IconTypeGlasreinigung';
import IconTypeKueche from '../../assets/icons/IconTypeKueche';
import IconTypeSonstiges from '../../assets/icons/IconTypeSonstiges';
import { usePermanentDraft } from '../../hooks/permanents';

const PermanentCreateType = ({ onClickTypeButton }) => {
    const { permanentDraft, updatePermanentDraftType } = usePermanentDraft();

    return (
        <Block justify="center">
            <Block maxWidth="1030px">
                <Grid columns="3" hSpacing="25px" vSpacing="33px">
                    <GridItem>
                        <TypeButton
                            label="Teppichböden"
                            icon={IconTypeBodenreinigung}
                            active={permanentDraft.type === 'Teppichböden'}
                            onClick={() => {
                                updatePermanentDraftType('Teppichböden');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Hartbelagböden"
                            icon={IconTypeBodenreinigung}
                            active={permanentDraft.type === 'Hartbelagböden'}
                            onClick={() => {
                                updatePermanentDraftType('Hartbelagböden');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Mobiliar"
                            icon={IconTypeMoebel}
                            active={permanentDraft.type === 'Mobiliar'}
                            onClick={() => {
                                updatePermanentDraftType('Mobiliar');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Glas"
                            icon={IconTypeGlasreinigung}
                            active={permanentDraft.type === 'Glas'}
                            onClick={() => {
                                updatePermanentDraftType('Glas');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Küche"
                            icon={IconTypeKueche}
                            active={permanentDraft.type === 'Küche'}
                            onClick={() => {
                                updatePermanentDraftType('Küche');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <TypeButton
                            label="Sonstiges"
                            icon={IconTypeSonstiges}
                            active={permanentDraft.type === 'Sonstiges'}
                            onClick={() => {
                                updatePermanentDraftType('Sonstiges');
                                onClickTypeButton();
                            }}
                        />
                    </GridItem>
                </Grid>
            </Block>
        </Block>
    );
};

PermanentCreateType.propTypes = {
    onClickTypeButton: PropTypes.func
};

PermanentCreateType.defaultProps = {
    onClickTypeButton: () => {}
};

export default PermanentCreateType;

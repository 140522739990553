/* xeslint-disable */
import axios from 'axios';
import auth from '../../utils/auth';
import api from '../../api';
import { REDUX_ACTIONS } from '../../global/constants';

export function fetchClaims() {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CLAIMS
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/claim`,
            params: {
                _limit: 100000
            },
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const claims = res.data || {};

                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CLAIMS,
                    claims
                });

                return claims;
            })
            .catch((error) => {
                console.log('#fetchClaims error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CLAIMS_ERROR
                });
            });
    };
}

export function createClaim(data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CREATE_CLAIM
        });

        return axios({
            method: 'post',
            url: `${api.apiUrl}/claim`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const claim = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CREATE_CLAIM,
                    claim
                });
                return claim;
            })
            .catch((error) => {
                console.log('#createClaim error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CREATE_CLAIM_ERROR
                });
            });
    };
}

export function fetchClaim(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CLAIM
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/claim/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const claim = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CLAIM,
                    claim
                });
                return claim;
            })
            .catch((error) => {
                console.log('#fetchClaim error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CLAIM_ERROR
                });
            });
    };
}

export function updateClaim(id, data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_UPDATE_CLAIM
        });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/claim/${id}`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const claim = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_UPDATE_CLAIM,
                    claim
                });
                return claim;
            })
            .catch((error) => {
                console.log('#updateClaim error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_UPDATE_CLAIM_ERROR
                });
            });
    };
}

export function deleteClaim(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_DELETE_CLAIM
        });

        return axios({
            method: 'delete',
            url: `${api.apiUrl}/claim/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const claim = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_DELETE_CLAIM,
                    claim
                });
                return claim;
            })
            .catch((error) => {
                console.log('#deleteClaim error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_DELETE_CLAIM_ERROR
                });
            });
    };
}

export function setClaimDraft(claimDraft) {
    return {
        type: REDUX_ACTIONS.SET_CLAIM_DRAFT,
        claimDraft
    };
}

export function resetClaimDraft() {
    return {
        type: REDUX_ACTIONS.RESET_CLAIM_DRAFT
    };
}

export function setClaimsFilters(claimsFilters) {
    return {
        type: REDUX_ACTIONS.SET_CLAIMS_FILTERS,
        claimsFilters
    };
}

export function setClaimsSorting(claimsSortingKey, claimsSortingOrder) {
    return {
        type: REDUX_ACTIONS.SET_CLAIMS_SORTING,
        claimsSortingKey,
        claimsSortingOrder
    };
}

/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Block } from '@webfox-sc/core';

import { STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';

const StyledHeading = styled.div({
    ...STYLE_TEXT_M,
    fontSize: '30px',
    lineHeight: 'normal',
    textAlign: 'center',
    color: COLORS.DARK_BLUE
});

const ViewMaintenance = () => {
    return (
        <Block
            position="absolute"
            zIndex="1000"
            top="0"
            left="0"
            width="100vw"
            height="calc(100vh - 80px)"
            justify="center"
            alignItems="center"
        >
            <Block width="100%" maxWidth="500px" padding="40px" background="white">
                <Block justify="center">
                    <StyledHeading>Technische Störung</StyledHeading>
                </Block>
                <Block paddingTop="40px">
                    Es liegt eine technische Störung vor. Die Benutzung  der Applikation ist daher nicht möglich.
                </Block>
            </Block>
        </Block>
    );
}

export default ViewMaintenance;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import DateUtil from '../utils/DateUtil';
import { PERMANENT_STATUS, PERMANENT_STATUS_LABELS } from '../global/constants';

export function usePermanentHistory(permanentId) {
    const permanent = useSelector(({ dataState }) => dataState.permanents[permanentId]);
    const histories = useSelector(({ dataState }) => dataState.histories);
    const users = useSelector(({ dataState }) => dataState.users);
    const roles = useSelector(({ dataState }) => dataState.roles);

    const [permanentHistory, setPermanentHistory] = useState([]);
    const [permanentComments, setPermanentComments] = useState([]);

    useEffect(() => {
        if (permanent) {
            const _permanentHistory = [];
            const _permanentComments = [];
            const historyIds = permanent.historyIds || [];

            historyIds.forEach((historyId) => {
                const history = histories[historyId];
                const { previousStatus, nextStatus, comment, creatorId, diff } = history;

                const creator = users[creatorId];
                const creatorUsername = creator?.username || '?';
                const role = creator ? roles[creator.roleId] : null;
                const roleName = role ? `(${role.name})` : '';
                const creatorDescription = `${creatorUsername} ${roleName}`;

                /**
                 * history
                 */
                let text = '';

                if (previousStatus !== nextStatus) {
                    if (!previousStatus && nextStatus === PERMANENT_STATUS.ZUR_FREIGABE) {
                        text = `Erstellt von ${creatorDescription}`;
                    } else {
                        text = `Status geändert zu <em>${PERMANENT_STATUS_LABELS[nextStatus]}</em> von ${creatorDescription}`;
                    }
                } else if (diff?.assignee) {
                    text = `<em>Bearbeitung</em> geändert von ${creatorDescription}`;
                    if (comment) {
                        text += `<br /><br />Grund der Änderung:<br /><em>${comment}</em>`;
                    }
                } else if (diff?.billing) {
                    text = `<em>Verrechnungsdaten</em> geändert von ${creatorDescription}`;
                } else if (diff?.contact_person_list) {
                    text = `<em>Ansprechpartner</em> geändert von ${creatorDescription}`;
                }

                if (text) {
                    _permanentHistory.push({
                        createdAt: history.createdAt,
                        dateFormatted: DateUtil.getDateTimeFormattedExtended(history.createdAt),
                        text
                    });
                }

                /**
                 * comments
                 */
                if (!previousStatus && !nextStatus && comment && Object.keys(diff).length === 0) {
                    _permanentComments.push({
                        createdAt: history.createdAt,
                        dateFormatted: DateUtil.getDateTimeFormattedExtended(history.createdAt),
                        creatorDescription,
                        comment
                    });
                }
            });

            setPermanentHistory(_orderBy(_permanentHistory, ['createdAt'], ['asc']));
            setPermanentComments(_orderBy(_permanentComments, ['createdAt'], ['asc']));
        }
    }, [permanent, histories, users, roles]);

    return {
        permanentHistory,
        permanentComments
    };
}

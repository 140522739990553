import React from 'react';

const IconArrowRight = (
    <svg viewBox="0 0 100 100">
        <path
            d="M76.022 50.458L25.637 92.145a1 1 0 01-1.637-.77V8a1 1 0 011.637-.77l50.385 41.687a1 1 0 010 1.54z"
            stroke="currentColor"
            strokeWidth="6"
            fill="none"
            fillRule="evenodd"
        />
    </svg>
);

export default IconArrowRight;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconClose from '../../assets/icons/IconClose';
import FormLabel from '../../atoms/FormLabel';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormInput from '../../atoms/FormInput';
import FormButton from '../../atoms/FormButton';
import TextS from '../../atoms/TextS';
import { createUser } from '../../redux/actions/usersActions';
import { useRoles } from '../../hooks/roles';
import AppUtil from '../../utils/AppUtil';

const SettingsUsersCreate = ({ onClickCancel, onCreated }) => {
    const dispatch = useDispatch();
    const { roleDropdownOptions } = useRoles();

    const [usernameDraft, setUsernameDraft] = useState('');
    const [emailDraft, setEmailDraft] = useState('');
    const [passwordDraft, setPasswordDraft] = useState('');
    const [roleIdDraft, setRoleIdDraft] = useState('');
    const [isButtonCreateClicked, setIsButtonCreateClicked] = useState(false);

    const handleClickCreate = async () => {
        if (
            usernameDraft &&
            AppUtil.isEmailValid(emailDraft) &&
            AppUtil.isPasswordValid(passwordDraft) &&
            roleIdDraft
        ) {
            await dispatch(
                createUser({
                    username: usernameDraft,
                    email: emailDraft,
                    password: passwordDraft,
                    role: roleIdDraft
                })
            );
            onCreated();
        } else {
            setIsButtonCreateClicked(true);
        }
    };

    return (
        <Block padding="20px" border="1px solid white">
            <Grid spacing="20px">
                <GridItem cols="4">
                    <FormLabel top>Name *</FormLabel>
                    <FormInput
                        ariaLabel="Name"
                        value={usernameDraft}
                        error={isButtonCreateClicked && !usernameDraft}
                        onChange={(e) => setUsernameDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>E-Mail *</FormLabel>
                    <FormInput
                        ariaLabel="E-Mail"
                        value={emailDraft}
                        error={isButtonCreateClicked && !AppUtil.isEmailValid(emailDraft)}
                        onChange={(e) => setEmailDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Rolle *</FormLabel>
                    <FormNativeSelect
                        options={roleDropdownOptions}
                        value={roleIdDraft}
                        error={isButtonCreateClicked && !roleIdDraft}
                        onChange={(e) => setRoleIdDraft(e.target.value)}
                    />
                </GridItem>
            </Grid>

            <Block paddingTop="20px">
                <Grid spacing="20px">
                    <GridItem cols="4">
                        <FormLabel top>Passwort *</FormLabel>
                        <FormInput
                            ariaLabel="Passwort"
                            value={passwordDraft}
                            error={isButtonCreateClicked && !AppUtil.isPasswordValid(passwordDraft)}
                            onChange={(e) => setPasswordDraft(e.target.value)}
                        />
                    </GridItem>
                </Grid>
            </Block>
            <Block paddingTop="10px">
                <TextS>
                    Das Passwort muss mindestens 8 Zeichen lang sein und 3 von 4 Kriterien erfüllen:
                    <br />
                    - mind. 1 Kleinbuchstabe (a–z)
                    <br />
                    - mind. 1 Großbuchstabe (A–Z)
                    <br />
                    - mind. 1 Ziffer (0–9)
                    <br />- mind. 1 der folgenden Sonderzeichen: #?!@$%^&*\-_
                </TextS>
            </Block>

            <Group paddingTop="20px" spacing="20px">
                <FormButton label="Erstellen" icon={IconCheckbox} iconSize="small" onClick={handleClickCreate} />
                <FormButton label="Abbrechen" icon={IconClose} iconSize="small" onClick={onClickCancel} />
            </Group>
        </Block>
    );
};

SettingsUsersCreate.propTypes = {
    onClickCancel: PropTypes.func,
    onCreated: PropTypes.func
};
SettingsUsersCreate.defaultProps = {
    onClickCancel: () => {},
    onCreated: () => {}
};

export default SettingsUsersCreate;

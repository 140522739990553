/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';

/* eslint-disable react/prefer-stateless-function */
class ServiceListEmptyState extends React.Component {
    render() {
        return (
            <div className="comp-list-empty-state">
                <h4>Keine Aufträge vorhanden</h4>
            </div>
        );
    }
}

ServiceListEmptyState.propTypes = {};

export default ServiceListEmptyState;

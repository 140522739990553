import Strapi from 'strapi-sdk-javascript';

// console.log('#REACT_APP_PUBLIC_URL: ', process.env.REACT_APP_PUBLIC_URL);
// console.log('#REACT_APP_API_URL: ', process.env.REACT_APP_API_URL);
// console.log('#REACT_APP_LOCATION: ', process.env.REACT_APP_LOCATION);

const strapiapi = new Strapi(process.env.REACT_APP_API_URL);

export default {
    strapiapi,
    apiUrl: process.env.REACT_APP_API_URL,
    location: process.env.REACT_APP_LOCATION,
    additionalContactsGGB: [
        {
            name: process.env.REACT_APP_CONTACT_GGB_NAME_1,
            email: process.env.REACT_APP_CONTACT_GGB_EMAIL_1
        },
        {
            name: process.env.REACT_APP_CONTACT_GGB_NAME_2,
            email: process.env.REACT_APP_CONTACT_GGB_EMAIL_2
        }
    ],
    additionalContactsAll: [
        {
            name: process.env.REACT_APP_CONTACT_GGB_NAME_1,
            email: process.env.REACT_APP_CONTACT_GGB_EMAIL_1
        },
        {
            name: process.env.REACT_APP_CONTACT_GGB_NAME_2,
            email: process.env.REACT_APP_CONTACT_GGB_EMAIL_2
        },
        {
            name: process.env.REACT_APP_CONTACT_CLIENT_NAME_1,
            email: process.env.REACT_APP_CONTACT_CLIENT_EMAIL_1
        },
        {
            name: process.env.REACT_APP_CONTACT_CLIENT_NAME_2,
            email: process.env.REACT_APP_CONTACT_CLIENT_EMAIL_2
        },
        {
            name: process.env.REACT_APP_CONTACT_CLIENT_NAME_3,
            email: process.env.REACT_APP_CONTACT_CLIENT_EMAIL_3
        }
    ]
};

const orderInitial = {
    id: '',
    type: '',
    serviceId: '',
    dateDone: null,
    datePlanned: null,
    dateScheduled: null,
    location: '',
    buildingId: '',
    message: '',
    assigneeId: '',
    isOverhead: false,
    billing: {
        org_id: '',
        c: '',
        w: '',
        g: ''
    },
    contactPersonList: [
        {
            first_name: '',
            last_name: '',
            email: ''
        }
    ],
    quantity: undefined
};

export default orderInitial;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_TEXT_S } from '../../styles/type';

const StyledComponent = styled.div(({ isLinkStyle }) => {
    const style = {
        ...STYLE_TEXT_S
    };

    if (isLinkStyle) {
        style.textDecoration = 'underline';
        style['&:hover'] = {
            textDecoration: 'none'
        };
    }
    return style;
});

const TextS = ({ isLinkStyle, children }) => {
    return <StyledComponent isLinkStyle={isLinkStyle}>{children}</StyledComponent>;
};

TextS.propTypes = {
    isLinkStyle: PropTypes.bool,
    children: PropTypes.any
};

TextS.defaultProps = {
    isLinkStyle: false,
    children: ''
};

export default TextS;

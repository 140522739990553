import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_TEXT_M } from '../../styles/type';
import errorImageSrc from '../../assets/images/icon-error.png';

const StyledComponent = styled.div({
    position: 'relative'
});

const StyledTextarea = styled.textarea(({ height }) => {
    const style = {
        border: '1px solid white',
        background: 'transparent',
        height: height || '54px',
        minHeight: '54px',
        padding: '15px',
        width: '100%',
        boxSizing: 'border-box',
        outline: 0,
        verticalAlign: 'top',
        ...STYLE_TEXT_M,
        '&::placeholder': {
            fontStyle: 'italic'
        }
    };

    return style;
});

const StyledErrorImage = styled.img({
    position: 'absolute',
    top: '-16px',
    right: '10px',
    width: '24px',
    height: '24px',
    verticalAlign: 'top'
});

const FormTextarea = ({ value, placeholder, height, error, onClick, onChange }) => {
    const [valueDraft, setValueDraft] = useState(value);

    useEffect(() => {
        setValueDraft(value);
    }, [value]);

    return (
        <StyledComponent>
            <StyledTextarea
                value={valueDraft}
                placeholder={placeholder}
                height={height}
                onClick={onClick}
                onChange={(e) => {
                    setValueDraft(e.target.value);
                    onChange(e);
                }}
            />
            {error && <StyledErrorImage src={errorImageSrc} alt="Error" />}
        </StyledComponent>
    );
};

FormTextarea.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    height: PropTypes.string,
    error: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func
};

FormTextarea.defaultProps = {
    value: '',
    placeholder: '',
    height: '',
    error: false,
    onClick: () => {},
    onChange: () => {}
};

export default FormTextarea;

import React from 'react';
import { Block } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import HeadingM from '../../atoms/HeadingM';
import TextM from '../../atoms/TextM';
import TextXL from '../../atoms/TextXL';

const Privacy = () => {
    return (
        <PageContentBlock>
            <Block paddingTop="180px" justify="center">
                <Block maxWidth="800px">
                    <HeadingM>Datenschutzhinweis</HeadingM>

                    <Block paddingTop="L">
                        <TextM>
                            <strong>
                                Informationen über die Erhebung und Verarbeitung personenbezogener Daten gegenüber dem
                                Betroffenen gem. Art. 13, 14 und 21 DSGVO bei der Nutzung der Webanwendung BeClean
                            </strong>
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextM>
                            Die Unternehmensgruppe Gegenbauer verarbeitet Ihre personenbezogenen Daten grundsätzlich auf
                            Basis der Europäischen Datenschutzgrundverordnung (Verordnung (EU) 2016/679 – DSGVO) und des
                            neuen Bundesdatenschutzgesetzes (BDSG).
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>1. Verantwortlicher</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Gegenbauer Services GmbH
                            <br />
                            Paul-Robeson-Straße 37
                            <br />
                            10439 Berlin
                            <br />
                            Telefon: +49 30 44670-76020
                            <br />
                            Fax: +49 30 44670- 90101
                            <br />
                            E-Mail: info@gegenbauer.de
                            <br />
                            Webseite: www.gegenbauer.de
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>2. Kontaktdaten des Datenschutzbeauftragten</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Gegenbauer Services GmbH
                            <br />
                            Datenschutzbeauftragter
                            <br />
                            Paul-Robeson-Straße 37
                            <br />
                            10439 Berlin
                            <br />
                            Telefon: +49 30 44670-76020
                            <br />
                            Fax: +49 30 44670- 90101
                            <br />
                            E-Mail: datenschutz@gegenbauer.de
                            <br />
                            Webseite: www.gegenbauer.de
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>3. Verarbeitungszwecke und Rechtsgrundlage</TextXL>
                        <br />
                        <br />
                        <TextM>
                            <strong>a) Zur Erfüllung von vertraglichen Pflichten (Art. 6 Abs.1 Buchst. b DSGVO)</strong>
                            <br />
                            <br />
                            Die Verarbeitung personenbezogener Daten über BeClean erfolgt zur Begründung, Durchführung
                            und Beendigung eines zwischen Ihnen und uns bestehenden Vertrages über die Erbringung von
                            Dienst- und Werkleistungen sowie zur Durchführung vorvertraglicher Maßnahmen, wie etwa der
                            Erstellung von Angeboten.
                            <br />
                            <br />
                            Mit Hilfe der Anwendung BeClean ist es dem Kunden möglich, Reklamationen gegenüber der
                            Gegenbauer Services GmbH zu melden und die Gegenbauer Services GmbH mit Ad-hoc- sowie
                            Daueraufträgen zu beauftragen. Die Gegenbauer Services GmbH hat die Möglichkeit den
                            Bearbeitungsstatus dieser Aufträge zu ändern.
                            <br />
                            <br />
                            Darüber hinaus richten sich die Zwecke der Datenverarbeitung nach den konkreten geschuldeten
                            Dienst- und Werkleistungen oder Produkten und können neben der klassischen Leistung unter
                            anderem auch Bedarfsanalysen, Beratungen und Betreuung umfassen. Die weiteren Einzelheiten
                            zum Zweck der Datenverarbeitung sind den jeweiligen (auch vorvertraglichen)
                            Vertragsunterlagen zu entnehmen sowie unter www.gegenbauer.de/kdn.
                            <br />
                            <br />
                            <strong>b) Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a DSGVO)</strong>
                            <br />
                            <br />
                            Die Rechtmäßigkeit der Verarbeitung besteht ferner, wenn Sie uns zuvor eine Einwilligung für
                            den entsprechenden Verarbeitungszweck erteilt haben. Der Verarbeitungszweck ergibt sich aus
                            einer Einwilligungserklärung und kann etwa den Empfang von Newslettern oder der Verwendung
                            eines Kontaktformulars auf unserer Webseite betreffen. Sie können Ihre erteilte Einwilligung
                            jederzeit für die Zukunft widerrufen. Durch den Widerruf der Einwilligung wird die
                            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung jedoch
                            nicht berührt. Den Widerruf können Sie richten an widerruf@gegenbauer.de.
                            <br />
                            <br />
                            <strong>c) Aufgrund gesetzlicher Vorgaben (Art. 6 Abs.1 Buchst. c DSGVO)</strong>
                            <br />
                            <br />
                            Eine Verarbeitung kann ferner aufgrund von steuerrechtlichen Kontroll- und Meldepflichten
                            erfolgen, die auf gesetzlichen Vorgaben beruhen.
                            <br />
                            <br />
                            <strong>d) Im Rahmen der lnteressenabwägung (Art. 6 Abs. 1 Buchst. f DSGVO)</strong>
                            <br />
                            <br />
                            Im Einzelfall ist es ferner erforderlich, Ihre Daten zu weiteren Zwecken zu verarbeiten,
                            wobei dies auf einem berechtigten Interesse von uns oder Dritten beruht. Hierzu zählen unter
                            anderem folgende Zwecke:
                            <br />
                            <br />
                            <ul>
                                <li>
                                    Bearbeitung von sonstigen Kontaktaufnahmen eines Betroffenen/Nutzers, die dieser
                                    über die bereitgestellte E-Mail-Adresse an uns sendet und nicht die Vorbereitung,
                                    Durchführung oder Beendigung eines Vertrages betreffen
                                </li>
                                <li>
                                    Vertragsanbahnung und Vertragserfüllung (etwa im Rahmen der Benennung als
                                    Ansprechpartner, wenn Sie selbst nicht der Vertragspartner sind, sondern etwa nur
                                    ein Mitarbeiter unseres Vertragspartners bzw. Interessenten)
                                </li>
                                <li>
                                    Prüfung und Optimierung von Verfahren zur Bedarfsanalyse und zu direkter
                                    Kundenansprache
                                </li>
                                <li>
                                    Werbung, Markt- und Meinungsforschung oder Kundenbefragungen, soweit Sie der Nutzung
                                    Ihrer Daten nicht widersprochen haben
                                </li>
                                <li>Sicherstellung der IT-Sicherheit und des IT-Betriebs</li>
                                <li>
                                    Konsultation von und Datenaustausch mit Auskunfteien zur Ermittlung von Bonitäts-
                                    bzw. Ausfallrisiken
                                </li>
                                <li>Verhinderung von Straftaten und Sammlung von Beweismitteln</li>
                                <li>
                                    Maßnahmen zur Geschäftssteuerung und Weiterentwicklung von Dienstleistungen und
                                    Produkten
                                </li>
                                <li>Risikosteuerung in der Unternehmensgruppe Gegenbauer</li>
                                <li>
                                    Bearbeitung von Schadensersatzansprüchen und Versicherungsschäden einschließlich der
                                    gerichtlichen Geltendmachung oder Abwehr
                                </li>
                                <li>Im Rahmen einer Due-Diligence</li>
                            </ul>
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>4. Kategorien der zu verarbeitenden personenbezogenen Daten</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Folgende Kategorien können bei der Verwendung der Webanwendung BeClean verarbeitet werden:
                            <br />
                            <br />
                            <ul>
                                <li>Daten zur Person (Name, Vorname, Rolle/Funktion)</li>
                                <li>
                                    Kommunikationsdaten (dienstliche Telefon-/Telefaxnummer, dienstliche E-Mail-Adresse)
                                </li>
                                <li>Vertragsstammdaten (Daten im Zusammenhang mit dem Auftrag,)</li>
                                <li>
                                    Auftragsdaten (z.B. Leistungsart, Status zum Auftrag, Annahmedaten) sowie Datum und
                                    Uhrzeit der Auftragserteilung, der Auftragsannahme, Startdatum, Erledigungsdatum)
                                </li>
                                <li>
                                    Abrechnungs-, Verrechnungsdaten, Leistungs- und Zahlungsdaten (z.B. Kostenstelle,
                                    Leistungsbeschreibung, Leistungsort, Leistungstyp, Datum der Erledigung)
                                </li>
                            </ul>
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>5. Empfänger und Kategorien von Empfängern personenbezogener Daten</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Die Unternehmensgruppe Gegenbauer hat sich dazu entschlossen, einzelne
                            Verarbeitungstätigkeiten zu zentralisieren. Aus diesem Grund können innerhalb der
                            Unternehmensgruppe diejenigen Stellen Zugriff auf Ihre Daten bekommen, die diese zur
                            Erfüllung unserer vertraglichen und gesetzlichen Pflichten benötigen. Darüber hinaus werden
                            Ihre personenbezogenen Daten, soweit dies erforderlich ist, an von uns eingesetzte
                            Dienstleister übermittelt, die sich uns gegenüber zusätzlich auf die Wahrung der
                            datenschutzrechtlichen Grundsätze zu verpflichten haben.
                            <br />
                            <br />
                            Ihre Daten können ferner an Unternehmen übermittelt werden, die für uns als
                            Auftragsverarbeiter tätig sind. Dies können sein: Unterstützung/Wartung von
                            EDV-/IT-Anwendungen, Archivierung, Belegbearbeitung, Call-Center-Services,
                            Compliance-Services, Controlling, Datenvernichtung, Einkauf/Beschaffung, Flächenmanagement,
                            Beitreibung, Kun-denverwaltung, Marketing, Medientechnik, Meldewesen, Research,
                            Risikocontrolling, Telefonie, Videolegitimation, Webseitenmanagement,
                            Wirtschaftsprüfungsdienstleistung, Zahlungsverkehr.
                            <br />
                            <br />
                            Sonstige Dritte erhalten von uns nur personenbezogene Daten, wenn gesetzliche Bestimmungen
                            dies vorsehen, Sie eingewilligt haben oder wir ein berechtigtes Interesse verfolgen. Unter
                            diesen Voraussetzungen können Empfänger personenbezogener Daten z. B. sein:
                            <br />
                            <br />
                            <ul>
                                <li>
                                    Öffentliche Stellen und Institutionen bei Vorliegen einer gesetzlichen oder
                                    behördlichen Verpflichtung
                                </li>
                                <li>Haftpflichtversicherungen (etwa in Fällen von Versicherungsschäden)</li>
                            </ul>
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>6. Herkunft der personenbezogenen Daten</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Personenbezogene Daten können wir von Ihnen im Rahmen unserer Geschäftsbeziehung bzw. der
                            Nutzung der Webanwendung BeClean erhalten. Personenbezogene Daten können darüber hinaus,
                            soweit dies rechtlich zulässig ist, von anderen Unternehmen der Unternehmensgruppe
                            Gegenbauer oder sonstige Dritte (z. B. Arbeitgeber, Kunde) an uns übermittelt werden. Diese
                            Übermittlung kann etwa aufgrund einer Geschäftsbeziehung, eines berechtigten Interesses,
                            einer Auftragsverarbeitung oder einer von Ihnen gegenüber dem Dritten oder dem Unternehmen
                            der Unternehmensgruppe erteilten Einwilligung erfolgen. Eine Übermittlung kann aber zum
                            Beispiel auch zur Erfüllung von Verträgen, die mit Ihnen bestehen, erforderlich sein.
                            <br />
                            <br />
                            Personenbezogene Daten entnehmen wir darüber hinaus im Einzelfall auch aus öffentlich
                            zugänglichen Quellen (z. B. Handels- und Vereinsregister, Presse, Medien, Internet).
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>7. Datenübermittlung an Drittländer oder internationale Organisationen</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Eine Datenübermittlung in Länder außerhalb der EU bzw. des Europäischen Wirtschaftsraumes
                            (sogenannte Drittstaaten) findet grundsätzlich nicht statt. Etwas Anderes gilt nur, soweit
                            dies zur Durchführung des mit Ihnen bestehenden Vertragsverhältnisses zwingend erforderlich
                            oder gesetzlich vorgeschrieben ist oder Sie uns eine Einwilligung erteilt haben.
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>8. Dauer der Speicherung personenbezogener Daten</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Ihre personenbezogenen Daten verarbeiten wir, solange es für die Erfüllung unserer
                            vertraglichen und gesetzlichen Pflichten erforderlich ist. Ist die Verarbeitung nicht mehr
                            erforderlich, werden die Daten von uns gelöscht, soweit nicht gesetzliche
                            Aufbewahrungspflichten oder berechtigte Interessen unsererseits dem entgegenstehen. Die
                            gesetzlichen Aufbewahrungspflichten können sich insbesondere aus dem Handels- und
                            Steuergesetz ergeben und betragen zwischen 2 und 10 Jahren. Ein berechtigtes Interesse kann
                            bei der Erhaltung von Beweismitteln vorliegen. In diesem Fall erfolgt eine Löschung nach
                            Ablauf der jeweils geltenden Verjährungsfristen. Diese können bis zu 30 Jahren betragen; die
                            Regelverjährung beträgt jedoch 3 Jahre.
                            <br />
                            <br />
                            Ist eine Löschung nicht möglich, wie etwa für Daten, die in einem elektronischen
                            Archivsystem gespeichert sind, werden diese Daten für eine weitere Verarbeitung gesperrt.
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>9. Pflicht zur Bereitstellung von Daten</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Zur Bearbeitung der erteilten Aufträge über die Webanwendung BeClean ist es notwendig, die
                            erforderlichen personenbezogenen Daten bereitzustellen. Eine Bereitstellungspflicht kann
                            ferner aus dem Gesetz folgen. Ohne diese Daten können wir über die Webanwendung BeClean
                            keine Aufträge oder Reklamationen entgegennehmen.
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>10. Ihre Rechte</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Sie haben jederzeit das Recht auf
                            <br />
                            <br />
                            <ul>
                                <li>Auskunft (Art. 15 DSGVO) der Sie betreffenden personenbezogenen Daten,</li>
                                <li>
                                    Berichtigung (Art. 16 DSGVO) oder Löschung (Art. 17 DSGVO) der Sie betreffenden
                                    personenbezogenen Daten,
                                </li>
                                <li>Einschränkung der Verarbeitung (Art. 18 DSGVO).</li>
                            </ul>
                            <br />
                            Bei Vorliegen der in Art. 20 DSGVO genannten Voraussetzungen können Sie von uns Erhalt Ihrer
                            personenbezogenen Daten, die Sie uns auf Grund einer Einwilligung oder eines Vertrages
                            bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format oder
                            deren Übermittlung an einen anderen Verantwortlichen verlangen (Recht auf
                            Datenübertragbarkeit, Art. 20 DSGVO).
                            <br />
                            <br />
                            <strong>
                                Soweit die Verarbeitung Ihrer Daten auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO)
                                beruht, können Sie diese jederzeit für die Zukunft widerrufen; die Rechtmäßigkeit der
                                auf Grund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung bleibt hiervon
                                unberührt.
                            </strong>
                            <br />
                            <br />
                            Zur Geltendmachung all dieser Rechte sowie zu weiteren Fragen zum Thema personenbezogene
                            Daten können Sie sich jederzeit unter datenschutz@gegenbauer.de oder Gegenbauer Services
                            GmbH, Datenschutzbeauftragter, Paul-Robeson-Str. 37, 10439 Berlin an uns wenden.
                            <br />
                            <br />
                            Sie haben ferner das Recht auf eine Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO),
                            insbesondere in dem EU-Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des
                            Orts des mutmaßlichen Verstoßes, wenn Sie der Auffassung sind, dass die Verarbeitung Ihrer
                            personenbezogenen Daten durch uns gegen die DSGVO verstößt.
                            <br />
                            <br />
                            Die für uns zuständige Aufsichtsbehörde ist
                            <br />
                            <br />
                            Berliner Beauftragte für Datenschutz und Informationsfreiheit
                            <br />
                            Friedrichstraße 219
                            <br />
                            10969 Berlin
                            <br />
                            mailbox@datenschutz-berlin.de.
                            <br />
                            <br />
                            Weitere verwaltungsrechtliche oder gerichtliche Rechtsbehelfe, die Ihnen möglicherweise
                            zustehen, bleiben hiervon unberührt.
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>Widerspruchsrecht gemäß Art. 21 DSGVO</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Sie haben jederzeit das Recht, aus persönlichen Gründen Widerspruch gegen die Verarbeitung
                            Ihrer Daten einzulegen, die zur Wahrung unserer berechtigten Interessen oder der
                            berechtigten Interessen Dritter erforderlich ist (Verarbeitung gemäß Art. 6 Abs. lit. f)
                            DSGVO). Ihren Widerspruch und dessen Begründung richten Sie bitte an widerruf@gegenbauer.de.
                            <br />
                            <br />
                            Wir werden anhand der von Ihnen angegebenen Gründe unverzüglich, spätestens jedoch innerhalb
                            von einem Monat nach Zugang Ihres Widerspruchs, prüfen, ob wir zur Löschung Ihrer Daten
                            verpflichtet sind, oder eine weitere Verarbeitung Ihrer Daten durch uns zur Wahrung
                            überwiegender schutzwürdiger Interessen oder zur Geltendmachung, Ausübung oder Verteidigung
                            von Rechtsansprüchen erforderlich ist. Über das Ergebnis unserer Prüfung werden wir Sie
                            schriftlich oder in Textform unterrichten.
                        </TextM>
                    </Block>
                    <Block paddingTop="L">
                        <TextXL>11. Automatisierte Entscheidungsfindung (einschließlich Profiling)</TextXL>
                        <br />
                        <br />
                        <TextM>
                            Eine automatisierte Entscheidungsfindung (einschließlich Profiling) findet in der
                            Unternehmensgruppe Gegenbauer nicht statt. Sollten wir dieses Verfahren in Einzelfällen
                            einsetzen, werden wir Sie hierüber gesondert informieren, soweit dies gesetzlich vorgegeben
                            ist.
                        </TextM>
                    </Block>
                </Block>
            </Block>
        </PageContentBlock>
    );
};

Privacy.propTypes = {};
Privacy.defaultProps = {};

export default Privacy;

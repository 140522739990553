/* eslint-disable */
/**
 *
 * CockpitClaim
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import TopFive from '../TopFive';
import { BarChart, Legend } from 'react-easy-chart';
import { PieChart, Pie } from 'recharts';

class CockpitClaim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}

    // getAverageTime(claims) {
    //     let i;
    //     let avgTime = [];
    //     for (i = 0; i < claims.length; i++) {
    //         if (claims[i].status == "ERLEDIGT") {
    //             console.log("created", claims[i].date_created);
    //             console.log("done", claims[i].date_done);
    //             avgTime.push(claims[i].date_done - claims[i].date_created);
    //             console.log("avgTime", avgTime);
    //         }
    //     }
    // }

    render() {
        let startDate = this.props.startDate;
        let endDate = this.props.endDate;

        let filteredData = this.props.data.filter(
            (c) => new Date(c.date_created) >= startDate && new Date(c.date_created) <= endDate
        );

        let totalOrders = filteredData.length;
        let openOrders = this.props.countOrders(filteredData, 'OFFEN');
        let deniedOrders = this.props.countOrders(filteredData, 'UNBERECHTIGT');
        let progressOrders = this.props.countOrders(filteredData, 'IN ARBEIT');
        let doneOrders = this.props.countOrders(filteredData, 'ERLEDIGT');
        let confirmedOrders = progressOrders + doneOrders;

        let barData = [
            {
                x: 'OFFEN: ' + openOrders,
                y: openOrders,
                color: '#63008E'
            },
            {
                x: 'IN ARBEIT: ' + progressOrders,
                y: progressOrders,
                color: '#67DBC6'
            },
            {
                x: 'ERLEDIGT: ' + doneOrders,
                y: doneOrders,
                color: '#FFF'
            }
        ];
        const barConfig = [{ color: '#63008E' }, { color: '#67DBC6' }, { color: '#FFF' }];

        let pieData = [
            {
                key: 'BERECHTIGT: ' + confirmedOrders,
                value: confirmedOrders,
                color: '#ffffff'
            },

            {
                key: 'UNBERECHTIGT: ' + deniedOrders,
                value: deniedOrders,
                color: '#000'
            }
        ];

        const pieData2 = [
            {
                key: 'Berechtigt',
                value: confirmedOrders,
                fill: 'white'
            },
            {
                key: 'Unberechtigt',
                value: deniedOrders,
                fill: 'black'
            }
        ];

        const pieConfig = [{ color: '#ffffff' }, { color: '#000' }];

        return (
            <div className="sub-base center-flex">
                <Container className="grid-container grid-reset">
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <fieldset>
                                <legend>Reklamationen</legend>
                                <div className="cockpit-background center-flex">
                                    <h4>
                                        Insgesamt {totalOrders} Reklamationen <br />
                                        davon {deniedOrders} unberechtigt
                                        {/* <br />Durchschnittliche
                                        Bearbeitungszeit: ? */}
                                    </h4>

                                    <BarChart height={200} width={450} data={barData} />
                                    <Legend data={barData} dataId={'x'} config={barConfig} horizontal />
                                </div>
                                <div className="cockpit-background center-flex">
                                    <h3 className="cockpit-header">Berechtigungsprüfung</h3>
                                    <PieChart width={200} height={200}>
                                        <Pie
                                            data={pieData2}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={100}
                                            innerRadius={95}
                                            startAngle={90}
                                            endAngle={-270}
                                            fill="transparent"
                                            stroke="none"
                                        />
                                    </PieChart>
                                    <Legend data={pieData} dataId={'key'} config={pieConfig} horizontal />
                                    {/* <div className="claim-count">
                    <h1>{totalOrders}</h1>
                    <h2>Reklamationen</h2>
                  </div> */}
                                </div>

                                <TopFive data={filteredData} />
                            </fieldset>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CockpitClaim;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@webfox-sc/core';
import FormButton from '../FormButton';
import { STYLE_TEXT_M_BOLD } from '../../styles/type';
import { COLORS } from '../../styles/common';

const dropdownTheme = {
    button: {
        minHeight: '54px',
        border: '1px solid white',
        background: 'transparent',
        padding: '0 22px',
        outline: 0,
        transition: 'background-color 0.2s ease, border-color 0.2s ease',
        svg: {
            width: '24px',
            height: '24px'
        },
        span: {
            display: 'inline-block',
            minWidth: '30px',
            marginLeft: '10px',
            ...STYLE_TEXT_M_BOLD,
            color: 'white',
            textTransform: 'uppercase'
        },
        '&[data-with-icon-right]': {
            paddingRight: '12px'
        },
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.14)'
        },
        '&:active': {
            background: COLORS.WHITE,
            span: {
                color: COLORS.DARK_BLUE
            }
        }
    },
    menu: {
        background: COLORS.DARK_BLUE,
        top: '3px'
    }
};

const OrderListDropdown = ({ disabled, onClickChangeCostcenter }) => {
    const [show, setShow] = useState(false);

    return (
        <Dropdown label="Auswahl" show={show} disabled={disabled} onChange={(s) => setShow(s)} theme={dropdownTheme}>
            <FormButton
                label="Kostenstelle ändern"
                onClick={() => {
                    setShow(false);
                    onClickChangeCostcenter();
                }}
            />
        </Dropdown>
    );
};

OrderListDropdown.propTypes = {
    ...Dropdown.propTypes,
    disabled: PropTypes.bool,
    onClickChangeCostcenter: PropTypes.func
};

OrderListDropdown.defaultProps = {
    ...Dropdown.defaultProps,
    disabled: false,
    onClickChangeCostcenter: () => {}
};

export default OrderListDropdown;

/* xeslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import TextL from '../../atoms/TextL';
import FormStaticField from '../../atoms/FormStaticField';
import { useClaim } from '../../hooks/claims';
import { useClaimHistory } from '../../hooks/useClaimHistory';

const ClaimDetailsHistory = ({ claimId }) => {
    const { claim } = useClaim(claimId);
    const { claimHistory } = useClaimHistory(claimId);

    if (!claim) {
        return null;
    }

    return (
        <>
            <Block justify="center">
                <TextL>
                    <strong>Historie</strong>
                </TextL>
            </Block>
            <Block paddingTop="20px" justify="center">
                <Block width="100%" maxWidth="1000px">
                    <Group vertical spacing="5px">
                        {claimHistory.map((historyItem, index) => {
                            return (
                                <Group key={String(index)} nowrap spacing="5px">
                                    <GroupItem width="40%">
                                        <FormStaticField value={historyItem.dateFormatted} height="100%" />
                                    </GroupItem>
                                    <GroupItem grow>
                                        <FormStaticField value={historyItem.text} />
                                    </GroupItem>
                                </Group>
                            );
                        })}
                    </Group>
                </Block>
            </Block>
        </>
    );
};

ClaimDetailsHistory.propTypes = {
    claimId: PropTypes.any
};

ClaimDetailsHistory.defaultProps = {
    claimId: null
};

export default ClaimDetailsHistory;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormCheckbox from '../../atoms/FormCheckbox';
import FormStaticField from '../../atoms/FormStaticField';
import FormButton from '../../atoms/FormButton';
import TextS from '../../atoms/TextS';
import TextM from '../../atoms/TextM';
import { useBilling } from '../../hooks/billing';

const FormPanelBilling = ({
    billing,
    isOverhead,
    isOverheadEditable,
    showFormLabel,
    isEditMode,
    isValidationMode,
    isInlineEditing,
    onChangeOverhead,
    onChangeBilling,
    onSubmitBilling
}) => {
    const { isBillingOrgIdValid, isBillingCWValid, isBillingGValid, isBillingValid } = useBilling();
    const [billingDraft, setBillingDraft] = useState(billing);
    const [isLocalEditMode, setIsLocalEditMode] = useState(isEditMode);
    const [isLocalValidationMode, setIsLocalValidationMode] = useState(isValidationMode);

    useEffect(() => {
        setIsLocalEditMode(isEditMode);
    }, [isEditMode]);

    useEffect(() => {
        setIsLocalValidationMode(isValidationMode);
    }, [isValidationMode]);

    useEffect(() => {
        setBillingDraft(billing);
    }, [billing]);

    useEffect(() => {
        if (!isLocalEditMode) {
            setBillingDraft(billing);
            setIsLocalValidationMode(false);
        }
    }, [isLocalEditMode, billing]);

    const handleChangeBillingDraft = (key, value) => {
        const _billingDraft = { ...billingDraft };
        _billingDraft[key] = value;
        setBillingDraft(_billingDraft);
        onChangeBilling(_billingDraft);
    };

    const handleOnClickSubmit = () => {
        if (isBillingValid(billingDraft)) {
            setIsLocalEditMode(false);
            onSubmitBilling(billingDraft);
        } else {
            setIsLocalValidationMode(true);
        }
    };

    return (
        <FormPanel
            title={showFormLabel ? 'Verrechnungsdaten' : null}
            showEditButton={isInlineEditing && !isEditMode && !isOverhead}
            onClickEditButton={() => setIsLocalEditMode(!isLocalEditMode)}
        >
            {!isOverhead && (
                <Group nowrap spacing="5px">
                    <GroupItem grow>
                        <Grid spacing="5px">
                            <GridItem cols="3">
                                <FormLabel top bold>
                                    Org. ID {isLocalEditMode ? '*' : ''}
                                </FormLabel>
                                {!isLocalEditMode && (
                                    <FormStaticField value={billingDraft.org_id || '–'} wordBreakAll />
                                )}
                                {isLocalEditMode && (
                                    <>
                                        <FormInput
                                            ariaLabel="Org ID"
                                            value={billingDraft.org_id}
                                            maxLength="8"
                                            error={isLocalValidationMode && !isBillingOrgIdValid(billingDraft.org_id)}
                                            onChange={(e) => handleChangeBillingDraft('org_id', e.target.value)}
                                        />
                                        {isLocalValidationMode && !isBillingOrgIdValid(billingDraft.org_id) && (
                                            <Block paddingTop="7px">
                                                <TextS>Bitte 8 Zeichen eingeben</TextS>
                                            </Block>
                                        )}
                                    </>
                                )}
                            </GridItem>
                            <GridItem cols="6">
                                <Grid spacing="5px" columns={2}>
                                    <GridItem>
                                        <FormLabel top bold>
                                            Kostenstelle {isLocalEditMode && !billingDraft.w ? '*' : ''}
                                        </FormLabel>
                                        <Group nowrap spacing="5px">
                                            <Block width="56px">
                                                <FormStaticField value="C=" />
                                            </Block>
                                            <GroupItem grow>
                                                {!isLocalEditMode && (
                                                    <FormStaticField value={billingDraft.c || '–'} wordBreakAll />
                                                )}
                                                {isLocalEditMode && (
                                                    <FormInput
                                                        ariaLabel="C"
                                                        value={billingDraft.c}
                                                        maxLength="128"
                                                        error={
                                                            isLocalValidationMode &&
                                                            !isBillingCWValid(billingDraft.c, billingDraft.w)
                                                        }
                                                        onChange={(e) => handleChangeBillingDraft('c', e.target.value)}
                                                    />
                                                )}
                                            </GroupItem>
                                        </Group>
                                    </GridItem>
                                    <GridItem>
                                        <FormLabel top bold>
                                            PSP-Element {isLocalEditMode && !billingDraft.c ? '*' : ''}
                                        </FormLabel>
                                        <Group nowrap spacing="5px">
                                            <Block width="56px">
                                                <FormStaticField value="W=" />
                                            </Block>
                                            <GroupItem grow>
                                                {!isLocalEditMode && (
                                                    <FormStaticField value={billingDraft.w || '–'} wordBreakAll />
                                                )}
                                                {isLocalEditMode && (
                                                    <FormInput
                                                        ariaLabel="W"
                                                        value={billingDraft.w}
                                                        maxLength="128"
                                                        error={
                                                            isLocalValidationMode &&
                                                            !isBillingCWValid(billingDraft.c, billingDraft.w)
                                                        }
                                                        onChange={(e) => handleChangeBillingDraft('w', e.target.value)}
                                                    />
                                                )}
                                            </GroupItem>
                                        </Group>
                                    </GridItem>
                                </Grid>
                                {isLocalValidationMode && !isBillingCWValid(billingDraft.c, billingDraft.w) && (
                                    <Block paddingTop="7px">
                                        <TextS>Bitte entweder &quot;C&quot; oder &quot;W&quot; ausfüllen</TextS>
                                    </Block>
                                )}
                            </GridItem>

                            <GridItem cols="3">
                                <FormLabel top bold>
                                    Sachkonto {isLocalEditMode ? '*' : ''}
                                </FormLabel>
                                <Group nowrap spacing="5px">
                                    <Block width="56px">
                                        <FormStaticField value="G=" />
                                    </Block>
                                    <GroupItem grow>
                                        {!isLocalEditMode && (
                                            <FormStaticField value={billingDraft.g || '–'} wordBreakAll />
                                        )}
                                        {isLocalEditMode && (
                                            <>
                                                <FormInput
                                                    ariaLabel="G"
                                                    value={billingDraft.g}
                                                    maxLength="8"
                                                    error={isLocalValidationMode && !isBillingGValid(billingDraft.g)}
                                                    onChange={(e) => handleChangeBillingDraft('g', e.target.value)}
                                                />
                                                {isLocalValidationMode && !isBillingGValid(billingDraft.g) && (
                                                    <Block paddingTop="7px">
                                                        <TextS>Bitte 8 Zeichen eingeben</TextS>
                                                    </Block>
                                                )}
                                            </>
                                        )}
                                    </GroupItem>
                                </Group>
                            </GridItem>
                        </Grid>
                    </GroupItem>
                    {isInlineEditing && isLocalEditMode && (
                        <GroupItem>
                            <Group nowrap spacing="5px" paddingTop="29px">
                                <FormButton icon={IconCheck} iconSize="small" onClick={handleOnClickSubmit} />
                                <FormButton
                                    icon={IconClose}
                                    iconSize="small"
                                    onClick={() => setIsLocalEditMode(false)}
                                />
                            </Group>
                        </GroupItem>
                    )}
                </Group>
            )}

            {isOverhead && !isOverheadEditable && (
                <Block>
                    <FormStaticField value="Über Betriebskosten abrechnen" />
                </Block>
            )}

            {isOverheadEditable && (
                <Block paddingTop={isOverhead ? null : '30px'}>
                    <Group spacing="10px" alignItems="center">
                        <FormCheckbox onChange={(e) => onChangeOverhead(e.target.checked)} checked={isOverhead} />
                        <TextM>Über Betriebskosten abrechnen</TextM>
                    </Group>
                </Block>
            )}
        </FormPanel>
    );
};

FormPanelBilling.propTypes = {
    isOverhead: PropTypes.bool,
    isOverheadEditable: PropTypes.bool,
    billing: PropTypes.object,
    showFormLabel: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isValidationMode: PropTypes.bool,
    isInlineEditing: PropTypes.bool,
    onChangeOverhead: PropTypes.func,
    onChangeBilling: PropTypes.func,
    onSubmitBilling: PropTypes.func
};

FormPanelBilling.defaultProps = {
    billing: {},
    isOverhead: false,
    isOverheadEditable: false,
    showFormLabel: true,
    isEditMode: false,
    isValidationMode: false,
    isInlineEditing: false,
    onChangeOverhead: () => {},
    onChangeBilling: () => {},
    onSubmitBilling: () => {}
};

export default FormPanelBilling;

import React from 'react';

const IconCheck = (
    <svg viewBox="0 0 100 100">
        <path
            d="M33 82.784L5.529 55.312a1.97 1.97 0 012.783-2.783L33 77.216l58.294-58.293a1.97 1.97 0 112.783 2.783L33 82.784z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconCheck;

import React from 'react';

const IconClose = (
    <svg viewBox="0 0 100 100">
        <path
            d="M52.784 50L89.47 86.688a1.97 1.97 0 01-2.783 2.783L50 52.784 13.557 89.226a1.97 1.97 0 11-2.783-2.783L47.216 50 10.53 13.312a1.97 1.97 0 012.783-2.783L50 47.216l36.294-36.293a1.97 1.97 0 112.783 2.783L52.784 50z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconClose;

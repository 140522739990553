import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconClose from '../../assets/icons/IconClose';
import IconDelete from '../../assets/icons/IconDelete';
import FormLabel from '../../atoms/FormLabel';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormInput from '../../atoms/FormInput';
import FormButton from '../../atoms/FormButton';
import { updateService } from '../../redux/actions/servicesActions';
import { useServices, serviceTypes } from '../../hooks/services';
import { STYLE_TEXT_M } from '../../styles/type';

const OldValue = styled.div({
    ...STYLE_TEXT_M,
    textDecoration: 'line-through',
    paddingLeft: '15px',
    marginTop: '5px'
});

const SettingsServicesEdit = ({ serviceId, onClickCancel, onUpdated, onClickDelete }) => {
    const dispatch = useDispatch();
    const { serviceTypeDropdownOptions } = useServices();
    const service = useSelector(({ dataState }) => dataState.services[serviceId]);

    const [numberDraft, setNumberDraft] = useState('');
    const [nameDraft, setNameDraft] = useState('');
    const [typeDraft, setTypeDraft] = useState('');
    const [unitDraft, setUnitDraft] = useState('');
    const [priceDraft, setPriceDraft] = useState(0);

    const [isButtonUpdateClicked, setIsButtonUpdateClicked] = useState(false);

    useEffect(() => {
        if (service) {
            setNumberDraft(String(service.number).trim());
            setNameDraft(String(service.name).trim());
            setTypeDraft(serviceTypes.includes(service.type) ? service.type : '');
            setUnitDraft(service.unit);
            setPriceDraft(service.price);
        }
    }, [service]);

    const handleClickUpdate = async () => {
        if (numberDraft && nameDraft && typeDraft && unitDraft && priceDraft) {
            await dispatch(
                updateService(service.id, {
                    Number: numberDraft,
                    Name: nameDraft,
                    Type: typeDraft,
                    Unit: unitDraft,
                    Price: priceDraft
                })
            );
            onUpdated();
        } else {
            setIsButtonUpdateClicked(true);
        }
    };

    const diff = service ? service.diff : null;

    return (
        <Block padding="20px">
            <Grid spacing="20px">
                <GridItem cols="3">
                    <FormLabel top>Nummer *</FormLabel>
                    <FormInput
                        ariaLabel="Nummer"
                        value={numberDraft}
                        error={isButtonUpdateClicked && !numberDraft}
                        onChange={(e) => setNumberDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="9">
                    <FormLabel top>Name *</FormLabel>
                    <FormInput
                        ariaLabel="Name"
                        value={nameDraft}
                        error={isButtonUpdateClicked && !nameDraft}
                        onChange={(e) => setNameDraft(e.target.value)}
                    />
                    {diff && diff.Name !== undefined && <OldValue>{diff.Name}</OldValue>}
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Typ *</FormLabel>
                    <FormNativeSelect
                        options={serviceTypeDropdownOptions}
                        value={typeDraft}
                        error={isButtonUpdateClicked && !typeDraft}
                        onChange={(e) => setTypeDraft(e.target.value)}
                    />
                    {diff && diff.Type !== undefined && <OldValue>{diff.Type}</OldValue>}
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Einheit *</FormLabel>
                    <FormInput
                        ariaLabel="Einheit"
                        value={unitDraft}
                        error={isButtonUpdateClicked && !unitDraft}
                        onChange={(e) => setUnitDraft(e.target.value)}
                    />
                    {diff && diff.Unit !== undefined && <OldValue>{diff.Unit}</OldValue>}
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Preis EUR *</FormLabel>
                    <FormInput
                        ariaLabel="Preis"
                        type="number"
                        value={priceDraft}
                        error={isButtonUpdateClicked && !priceDraft}
                        onChange={(e) => setPriceDraft(e.target.value)}
                    />
                    {diff && diff.Price !== undefined && <OldValue>{parseFloat(diff.Price).toLocaleString()}</OldValue>}
                </GridItem>
            </Grid>

            <Group paddingTop="20px" spacing="20px">
                <GroupItem>
                    <Group spacing="20px">
                        <FormButton
                            label="Übernehmen"
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={handleClickUpdate}
                        />
                        <FormButton label="Abbrechen" icon={IconClose} iconSize="small" onClick={onClickCancel} />
                    </Group>
                </GroupItem>
                <GroupItem grow>
                    <Block justify="flex-end">
                        <FormButton label="Löschen" icon={IconDelete} iconSize="small" onClick={onClickDelete} />
                    </Block>
                </GroupItem>
            </Group>
        </Block>
    );
};

SettingsServicesEdit.propTypes = {
    serviceId: PropTypes.string,
    onClickCancel: PropTypes.func,
    onUpdated: PropTypes.func,
    onClickDelete: PropTypes.func
};
SettingsServicesEdit.defaultProps = {
    serviceId: '',
    onClickCancel: () => {},
    onUpdated: () => {},
    onClickDelete: () => {}
};

export default SettingsServicesEdit;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Grid, GridItem, Group, InlineSVG } from '@webfox-sc/core';
import FormLabel from '../../atoms/FormLabel';
import FormButton from '../../atoms/FormButton';
import IconTypeBodenreinigung from '../../assets/icons/IconTypeBodenreinigung';
import IconTypeMoebel from '../../assets/icons/IconTypeMoebel';
import IconTypeGlasreinigung from '../../assets/icons/IconTypeGlasreinigung';
import IconTypeKueche from '../../assets/icons/IconTypeKueche';
import IconTypeSonstiges from '../../assets/icons/IconTypeSonstiges';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import { usePermanentDraft, usePermanentDraftServices } from '../../hooks/permanents';
import { useBilling } from '../../hooks/billing';
import DateUtil from '../../utils/DateUtil';
import FormatUtil from '../../utils/FormatUtil';

const StyledIcon = styled.div({
    position: 'relative',
    top: '-4px'
});

const PermanentCreateSummary = ({ onClickSubmit }) => {
    const { getBillingFormatted } = useBilling();
    const {
        permanentDraft,
        frequencyIntervalFormatted,
        permanentDraftBuilding,
        permanentDraftMonthlyPrice
    } = usePermanentDraft();
    const { permanentService } = usePermanentDraftServices();

    let typeIcon = '';
    switch (permanentDraft.type) {
        case 'Teppichböden':
            typeIcon = IconTypeBodenreinigung;
            break;

        case 'Hartbelagböden':
            typeIcon = IconTypeBodenreinigung;
            break;

        case 'Mobiliar':
            typeIcon = IconTypeMoebel;
            break;

        case 'Glas':
            typeIcon = IconTypeGlasreinigung;
            break;

        case 'Küche':
            typeIcon = IconTypeKueche;
            break;

        case 'Sonstiges':
            typeIcon = IconTypeSonstiges;
            break;

        default:
            break;
    }

    const { contactPersonList } = permanentDraft;
    const contactPerson = contactPersonList[0] || {};

    return (
        <>
            <Grid spacing="20px">
                <GridItem cols="6">
                    <FormLabel textAlign="right">Leistung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <Group nowrap spacing="15px">
                        {permanentDraft.type && (
                            <StyledIcon>
                                <InlineSVG width="30px" height="30px" color="white">
                                    {typeIcon}
                                </InlineSVG>
                            </StyledIcon>
                        )}
                        <FormLabel>{permanentDraft.type || '–'}</FormLabel>
                    </Group>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Leistungsbeschreibung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{permanentService ? permanentService.name : '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Menge:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>
                        {permanentDraft.quantity} {permanentService ? permanentService.unit : '–'}
                    </FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">Intervall:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{frequencyIntervalFormatted || '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Monatspreis:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>
                        {permanentDraftMonthlyPrice
                            ? `${FormatUtil.getDecimalFormatted(permanentDraftMonthlyPrice)} EUR`
                            : '–'}
                    </FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Ort:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{permanentDraft.location || '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">Standort:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{permanentDraftBuilding ? permanentDraftBuilding.nameCode : '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">Startdatum:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{DateUtil.getDateFormatted(permanentDraft.startDate) || '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">Bemerkung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{FormatUtil.nl2br(permanentDraft.message) || '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">Verrechnungsdaten:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{getBillingFormatted(permanentDraft.billing) || '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <Block paddingTop="15px">
                        <FormLabel textAlign="right">
                            <b>Kfm. Ansprechpartner:</b>
                        </FormLabel>
                    </Block>
                </GridItem>
                <GridItem cols="6" />
                <GridItem cols="6">
                    <FormLabel textAlign="right">Vorname:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{contactPerson.first_name || '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">Nachname:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{contactPerson.last_name || '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">E-Mail:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{contactPerson.email || '–'}</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel textAlign="right">Abteilung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{contactPerson.department || '–'}</FormLabel>
                </GridItem>
            </Grid>
            <Block paddingTop="35px" justify="center">
                <FormButton label="Zur Freigabe" icon={IconCheckbox} onClick={onClickSubmit} />
            </Block>
        </>
    );
};

PermanentCreateSummary.propTypes = {
    onClickSubmit: PropTypes.func
};

PermanentCreateSummary.defaultProps = {
    onClickSubmit: () => {}
};

export default PermanentCreateSummary;

import React from 'react';

const IconCalendar = (
    <svg viewBox="0 0 100 100">
        <path
            fill="currentColor"
            d="M69.02,5.90909091 L69.02,0 L61.9611765,0 L61.9611765,5.90909091 L38.0388235,5.90909091 L38.0388235,0 L30.98,0 L30.98,5.90909091 L0,5.90909091 L0,91 L100,91 L100,5.90909091 L69.02,5.90909091 Z M30.98,13 L30.98,18.9090909 L38.0388235,18.9090909 L38.0388235,13 L61.9611765,13 L61.9611765,17.7272727 L69.02,17.7272727 L69.02,13 L92.9411765,13 L92.9411765,24.8181818 L7.05882353,24.8181818 L7.05882353,13 L30.98,13 Z M7.05882353,83.9090909 L7.05882353,31.9090909 L92.9411765,31.9090909 L92.9411765,83.9090909 L7.05882353,83.9090909 Z M22.9411765,74.4545455 L34.7058824,74.4545455 L34.7058824,62.6363636 L22.9411765,62.6363636 L22.9411765,74.4545455 Z M44.1176471,74.4545455 L55.8823529,74.4545455 L55.8823529,62.6363636 L44.1176471,62.6363636 L44.1176471,74.4545455 Z M65.2941176,74.4545455 L77.0588235,74.4545455 L77.0588235,62.6363636 L65.2941176,62.6363636 L65.2941176,74.4545455 Z M44.1176471,53.1818182 L55.8823529,53.1818182 L55.8823529,41.3636364 L44.1176471,41.3636364 L44.1176471,53.1818182 Z M65.2941176,53.1818182 L77.0588235,53.1818182 L77.0588235,41.3636364 L65.2941176,41.3636364 L65.2941176,53.1818182 Z M22.9411765,53.1818182 L34.7058824,53.1818182 L34.7058824,41.3636364 L22.9411765,41.3636364 L22.9411765,53.1818182 Z"
        />
    </svg>
);

export default IconCalendar;

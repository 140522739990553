import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPageContentBlock = styled.div({
    position: 'relative',
    minWidth: '280px',
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '0 20px'
    // border: '1px dashed white'
});

const PageContentBlock = (props) => {
    const { children } = props;
    return <StyledPageContentBlock>{children}</StyledPageContentBlock>;
};

PageContentBlock.propTypes = {
    children: PropTypes.any
};

PageContentBlock.defaultProps = {
    children: null
};

export default PageContentBlock;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTable = styled.table({
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 6px'
});

const Table = ({ children }) => {
    return <StyledTable>{children}</StyledTable>;
};

Table.propTypes = {
    children: PropTypes.any
};

Table.defaultProps = {
    children: null
};

export default Table;

/* xeslint-disable */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Block, Group } from '@webfox-sc/core';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import TextL from '../../atoms/TextL';
import FormButton from '../../atoms/FormButton';
import FormTextarea from '../../atoms/FormTextarea';
import Comment from '../../components/Comment';
import { useOrder } from '../../hooks/orders';
import { useOrderHistory } from '../../hooks/useOrderHistory';
import { updateOrder } from '../../redux/actions/ordersActions';

const OrderDetailsComments = ({ orderId }) => {
    const dispatch = useDispatch();
    const { order } = useOrder(orderId);
    const { orderComments } = useOrderHistory(orderId);

    const [showForm, setShowForm] = useState(false);
    const [commentDraft, setCommentDraft] = useState('');

    if (!order) {
        return null;
    }

    const handleOnClickSave = () => {
        if (commentDraft) {
            dispatch(
                updateOrder(order.id, {
                    comment: commentDraft
                })
            );

            setShowForm(false);
            setCommentDraft('');
        }
    };

    const handleOnClickCancel = () => {
        setShowForm(false);
        setCommentDraft('');
    };

    return (
        <>
            <Block justify="center">
                <TextL>
                    <strong>Kommentare</strong>
                </TextL>
            </Block>
            {orderComments.length > 0 && (
                <Block paddingTop="20px" justify="center">
                    <Block width="100%" maxWidth="1000px">
                        <Group vertical spacing="5px">
                            {orderComments.map((commentItem, index) => {
                                const commentHtml = commentItem.comment.replace(/(?:\r\n|\r|\n)/g, '<br />');
                                return (
                                    <Comment
                                        key={String(index)}
                                        userName={commentItem.creatorDescription}
                                        dateFormatted={commentItem.dateFormatted}
                                        text={commentHtml}
                                    />
                                );
                            })}
                        </Group>
                    </Block>
                </Block>
            )}
            <Block paddingTop="10px">
                {showForm && (
                    <Block justify="center">
                        <Block width="100%" maxWidth="1000px">
                            <FormTextarea
                                value={commentDraft}
                                placeholder="Ihr Kommentar *"
                                height="100px"
                                onChange={(e) => setCommentDraft(e.target.value)}
                            />
                        </Block>
                    </Block>
                )}
                <Block justify="center">
                    {showForm ? (
                        <Group paddingTop="10px" spacing="10px" justify="center">
                            <FormButton label="Abbrechen" icon={IconClose} onClick={handleOnClickCancel} />
                            <FormButton
                                label="Speichern"
                                icon={IconCheck}
                                disabled={!commentDraft}
                                onClick={handleOnClickSave}
                            />
                        </Group>
                    ) : (
                        <FormButton label="Kommentar hinzufügen" onClick={() => setShowForm(true)} />
                    )}
                </Block>
            </Block>
        </>
    );
};

OrderDetailsComments.propTypes = {
    orderId: PropTypes.any
};

OrderDetailsComments.defaultProps = {
    orderId: null
};

export default OrderDetailsComments;

/* xeslint-disable */
import axios from 'axios';
import auth from '../../utils/auth';
import api from '../../api';
import { REDUX_ACTIONS } from '../../global/constants';

export function fetchBuildings() {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_BUILDINGS
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/buildings`,
            params: {
                _limit: 100000
            },
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const buildings = res.data || [];
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_BUILDINGS,
                    buildings
                });
                return buildings;
            })
            .catch((error) => {
                console.log('#fetchBuildings error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_BUILDINGS_ERROR
                });
            });
    };
}

export function createBuilding(data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CREATE_BUILDING
        });

        return axios({
            method: 'post',
            url: `${api.apiUrl}/buildings`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const building = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CREATE_BUILDING,
                    building
                });
                return building;
            })
            .catch((error) => {
                console.log('#createBuilding error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CREATE_BUILDING_ERROR
                });
            });
    };
}

export function updateBuiling(id, data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_UPDATE_BUILDING
        });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/buildings/${id}`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const building = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_UPDATE_BUILDING,
                    building
                });
                return building;
            })
            .catch((error) => {
                console.log('#updateBuiling error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_UPDATE_BUILDING_ERROR
                });
            });
    };
}

export function deleteBuilding(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_DELETE_BUILDING
        });

        return axios({
            method: 'delete',
            url: `${api.apiUrl}/buildings/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const building = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_DELETE_BUILDING,
                    building
                });
                return building;
            })
            .catch((error) => {
                console.log('#deleteBuilding error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_DELETE_BUILDING_ERROR
                });
            });
    };
}

export function setBuildingsSorting(buildingsSortingKey, buildingsSortingOrder) {
    return {
        type: REDUX_ACTIONS.SET_BUILDINGS_SORTING,
        buildingsSortingKey,
        buildingsSortingOrder
    };
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';

export function useBuildings() {
    const buildings = useSelector(({ dataState }) => dataState.buildings);
    const [buildingDropdownOptions, setBuildingDropdownOptions] = useState([]);

    useEffect(() => {
        const _buildingDropdownOptions = [
            {
                value: '',
                label: 'Auswählen',
                disabled: true
            }
        ];

        const buildingsSorted = _orderBy(buildings, ['name'], ['asc']);

        Object.values(buildingsSorted).forEach((building) => {
            _buildingDropdownOptions.push({
                value: building.id,
                label: building.nameCode
            });
        });

        setBuildingDropdownOptions(_buildingDropdownOptions);
    }, [buildings]);

    return {
        buildingDropdownOptions
    };
}

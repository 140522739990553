/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Group, GroupItem } from '@webfox-sc/core';
import { STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';
import IconCalendar from '../../assets/icons/IconCalendar';
import errorImageSrc from '../../assets/images/icon-error.png';

const StyledComponent = styled.div(({ minWidth }) => {
    return {
        position: 'relative',
        minWidth
    };
});

const StyledInput = styled.input(({ layout, size }) => {
    const style = {
        border: layout === 'white' ? `1px solid ${COLORS.DARK_BLUE}` : '1px solid white',
        background: 'transparent',
        minHeight: size === 'L' ? '58px' : '54px',
        width: '100%',
        padding: size === 'L' ? '17px !important' : '15px !important',
        boxSizing: 'border-box',
        ...STYLE_TEXT_M,
        color: layout === 'white' ? COLORS.DARK_GREY : COLORS.WHITE,
        '&:focus': {
            outline: 0
        }
    };
    return style;
});

const StyledButton = styled.button(({ layout, size }) => {
    const style = {
        border: layout === 'white' ? `1px solid ${COLORS.DARK_BLUE}` : '1px solid white',
        borderLeft: 'none',
        background: 'transparent',
        width: size === 'L' ? '58px' : '54px',
        height: size === 'L' ? '58px' : '54px',
        padding: 0,
        boxSizing: 'border-box',
        outline: 0,
        cursor: 'pointer',
        ...STYLE_TEXT_M,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.14)'
        },
        svg: {
            width: '24px',
            height: '24px',
            verticalAlign: 'top',
            color: layout === 'white' ? COLORS.DARK_BLUE : COLORS.WHITE
        }
    };
    return style;
});

const StyledErrorImage = styled.img({
    position: 'absolute',
    top: '-16px',
    right: '68px',
    width: '24px',
    height: '24px',
    verticalAlign: 'top'
});

const FormDatePicker = (props) => {
    const { layout, size, selected, dateFormat, error, minWidth, hideButton, onChange } = props;
    const [selectedDate, setSelectedDate] = useState(selected);
    const ref = React.createRef();

    useEffect(() => {
        setSelectedDate(selected);
    }, [selected]);

    const CustomDateInput = forwardRef(({ onClick, value }, _ref) => (
        <Group nowrap>
            <GroupItem grow>
                <StyledInput
                    layout={layout}
                    size={size}
                    onClick={onClick}
                    value={value}
                    onChange={onClick}
                    ref={_ref}
                />
            </GroupItem>
            {!hideButton && (
                <StyledButton type="button" layout={layout} size={size} onClick={onClick}>
                    {IconCalendar}
                </StyledButton>
            )}
        </Group>
    ));

    return (
        <StyledComponent minWidth={minWidth}>
            <DatePicker
                {...props}
                selected={selectedDate}
                dateFormat={dateFormat}
                customInput={<CustomDateInput ref={ref} />}
                onChange={(date) => {
                    setSelectedDate(date);
                    onChange(date);
                }}
            />
            {error && <StyledErrorImage src={errorImageSrc} alt="Error" />}
            <style>
                {`.react-datepicker-wrapper,
                .react-datepicker__input-container {
                    width: 100%;
                }`}
            </style>
        </StyledComponent>
    );
};

FormDatePicker.propTypes = {
    layout: PropTypes.string,
    size: PropTypes.string,
    selected: PropTypes.any,
    dateFormat: PropTypes.string,
    error: PropTypes.bool,
    minWidth: PropTypes.string,
    hideButton: PropTypes.bool,
    onChange: PropTypes.func
};

FormDatePicker.defaultProps = {
    layout: null,
    size: null,
    selected: null,
    dateFormat: 'dd.MM.yyyy',
    error: false,
    minWidth: null,
    hideButton: false,
    onChange: () => {}
};

export default FormDatePicker;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Grid, GridItem, Group, InlineSVG } from '@webfox-sc/core';
import FormButton from '../../atoms/FormButton';
import FormLabel from '../../atoms/FormLabel';
import IconTypeAbfall from '../../assets/icons/IconTypeAbfall';
import IconTypeBodenreinigung from '../../assets/icons/IconTypeBodenreinigung';
import IconTypeOberflaechenreinigung from '../../assets/icons/IconTypeOberflaechenreinigung';
import IconTypeVerbrauchsmaterial from '../../assets/icons/IconTypeVerbrauchsmaterial';
import IconTypeGlasreinigung from '../../assets/icons/IconTypeGlasreinigung';
import IconTypeSonstiges from '../../assets/icons/IconTypeSonstiges';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import { useClaimDraft } from '../../hooks/claims';
import FormatUtil from '../../utils/FormatUtil';

const StyledIcon = styled.div({
    position: 'relative',
    top: '-8px'
});

const ClaimCreateSummary = ({ onClickSubmit }) => {
    const { claimDraft } = useClaimDraft();

    let typeIcon = '';
    switch (claimDraft.type) {
        case 'Abfall':
            typeIcon = IconTypeAbfall;
            break;

        case 'Bodenreinigung':
            typeIcon = IconTypeBodenreinigung;
            break;

        case 'Oberflächenreinigung':
            typeIcon = IconTypeOberflaechenreinigung;
            break;

        case 'Verbrauchsmaterial':
            typeIcon = IconTypeVerbrauchsmaterial;
            break;

        case 'Glasreinigung':
            typeIcon = IconTypeGlasreinigung;
            break;

        case 'Sonstiges':
            typeIcon = IconTypeSonstiges;
            break;

        default:
            break;
    }

    return (
        <>
            <Grid spacing="20px">
                <GridItem cols="6">
                    <FormLabel textAlign="right">Leistung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <Group nowrap spacing="15px">
                        {claimDraft.type && (
                            <StyledIcon>
                                <InlineSVG width="30px" height="30px" color="white">
                                    {typeIcon}
                                </InlineSVG>
                            </StyledIcon>
                        )}
                        <FormLabel>{claimDraft.type || '–'}</FormLabel>
                    </Group>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Ort:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{claimDraft.location || '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Problembeschreibung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{FormatUtil.nl2br(claimDraft.problemDescription) || '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <Block paddingTop="15px">
                        <FormLabel textAlign="right">
                            <b>Kfm. Ansprechpartner:</b>
                        </FormLabel>
                    </Block>
                </GridItem>
                <GridItem cols="6" />
            </Grid>

            {claimDraft.contactPersonList.map((contactPerson, index) => {
                const { first_name, last_name, email } = contactPerson;
                if (!first_name && !last_name && !email) {
                    return null;
                }

                return (
                    <Block paddingTop="20px" key={String(index)}>
                        <Grid spacing="20px">
                            <GridItem cols="6">
                                <FormLabel textAlign="right">Ansprechpartner {index + 1}:</FormLabel>
                            </GridItem>
                            <GridItem cols="6">
                                <FormLabel>
                                    {contactPerson.first_name} {contactPerson.last_name}{' '}
                                    {contactPerson.email ? `– ${contactPerson.email}` : ''}
                                </FormLabel>
                            </GridItem>
                        </Grid>
                    </Block>
                );
            })}

            <Block paddingTop="35px" justify="center">
                <FormButton label="Zur Freigabe" icon={IconCheckbox} onClick={onClickSubmit} />
            </Block>
        </>
    );
};

ClaimCreateSummary.propTypes = {
    onClickSubmit: PropTypes.func
};

ClaimCreateSummary.defaultProps = {
    onClickSubmit: () => {}
};

export default ClaimCreateSummary;

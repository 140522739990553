/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import StarRatingComponent from 'react-star-rating-component';

class Stars extends React.Component {
    constructor() {
        super();

        this.state = {
            rating: 1
        };
    }

    onStarClick(nextValue, prevValue, name) {
        // if()
        this.setState({ rating: nextValue }, () => {
            // console.log('STATE>>', this.state);
        });
        this.props.changeRating(nextValue);
    }
    // onStarClick(nextValue, prevValue, name) {
    //     if (this.props.rating) {
    //         this.props.changeRating(nextValue);
    //     }
    // }
    render() {
        // const { rating } = this.state;

        return (
            <div>
                <StarRatingComponent
                    name="rate"
                    editing={this.props.editing}
                    starCount={5}
                    value={this.props.rating}
                    starColor={'#FFFFFF'}
                    emptyStarColor={'rgba(255, 255, 255, 0.3)'}
                    onStarClick={this.onStarClick.bind(this)}
                />
            </div>
        );
    }
}

Stars.defaultProps = {
    editing: false
};

export default Stars;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Collapse, Group, InlineSVG } from '@webfox-sc/core';
import { COLORS } from '../../styles/common';
import { STYLE_TEXT } from '../../styles/type';

const StyledButton = styled.a(() => {
    const style = {
        display: 'block',
        background: 'white',
        boxSizing: 'border-box',
        width: '100%',
        margin: 0,
        padding: '25px',
        textDecoration: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.95)'
        },
        '&[data-active="true"]': {
            backgroundColor: COLORS.DARK_BLUE
        }
    };

    return style;
});

const StyledLabel = styled.span(() => {
    const style = {
        display: 'block',

        ...STYLE_TEXT,
        fontWeight: 400,
        fontSize: '27px',
        lineHeight: '36px',
        color: COLORS.DARK_BLUE,

        '&[data-active="true"]': {
            color: COLORS.WHITE
        }
    };
    return style;
});

const StyledText = styled.span(() => {
    const style = {
        display: 'block',
        minHeight: '66px',

        ...STYLE_TEXT,
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '22px',
        textAlign: 'center',
        color: COLORS.DARK_GREY,

        '&[data-active="true"]': {
            color: COLORS.DARK_BLUE
        }
    };
    return style;
});

const MenuButton = ({ label, icon, text, showText, active, href, onClick }) => {
    const dataAttributes = {
        'data-active': active
    };

    return (
        <StyledButton href={href} onClick={onClick} {...dataAttributes}>
            <Block height="48px" justify="center" alignItems="center">
                <Group nowrap spacing="15px" alignItems="center">
                    <InlineSVG width="48px" height="48px" color={active ? COLORS.WHITE : COLORS.DARK_BLUE}>
                        {icon}
                    </InlineSVG>
                    <StyledLabel {...dataAttributes}>{label}</StyledLabel>
                </Group>
            </Block>
            <Collapse show={showText}>
                <Block paddingTop="25px">
                    <StyledText {...dataAttributes} dangerouslySetInnerHTML={{ __html: text }} />
                </Block>
            </Collapse>
        </StyledButton>
    );
};

MenuButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.any,
    text: PropTypes.string,
    showText: PropTypes.bool,
    active: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func
};

MenuButton.defaultProps = {
    label: '',
    icon: null,
    text: '',
    showText: false,
    active: false,
    href: '',
    onClick: () => {}
};

export default MenuButton;

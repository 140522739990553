import React from 'react';

const IconOrder = (
    <svg viewBox="0 0 50 100">
        <path
            d="M66.157 96.264h-10.64L24.178 1.266A1.672 1.672 0 1021 2.318l17.346 52.577-36.62-.001c-.922 0-1.672.75-1.672 1.676v25.665a1.67 1.67 0 001.993 1.644l1.101-.216 1.7 14.852A1.673 1.673 0 006.512 100h27.506c.85 0 1.565-.639 1.662-1.485l1.247-10.898.03-.005c4.005-.81 6.425-2.642 7.194-5.45.925-3.37-.78-7.893-5.058-13.45l.983-8.58 11.92 36.132H41.698c-.924 0-1.672.75-1.672 1.676 0 .926.748 1.676 1.672 1.676h24.459c.923 0 1.672-.75 1.672-1.676 0-.925-.748-1.676-1.672-1.676zM3.4 80.199V58.247h14.236v19.161L3.4 80.2zm29.126 16.449H8.003L6.44 83.018l13.188-2.586a1.674 1.674 0 001.351-1.645v-20.54h15.944l-.791 6.915c-2.58-2.883-4.787-4.89-4.905-4.997a1.67 1.67 0 00-2.362.119 1.68 1.68 0 00.119 2.368c2.043 1.851 4.48 4.38 6.628 7.044l-3.087 26.952zm8.4-15.373c-.352 1.28-1.56 2.22-3.594 2.8L38.52 73.71c1.836 2.902 2.931 5.651 2.407 7.564zM9.084 53.582a4.424 4.424 0 010-8.847 4.424 4.424 0 010 8.847zm0-5.495a1.07 1.07 0 000 2.143 1.072 1.072 0 000-2.143zm13.823-.315c-3.235 0-5.868-2.638-5.868-5.881 0-3.243 2.633-5.88 5.868-5.88 3.234 0 5.866 2.637 5.866 5.88s-2.63 5.88-5.866 5.88zm0-8.41a2.53 2.53 0 000 5.058 2.529 2.529 0 002.522-2.53c0-1.394-1.13-2.529-2.522-2.529zm-9.78-1.845a1.696 1.696 0 01.643-2.054c1.283-.82 2.947.44 2.502 1.9a1.691 1.691 0 01-1.518 1.186 1.69 1.69 0 01-1.626-1.032z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconOrder;

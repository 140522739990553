/* xeslint-disable */
import axios from 'axios';
import auth from '../../utils/auth';
import api from '../../api';
import { REDUX_ACTIONS } from '../../global/constants';

export function fetchRoles() {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_ROLES
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/users-permissions/roles`,
            params: {
                _limit: 100000
            },
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const data = res.data || {};
                const roles = data.roles || [];
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_ROLES,
                    roles
                });
                return roles;
            })
            .catch((error) => {
                console.log('#fetchRoles error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_ROLES_ERROR
                });
            });
    };
}

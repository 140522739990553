/* xeslint-disable */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import IconCockpit from '../../assets/icons/IconCockpit';
import IconClaim from '../../assets/icons/IconClaim';
import IconOrder from '../../assets/icons/IconOrder';
import IconPermanent from '../../assets/icons/IconPermanent';
import IconOverview from '../../assets/icons/IconOverview';
import IconCreate from '../../assets/icons/IconCreate';
import PageContentBlock from '../../atoms/PageContentBlock';
import FormButton from '../../atoms/FormButton';
import MenuButton from '../../atoms/MenuButton';
import { useUser } from '../../hooks/user';
import { useAuth } from '../../hooks/useAuth';
import { USER_ROLES } from '../../global/constants';

const MainMenu = () => {
    const { isLoggedIn } = useAuth();
    const { userRoleName } = useUser();
    const history = useHistory();
    const { pathname } = useLocation();
    const basepath = pathname.split('/')[1];

    if (
        !isLoggedIn ||
        basepath === 'auth' ||
        basepath === 'settings' ||
        basepath === 'imprint' ||
        basepath === 'privacy'
    ) {
        return null;
    }

    const isHomepage = pathname === '/';

    const userCanViewCockpit =
        userRoleName === USER_ROLES.ADMIN ||
        userRoleName === USER_ROLES.LOCATION_MANAGER ||
        userRoleName === USER_ROLES.SERVICE_MANAGER;
    const userCanCreateOffer = userRoleName !== USER_ROLES.LOCATION_MANAGER;
    const userCanCreatePermanent =
        userRoleName !== USER_ROLES.LOCATION_MANAGER && userRoleName !== USER_ROLES.SERVICE_MANAGER;

    return (
        <Block
            paddingTop={isHomepage ? '200px' : '180px'}
            paddingBottom="50px"
            style={{ transition: 'padding-top 0.3s ease' }}
        >
            <PageContentBlock>
                <Grid columns={userCanViewCockpit ? 4 : 3} spacing="20px">
                    {userCanViewCockpit && (
                        <GridItem>
                            <MenuButton
                                label="Cockpit"
                                icon={IconCockpit}
                                text="Hier finden Sie eine Übersicht über die <strong>wichtigsten</strong> Kennzahlen"
                                showText={isHomepage}
                                active={basepath === 'cockpit'}
                                href="/cockpit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/cockpit');
                                }}
                            />
                        </GridItem>
                    )}
                    <GridItem>
                        <MenuButton
                            label="Reklamation"
                            icon={IconClaim}
                            text="Sind Sie mit der Reinigung <strong>unzufrieden</strong>? Hier können Sie Ihre Reklamation abgeben!"
                            showText={isHomepage}
                            active={basepath === 'claims'}
                            href="/claims"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/claims');
                            }}
                        />
                        <Group paddingTop="6px" spacing="6px" grow>
                            <FormButton
                                label="Übersicht"
                                icon={IconOverview}
                                iconSize="small"
                                block
                                active={pathname === '/claims'}
                                href="/claims"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/claims');
                                }}
                            />
                            <FormButton
                                label="Erfassen"
                                icon={IconCreate}
                                iconSize="small"
                                block
                                active={pathname === '/claims/create'}
                                href="/claims/create"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/claims/create');
                                }}
                            />
                        </Group>
                    </GridItem>
                    <GridItem>
                        <MenuButton
                            label="Ad-hoc-Aufträge"
                            icon={IconOrder}
                            text="Kaffeefleck oder sonstiges <strong>einmaliges Anliegen</strong>? Wir sind für Sie da!"
                            showText={isHomepage}
                            active={basepath === 'orders'}
                            href="/orders"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/orders');
                            }}
                        />
                        <Group paddingTop="6px" spacing="6px" grow>
                            <FormButton
                                label="Übersicht"
                                icon={IconOverview}
                                iconSize="small"
                                block
                                active={pathname === '/orders'}
                                href="/orders"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/orders');
                                }}
                            />
                            {userCanCreateOffer && (
                                <FormButton
                                    label="Erfassen"
                                    icon={IconCreate}
                                    iconSize="small"
                                    block
                                    active={pathname === '/orders/create'}
                                    href="/orders/create"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push('/orders/create');
                                    }}
                                />
                            )}
                        </Group>
                    </GridItem>
                    <GridItem>
                        <MenuButton
                            label="Daueraufträge"
                            icon={IconPermanent}
                            text="Arbeitstisch <strong>wöchentlich</strong> reinigen lassen? Kein Problem!"
                            showText={isHomepage}
                            active={basepath === 'permanents'}
                            href="/permanents"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/permanents');
                            }}
                        />
                        <Group paddingTop="6px" spacing="6px" grow>
                            <FormButton
                                label="Übersicht"
                                icon={IconOverview}
                                iconSize="small"
                                block
                                active={pathname === '/permanents'}
                                href="/permanents"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/permanents');
                                }}
                            />
                            {userCanCreatePermanent && (
                                <FormButton
                                    label="Erfassen"
                                    icon={IconCreate}
                                    iconSize="small"
                                    block
                                    active={pathname === '/permanents/create'}
                                    href="/permanents/create"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push('/permanents/create');
                                    }}
                                />
                            )}
                        </Group>
                    </GridItem>
                </Grid>
            </PageContentBlock>
        </Block>
    );
};

export default MainMenu;

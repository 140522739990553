import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';

const StyledComponent = styled.div(({ layout, height, wordBreakAll }) => {
    let style = {
        background: layout === 'white' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
        height: height || 'auto',
        minHeight: '54px',
        padding: '15px',
        boxSizing: 'border-box',

        ...STYLE_TEXT_M,
        color: layout === 'white' ? COLORS.DARK_BLUE : 'white',

        overflowWrap: 'break-word',
        wordWrap: 'break-word'
    };

    if (wordBreakAll) {
        style = {
            ...style,
            wordBreak: 'break-all'
        };
    }

    return style;
});

const FormStaticField = ({ layout, height, value, wordBreakAll, children }) => {
    if (value) {
        return (
            <StyledComponent
                layout={layout}
                height={height}
                wordBreakAll={wordBreakAll}
                dangerouslySetInnerHTML={{ __html: value }}
            />
        );
    }

    return (
        <StyledComponent layout={layout} wordBreakAll={wordBreakAll}>
            {children}
        </StyledComponent>
    );
};

FormStaticField.propTypes = {
    layout: PropTypes.string,
    height: PropTypes.string,
    value: PropTypes.any,
    wordBreakAll: PropTypes.bool,
    children: PropTypes.any
};

FormStaticField.defaultProps = {
    layout: '',
    height: null,
    value: '',
    wordBreakAll: false,
    children: null
};

export default FormStaticField;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';
import { COLORS } from '../../styles/common';
import { STYLE_TEXT_S } from '../../styles/type';

const theme = {
    root: {
        border: 'none',
        background: 'transparent',
        padding: 0,
        outline: 0,
        span: {
            ...STYLE_TEXT_S,
            textDecoration: 'underline',
            color: COLORS.WHITE
        }
    }
};

const TextButton = (props) => {
    return <Button {...props} theme={theme} />;
};

TextButton.propTypes = {
    ...Button.propTypes,
    label: PropTypes.string
};

TextButton.defaultProps = {
    ...Button.defaultProps,
    label: ''
};

export default TextButton;

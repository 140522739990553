import axios from 'axios';
import Cookies from 'js-cookie';
import api from '../../api';
import { fetchMe } from './usersActions';
import { REDUX_ACTIONS } from '../../global/constants';

export function auth(userId, jwt, userData) {
    return async (dispatch) => {
        sessionStorage.setItem('jwtToken', JSON.stringify(jwt));
        sessionStorage.setItem('userInfo', JSON.stringify(userData));

        // temp dev migration
        const cookieOptions = { expires: 7, path: '/' };
        Cookies.set('jwt', jwt, cookieOptions);
        localStorage.setItem('jwt', JSON.stringify(jwt));

        return dispatch({
            type: REDUX_ACTIONS.LOGIN,
            userId,
            jwt
        });
    };
}

export function login(identifier, password) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_LOGIN
        });

        return axios({
            method: 'post',
            url: `${api.apiUrl}/auth/local`,
            data: {
                identifier,
                password
            }
        })
            .then((res) => {
                const authData = res.data || {};
                const userData = authData?.user || {};

                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_LOGIN,
                    user: userData
                });

                dispatch(auth(userData?.id, authData?.jwt, userData));

                return authData;
            })
            .catch((error) => {
                console.log('#login error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_LOGIN_ERROR,
                    loginErrorText: 'Der Benutzername oder das Passwort ist ungültig!'
                });
            });
    };
}

export function logout() {
    return async (dispatch) => {
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('userInfo');

        return dispatch({
            type: REDUX_ACTIONS.LOGOUT
        });
    };
}

export function autoLogin() {
    return async (dispatch, getState) => {
        const { authState } = getState();

        const jwt = JSON.parse(sessionStorage.getItem('jwtToken')) || null;
        const user = JSON.parse(sessionStorage.getItem('userInfo')) || null;

        if (!authState.isLoggedIn && !!jwt && !!user) {
            dispatch({
                type: REDUX_ACTIONS.REQUEST_AUTO_LOGIN
            });

            return dispatch(fetchMe(jwt))
                .then(() => {
                    dispatch({
                        type: REDUX_ACTIONS.RECIEVE_AUTO_LOGIN
                    });

                    dispatch(auth(user?.id, jwt, user));
                })
                .catch(() => {
                    dispatch({
                        type: REDUX_ACTIONS.REQUEST_AUTO_LOGIN_ERROR
                    });
                    dispatch(logout());
                    return false;
                });
        }

        return true;
    };
}

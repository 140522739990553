/* xeslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import IconArrowRight from '../../assets/icons/IconArrowRight';
import TextXL from '../../atoms/TextXL';
import FormPanelGroup from '../../atoms/FormPanelGroup';
import FormPanel from '../../atoms/FormPanel';
import FormButton from '../../atoms/FormButton';
import FormLabel from '../../atoms/FormLabel';
import FormTextarea from '../../atoms/FormTextarea';
import FormInput from '../../atoms/FormInput';
import FormPanelContactPersonList from '../../components/FormPanelContactPersonList';
import { useClaimDraft } from '../../hooks/claims';

const ClaimCreateData = ({ onClickNext }) => {
    const { claimDraft, updateClaimDraft } = useClaimDraft();

    const [isButtonNextClicked, setIsButtonNextClicked] = useState(false);

    return (
        <>
            <FormPanelGroup>
                <FormPanel>
                    <Block paddingBottom="20px">
                        <TextXL>
                            <strong>Daten</strong>
                        </TextXL>
                    </Block>
                    <Grid spacing="20px">
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Ort *
                            </FormLabel>
                            <FormInput
                                ariaLabel="Ort"
                                placeholder="Gebäude- und Raumnummer"
                                value={claimDraft.location}
                                error={isButtonNextClicked && !claimDraft.location}
                                onChange={(e) => {
                                    updateClaimDraft({
                                        location: e.target.value
                                    });
                                }}
                            />
                        </GridItem>
                    </Grid>

                    <Block paddingTop="20px">
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Problembeschreibung *
                            </FormLabel>
                            <FormTextarea
                                value={claimDraft.problemDescription}
                                height="163px"
                                error={isButtonNextClicked && !claimDraft.problemDescription}
                                onChange={(e) => {
                                    updateClaimDraft({
                                        problemDescription: e.target.value
                                    });
                                }}
                            />
                        </GridItem>
                    </Block>
                </FormPanel>

                <FormPanelContactPersonList
                    contactPersonList={claimDraft.contactPersonList}
                    isEditMode
                    isValidationMode={isButtonNextClicked}
                    onChange={(contactPersonListDraft) =>
                        updateClaimDraft({
                            contactPersonList: contactPersonListDraft
                        })
                    }
                />
            </FormPanelGroup>

            <Block paddingTop="50px" justify="center">
                <FormButton
                    label="Weiter"
                    icon={IconArrowRight}
                    iconSize="small"
                    onClick={() => {
                        setIsButtonNextClicked(true);
                        onClickNext();
                    }}
                />
            </Block>
        </>
    );
};

ClaimCreateData.propTypes = {
    onClickNext: PropTypes.func
};

ClaimCreateData.defaultProps = {
    onClickNext: () => {}
};

export default ClaimCreateData;

import React from 'react';

const IconTypeVerbrauchsmaterial = (
    <svg viewBox="0 0 100 100">
        <path
            d="M94.878 12.89L91.22 9.194C87.98 5.92 83.673 4.115 79.09 4.115c-4.583 0-8.892 1.805-12.13 5.078L20.205 56.444a12.412 12.412 0 00-3.555 8.671v8.414c0 3.479 2.802 6.31 6.244 6.31h8.326a12.15 12.15 0 008.579-3.593l34.52-34.887a2.12 2.12 0 000-2.974 2.067 2.067 0 00-2.943 0l-34.52 34.887a7.978 7.978 0 01-5.636 2.36h-8.326a2.094 2.094 0 01-2.081-2.103v-8.415c0-2.12.848-4.193 2.335-5.695l46.755-47.253c4.907-4.96 13.466-4.96 18.374 0l3.658 3.699a13.123 13.123 0 013.805 9.284c0 3.505-1.353 6.807-3.805 9.285L39.8 87.126c-3.405 3.441-7.93 5.334-12.742 5.334h-16.65c-3.443 0-6.245-2.832-6.245-6.31V69.321c0-4.863 1.874-9.436 5.279-12.878l39.903-40.327a2.121 2.121 0 000-2.974 2.068 2.068 0 00-2.944 0L6.498 53.47C2.307 57.707 0 63.335 0 69.322V86.15c0 5.797 4.67 10.517 10.407 10.517h16.65c5.923 0 11.493-2.33 15.684-6.567l52.137-52.69c3.238-3.273 5.025-7.628 5.025-12.26 0-4.631-1.787-8.986-5.025-12.258z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconTypeVerbrauchsmaterial;

/* xeslint-disable */
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import CockpitView from '../../components/CockpitView';
import { fetchClaims } from '../../redux/actions/claimsActions';
import { fetchOrders } from '../../redux/actions/ordersActions';
import { fetchPermanents } from '../../redux/actions/permanentsActions';

const Cockpit = () => {
    const dispatch = useDispatch();

    const [claims, setClaims] = useState([]);
    const [orders, setOrders] = useState([]);
    const [permanents, setPermanents] = useState([]);

    const fetchData = useCallback(async () => {
        const _claims = await dispatch(fetchClaims());
        setClaims(_claims);

        const _orders = await dispatch(fetchOrders());
        setOrders(_orders);

        const _permanents = await dispatch(fetchPermanents());
        setPermanents(_permanents);
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="xview-base">
            {claims.length > 0 && orders.length > 0 && permanents.length > 0 && (
                <div className="view-base-cont">
                    <CockpitView claims={claims} orders={orders} permanents={permanents} />
                </div>
            )}
        </div>
    );
};

export default Cockpit;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block } from '@webfox-sc/core';
import AccordionSection from '../../atoms/AccordionSection';
import PageContentBlock from '../../atoms/PageContentBlock';
import OrderCreateType from '../OrderCreateType';
import OrderCreateService from '../OrderCreateService';
import OrderCreateDetails from '../OrderCreateDetails';
import OrderCreateSummary from '../OrderCreateSummary';
import { fetchServices } from '../../redux/actions/servicesActions';
import { fetchBuildings } from '../../redux/actions/buildingsActions';
import { fetchMe, fetchUsers } from '../../redux/actions/usersActions';
import { useUser } from '../../hooks/user';
import { useOrderDraft, useOrderActions } from '../../hooks/orders';

const OrderCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userId } = useUser();
    const {
        orderDraft,
        isOrderDraftServiceDataValid,
        isOrderDraftDetailsDataValid,
        stableResetOrderDraft
    } = useOrderDraft();
    const { handleCreateOrderFromDraft } = useOrderActions();

    const [stateAcc, setStateAcc] = useState(1);

    useEffect(() => {
        dispatch(fetchServices());
        dispatch(fetchBuildings());
        dispatch(fetchUsers());
        dispatch(fetchMe());

        stableResetOrderDraft();
        return () => {
            stableResetOrderDraft();
        };
    }, [dispatch, stableResetOrderDraft, userId]);

    const handleOnClickServiceNext = () => {
        if (isOrderDraftServiceDataValid()) {
            setStateAcc(3);
        }
    };

    const handleOnClickDetailsNext = () => {
        if (isOrderDraftDetailsDataValid()) {
            setStateAcc(4);
        }
    };

    const handleOnClickSubmit = async () => {
        await handleCreateOrderFromDraft();
        history.push('/orders');
    };

    return (
        <>
            <AccordionSection
                title={orderDraft.type ? `1. Leistung: ${orderDraft.type}` : '1. Leistung auswählen'}
                expanded={stateAcc === 1}
                onToggleExpanded={(expanded) => setStateAcc(expanded ? 1 : 0)}
            >
                <PageContentBlock>
                    <Block padding="75px 0 40px 0">
                        <OrderCreateType onClickTypeButton={() => setStateAcc(2)} />
                    </Block>
                </PageContentBlock>
            </AccordionSection>

            <Block paddingTop="40px">
                <AccordionSection
                    title="2. Leistung spezifizieren"
                    expanded={stateAcc === 2}
                    disabled={!orderDraft.type}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 2 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <OrderCreateService onClickNext={handleOnClickServiceNext} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>

            <Block paddingTop="40px">
                <AccordionSection
                    title="3. Auftragsdetails"
                    expanded={stateAcc === 3}
                    disabled={!isOrderDraftServiceDataValid()}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 3 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <OrderCreateDetails onClickNext={handleOnClickDetailsNext} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>

            <Block paddingTop="40px">
                <AccordionSection
                    title="4. Zusammenfassung & Bestätigung"
                    expanded={stateAcc === 4}
                    disabled={!isOrderDraftDetailsDataValid()}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 4 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <OrderCreateSummary onClickSubmit={handleOnClickSubmit} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>
        </>
    );
};

OrderCreate.propTypes = {};
OrderCreate.defaultProps = {};

export default OrderCreate;

/* eslint-disable */
/**
 *
 * TopFive
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';

class TopFive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}

    getTopFive(data) {
        let i;
        let top5 = [
            { type: 'Bodenreinigung', count: 0 },
            { type: 'Abfall', count: 0 },
            { type: 'Oberflächenreinigung', count: 0 },
            { type: 'Verbrauchsmaterial', count: 0 },
            { type: 'Glasreinigung', count: 0 },
            { type: 'Teeküche', count: 0 },
            { type: 'Bewirtung', count: 0 },
            { type: 'Sonstiges', count: 0 },
            { type: 'Möbel', count: 0 }
        ];
        for (i = 0; i < data.length; i++) {
            let j;
            for (j = 0; j < top5.length; j++) {
                if (top5[j].type == data[i].type) {
                    top5[j].count++;
                }
            }
        }
        top5 = top5
            .sort(function (obj1, obj2) {
                return obj2.count - obj1.count;
            })
            .slice(0, 5);
        return top5;
    }

    render() {
        let top5 = this.getTopFive(this.props.data);

        return (
            <div className="cockpit-background top-5-container">
                <h3 className="cockpit-header">Top 5</h3>
                <div className="top-5-container">
                    {top5[0].count != 0 && (
                        <div className="top-5">
                            1. {top5[0].type} ({top5[0].count})
                        </div>
                    )}
                    {top5[1].count != 0 && (
                        <div className="top-5">
                            2. {top5[1].type} ({top5[1].count})
                        </div>
                    )}
                    {top5[2].count != 0 && (
                        <div className="top-5">
                            3. {top5[2].type} ({top5[2].count})
                        </div>
                    )}
                    {top5[3].count != 0 && (
                        <div className="top-5">
                            4. {top5[3].type} ({top5[3].count})
                        </div>
                    )}
                    {top5[4].count != 0 && (
                        <div className="top-5">
                            5. {top5[4].type} ({top5[4].count})
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TopFive;

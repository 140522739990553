import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';

const StyledComponent = styled.div(({ color, isLinkStyle }) => {
    const style = {
        ...STYLE_TEXT_M,
        ul: { ...STYLE_TEXT_M },
        color: color || COLORS.WHITE
    };

    if (isLinkStyle) {
        style.textDecoration = 'underline';
        style['&:hover'] = {
            textDecoration: 'none'
        };
    }
    return style;
});

const TextM = ({ color, isLinkStyle, children }) => {
    return (
        <StyledComponent color={color} isLinkStyle={isLinkStyle}>
            {children}
        </StyledComponent>
    );
};

TextM.propTypes = {
    color: PropTypes.string,
    isLinkStyle: PropTypes.bool,
    children: PropTypes.any
};

TextM.defaultProps = {
    color: null,
    isLinkStyle: false,
    children: ''
};

export default TextM;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _at from 'lodash/at';
import _orderBy from 'lodash/orderBy';
import { setClaimsFilters, setClaimsSorting } from '../../redux/actions/claimsActions';
import { useUser } from '../../hooks/user';

export function useClaimList() {
    const dispatch = useDispatch();
    const { userId, userRoleName } = useUser();

    const claims = useSelector(({ dataState }) => dataState.claims);

    const { isClaimsRequest, claimsFilters, claimsSortingKey, claimsSortingOrder } = useSelector(
        ({ claimsState }) => claimsState
    );

    const claimsDisplayStep = 10;
    const [claimsDisplayMax, setClaimsDisplayMax] = useState(claimsDisplayStep);

    const [claimsFiltered, setClaimsFiltered] = useState([]);

    useEffect(() => {
        /**
         * FILTER
         */
        let _claimsFiltered = Object.values(claims).filter((claim) => {
            /**
             * NO FILTER STATUS
             */
            if (!claimsFilters.length) {
                return true;
            }

            /**
             * FILTER STATUS
             */
            if (claimsFilters.includes(claim.status)) {
                return true;
            }

            return false;
        });

        /**
         * SORT
         */
        _claimsFiltered = _orderBy(_claimsFiltered, (object) => _at(object, [claimsSortingKey]), [claimsSortingOrder]);

        /**
         * SET
         */
        setClaimsFiltered(_claimsFiltered);
    }, [claims, claimsFilters, claimsSortingKey, claimsSortingOrder, userId, userRoleName]);

    const toggleClaimsFilter = (filter) => {
        let claimsListFiltersNew = [];
        if (claimsFilters.includes(filter)) {
            claimsListFiltersNew = claimsFilters.filter((f) => f !== filter);
        } else {
            claimsListFiltersNew = [...claimsFilters, filter];
        }
        dispatch(setClaimsFilters(claimsListFiltersNew));
    };

    const handleSetClaimsSortingKey = (sortingKey) => {
        let sortingOrder = 'asc';
        if (sortingKey === claimsSortingKey) {
            sortingOrder = claimsSortingOrder === 'asc' ? 'desc' : 'asc';
        }
        dispatch(setClaimsSorting(sortingKey, sortingOrder));
    };

    const displayFurtherClaims = () => {
        setClaimsDisplayMax(claimsDisplayMax + claimsDisplayStep);
    };

    return {
        claims,
        isClaimsInitialRequest: isClaimsRequest && !Object.values(claims).length,
        claimsFiltered,
        claimsFilters,
        claimsSortingKey,
        claimsSortingOrder,
        claimsDisplayMax,
        toggleClaimsFilter,
        handleSetClaimsSortingKey,
        displayFurtherClaims
    };
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import DateUtil from '../utils/DateUtil';
import { CLAIM_STATUS, CLAIM_STATUS_LABELS } from '../global/constants';

export function useClaimHistory(claimId) {
    const claim = useSelector(({ dataState }) => dataState.claims[claimId]);
    const histories = useSelector(({ dataState }) => dataState.histories);
    const users = useSelector(({ dataState }) => dataState.users);
    const roles = useSelector(({ dataState }) => dataState.roles);

    const [claimHistory, setClaimHistory] = useState([]);
    const [lastRejectionComment, setLastRejectionComment] = useState('');
    const [claimComments, setClaimComments] = useState([]);

    useEffect(() => {
        if (claim) {
            const _claimHistory = [];
            const _claimComments = [];
            const historyIds = claim.historyIds || [];

            historyIds.forEach((historyId) => {
                const history = histories[historyId];
                const { previousStatus, nextStatus, comment, creatorId, diff } = history;

                const creator = users[creatorId];
                const creatorUsername = creator?.username || '?';
                const role = creator ? roles[creator.roleId] : null;
                const roleName = role ? `(${role.name})` : '';
                const creatorDescription = `${creatorUsername} ${roleName}`;

                /**
                 * history
                 */
                let text = '';

                if (previousStatus !== nextStatus) {
                    if (!previousStatus && nextStatus === CLAIM_STATUS.OFFEN) {
                        text = `Erstellt von ${creatorDescription}`;
                    } else if (nextStatus === CLAIM_STATUS.UNBERECHTIGT) {
                        text = `Status geändert zu <em>${CLAIM_STATUS_LABELS[nextStatus]}</em> von ${creatorDescription}`;
                        if (comment) {
                            text += `<br /><br />Begründung:<br /><em>${comment}</em>`;
                            setLastRejectionComment(comment);
                        }
                    } else {
                        text = `Status geändert zu <em>${CLAIM_STATUS_LABELS[nextStatus]}</em> von ${creatorDescription}`;
                    }
                }

                if (text) {
                    _claimHistory.push({
                        createdAt: history.createdAt,
                        dateFormatted: DateUtil.getDateTimeFormattedExtended(history.createdAt),
                        text
                    });
                }

                /**
                 * comments
                 */
                if (!previousStatus && !nextStatus && comment && Object.keys(diff).length === 0) {
                    _claimComments.push({
                        createdAt: history.createdAt,
                        dateFormatted: DateUtil.getDateTimeFormattedExtended(history.createdAt),
                        creatorDescription,
                        comment
                    });
                }
            });

            setClaimHistory(_orderBy(_claimHistory, ['createdAt'], ['asc']));
            setClaimComments(_orderBy(_claimComments, ['createdAt'], ['asc']));
        }
    }, [claim, histories, users, roles]);

    return {
        claimHistory,
        lastRejectionComment,
        claimComments
    };
}

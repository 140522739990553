import React from 'react';

const IconOverview = (
    <svg viewBox="0 0 100 100">
        <path
            d="M17.927 31.563c4.93 0 8.926-3.945 8.926-8.774 0-4.827-3.997-8.789-8.926-8.789C12.997 14 9 17.946 9 22.773c0 4.828 4.015 8.79 8.927 8.79zm0-13.484c2.642 0 4.776 2.114 4.776 4.694s-2.151 4.695-4.776 4.695-4.777-2.115-4.777-4.695 2.152-4.694 4.777-4.694zm0 40.586C13.015 58.665 9 54.737 9 49.892s3.997-8.773 8.927-8.773 8.926 3.945 8.926 8.773c0 4.828-3.997 8.773-8.926 8.773zm0-13.467c-2.625 0-4.777 2.097-4.777 4.694s2.152 4.694 4.777 4.694 4.776-2.114 4.776-4.694c0-2.581-2.134-4.694-4.776-4.694zm0 40.586C13.015 85.784 9 81.838 9 77.01c0-4.828 4.015-8.774 8.927-8.774s8.926 3.929 8.926 8.774c0 4.827-3.997 8.773-8.926 8.773zm0-13.485c-2.625 0-4.777 2.114-4.777 4.695 0 2.58 2.152 4.695 4.777 4.695s4.776-2.098 4.776-4.695c0-2.58-2.134-4.695-4.776-4.695zm20.868-47.478c-1.151 0-2.083-.915-2.083-2.048 0-1.132.932-2.047 2.083-2.047h51.071c1.151 0 2.084.915 2.084 2.047 0 1.133-.933 2.048-2.084 2.048h-51.07zm0 27.118c-1.151 0-2.083-.915-2.083-2.047 0-1.132.932-2.048 2.083-2.048h51.071c1.151 0 2.084.916 2.084 2.048 0 1.132-.933 2.047-2.084 2.047h-51.07zm0 27.102c-1.151 0-2.083-.915-2.083-2.047 0-1.132.932-2.048 2.083-2.048h51.071c1.151 0 2.084.916 2.084 2.048 0 1.132-.933 2.047-2.084 2.047h-51.07z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default IconOverview;

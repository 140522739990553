import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import DetailsHeader from '../../atoms/DetailsHeader';
import FormPanelGroup from '../../atoms/FormPanelGroup';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormStaticField from '../../atoms/FormStaticField';
import FormPanelContactPersonList from '../../components/FormPanelContactPersonList';
import ClaimDetailsOptions from '../ClaimDetailsOptions';
import ClaimDetailsComments from '../ClaimDetailsComments';
import ClaimDetailsHistory from '../ClaimDetailsHistory';
import { fetchClaim } from '../../redux/actions/claimsActions';
import { fetchUsers } from '../../redux/actions/usersActions';
import { useClaim } from '../../hooks/claims';
import { useClaimHistory } from '../../hooks/useClaimHistory';
import DateUtil from '../../utils/DateUtil';
import FormatUtil from '../../utils/FormatUtil';

const ClaimDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { claimId } = useParams();
    const { claim } = useClaim(claimId);
    const { claimHistory } = useClaimHistory(claimId);

    useEffect(() => {
        dispatch(fetchClaim(claimId));
        dispatch(fetchUsers());
    }, [claimId, dispatch]);

    if (!claim) {
        return null;
    }

    return (
        <PageContentBlock>
            <Block paddingTop="50px">
                <DetailsHeader
                    title={`Reklamation #${claim.entryId}`}
                    statusLabel={claim.status}
                    backLinkLabel="Zurück zur Übersicht"
                    backLinkHref="/claims"
                    onClickBackLink={(e) => {
                        e.preventDefault();
                        history.push('/claims');
                    }}
                />
            </Block>

            <FormPanelGroup paddingTop="40px">
                <FormPanel title="Daten">
                    <Grid spacing="20px">
                        <GridItem cols="3">
                            <FormLabel top bold>
                                Auftragsersteller
                            </FormLabel>
                            <FormStaticField value={claim.creator ? claim.creator.username : ''} />
                        </GridItem>
                        <GridItem cols="3">
                            <FormLabel top bold>
                                Erstellt am
                            </FormLabel>
                            <FormStaticField value={DateUtil.getDateFormatted(claim.createdAt)} />
                        </GridItem>

                        <GridItem cols="3">
                            <FormLabel top bold>
                                Leistungstyp
                            </FormLabel>
                            <FormStaticField value={claim.type} />
                        </GridItem>

                        <GridItem cols="3">
                            <FormLabel top bold>
                                Ort
                            </FormLabel>
                            <FormStaticField value={claim.location || '–'} />
                        </GridItem>

                        <GridItem cols="12">
                            <FormLabel top bold>
                                Problembeschreibung
                            </FormLabel>
                            <FormStaticField value={FormatUtil.nl2br(claim.problemDescription) || '–'} />
                        </GridItem>
                    </Grid>
                </FormPanel>

                <FormPanelContactPersonList contactPersonList={claim.contactPersonList} />
            </FormPanelGroup>

            <Block paddingTop="70px">
                <ClaimDetailsOptions claimId={claimId} />
            </Block>

            <Block paddingTop="70px">
                <ClaimDetailsComments claimId={claimId} />
            </Block>

            {claimHistory.length > 0 && (
                <Block paddingTop="70px">
                    <ClaimDetailsHistory claimId={claimId} />
                </Block>
            )}
        </PageContentBlock>
    );
};

ClaimDetails.propTypes = {};
ClaimDetails.defaultProps = {};

export default ClaimDetails;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block } from '@webfox-sc/core';
import AccordionSection from '../../atoms/AccordionSection';
import PageContentBlock from '../../atoms/PageContentBlock';
import PermanentCreateType from '../PermanentCreateType';
import PermanentCreateService from '../PermanentCreateService';
import PermanentCreateDetails from '../PermanentCreateDetails';
import PermanentCreateSummary from '../PermanentCreateSummary';
import { fetchServices } from '../../redux/actions/servicesActions';
import { fetchBuildings } from '../../redux/actions/buildingsActions';
import { fetchMe } from '../../redux/actions/usersActions';
import { useUser } from '../../hooks/user';
import { usePermanentDraft, usePermanentActions } from '../../hooks/permanents';

const PermanentCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userId } = useUser();

    const {
        permanentDraft,
        isPermanentDraftServiceDataValid,
        isPermanentDraftDetailsDataValid,
        stableResetPermanentDraft
    } = usePermanentDraft();

    const { handleCreatePermanentFromDraft } = usePermanentActions();

    const [stateAcc, setStateAcc] = useState(1);

    useEffect(() => {
        dispatch(fetchServices());
        dispatch(fetchBuildings());
        dispatch(fetchMe());

        stableResetPermanentDraft();
        return () => {
            stableResetPermanentDraft();
        };
    }, [dispatch, stableResetPermanentDraft, userId]);

    const handleOnClickServiceNext = () => {
        if (isPermanentDraftServiceDataValid()) {
            setStateAcc(3);
        }
    };

    const handleOnClickDetailsNext = () => {
        if (isPermanentDraftDetailsDataValid()) {
            setStateAcc(4);
        }
    };

    const handleOnClickSubmit = async () => {
        await handleCreatePermanentFromDraft();
        history.push('/permanents');
    };

    return (
        <>
            <AccordionSection
                title={permanentDraft.type ? `1. Leistung: ${permanentDraft.type}` : '1. Leistung auswählen'}
                expanded={stateAcc === 1}
                onToggleExpanded={(expanded) => setStateAcc(expanded ? 1 : 0)}
            >
                <PageContentBlock>
                    <Block padding="75px 0 40px 0">
                        <PermanentCreateType onClickTypeButton={() => setStateAcc(2)} />
                    </Block>
                </PageContentBlock>
            </AccordionSection>

            <Block paddingTop="40px">
                <AccordionSection
                    title="2. Leistung spezifizieren"
                    expanded={stateAcc === 2}
                    disabled={!permanentDraft.type}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 2 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <PermanentCreateService onClickNext={handleOnClickServiceNext} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>

            <Block paddingTop="40px">
                <AccordionSection
                    title="3. Auftragsdetails"
                    expanded={stateAcc === 3}
                    disabled={!isPermanentDraftServiceDataValid()}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 3 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <PermanentCreateDetails onClickNext={handleOnClickDetailsNext} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>

            <Block paddingTop="40px">
                <AccordionSection
                    title="4. Zusammenfassung & Bestätigung"
                    expanded={stateAcc === 4}
                    disabled={!isPermanentDraftDetailsDataValid()}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 4 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <PermanentCreateSummary onClickSubmit={handleOnClickSubmit} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>
        </>
    );
};

PermanentCreate.propTypes = {};
PermanentCreate.defaultProps = {};

export default PermanentCreate;

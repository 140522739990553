import React from 'react';

const IconCheckbox = (
    <svg viewBox="0 0 100 100">
        <path
            fill="currentColor"
            d="M86.6708333,30.1353488 C87.5895833,30.1353488 88.3291667,30.8888372 88.3291667,31.8118605 L88.3291667,88.74 L0,88.74 L0,0 L88.3291667,0 L88.3291667,5.01906977 C88.3291667,5.95255814 87.5895833,6.6955814 86.6708333,6.6955814 C85.7416667,6.6955814 85.0020833,5.95255814 85.0020833,5.01906977 L85.0020833,3.35302326 L3.3375,3.35302326 L3.3375,85.3974419 L85.0020833,85.3974419 L85.0020833,31.8118605 C85.0020833,30.8888372 85.7416667,30.1353488 86.6708333,30.1353488 Z M97.84375,7.18744186 C98.49375,7.84046512 98.49375,8.90372093 97.84375,9.55674419 L46.175,61.465814 C45.85,61.7923256 45.4270833,61.9576744 45.0041667,61.9576744 C44.5729167,61.9576744 44.1479167,61.7923256 43.8229167,61.465814 L20.8583333,38.3944186 C20.20625,37.7393023 20.20625,36.6844186 20.8583333,36.0230233 C21.5083333,35.367907 22.5666667,35.367907 23.21875,36.0230233 L45.0041667,57.9097674 L95.4916667,7.18744186 C96.1416667,6.53232558 97.1916667,6.53232558 97.84375,7.18744186 Z"
        />
    </svg>
);

export default IconCheckbox;

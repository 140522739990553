/* xeslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InlineSVG } from '@webfox-sc/core';
import IconChevronDown from '../../assets/icons/IconChevronDown';
import { STYLE_TEXT_M } from '../../styles/type';
import errorImageSrc from '../../assets/images/icon-error.png';
import { COLORS } from '../../styles/common';

const StyledComponent = styled.div({
    position: 'relative'
});

const StyledSelect = styled.select(({ layout }) => {
    return {
        position: 'relative',
        zIndex: 1,
        appearance: 'none',
        border: layout === 'white' ? `1px solid ${COLORS.DARK_BLUE}` : '1px solid white',
        borderRadius: 0,
        background: 'transparent',
        width: '100%',
        padding: '15px 40px 15px 15px',
        outline: 0,
        cursor: 'pointer',
        ...STYLE_TEXT_M,
        color: layout === 'white' ? COLORS.DARK_GREY : COLORS.WHITE,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.14)'
        }
    };
});

const StyledOption = styled.option({
    background: 'white',
    padding: '10px',
    color: COLORS.DARK_BLUE
});

const StyledIcon = styled.div({
    position: 'absolute',
    // zIndex: -1,
    top: 0,
    right: '14px',
    width: '24px',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const StyledErrorImage = styled.img({
    position: 'absolute',
    zIndex: 20,
    top: '-16px',
    right: '10px',
    width: '24px',
    height: '24px',
    verticalAlign: 'top'
});

const FormNativeSelect = ({ layout, options, value, error, onChange }) => {
    const [valueLocal, setValueLocal] = useState('');

    useEffect(() => {
        setValueLocal(value);
    }, [value]);

    const handleOnChange = (e) => {
        setValueLocal(e.target.value);
        onChange(e);
    };

    return (
        <StyledComponent>
            <StyledSelect layout={layout} value={valueLocal || ''} onChange={handleOnChange}>
                {options.map((option, i) => {
                    return (
                        <StyledOption key={String(i)} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </StyledOption>
                    );
                })}
            </StyledSelect>
            <StyledIcon>
                <InlineSVG width="24px" color={layout === 'white' ? COLORS.DARK_GREY : COLORS.WHITE}>
                    {IconChevronDown}
                </InlineSVG>
            </StyledIcon>
            {error && <StyledErrorImage src={errorImageSrc} alt="Error" />}
        </StyledComponent>
    );
};

FormNativeSelect.propTypes = {
    layout: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.any,
    error: PropTypes.bool,
    onChange: PropTypes.func
};

FormNativeSelect.defaultProps = {
    layout: null,
    options: [],
    value: '',
    error: false,
    onChange: () => false
};

export default FormNativeSelect;

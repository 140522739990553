/* xeslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Block, Group } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconReject from '../../assets/icons/IconReject';
import IconDelete from '../../assets/icons/IconDelete';
import ConfirmDialog from '../../atoms/ConfirmDialog';
import FormButton from '../../atoms/FormButton';
import FormDatePicker from '../../atoms/FormDatePicker';
import { updatePermanent, deletePermanent } from '../../redux/actions/permanentsActions';
import { usePermanent } from '../../hooks/permanents';
import { useUser } from '../../hooks/user';
import { PERMANENT_STATUS, USER_ROLES } from '../../global/constants';

const PermanentDetailsOptions = ({ permanentId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userRoleName } = useUser();
    const { permanent } = usePermanent(permanentId);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [expiryDateDraft, setExpiryDateDraft] = useState(null);
    const [isButtonCancellationClicked, setIsButtonCancellationClicked] = useState(false);

    useEffect(() => {
        if (permanent) {
            setExpiryDateDraft(permanent.expiryDate);
        }
    }, [permanent]);

    if (!permanent) {
        return null;
    }

    const handleOnUpdateStatus = (newStatus) => {
        dispatch(
            updatePermanent(permanent.id, {
                status: newStatus
            })
        );
    };

    const handleOnClickDelete = async () => {
        await dispatch(deletePermanent(permanent.id));
        history.push('/permanents');
    };

    const handleOnChangeExpiryDate = (expiryDateNew) => {
        setExpiryDateDraft(expiryDateNew);
    };

    const handleOnClickCancellation = () => {
        setIsButtonCancellationClicked(true);
        if (expiryDateDraft) {
            dispatch(
                updatePermanent(permanent.id, {
                    status: PERMANENT_STATUS.STORNIERUNG,
                    expiry_date: expiryDateDraft
                })
            );
        }
    };

    const showOptionsByStatusZurFreigabe =
        permanent.listStatus === PERMANENT_STATUS.ZUR_FREIGABE &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.LOCATION_MANAGER);

    const showOptionStornieren =
        permanent.listStatus === PERMANENT_STATUS.AKTIV &&
        (userRoleName === USER_ROLES.ADMIN ||
            userRoleName === USER_ROLES.KEY_USER ||
            userRoleName === USER_ROLES.LOCATION_MANAGER);

    const showOptionChangerExpiryDate =
        (permanent.listStatus === PERMANENT_STATUS.STORNIERUNG ||
            permanent.listStatus === PERMANENT_STATUS.ABGELAUFEN) &&
        (userRoleName === USER_ROLES.ADMIN || userRoleName === USER_ROLES.KEY_USER);

    const showOptionDelete = userRoleName === USER_ROLES.ADMIN;

    return (
        <>
            <Group vertical spacing="70px">
                {showOptionsByStatusZurFreigabe && (
                    <Group nowrap spacing="20px" justify="center">
                        <FormButton
                            layout="primary"
                            label={'Status ändern zu "Aktiv"'}
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={() => handleOnUpdateStatus(PERMANENT_STATUS.AKTIV)}
                        />
                        <FormButton
                            layout="primary"
                            label={'Status ändern zu "Abgelehnt"'}
                            icon={IconReject}
                            iconSize="small"
                            onClick={() => handleOnUpdateStatus(PERMANENT_STATUS.ABGELEHNT)}
                        />
                    </Group>
                )}
                {showOptionStornieren && (
                    <>
                        <Group nowrap spacing="20px" justify="center">
                            <FormDatePicker
                                selected={expiryDateDraft ? new Date(expiryDateDraft) : null}
                                onChange={handleOnChangeExpiryDate}
                                minDate={Date.now()}
                                error={isButtonCancellationClicked && !expiryDateDraft}
                            />
                            <FormButton
                                layout="primary"
                                label="Stornieren"
                                icon={IconCheckbox}
                                iconSize="small"
                                onClick={() => handleOnClickCancellation()}
                            />
                        </Group>
                    </>
                )}
                {showOptionChangerExpiryDate && (
                    <Group nowrap spacing="20px" justify="center">
                        <FormDatePicker
                            selected={expiryDateDraft ? moment(expiryDateDraft).toDate() : ''}
                            onChange={handleOnChangeExpiryDate}
                            minDate={Date.now()}
                        />
                        <FormButton
                            layout="primary"
                            label="Ablaufdatum ändern"
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={() => handleOnClickCancellation()}
                        />
                    </Group>
                )}
                {showOptionDelete && (
                    <Block justify="center">
                        <FormButton
                            label="Auftrag endgültig löschen"
                            icon={IconDelete}
                            iconSize="small"
                            onClick={() => setShowDeleteDialog(true)}
                        />
                    </Block>
                )}
            </Group>

            <ConfirmDialog
                show={showDeleteDialog}
                heading={`Möchten Sie den Dauerauftrag<br />#${permanent.entryId} wirklich löschen?`}
                text="Diese Aktion kann nicht rückgängig gemacht werden."
                confirmButtonLabel="Löschen"
                confirmButtonIcon={IconDelete}
                onClickConfirm={() => {
                    handleOnClickDelete();
                    setShowDeleteDialog(false);
                }}
                onClickCancel={() => setShowDeleteDialog(false)}
                onClickOutside={() => setShowDeleteDialog(false)}
            />
        </>
    );
};

PermanentDetailsOptions.propTypes = {
    permanentId: PropTypes.any
};

PermanentDetailsOptions.defaultProps = {
    permanentId: null
};

export default PermanentDetailsOptions;

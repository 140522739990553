import React from 'react';

const IconTypeSonstiges = (
    <svg viewBox="0 0 100 100">
        <path
            d="M53.16.015c-.75 0-1.45.368-1.874.984-1.239 1.8-30.348 44.336-30.348 65.85 0 17.722 14.455 32.142 32.221 32.142s32.22-14.42 32.22-32.143C85.38 45.335 56.273 2.8 55.033.998A2.273 2.273 0 0053.16.016zm0 94.441c-15.26 0-27.676-12.384-27.676-27.608 0-17.111 21.174-50.6 27.675-60.48 6.502 9.88 27.675 43.369 27.675 60.48 0 15.224-12.415 27.608-27.675 27.608zM97.722 9.198a2.27 2.27 0 012.272 2.267 2.27 2.27 0 01-2.272 2.267h-4.417v4.406a2.27 2.27 0 01-2.273 2.267 2.27 2.27 0 01-2.272-2.267v-4.406h-4.417a2.27 2.27 0 01-2.273-2.267 2.269 2.269 0 012.273-2.267h4.417V4.792a2.269 2.269 0 012.272-2.267 2.27 2.27 0 012.273 2.267v4.406h4.417zM15.652 77.464a2.27 2.27 0 012.272 2.267 2.27 2.27 0 01-2.272 2.267h-4.417v4.406a2.27 2.27 0 01-2.273 2.268 2.269 2.269 0 01-2.273-2.268v-4.406H2.273A2.27 2.27 0 010 79.731a2.27 2.27 0 012.273-2.267h4.416v-4.406a2.27 2.27 0 012.273-2.267 2.27 2.27 0 012.273 2.267v4.406h4.417z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconTypeSonstiges;

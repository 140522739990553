/* xeslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Group } from '@webfox-sc/core';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import ClickWrapper from '../ClickWrapper';
import FormButton from '../FormButton';
import { STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';

const StyledShader = styled.div({
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const StyledDialog = styled.div({
    position: 'absolute',
    zIndex: 11,
    width: 'calc(100vw - 60px)',
    maxWidth: '700px',
    boxSizing: 'border-box',
    background: 'white'
});

const StyledHeading = styled.div({
    ...STYLE_TEXT_M,
    fontSize: '40px',
    lineHeight: 'normal',
    textAlign: 'center',
    color: COLORS.DARK_BLUE
});

const StyledText = styled.div({
    ...STYLE_TEXT_M,
    textAlign: 'center',
    color: COLORS.DARK_GREY
});

const ConfirmDialog = ({
    show,
    heading,
    text,
    confirmButtonLabel,
    confirmButtonIcon,
    cancelButtonLabel,
    cancelButtonIcon,
    onClickConfirm,
    onClickCancel,
    onClickOutside
}) => {
    if (!show) {
        return null;
    }

    return (
        <StyledShader>
            <StyledDialog>
                <ClickWrapper onClickOutside={onClickOutside}>
                    <Block padding="30px">
                        {heading && (
                            <Block paddingBottom="20px" justify="center">
                                <StyledHeading dangerouslySetInnerHTML={{ __html: heading }} />
                            </Block>
                        )}
                        {text && (
                            <Block paddingBottom="30px" justify="center">
                                <StyledText dangerouslySetInnerHTML={{ __html: text }} />
                            </Block>
                        )}
                        <Group nowrap spacing="20px" justify="center">
                            <FormButton
                                layout="white"
                                label={cancelButtonLabel || 'Abbrechen'}
                                icon={cancelButtonIcon || IconClose}
                                iconSize="small"
                                onClick={onClickCancel}
                            />
                            <FormButton
                                layout="white"
                                label={confirmButtonLabel || 'Bestätigen'}
                                icon={confirmButtonIcon || IconCheck}
                                iconSize="small"
                                onClick={onClickConfirm}
                            />
                        </Group>
                    </Block>
                </ClickWrapper>
            </StyledDialog>
        </StyledShader>
    );
};

ConfirmDialog.propTypes = {
    show: PropTypes.bool,
    heading: PropTypes.string,
    text: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    confirmButtonIcon: PropTypes.any,
    cancelButtonLabel: PropTypes.string,
    cancelButtonIcon: PropTypes.any,
    onClickConfirm: PropTypes.func,
    onClickCancel: PropTypes.func,
    onClickOutside: PropTypes.func
};

ConfirmDialog.defaultProps = {
    show: false,
    heading: '',
    text: '',
    confirmButtonLabel: null,
    confirmButtonIcon: null,
    cancelButtonLabel: null,
    cancelButtonIcon: null,
    onClickConfirm: () => {},
    onClickCancel: () => {},
    onClickOutside: () => {}
};

export default ConfirmDialog;

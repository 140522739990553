import { useEffect, useState } from 'react';

export const serviceTypes = ['Teppichböden', 'Hartbelagböden', 'Mobiliar', 'Glas', 'Küche', 'Sonstiges'];

export function useServices() {
    const [serviceTypeDropdownOptions, setServiceTypeDropdownOptions] = useState([]);

    useEffect(() => {
        const _serviceTypeDropdownOptions = [
            {
                value: '',
                label: 'Auswählen',
                disabled: true
            }
        ];
        serviceTypes.forEach((serviceType) => {
            _serviceTypeDropdownOptions.push({
                value: serviceType,
                label: serviceType
            });
        });
        setServiceTypeDropdownOptions(_serviceTypeDropdownOptions);
    }, []);

    return {
        serviceTypeDropdownOptions
    };
}

/* xeslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import TextButton from '../../atoms/TextButton';
import logo from '../../images/logos/gegenbauer-logo.svg';

const Footer = () => {
    const history = useHistory();

    history.listen((location, action) => {
        if (action === 'PUSH') {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    });

    return (
        <Group justify="flex-end" padding="20px" spacing="40px">
            <TextButton label="Datenschutz" onClick={() => history.push('/privacy')} />
            <TextButton label="Impressum" onClick={() => history.push('/imprint')} />
            <Block paddingTop="4px">
                <img src={logo} alt="Logo" />
            </Block>
        </Group>
    );
};

export default Footer;

import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Block, Collapse, Group } from '@webfox-sc/core';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormButton from '../../atoms/FormButton';
import TextButtonDialog from '../../atoms/TextButtonDialog';
import TextM from '../../atoms/TextM';

import { STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';
import { login } from '../../redux/actions/authActions';
import { useAuth } from '../../hooks/useAuth';

const StyledHeading = styled.div({
    ...STYLE_TEXT_M,
    fontSize: '30px',
    lineHeight: 'normal',
    textAlign: 'center',
    color: COLORS.DARK_BLUE
});

const LoginForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loginErrorText } = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleOnSubmit = (e) => {
        e.preventDefault();
        dispatch(login(username, password));
    };

    return (
        <Block width="100%" maxWidth="500px" padding="40px" background="white">
            <Block justify="center">
                <StyledHeading>Login</StyledHeading>
            </Block>
            <Block paddingTop="40px">
                <form onSubmit={handleOnSubmit}>
                    <Group vertical spacing="20px">
                        <Block>
                            <FormLabel color={COLORS.DARK_GREY} top>
                                Benutzername
                            </FormLabel>
                            <FormInput
                                layout="white"
                                type="text"
                                name="username"
                                autoComplete="username"
                                value={username}
                                placeholder="Nutzername"
                                width="100%"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Block>
                        <Block>
                            <FormLabel color={COLORS.DARK_GREY} top>
                                Passwort
                            </FormLabel>
                            <FormInput
                                layout="white"
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                value={password}
                                placeholder="Passwort"
                                width="100%"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Block>
                    </Group>
                    <Block paddingTop="30px" justify="center">
                        <FormButton layout="white" label="Anmelden" type="submit" disabled={!username || !password} />
                    </Block>
                    <Collapse show={!!loginErrorText}>
                        <Block paddingTop="40px" justify="center">
                            <TextM color={COLORS.RED}>{loginErrorText}</TextM>
                        </Block>
                    </Collapse>
                    <Block paddingTop="40px" justify="center">
                        <TextButtonDialog
                            label="Passwort zurücksetzen"
                            href="/auth/forgot"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/auth/forgot');
                            }}
                        />
                    </Block>
                </form>
            </Block>
        </Block>
    );
};

LoginForm.propTypes = {};
LoginForm.defaultProps = {};

export default LoginForm;

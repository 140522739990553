/* eslint-disable */
/**
 *
 * CockpitUpload
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import api from '../../../api';
import UploadSection from './UploadSection';
import moment from 'moment';

class CockpitUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadObj: null,
            uploadName: '',
            pdfList: null,
            uploadStat: true
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.listFiles();
    }

    handleClick(num, uploadObj, uploadName, year) {
        let finalname = year + '-' + num + '-' + uploadName;

        const form = new FormData();
        let fileInputElement = document.getElementById('fileInputElement');
        form.append('files', uploadObj, finalname);
        const base = this;
        api.strapiapi
            .upload(form)
            .then((response) => {
                // console.log('success: ', response);
                base.listFiles();
            })
            .catch((err) => {
                console.log('error: ', err);
            });
    }

    listFiles() {
        api.strapiapi
            .getFiles()
            .then((response) => {
                // console.log('success - getFiles: ', response);
                this.setState({ pdfList: response });
            })
            .catch((err) => {
                console.log('error - getFiles: ', err);
            });
    }

    render() {
        const { startDate, endDate } = this.props;

        // let checkStart = startDate.format('MM');
        let checkStart = moment(startDate).format('MM');
        let checkEnd = moment(endDate).format('MM');

        const data = this.state.pdfList;
        const uploadStat = this.state.uploadStat;

        let year = '2018';

        let list2018 = [
            {
                month: 'Dezember' + ' ' + year,
                id: '12'
            },
            {
                month: 'November' + ' ' + year,
                id: '11'
            },
            {
                month: 'Oktober' + ' ' + year,
                id: '10'
            },
            {
                month: 'September' + ' ' + year,
                id: '09'
            },
            {
                month: 'August' + ' ' + year,
                id: '08'
            },
            {
                month: 'Juli' + ' ' + year,
                id: '07'
            },
            {
                month: 'Juni' + ' ' + year,
                id: '06'
            },
            {
                month: 'Mai' + ' ' + year,
                id: '05'
            },
            {
                month: 'April' + ' ' + year,
                id: '04'
            },
            {
                month: 'März' + ' ' + year,
                id: '03'
            },
            {
                month: 'Februar' + ' ' + year,
                id: '02'
            },
            {
                month: 'Januar' + ' ' + year,
                id: '01'
            }
        ];

        let list2019 = [
            {
                month: 'Januar 2019',
                id: '01'
            }
        ];

        let renderList2018 = null;
        if (data != null) {
            renderList2018 = list2018.map((item, index) => {
                if (item.id >= checkStart && item.id <= checkEnd) {
                    return (
                        <UploadSection
                            key={index}
                            api={api.apiUrl}
                            year="2018"
                            month={item.month}
                            num={item.id}
                            data={data}
                            onClick={this.handleClick}
                        />
                    );
                } else {
                    return null;
                }
            });
        }

        let renderList2019 = null;
        if (data != null) {
            renderList2019 = list2019.map((item, index) => {
                if (item.id >= checkStart && item.id <= checkEnd) {
                    return (
                        <UploadSection
                            key={index}
                            api={api.apiUrl}
                            year="2019"
                            month={item.month}
                            num={item.id}
                            data={data}
                            onClick={this.handleClick}
                        />
                    );
                } else {
                    return null;
                }
            });
        }

        return (
            <div className="cockpit-sub-base center-flex upload-container">
                <fieldset>
                    <legend>Qualität</legend>
                    <span>PDF-Downloads</span>

                    {uploadStat == true ? (
                        <React.Fragment>
                            {renderList2019}
                            {renderList2018}
                        </React.Fragment>
                    ) : (
                        <React.Fragment />
                    )}
                </fieldset>
            </div>
        );
    }
}

export default CockpitUpload;

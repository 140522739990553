import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@webfox-sc/core';
import IconSort from '../../assets/icons/IconSort';
import IconSortAsc from '../../assets/icons/IconSortAsc';
import IconSortDesc from '../../assets/icons/IconSortDesc';
import { STYLE_TEXT_S_BOLD } from '../../styles/type';

const StyledTableHeaderCell = styled.th(({ width }) => {
    return {
        width,
        padding: '0px 17px',
        textAlign: 'left'
    };
});

const buttonTheme = ({ order }) => {
    return {
        root: {
            background: 'transparent',
            padding: 0,
            outline: 0,
            userSelect: 'none',
            svg: {
                width: '9px',
                height: order ? '9px' : '18px'
            },
            span: {
                ...STYLE_TEXT_S_BOLD,
                flexShrink: 0
            }
        }
    };
};

const TableHeaderCell = ({ width, colSpan, label, order, onClick }) => {
    let iconRight = IconSort;
    if (order === 'asc') {
        iconRight = IconSortAsc;
    } else if (order === 'desc') {
        iconRight = IconSortDesc;
    }

    return (
        <StyledTableHeaderCell width={width} colSpan={colSpan}>
            {label && <Button label={label} iconRight={iconRight} theme={buttonTheme({ order })} onClick={onClick} />}
        </StyledTableHeaderCell>
    );
};

TableHeaderCell.propTypes = {
    width: PropTypes.string,
    colSpan: PropTypes.any,
    label: PropTypes.string,
    order: PropTypes.string,
    onClick: PropTypes.func
};

TableHeaderCell.defaultProps = {
    width: null,
    colSpan: null,
    label: '',
    order: null,
    onClick: () => {}
};

export default TableHeaderCell;

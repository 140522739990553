import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/useAuth';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();
    return <Route {...rest} render={(props) => isLoggedIn && <Component {...props} />} />;
};

PrivateRoute.propTypes = {
    component: PropTypes.any
};
PrivateRoute.defaultProps = {
    component: null
};

export default PrivateRoute;

/* xeslint-disable */
import { REDUX_ACTIONS } from '../../global/constants';
import DataReducerUtil from '../../utils/DataReducerUtil';

const dataReducerUtil = new DataReducerUtil();

const initialState = {
    claims: {},
    orders: {},
    permanents: {},
    services: {},
    buildings: {},
    histories: {},
    users: {},
    roles: {}
};

function dataReducer(state = { ...initialState }, action) {
    switch (action.type) {
        /**
         * claims
         */
        case REDUX_ACTIONS.RECIEVE_CLAIMS:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setClaims(action.claims);

        case REDUX_ACTIONS.RECIEVE_CLAIM:
        case REDUX_ACTIONS.RECIEVE_UPDATE_CLAIM:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setClaim(action.claim);

        case REDUX_ACTIONS.RECIEVE_DELETE_CLAIM:
            dataReducerUtil.setState(state);
            return dataReducerUtil.unsetClaim(action.claim);

        /**
         * orders
         */
        case REDUX_ACTIONS.RECIEVE_ORDERS:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setOrders(action.orders);

        case REDUX_ACTIONS.RECIEVE_ORDER:
        case REDUX_ACTIONS.RECIEVE_UPDATE_ORDER:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setOrder(action.order);

        case REDUX_ACTIONS.RECIEVE_DELETE_ORDER:
            dataReducerUtil.setState(state);
            return dataReducerUtil.unsetOrder(action.order);

        /**
         * permanents
         */
        case REDUX_ACTIONS.RECIEVE_PERMANENTS:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setPermanents(action.permanents);

        case REDUX_ACTIONS.RECIEVE_PERMANENT:
        case REDUX_ACTIONS.RECIEVE_UPDATE_PERMANENT:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setPermanent(action.permanent);

        case REDUX_ACTIONS.RECIEVE_DELETE_PERMANENT:
            dataReducerUtil.setState(state);
            return dataReducerUtil.unsetPermanent(action.permanent);

        /**
         * services
         */
        case REDUX_ACTIONS.RECIEVE_SERVICES:
        case REDUX_ACTIONS.RECIEVE_UPDATE_SERVICES:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setServices(action.services);

        case REDUX_ACTIONS.RECIEVE_CREATE_SERVICE:
        case REDUX_ACTIONS.RECIEVE_UPDATE_SERVICE:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setService(action.service);

        case REDUX_ACTIONS.RECIEVE_DELETE_SERVICE:
            dataReducerUtil.setState(state);
            return dataReducerUtil.unsetService(action.service);

        /**
         * buildings
         */
        case REDUX_ACTIONS.RECIEVE_BUILDINGS:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setBuildings(action.buildings);

        case REDUX_ACTIONS.RECIEVE_CREATE_BUILDING:
        case REDUX_ACTIONS.RECIEVE_UPDATE_BUILDING:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setBuilding(action.building);

        case REDUX_ACTIONS.RECIEVE_DELETE_BUILDING:
            dataReducerUtil.setState(state);
            return dataReducerUtil.unsetBuilding(action.building);

        /**
         * users
         */
        case REDUX_ACTIONS.RECIEVE_USERS:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setUsers(action.users);

        case REDUX_ACTIONS.RECIEVE_LOGIN:
        case REDUX_ACTIONS.RECIEVE_ME:
        case REDUX_ACTIONS.RECIEVE_USER:
        case REDUX_ACTIONS.RECIEVE_CREATE_USER:
        case REDUX_ACTIONS.RECIEVE_UPDATE_USER:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setUser(action.user);

        case REDUX_ACTIONS.RECIEVE_DELETE_USER:
            dataReducerUtil.setState(state);
            return dataReducerUtil.unsetUser(action.user);

        /**
         * roles
         */
        case REDUX_ACTIONS.RECIEVE_ROLES:
            dataReducerUtil.setState(state);
            return dataReducerUtil.setRoles(action.roles);

        /**
         * logout
         */
        case REDUX_ACTIONS.LOGOUT:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default dataReducer;

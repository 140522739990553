import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _at from 'lodash/at';
import _orderBy from 'lodash/orderBy';
import { setBuildingsSorting } from '../../redux/actions/buildingsActions';

export function useSettingsBuildings() {
    const dispatch = useDispatch();
    const buildings = useSelector(({ dataState }) => dataState.buildings);
    const { isBuildingsRequest, buildingsSortingKey, buildingsSortingOrder } = useSelector(
        ({ buildingsState }) => buildingsState
    );

    const buildingsDisplayStep = 50;
    const [buildingsDisplayMax, setBuildingsDisplayMax] = useState(buildingsDisplayStep);

    const [buildingsSorted, setBuildingsSorted] = useState([]);

    useEffect(() => {
        let _buildingsSorted = Object.values(buildings);

        /**
         * SORT
         */
        _buildingsSorted = _orderBy(_buildingsSorted, (object) => _at(object, [buildingsSortingKey]), [
            buildingsSortingOrder
        ]);

        /**
         * SET
         */
        setBuildingsSorted(_buildingsSorted);
    }, [buildings, buildingsSortingKey, buildingsSortingOrder]);

    const handleSetBuildingsSortingKey = (sortingKey) => {
        let sortingOrder = 'asc';
        if (sortingKey === buildingsSortingKey) {
            sortingOrder = buildingsSortingOrder === 'asc' ? 'desc' : 'asc';
        }
        dispatch(setBuildingsSorting(sortingKey, sortingOrder));
    };

    const displayFurtherBuildings = () => {
        setBuildingsDisplayMax(buildingsDisplayMax + buildingsDisplayStep);
    };

    return {
        buildings,
        isBuildingsInitialRequest: isBuildingsRequest && !Object.values(buildings).length,
        buildingsSorted,
        buildingsSortingKey,
        buildingsSortingOrder,
        buildingsDisplayMax,
        handleSetBuildingsSortingKey,
        displayFurtherBuildings
    };
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconClose from '../../assets/icons/IconClose';
import IconDelete from '../../assets/icons/IconDelete';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormButton from '../../atoms/FormButton';
import { updateBuiling } from '../../redux/actions/buildingsActions';

const SettingsBuildingsEdit = ({ buildingId, onClickCancel, onUpdated, onClickDelete }) => {
    const dispatch = useDispatch();
    const building = useSelector(({ dataState }) => dataState.buildings[buildingId]);

    const [nameDraft, setNameDraft] = useState('');
    const [codeDraft, setCodeDraft] = useState('');
    const [isButtonUpdateClicked, setIsButtonUpdateClicked] = useState(false);

    useEffect(() => {
        if (building) {
            setNameDraft(String(building.name).trim());
            setCodeDraft(String(building.code).trim());
        }
    }, [building]);

    const handleClickUpdate = async () => {
        if (nameDraft && codeDraft) {
            await dispatch(
                updateBuiling(building.id, {
                    name: nameDraft,
                    code: codeDraft
                })
            );
            onUpdated();
        } else {
            setIsButtonUpdateClicked(true);
        }
    };

    return (
        <Block padding="20px">
            <Grid spacing="20px">
                <GridItem cols="6">
                    <FormLabel top>Name *</FormLabel>
                    <FormInput
                        ariaLabel="Name"
                        value={nameDraft}
                        error={isButtonUpdateClicked && !nameDraft}
                        onChange={(e) => setNameDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="6">
                    <FormLabel top>Code *</FormLabel>
                    <FormInput
                        ariaLabel="Code"
                        value={codeDraft}
                        error={isButtonUpdateClicked && !codeDraft}
                        onChange={(e) => setCodeDraft(e.target.value)}
                    />
                </GridItem>
            </Grid>

            <Group paddingTop="20px" spacing="20px">
                <GroupItem>
                    <Group spacing="20px">
                        <FormButton
                            label="Übernehmen"
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={handleClickUpdate}
                        />
                        <FormButton label="Abbrechen" icon={IconClose} iconSize="small" onClick={onClickCancel} />
                    </Group>
                </GroupItem>
                <GroupItem grow>
                    <Block justify="flex-end">
                        <FormButton label="Löschen" icon={IconDelete} iconSize="small" onClick={onClickDelete} />
                    </Block>
                </GroupItem>
            </Group>
        </Block>
    );
};

SettingsBuildingsEdit.propTypes = {
    buildingId: PropTypes.string,
    onClickCancel: PropTypes.func,
    onUpdated: PropTypes.func,
    onClickDelete: PropTypes.func
};

SettingsBuildingsEdit.defaultProps = {
    buildingId: '',
    onClickCancel: () => {},
    onUpdated: () => {},
    onClickDelete: () => {}
};

export default SettingsBuildingsEdit;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Block, Collapse, Grid, GridItem } from '@webfox-sc/core';
import IconCreate from '../../assets/icons/IconCreate';
import IconDelete from '../../assets/icons/IconDelete';
import Table from '../../atoms/Table';
import TableBody from '../../atoms/TableBody';
import TableHeader from '../../atoms/TableHeader';
import TableRow from '../../atoms/TableRow';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import TableBodyCell from '../../atoms/TableBodyCell';
import TextM from '../../atoms/TextM';
import FormButton from '../../atoms/FormButton';
import ConfirmDialog from '../../atoms/ConfirmDialog';
import SettingsServicesEdit from '../SettingsServicesEdit';
import SettingsServicesCreate from '../SettingsServicesCreate';
import { fetchServices, deleteService } from '../../redux/actions/servicesActions';
import { useSettingsServices } from './SettingsServices.hooks';
import FormatUtil from '../../utils/FormatUtil';
import CSVUploader from '../../components/MicroComps/CSVUploader';
import { useUser } from '../../hooks/user';
import { USER_ROLES } from '../../global/constants';

const colors = {
    NEW: '#42bf00',
    UPDATED: '#ffb700'
};

const SettingsServices = () => {
    const dispatch = useDispatch();
    const { userRoleName } = useUser();
    const [activeServiceId, setActiveServiceId] = useState('');
    const [showCreatePanel, setShowCreatePanel] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const {
        isServicesInitialRequest,
        servicesSorted,
        servicesSortingKey,
        servicesSortingOrder,
        servicesDisplayMax,
        handleSetServicesSortingKey,
        displayFurtherServices,
        handleUpload
    } = useSettingsServices();

    useEffect(() => {
        dispatch(fetchServices());
    }, [dispatch]);

    if (userRoleName !== USER_ROLES.ADMIN) {
        return null;
    }

    if (isServicesInitialRequest) {
        return null;
    }

    const handleOnClickShowCreatePanel = () => {
        setShowCreatePanel(!showCreatePanel);
        setActiveServiceId('');
    };

    const handleOnClickTableRow = (userId) => {
        setActiveServiceId(userId);
        setShowCreatePanel(false);
    };

    const handleOnClickDelete = async () => {
        dispatch(deleteService(activeServiceId));
    };

    const handleOnUpload = (data) => {
        handleUpload(data);
    };

    return (
        <>
            <Block justify="center">
                <TextM>
                    <strong>
                        {servicesSorted.length} {servicesSorted.length === 1 ? 'Leistung' : 'Leistungen'}
                    </strong>
                </TextM>
            </Block>

            <Block paddingTop="30px">
                <Grid columns="2">
                    <GridItem>
                        <FormButton
                            label="Neue Leistung"
                            icon={IconCreate}
                            iconSize="small"
                            active={showCreatePanel}
                            onClick={handleOnClickShowCreatePanel}
                        />
                    </GridItem>
                    <GridItem>
                        <Block justify="flex-end">
                            <CSVUploader inputId="csv-upload" onUpload={handleOnUpload} />
                        </Block>
                    </GridItem>
                </Grid>
            </Block>

            <Collapse show={showCreatePanel} keepChildren={false}>
                <Block paddingTop="10px">
                    <SettingsServicesCreate
                        onClickCancel={() => setShowCreatePanel(false)}
                        onCreated={() => setShowCreatePanel(false)}
                    />
                </Block>
            </Collapse>

            <Block paddingTop="50px">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell
                                width="10%"
                                label="Nummer"
                                order={servicesSortingKey === 'number' ? servicesSortingOrder : null}
                                onClick={() => handleSetServicesSortingKey('number')}
                            />
                            <TableHeaderCell
                                label="Name"
                                order={servicesSortingKey === 'name' ? servicesSortingOrder : null}
                                onClick={() => handleSetServicesSortingKey('name')}
                            />
                            <TableHeaderCell
                                width="10%"
                                label="Einheit"
                                order={servicesSortingKey === 'unit' ? servicesSortingOrder : null}
                                onClick={() => handleSetServicesSortingKey('unit')}
                            />
                            <TableHeaderCell
                                width="10%"
                                label="Preis"
                                order={servicesSortingKey === 'price' ? servicesSortingOrder : null}
                                onClick={() => handleSetServicesSortingKey('price')}
                            />
                            <TableHeaderCell
                                width="20%"
                                label="Typ"
                                order={servicesSortingKey === 'type' ? servicesSortingOrder : null}
                                onClick={() => handleSetServicesSortingKey('type')}
                            />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {servicesSorted.slice(0, servicesDisplayMax).map((service) => {
                            return (
                                <TableRow
                                    key={service.id}
                                    onClick={
                                        activeServiceId === service.id ? null : () => handleOnClickTableRow(service.id)
                                    }
                                >
                                    {activeServiceId === service.id ? (
                                        <TableBodyCell
                                            colSpan="5"
                                            color={service.status ? colors[service.status] : null}
                                        >
                                            <SettingsServicesEdit
                                                serviceId={service.id}
                                                onClickCancel={() => setActiveServiceId('')}
                                                onUpdated={() => setActiveServiceId('')}
                                                onClickDelete={() => {
                                                    setShowDeleteDialog(true);
                                                }}
                                            />
                                        </TableBodyCell>
                                    ) : (
                                        <>
                                            <TableBodyCell
                                                layout="text"
                                                color={service.status ? colors[service.status] : null}
                                            >
                                                {service.number}
                                            </TableBodyCell>
                                            <TableBodyCell
                                                layout="text"
                                                color={service.status ? colors[service.status] : null}
                                            >
                                                {service.name}
                                            </TableBodyCell>
                                            <TableBodyCell
                                                layout="text"
                                                color={service.status ? colors[service.status] : null}
                                            >
                                                {service.unit}
                                            </TableBodyCell>
                                            <TableBodyCell
                                                layout="text"
                                                color={service.status ? colors[service.status] : null}
                                            >
                                                {FormatUtil.getDecimalFormatted(service.price)}
                                            </TableBodyCell>
                                            <TableBodyCell
                                                layout="text"
                                                color={service.status ? colors[service.status] : null}
                                            >
                                                {service.type}
                                            </TableBodyCell>
                                        </>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Block>

            {servicesDisplayMax < servicesSorted.length && (
                <Block paddingTop="10px" justify="center">
                    <FormButton label="Mehr anzeigen" onClick={displayFurtherServices} />
                </Block>
            )}

            <ConfirmDialog
                show={showDeleteDialog}
                heading="Möchten Sie diesen Eintrag wirklich löschen?"
                text="Diese Aktion kann nicht rückgängig gemacht werden."
                confirmButtonLabel="Löschen"
                confirmButtonIcon={IconDelete}
                onClickConfirm={() => {
                    handleOnClickDelete();
                    setShowDeleteDialog(false);
                }}
                onClickCancel={() => setShowDeleteDialog(false)}
                onClickOutside={() => setShowDeleteDialog(false)}
            />
        </>
    );
};

SettingsServices.propTypes = {};
SettingsServices.defaultProps = {};

export default SettingsServices;

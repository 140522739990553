/* xeslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Block } from '@webfox-sc/core';
import IconArrowRight from '../../assets/icons/IconArrowRight';
import FormPanelGroup from '../../atoms/FormPanelGroup';
import FormButton from '../../atoms/FormButton';
import FormPanelBilling from '../../components/FormPanelBilling';
import FormPanelContactPersonList from '../../components/FormPanelContactPersonList';
import { useOrderDraft } from '../../hooks/orders';

const OrderCreateDetails = ({ onClickNext }) => {
    const { orderDraft, updateOrderDraft } = useOrderDraft();

    const [isButtonNextClicked, setIsButtonNextClicked] = useState(false);

    return (
        <>
            <FormPanelGroup>
                <FormPanelBilling
                    billing={orderDraft.billing}
                    isEditMode
                    isValidationMode={isButtonNextClicked}
                    onChangeBilling={(billingDraft) => {
                        updateOrderDraft({
                            billing: billingDraft
                        });
                    }}
                />

                <FormPanelContactPersonList
                    contactPersonList={orderDraft.contactPersonList}
                    isEditMode
                    isValidationMode={isButtonNextClicked}
                    onChange={(contactPersonListDraft) =>
                        updateOrderDraft({
                            contactPersonList: contactPersonListDraft
                        })
                    }
                />
            </FormPanelGroup>

            <Block paddingTop="50px" justify="center">
                <FormButton
                    label="Weiter"
                    icon={IconArrowRight}
                    iconSize="small"
                    onClick={() => {
                        setIsButtonNextClicked(true);
                        onClickNext();
                    }}
                />
            </Block>
        </>
    );
};

OrderCreateDetails.propTypes = {
    onClickNext: PropTypes.func
};

OrderCreateDetails.defaultProps = {
    onClickNext: () => {}
};

export default OrderCreateDetails;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';

export function useOrdersCostcenterDialog() {
    const { orders } = useSelector(({ dataState }) => dataState);
    const { selectedOrderIds } = useSelector(({ ordersState }) => ordersState);

    const [selectedOrders, setSelectedOrders] = useState([]);

    useEffect(() => {
        const _selectedOrders = Object.values(orders).filter((order) => selectedOrderIds.includes(order.id));
        setSelectedOrders(_orderBy(_selectedOrders, ['entryId'], ['desc']));
    }, [selectedOrderIds, orders]);

    return {
        selectedOrderIds,
        selectedOrders
    };
}

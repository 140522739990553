import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '@webfox-sc/core';
import { COLORS } from '../../styles/common';
import { STYLE_TEXT_M } from '../../styles/type';
import errorImageSrc from '../../assets/images/icon-error.png';

const StyledComponent = styled.div({
    position: 'relative'
});

const inputTheme = ({ layout, size }) => {
    return {
        root: {
            border: layout === 'white' ? `1px solid ${COLORS.DARK_BLUE}` : '1px solid white',
            background: 'transparent',
            padding: size === 'L' ? '17px 17px' : '15px 15px',
            ...STYLE_TEXT_M,
            color: layout === 'white' ? COLORS.DARK_BLUE : 'white',
            '&::placeholder': {
                fontStyle: 'italic',
                color: layout === 'white' ? COLORS.DARK_BLUE : 'white'
            },
            '&:focus': {
                outline: 0
            }
        }
    };
};

const StyledErrorImage = styled.img({
    position: 'absolute',
    top: '-16px',
    right: '10px',
    width: '24px',
    height: '24px',
    verticalAlign: 'top'
});

const FormInput = (props) => {
    const { layout, size, error } = props;
    return (
        <StyledComponent>
            <Input {...props} block theme={inputTheme({ layout, size })} />
            {error && <StyledErrorImage src={errorImageSrc} alt="Error" />}
        </StyledComponent>
    );
};

FormInput.propTypes = {
    ...Input.propTypes,
    layout: PropTypes.string,
    size: PropTypes.string,
    error: PropTypes.bool
};

FormInput.defaultProps = {
    ...Input.defaultProps,
    layout: null,
    size: null,
    error: false
};

export default FormInput;

/* eslint-disable camelcase */
import _isEqual from 'lodash/isEqual';
import { PERMANENT_STATUS } from '../global/constants';
import AppUtil from './AppUtil';
import FormatUtil from './FormatUtil';

class DataReducerUtil {
    constructor() {
        this.state = {};
    }

    setState(state) {
        this.state = { ...state };
    }

    setClaim(claimData) {
        if (!claimData || typeof claimData !== 'object') {
            return this.state;
        }

        const {
            id,
            createdAt,
            updatedAt,
            entry_id,
            type,
            status,
            creator,
            // date_created: "2019-04-03T12:37:27.367Z"
            // date_done: "2019-04-03T12:40:45.212Z"
            location,
            // order_type: "claim"
            problem_description,
            contact_person_list,
            histories
        } = claimData;

        const historyIds = Array.isArray(histories) ? histories.map((history) => history.id) : [];

        const claim = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            entryId: entry_id || null,
            type: type || '',
            status: status || '',
            creator: creator || null,
            location: location || '',
            problemDescription: problem_description || '',
            contactPersonList: contact_person_list || [],
            historyIds
        };

        this.setHistories(histories);

        if (!_isEqual(claim, this.state.claims[id])) {
            this.state.claims = { ...this.state.claims };
            this.state.claims[id] = claim;
        }

        return this.state;
    }

    unsetClaim(claimData) {
        this.state.claims = { ...this.state.claims };
        delete this.state.claims[claimData.id];
        return this.state;
    }

    setClaims(claimsData) {
        if (!claimsData || !Array.isArray(claimsData)) {
            return this.state;
        }

        claimsData.forEach((claimData) => {
            this.setClaim(claimData);
        });

        return this.state;
    }

    setOrder(orderData) {
        if (!orderData || typeof orderData !== 'object') {
            return this.state;
        }

        const {
            id,
            createdAt,
            updatedAt,
            entry_id,
            type,
            status,
            // date_created, // TODO: obsolete ?
            date_done,
            date_planned,
            date_scheduled,
            location,
            message,
            contact_person_list,
            quantity,
            unit_price,
            is_overhead,
            billing,
            service,
            building,
            creator,
            assignee,
            histories
            //
            // costs: 52.8
            // order_number: "2019"
            // order_type: "order"
            // rating: 0
        } = orderData;

        const historyIds = Array.isArray(histories) ? histories.map((history) => history.id) : [];

        const assigneeName = assignee ? assignee.username : '';

        let listService = '';
        if (service) {
            listService = `${service.Number} ${service.Name}`;
        } else if (message && message.split(' ').length > 3) {
            listService = `${message.split(' ').slice(0, 3).join(' ')}...`;
        }

        const billingDetailsFormatted = FormatUtil.getBillingDetailsFormatted(billing);

        const order = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            entryId: entry_id || null,
            type: type || '',
            status: status || '',
            // creator: creator || null,
            // dateCreated: date_created || null, // TODO: obsolete ?
            dateDone: date_done || null,
            datePlanned: date_planned || null,
            dateScheduled: date_scheduled || null,
            location: location || '',
            message: message || '',
            contactPersonList: contact_person_list || [],
            quantity: quantity || null,
            unitPrice: unit_price || 0,
            isOverhead: is_overhead || false,
            billing: billing || {},
            /**
             * relations
             */
            serviceId: AppUtil.getEntryId(service),
            buildingId: AppUtil.getEntryId(building),
            creatorId: AppUtil.getEntryId(creator),
            assigneeId: AppUtil.getEntryId(assignee),
            historyIds,
            /**
             * synthetic props
             */
            assigneeName,
            billingDetailsFormatted,
            listService,
            listServiceSorting: `${type} ${listService} ${billingDetailsFormatted}`,
            searchString: String(
                `${entry_id}--${type}--${listService}--${billingDetailsFormatted}--${assigneeName}`
            ).toLowerCase()
        };

        this.setService(service);
        this.setUser(creator);
        this.setUser(assignee);
        this.setBuilding(building);
        this.setHistories(histories);

        if (!_isEqual(order, this.state.orders[id])) {
            this.state.orders = { ...this.state.orders };
            this.state.orders[id] = order;
        }

        return this.state;
    }

    unsetOrder(orderData) {
        this.state.orders = { ...this.state.orders };
        delete this.state.orders[orderData.id];
        return this.state;
    }

    setOrders(ordersData) {
        if (!ordersData || !Array.isArray(ordersData)) {
            return this.state;
        }

        ordersData.forEach((orderData) => {
            this.setOrder(orderData);
        });

        return this.state;
    }

    setPermanent(permanentData) {
        if (!permanentData || typeof permanentData !== 'object') {
            return this.state;
        }

        const {
            id,
            createdAt,
            updatedAt,
            entry_id,
            type,
            status,
            date_created,
            creator,
            assignee,
            start_date,
            expiry_date,
            quantity,
            frequency,
            interval,
            location,
            message,
            billing_details,
            billing,
            contact_person_list,
            service,
            building,
            histories
        } = permanentData;

        const creatorName = creator ? creator.username : '';
        const assigneeName = assignee ? assignee.username : '';

        const historyIds = Array.isArray(histories) ? histories.map((history) => history.id) : [];

        const permanent = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            entryId: entry_id || null,
            type: type || '',
            status: status || '',
            dateCreated: date_created || null,
            creator: creator || null,
            startDate: start_date || null,
            expiryDate: expiry_date || null,
            quantity: quantity || 0,
            frequency: frequency || 0,
            interval: interval || '',
            location: location || '',
            message: message || '',
            billingDetails: billing_details || '',
            billing: billing || {},
            contactPersonList: contact_person_list || [],
            /**
             * relations
             */
            serviceId: AppUtil.getEntryId(service),
            buildingId: AppUtil.getEntryId(building),
            historyIds,
            assigneeId: AppUtil.getEntryId(assignee),
            /**
             * synthetic props
             */
            assigneeName,
            creatorName,
            listStatus: status || '',
            filterStatus: status || '',
            searchString: String(`${entry_id}--${type}--${creatorName}`).toLowerCase()
        };

        this.setService(service);
        this.setBuilding(building);
        this.setHistories(histories);

        /**
         * set listStatus & filterStatus
         */
        if (permanent.status === PERMANENT_STATUS.STORNIERUNG && new Date(permanent.expiryDate) < new Date()) {
            permanent.listStatus = PERMANENT_STATUS.ABGELAUFEN;
            permanent.filterStatus = PERMANENT_STATUS.ARCHIV;
        } else if (permanent.status === PERMANENT_STATUS.ABGELEHNT) {
            permanent.filterStatus = PERMANENT_STATUS.ARCHIV;
        }

        if (!_isEqual(permanent, this.state.permanents[id])) {
            this.state.permanents = { ...this.state.permanents };
            this.state.permanents[id] = permanent;
        }

        return this.state;
    }

    unsetPermanent(permanentData) {
        this.state.permanents = { ...this.state.permanents };
        delete this.state.permanents[permanentData.id];
        return this.state;
    }

    setPermanents(permanentsData) {
        if (!permanentsData || !Array.isArray(permanentsData)) {
            return this.state;
        }

        permanentsData.forEach((permanentData) => {
            this.setPermanent(permanentData);
        });

        return this.state;
    }

    setService(serviceData) {
        if (!serviceData || typeof serviceData !== 'object') {
            return this.state;
        }

        const { id, _id, createdAt, updatedAt, Name, Number, Price, Type, Unit, diff, status } = serviceData;
        const validId = id || _id || null;

        const service = {
            id: validId,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            name: Name || '',
            number: Number || '',
            price: Price || 0,
            type: Type || '',
            unit: Unit || '',
            diff: diff || {},
            status: status || null
        };

        if (!_isEqual(service, this.state.services[validId])) {
            this.state.services = { ...this.state.services };
            this.state.services[validId] = service;
        }

        return this.state;
    }

    unsetService(serviceData) {
        this.state.services = { ...this.state.services };
        delete this.state.services[serviceData.id];
        return this.state;
    }

    setServices(servicesData) {
        if (!servicesData || !Array.isArray(servicesData)) {
            return this.state;
        }

        servicesData.forEach((serviceData) => {
            this.setService(serviceData);
        });

        return this.state;
    }

    setBuilding(buildingData) {
        if (!buildingData || typeof buildingData !== 'object') {
            return this.state;
        }

        const { id, createdAt, updatedAt, name, code } = buildingData;

        const building = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            name: name || '',
            code: code || '',
            /**
             * synthetic props
             */
            nameCode: `${name} (${code})`
        };

        if (!_isEqual(building, this.state.buildings[id])) {
            this.state.buildings = { ...this.state.buildings };
            this.state.buildings[id] = building;
        }

        return this.state;
    }

    unsetBuilding(buildingData) {
        this.state.buildings = { ...this.state.buildings };
        delete this.state.buildings[buildingData.id];
        return this.state;
    }

    setBuildings(buildingsData) {
        if (!buildingsData || !Array.isArray(buildingsData)) {
            return this.state;
        }

        buildingsData.forEach((buildingData) => {
            this.setBuilding(buildingData);
        });

        return this.state;
    }

    setHistory(historyData) {
        if (!historyData || typeof historyData !== 'object') {
            return this.state;
        }

        const { id, createdAt, previous_status, next_status, comment, diff, creator } = historyData;

        const history = {
            id: id || null,
            createdAt: createdAt || null,
            previousStatus: previous_status || '',
            nextStatus: next_status || '',
            comment: comment || '',
            diff: diff || {},
            creatorId: AppUtil.getEntryId(creator)
        };

        this.setUser(creator);

        if (!_isEqual(history, this.state.histories[id])) {
            this.state.histories = { ...this.state.histories };
            this.state.histories[id] = history;
        }

        return this.state;
    }

    setHistories(historiesData) {
        if (!historiesData || !Array.isArray(historiesData)) {
            return this.state;
        }

        historiesData.forEach((historyData) => {
            this.setHistory(historyData);
        });

        return this.state;
    }

    setUser(userData) {
        if (!userData || typeof userData !== 'object') {
            return this.state;
        }

        const {
            id,
            createdAt,
            updatedAt,
            username,
            email,
            confirmed,
            blocked,
            receive_notifications,
            billing,
            role
        } = userData;

        const user = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            username: username || '',
            email: email || '',
            confirmed: confirmed || false,
            blocked: blocked || false,
            receiveNotifications: receive_notifications || false,
            billing: billing || {},
            roleId: AppUtil.getEntryId(role),
            /**
             * synthetic props
             */
            roleName: role ? role.name : ''
        };

        this.setRole(role);

        if (!_isEqual(user, this.state.users[id])) {
            this.state.users = { ...this.state.users };
            this.state.users[id] = user;
        }

        return this.state;
    }

    unsetUser(userData) {
        this.state.users = { ...this.state.users };
        delete this.state.users[userData.id];
        return this.state;
    }

    setUsers(usersData) {
        if (!usersData || !Array.isArray(usersData)) {
            return this.state;
        }

        usersData.forEach((userData) => {
            this.setUser(userData);
        });

        return this.state;
    }

    setRole(roleData) {
        if (!roleData || typeof roleData !== 'object') {
            return this.state;
        }

        const { id, name, type, description } = roleData;

        const role = {
            id: id || null,
            name: name || '',
            type: type || '',
            description: description || ''
        };

        if (!_isEqual(role, this.state.roles[id])) {
            this.state.roles = { ...this.state.roles };
            this.state.roles[id] = role;
        }

        return this.state;
    }

    setRoles(rolesData) {
        if (!rolesData || !Array.isArray(rolesData)) {
            return this.state;
        }

        rolesData.forEach((roleData) => {
            this.setRole(roleData);
        });

        return this.state;
    }
}

export default DataReducerUtil;

/* xeslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@webfox-sc/core';
import { COLORS } from '../../styles/common';
import { STYLE_TEXT_M } from '../../styles/type';

const theme = {
    checkbox: {
        height: '30px',
        width: '30px',
        background: 'transparent',
        border: `1px solid ${COLORS.WHITE}`,
        boxSizing: 'border-box',
        transition: 'background-color 0.2s ease',
        svg: {
            width: '20px',
            height: '20px',
            color: COLORS.WHITE
        },
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.14)'
        },
        '&[data-checked]': {
            background: 'transparent'
        }
    },
    label: {
        ...STYLE_TEXT_M,
        color: 'white',
        paddingTop: '4px'
    }
};

const FilterCheckbox = (props) => {
    const { checked, onChange } = props;
    return <Checkbox {...props} spacing="10px" checked={checked} onChange={onChange} theme={theme} />;
};

FilterCheckbox.propTypes = {
    ...Checkbox.propTypes,
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

FilterCheckbox.defaultProps = {
    ...Checkbox.defaultProps,
    checked: false,
    onChange: () => {}
};

export default FilterCheckbox;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_TEXT_M, STYLE_TEXT_M_BOLD } from '../../styles/type';

const StyledComponent = styled.div(({ top, color, textAlign, bold }) => {
    // const style = {
    //     ...STYLE_TEXT_M
    // };

    const style = bold ? { ...STYLE_TEXT_M_BOLD } : { ...STYLE_TEXT_M };

    if (top) {
        style.paddingBottom = '7px';
    }

    if (color) {
        style.color = color;
    }

    if (textAlign) {
        style.textAlign = textAlign;
    }

    return style;
});

const FormLabel = ({ top, color, textAlign, bold, children }) => {
    return (
        <StyledComponent top={top} color={color} textAlign={textAlign} bold={bold}>
            {typeof children === 'string' ? <div dangerouslySetInnerHTML={{ __html: children }} /> : children}
        </StyledComponent>
    );
};

FormLabel.propTypes = {
    top: PropTypes.bool,
    color: PropTypes.string,
    textAlign: PropTypes.string,
    bold: PropTypes.bool,
    children: PropTypes.any
};

FormLabel.defaultProps = {
    top: false,
    color: undefined,
    textAlign: '',
    bold: false,
    children: ''
};

export default FormLabel;

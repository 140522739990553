import { REDUX_ACTIONS } from '../../global/constants';
import orderInitial from '../../data/orderInitial';

const initialState = {
    orderDraft: orderInitial,
    isOrdersRequest: false,
    ordersSearchText: '',
    ordersStartDate: null,
    ordersEndDate: null,
    ordersFilters: [],
    ordersSortingKey: 'entryId',
    ordersSortingOrder: 'desc',
    selectedOrderIds: [],
    ordersDisplayMax: 10
};

function ordersReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_ORDERS:
            return {
                ...state,
                isOrdersRequest: true
            };

        case REDUX_ACTIONS.RECIEVE_ORDERS:
        case REDUX_ACTIONS.REQUEST_ORDERS_ERROR:
            return {
                ...state,
                isOrdersRequest: false
            };

        case REDUX_ACTIONS.SET_ORDER_DRAFT:
            return {
                ...state,
                orderDraft: action.orderDraft
            };

        case REDUX_ACTIONS.RESET_ORDER_DRAFT:
            return {
                ...state,
                orderDraft: orderInitial
            };

        case REDUX_ACTIONS.SET_ORDERS_SEARCH_TEXT:
            return {
                ...state,
                ordersSearchText: action.ordersSearchText
            };

        case REDUX_ACTIONS.SET_ORDERS_START_DATE:
            return {
                ...state,
                ordersStartDate: action.ordersStartDate
            };

        case REDUX_ACTIONS.SET_ORDERS_END_DATE:
            return {
                ...state,
                ordersEndDate: action.ordersEndDate
            };

        case REDUX_ACTIONS.SET_ORDERS_FILTERS:
            return {
                ...state,
                ordersFilters: action.ordersFilters
            };

        case REDUX_ACTIONS.SET_ORDERS_SORTING:
            return {
                ...state,
                ordersSortingKey: action.ordersSortingKey,
                ordersSortingOrder: action.ordersSortingOrder
            };

        case REDUX_ACTIONS.SET_SELECTED_ORDER_IDS:
            return {
                ...state,
                selectedOrderIds: action.selectedOrderIds
            };

        case REDUX_ACTIONS.SET_ORDERS_DISPLAY_MAX:
            return {
                ...state,
                ordersDisplayMax: action.ordersDisplayMax
            };

        /**
         * logout
         */
        case REDUX_ACTIONS.LOGOUT:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default ordersReducer;

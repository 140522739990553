import { useSelector } from 'react-redux';
import auth from '../utils/auth';

export function useUser() {
    const userInfo = auth.getUserInfo() || {};
    const { id, username, email } = userInfo;
    const user = useSelector(({ dataState }) => dataState.users[id]);

    const userId = id;
    const userIsLoggedIn = !!userId;
    const userEmail = email;
    const userRole = userInfo.role || {};
    const userRoleName = userRole.name;

    return {
        user,
        userIsLoggedIn,
        userId,
        username,
        userEmail,
        userRoleName
    };
}

/* eslint-disable */
/**
 *
 * Logo
 *
 */

import React from 'react';
import logosrc from '../../../images/logos/logo_beclean_weiss.svg';
import logo from '../../../images/logos/logo_beclean_weiss.svg';

class Logo extends React.Component {
    render() {
        return (
            <div className="comp-logo">
                <img className="logo" src={logosrc} />
            </div>
        );
    }
}
export default Logo;

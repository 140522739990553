import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconClose from '../../assets/icons/IconClose';
import FormLabel from '../../atoms/FormLabel';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormInput from '../../atoms/FormInput';
import FormButton from '../../atoms/FormButton';
import { createService } from '../../redux/actions/servicesActions';
import { useServices } from '../../hooks/services';

const SettingsServicesCreate = ({ onClickCancel, onCreated }) => {
    const dispatch = useDispatch();
    const { serviceTypeDropdownOptions } = useServices();

    const [numberDraft, setNumberDraft] = useState('');
    const [nameDraft, setNameDraft] = useState('');
    const [typeDraft, setTypeDraft] = useState('');
    const [unitDraft, setUnitDraft] = useState('');
    const [priceDraft, setPriceDraft] = useState(0);

    const [isButtonCreateClicked, setIsButtonCreateClicked] = useState(false);

    const handleClickCreate = async () => {
        if (numberDraft && nameDraft && typeDraft && unitDraft && priceDraft) {
            await dispatch(
                createService({
                    Number: numberDraft,
                    Name: nameDraft,
                    Type: typeDraft,
                    Unit: unitDraft,
                    Price: priceDraft
                })
            );
            onCreated();
        } else {
            setIsButtonCreateClicked(true);
        }
    };

    return (
        <Block padding="20px" border="1px solid white">
            <Grid spacing="20px">
                <GridItem cols="3">
                    <FormLabel top>Nummer *</FormLabel>
                    <FormInput
                        ariaLabel="Nummer"
                        value={numberDraft}
                        error={isButtonCreateClicked && !numberDraft}
                        onChange={(e) => setNumberDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="9">
                    <FormLabel top>Name *</FormLabel>
                    <FormInput
                        ariaLabel="Name"
                        value={nameDraft}
                        error={isButtonCreateClicked && !nameDraft}
                        onChange={(e) => setNameDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Typ *</FormLabel>
                    <FormNativeSelect
                        options={serviceTypeDropdownOptions}
                        value={typeDraft}
                        error={isButtonCreateClicked && !typeDraft}
                        onChange={(e) => setTypeDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Einheit *</FormLabel>
                    <FormInput
                        ariaLabel="Einheit"
                        value={unitDraft}
                        error={isButtonCreateClicked && !unitDraft}
                        onChange={(e) => setUnitDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Preis EUR *</FormLabel>
                    <FormInput
                        ariaLabel="Preis"
                        type="number"
                        value={priceDraft}
                        error={isButtonCreateClicked && !priceDraft}
                        onChange={(e) => setPriceDraft(e.target.value)}
                    />
                </GridItem>
            </Grid>

            <Group paddingTop="20px" spacing="20px">
                <FormButton label="Erstellen" icon={IconCheckbox} iconSize="small" onClick={handleClickCreate} />
                <FormButton label="Abbrechen" icon={IconClose} iconSize="small" onClick={onClickCancel} />
            </Group>
        </Block>
    );
};

SettingsServicesCreate.propTypes = {
    onClickCancel: PropTypes.func,
    onCreated: PropTypes.func
};
SettingsServicesCreate.defaultProps = {
    onClickCancel: () => {},
    onCreated: () => {}
};

export default SettingsServicesCreate;

/* xeslint-disable */
import axios from 'axios';
import auth from '../../utils/auth';
import api from '../../api';
import { REDUX_ACTIONS } from '../../global/constants';

export function fetchOrders() {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_ORDERS
        });

        const params = {
            _limit: 100000
        };

        return axios({
            method: 'get',
            url: `${api.apiUrl}/order`,
            params,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const orders = res.data || {};

                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_ORDERS,
                    orders
                });

                return orders;
            })
            .catch((error) => {
                console.log('#fetchOrders error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_ORDERS_ERROR
                });
            });
    };
}

export function createOrder(data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CREATE_ORDER
        });

        return axios({
            method: 'post',
            url: `${api.apiUrl}/order`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const order = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CREATE_ORDER,
                    order
                });
                return order;
            })
            .catch((error) => {
                console.log('#createOrder error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CREATE_ORDER_ERROR
                });
            });
    };
}

export function fetchOrder(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_ORDER
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/order/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const order = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_ORDER,
                    order
                });
                return order;
            })
            .catch((error) => {
                console.log('#fetchOrder error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_ORDER_ERROR
                });
            });
    };
}

export function updateOrder(id, data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_UPDATE_ORDER
        });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/order/${id}`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const order = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_UPDATE_ORDER,
                    order
                });
                return order;
            })
            .catch((error) => {
                console.log('#updateOrder error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_UPDATE_ORDER_ERROR
                });
            });
    };
}

export function deleteOrder(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_DELETE_ORDER
        });

        return axios({
            method: 'delete',
            url: `${api.apiUrl}/order/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const order = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_DELETE_ORDER,
                    order
                });
                return order;
            })
            .catch((error) => {
                console.log('#deleteOrder error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_DELETE_ORDER_ERROR
                });
            });
    };
}

export function updateCostcenters(data) {
    return async (dispatch) => {
        // TODO implement
        // dispatch({
        //     type: REDUX_ACTIONS.REQUEST_UPDATE_ORDER
        // });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/updatecostcenters`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const orders = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_ORDERS,
                    orders
                });
                return orders;
            })
            .catch((error) => {
                console.log('#updateCostcenters error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_ORDERS_ERROR
                });
            });
    };
}

export function setOrderDraft(orderDraft) {
    return {
        type: REDUX_ACTIONS.SET_ORDER_DRAFT,
        orderDraft
    };
}

export function resetOrderDraft() {
    return {
        type: REDUX_ACTIONS.RESET_ORDER_DRAFT
    };
}

export function setOrdersSearchText(ordersSearchText) {
    return {
        type: REDUX_ACTIONS.SET_ORDERS_SEARCH_TEXT,
        ordersSearchText
    };
}

export function setOrdersStartDate(ordersStartDate) {
    return {
        type: REDUX_ACTIONS.SET_ORDERS_START_DATE,
        ordersStartDate
    };
}

export function setOrdersEndDate(ordersEndDate) {
    return {
        type: REDUX_ACTIONS.SET_ORDERS_END_DATE,
        ordersEndDate
    };
}

export function setOrdersFilters(ordersFilters) {
    return {
        type: REDUX_ACTIONS.SET_ORDERS_FILTERS,
        ordersFilters
    };
}

export function setOrdersSorting(ordersSortingKey, ordersSortingOrder) {
    return {
        type: REDUX_ACTIONS.SET_ORDERS_SORTING,
        ordersSortingKey,
        ordersSortingOrder
    };
}

export function setSelectedOrderIds(selectedOrderIds) {
    return {
        type: REDUX_ACTIONS.SET_SELECTED_ORDER_IDS,
        selectedOrderIds
    };
}

export function setOrdersDisplayMax(ordersDisplayMax) {
    return {
        type: REDUX_ACTIONS.SET_ORDERS_DISPLAY_MAX,
        ordersDisplayMax
    };
}

import React from 'react';

const IconClaim = (
    <svg viewBox="0 0 100 100">
        <path
            d="M98.992 79.052l-.002-.001c-6.651-13.686-17.8-33.098-27.635-50.223C66.208 19.863 61.346 11.4 57.83 4.967 56.173 1.929 53.226.114 49.95.114c-3.278 0-6.225 1.815-7.883 4.853-3.517 6.437-8.38 14.909-13.533 23.879C18.704 45.967 7.559 65.37.91 79.052A8.917 8.917 0 000 82.97c0 4.971 4.002 9.015 8.923 9.015l41.028-.005 41.029.005c4.92 0 8.923-4.044 8.923-9.015a8.928 8.928 0 00-.911-3.917zM90.98 87.81H8.927c-2.625 0-4.76-2.173-4.76-4.84 0-.715.164-1.418.49-2.088 6.584-13.552 17.69-32.89 27.488-49.95 5.162-8.99 10.038-17.483 13.574-23.958 1.833-3.36 6.63-3.36 8.463 0 3.535 6.471 8.408 14.956 13.566 23.938 9.802 17.066 20.912 36.412 27.5 49.968a4.74 4.74 0 01.492 2.09c0 2.667-2.137 4.84-4.76 4.84zM49.95 20.995a2.084 2.084 0 00-2.081 2.088v41.76c0 1.153.93 2.087 2.081 2.087 1.15 0 2.081-.934 2.081-2.087v-41.76c0-1.153-.93-2.088-2.08-2.088zm2.081 56.373a2.084 2.084 0 00-2.08-2.088c-1.15 0-2.082.935-2.082 2.088s.931 2.088 2.081 2.088 2.081-.935 2.081-2.088z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconClaim;

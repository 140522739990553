import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import IconClose from '../../assets/icons/IconClose';
import IconDelete from '../../assets/icons/IconDelete';
import FormLabel from '../../atoms/FormLabel';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormInput from '../../atoms/FormInput';
import FormButton from '../../atoms/FormButton';
import { updateUser } from '../../redux/actions/usersActions';
import { useRoles } from '../../hooks/roles';
import AppUtil from '../../utils/AppUtil';

const SettingsUsersEdit = ({ userId, onClickCancel, onUpdated, onClickDelete }) => {
    const dispatch = useDispatch();
    const { roleDropdownOptions } = useRoles();
    const user = useSelector(({ dataState }) => dataState.users[userId]);

    const [usernameDraft, setUsernameDraft] = useState('');
    const [emailDraft, setEmailDraft] = useState('');
    const [roleIdDraft, setRoleIdDraft] = useState('');
    const [isButtonUpdateClicked, setIsButtonUpdateClicked] = useState(false);

    useEffect(() => {
        if (user) {
            setUsernameDraft(String(user.username).trim());
            setEmailDraft(String(user.email).trim());
            setRoleIdDraft(user.roleId);
        }
    }, [user]);

    const handleClickUpdate = async () => {
        if (usernameDraft && AppUtil.isEmailValid(emailDraft)) {
            await dispatch(
                updateUser(user.id, {
                    username: usernameDraft,
                    email: emailDraft,
                    role: roleIdDraft
                })
            );
            onUpdated();
        } else {
            setIsButtonUpdateClicked(true);
        }
    };

    return (
        <Block padding="20px">
            <Grid spacing="20px">
                <GridItem cols="4">
                    <FormLabel top>Name *</FormLabel>
                    <FormInput
                        ariaLabel="Name"
                        value={usernameDraft}
                        error={isButtonUpdateClicked && !usernameDraft}
                        onChange={(e) => setUsernameDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>E-Mail *</FormLabel>
                    <FormInput
                        ariaLabel="E-Mail"
                        value={emailDraft}
                        error={isButtonUpdateClicked && !AppUtil.isEmailValid(emailDraft)}
                        onChange={(e) => setEmailDraft(e.target.value)}
                    />
                </GridItem>
                <GridItem cols="4">
                    <FormLabel top>Rolle *</FormLabel>
                    <FormNativeSelect
                        options={roleDropdownOptions}
                        value={roleIdDraft}
                        error={isButtonUpdateClicked && !roleIdDraft}
                        onChange={(e) => setRoleIdDraft(e.target.value)}
                    />
                </GridItem>
            </Grid>

            <Group paddingTop="20px" spacing="20px">
                <GroupItem>
                    <Group spacing="20px">
                        <FormButton
                            label="Übernehmen"
                            icon={IconCheckbox}
                            iconSize="small"
                            onClick={handleClickUpdate}
                        />
                        <FormButton label="Abbrechen" icon={IconClose} iconSize="small" onClick={onClickCancel} />
                    </Group>
                </GroupItem>
                <GroupItem grow>
                    <Block justify="flex-end">
                        <FormButton label="Löschen" icon={IconDelete} iconSize="small" onClick={onClickDelete} />
                    </Block>
                </GroupItem>
            </Group>
        </Block>
    );
};

SettingsUsersEdit.propTypes = {
    userId: PropTypes.string,
    onClickCancel: PropTypes.func,
    onUpdated: PropTypes.func,
    onClickDelete: PropTypes.func
};
SettingsUsersEdit.defaultProps = {
    userId: '',
    onClickCancel: () => {},
    onUpdated: () => {},
    onClickDelete: () => {}
};

export default SettingsUsersEdit;

import React from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import TextL from '../TextL';
import HeadingM from '../HeadingM';
import TextButton from '../TextButton';

const DetailsHeader = ({ title, statusLabel, backLinkLabel, backLinkHref, onClickBackLink }) => {
    return (
        <Grid spacing="20px" alignItems="center">
            <GridItem cols="4">
                <TextButton label={backLinkLabel} href={backLinkHref} onClick={onClickBackLink} />
            </GridItem>
            <GridItem cols="4">
                <Block justify="center">
                    <HeadingM>{title}</HeadingM>
                </Block>
            </GridItem>
            <GridItem cols="4">
                <Block justify="flex-end">
                    <TextL>
                        <b>{statusLabel}</b>
                    </TextL>
                </Block>
            </GridItem>
        </Grid>
    );
};

DetailsHeader.propTypes = {
    title: PropTypes.string,
    statusLabel: PropTypes.string,
    backLinkLabel: PropTypes.string,
    backLinkHref: PropTypes.string,
    onClickBackLink: PropTypes.func
};

DetailsHeader.defaultProps = {
    title: '',
    statusLabel: '',
    backLinkLabel: '',
    backLinkHref: '',
    onClickBackLink: () => {}
};

export default DetailsHeader;

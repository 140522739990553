import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';

const theme = {
    root: {
        background: 'transparent',
        svg: {
            width: '30px',
            height: '30px',
            color: 'white'
        },
        '&[data-with-icon-only]': {
            width: '32px',
            height: '32px',
            padding: 0
        }
    }
};

const IconButton = (props) => {
    return <Button {...props} theme={theme} />;
};

IconButton.propTypes = {
    ...Button.propTypes,
    ariaLabel: PropTypes.string,
    icon: PropTypes.any
};

IconButton.defaultProps = {
    ...Button.defaultProps,
    ariaLabel: '',
    icon: null
};

export default IconButton;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import claimInitial from '../data/claimInitial';
import { createClaim, setClaimDraft, resetClaimDraft } from '../redux/actions/claimsActions';

export function useClaim(claimId) {
    const claim = useSelector(({ dataState }) => dataState.claims[claimId]);

    return {
        claim
    };
}

export function useClaimActions() {
    const dispatch = useDispatch();
    const claimDraft = useSelector(({ claimsState }) => claimsState.claimDraft);

    const handleCreateClaimFromDraft = async () => {
        const createData = {
            type: claimDraft.type,
            location: claimDraft.location,
            problem_description: claimDraft.problemDescription,
            contact_person_list: claimDraft.contactPersonList
        };

        return dispatch(createClaim(createData));
    };

    return {
        handleCreateClaimFromDraft
    };
}

export function useClaimDraft() {
    const dispatch = useDispatch();
    const claimDraft = useSelector(({ claimsState }) => claimsState.claimDraft);

    const isClaimDraftDataValid = () => {
        const { location, problemDescription, contactPersonList } = claimDraft;

        let isContactPersonListValid = true;
        contactPersonList.forEach((contactPerson) => {
            if (!contactPerson.first_name || !contactPerson.last_name || !contactPerson.email) {
                isContactPersonListValid = false;
            }
        });

        if (!location || !problemDescription || !isContactPersonListValid) {
            return false;
        }

        return true;
    };

    const updateClaimDraft = (data) => {
        const claimDraftNew = { ...claimDraft, ...data };
        return dispatch(setClaimDraft(claimDraftNew));
    };

    const updateClaimDraftType = (type) => {
        if (type !== claimDraft.type) {
            const claimDraftNew = {
                ...claimInitial,
                type
            };
            dispatch(setClaimDraft(claimDraftNew));
        }
    };

    const reset = () => {
        dispatch(resetClaimDraft());
    };

    return {
        claimDraft,
        isClaimDraftDataValid,
        updateClaimDraft,
        updateClaimDraftType,
        stableResetClaimDraft: useCallback(reset, [])
    };
}

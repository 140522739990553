/* xeslint-disable */
import axios from 'axios';
import auth from '../../utils/auth';
import api from '../../api';
import { REDUX_ACTIONS } from '../../global/constants';

export function fetchServices() {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_SERVICES
        });

        return axios({
            method: 'get',
            url: `${api.apiUrl}/services`,
            params: {
                _limit: 100000
            },
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const services = res.data || {};

                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_SERVICES,
                    services
                });

                return services;
            })
            .catch((error) => {
                console.log('#fetchServices error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_SERVICES_ERROR
                });
            });
    };
}

export function createService(data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_CREATE_SERVICE
        });

        return axios({
            method: 'post',
            url: `${api.apiUrl}/services`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const service = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_CREATE_SERVICE,
                    service
                });
                return service;
            })
            .catch((error) => {
                console.log('#createService error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_CREATE_SERVICE_ERROR
                });
            });
    };
}

export function updateServices(data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_UPDATE_SERVICES
        });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/services`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const services = res.data || {};
                console.log('#services', services);
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_UPDATE_SERVICES,
                    services
                });
                return services;
            })
            .catch((error) => {
                console.log('#updateServices error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_UPDATE_SERVICES_ERROR
                });
            });
    };
}

export function updateService(id, data) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_UPDATE_SERVICE
        });

        return axios({
            method: 'put',
            url: `${api.apiUrl}/services/${id}`,
            data,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const service = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_UPDATE_SERVICE,
                    service
                });
                return service;
            })
            .catch((error) => {
                console.log('#updateService error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_UPDATE_SERVICE_ERROR
                });
            });
    };
}

export function deleteService(id) {
    return async (dispatch) => {
        dispatch({
            type: REDUX_ACTIONS.REQUEST_DELETE_SERVICE
        });

        return axios({
            method: 'delete',
            url: `${api.apiUrl}/services/${id}`,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
            .then((res) => {
                const service = res.data || {};
                dispatch({
                    type: REDUX_ACTIONS.RECIEVE_DELETE_SERVICE,
                    service
                });
                return service;
            })
            .catch((error) => {
                console.log('#deleteService error', error);
                dispatch({
                    type: REDUX_ACTIONS.REQUEST_DELETE_SERVICE_ERROR
                });
            });
    };
}

export function setServicesSorting(servicesSortingKey, servicesSortingOrder) {
    return {
        type: REDUX_ACTIONS.SET_SERVICES_SORTING,
        servicesSortingKey,
        servicesSortingOrder
    };
}

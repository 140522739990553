import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Page from './container/Page';
import ViewMaintenance from './container/ViewMaintenance';
import { autoLogin } from './redux/actions/authActions';

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(autoLogin());
    }, [dispatch]);

    if (process.env.REACT_APP_SHOW_MAINTENANCE_VIEW) {
        return (
            <ViewMaintenance />
        );
    }
    return (
        <BrowserRouter>
            <Page />
        </BrowserRouter>
    );
};

export default App;

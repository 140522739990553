/* eslint-disable */
/**
 *
 * CockpitAdHoc
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { BarChart, Legend } from 'react-easy-chart';
import TopFive from '../TopFive';

class CockpitAdHoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}

    render() {
        let startDate = new Date(this.props.startDate);
        let endDate = new Date(this.props.endDate);

        let filteredData = this.props.data.filter(
            (c) => new Date(c.date_created) >= startDate && new Date(c.date_created) <= endDate
        );

        let totalOrders = filteredData.length;
        let openOrders = this.props.countOrders(filteredData, 'OFFEN');
        let freeOrders = this.props.countOrders(filteredData, 'FREIGEGEBEN');
        let deniedOrders = this.props.countOrders(filteredData, 'ABGELEHNT');
        let progressOrders = this.props.countOrders(filteredData, 'IN ARBEIT');
        let doneOrders = this.props.countOrders(filteredData, 'ERLEDIGT');
        let accountedOrders = this.props.countOrders(filteredData, 'ABGERECHNET');
        let reducedOrders = totalOrders - accountedOrders - deniedOrders;

        let barData = [
            {
                x: 'OFFEN: ' + openOrders,
                y: openOrders,
                color: '#63008E'
            },
            {
                x: 'FREIGEGEBEN: ' + freeOrders,
                y: freeOrders,
                color: '#A2E87C'
            },
            {
                x: 'IN ARBEIT: ' + progressOrders,
                y: progressOrders,
                color: '#67DBC6'
            }
        ];
        const barConfig = [{ color: '#63008E' }, { color: '#A2E87C' }, { color: '#67DBC6' }];

        return (
            <div className="cockpit-adhoc">
                <Container className="grid-container grid-reset">
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <fieldset>
                                <legend>Ad-Hoc-Aufträge</legend>
                                <div className="cockpit-background center-flex">
                                    <h4>
                                        Insgesamt {reducedOrders} Ad-hoc-Aufträge <br /> davon {doneOrders} erledigt
                                    </h4>

                                    <BarChart height={200} width={450} data={barData} />
                                    <Legend data={barData} dataId={'x'} config={barConfig} horizontal />
                                </div>

                                <div className="cockpit-background center-flex">
                                    <h3 className="cockpit-header">Gesamtkosten erledigte Aufträge</h3>
                                    <h4 className="total-cost">{this.props.getCosts(filteredData)} €</h4>
                                </div>
                                <TopFive data={filteredData} />
                            </fieldset>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CockpitAdHoc;

/* xeslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Collapse, Group } from '@webfox-sc/core';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import IconChevronUp from '../../assets/icons/IconChevronUp';
import IconChevronDown from '../../assets/icons/IconChevronDown';
import ClickWrapper from '../../atoms/ClickWrapper';
import FormButton from '../../atoms/FormButton';
import FormInput from '../../atoms/FormInput';
import TextButtonDialog from '../../atoms/TextButtonDialog';
import { useOrdersCostcenterDialog } from './OrdersCostcenterDialog.hooks';
import { STYLE_TEXT_S, STYLE_TEXT_M } from '../../styles/type';
import { COLORS } from '../../styles/common';

const StyledShader = styled.div({
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const StyledDialog = styled.div({
    position: 'absolute',
    zIndex: 11,
    width: 'calc(100vw - 60px)',
    maxWidth: '1000px',
    boxSizing: 'border-box',
    background: 'white'
});

const StyledHeading = styled.div({
    ...STYLE_TEXT_M,
    fontSize: '40px',
    lineHeight: 'normal',
    textAlign: 'center',
    color: COLORS.DARK_BLUE
});

const StyledTableContainer = styled.div({
    maxHeight: '30vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    border: `1px solid ${COLORS.DARK_GREY}`,
    background: '#f7f7f7',
    padding: '10px'
});

const StyledTable = styled.table({
    width: '100%'
});

const StyledTableCell = styled.td({
    verticalAlign: 'top',
    padding: '5px 10px',
    ...STYLE_TEXT_S,
    color: COLORS.DARK_GREY
});

const OrdersCostcenterDialog = ({ show, onClickConfirm, onClickCancel, onClickOutside }) => {
    const { selectedOrders } = useOrdersCostcenterDialog();

    const [showList, setShowList] = useState(true);
    const [costcenterDraft, setCostcenterDraft] = useState('');

    useEffect(() => {
        if (show) {
            setCostcenterDraft('');
        }
    }, [show]);

    if (!show) {
        return null;
    }

    const handleOnClickConfirm = () => {
        if (costcenterDraft) {
            onClickConfirm(costcenterDraft);
            setCostcenterDraft('');
        }
    };

    return (
        <StyledShader>
            <StyledDialog>
                <ClickWrapper onClickOutside={onClickOutside}>
                    <Block padding="40px">
                        <Block paddingBottom="30px" justify="center">
                            <StyledHeading>Kostenstelle ändern</StyledHeading>
                        </Block>
                        <Block paddingBottom="30px">
                            <Block justify="center">
                                <TextButtonDialog
                                    label={`${selectedOrders.length} ${
                                        selectedOrders.length === 1 ? 'Ad-hoc-Auftrag' : 'Ad-hoc-Aufträge'
                                    }`}
                                    iconRight={showList ? IconChevronUp : IconChevronDown}
                                    onClick={() => setShowList(!showList)}
                                />
                            </Block>
                            <Collapse show={showList}>
                                <Block paddingTop="10px">
                                    <StyledTableContainer>
                                        <StyledTable>
                                            <tbody>
                                                {selectedOrders.map((order) => {
                                                    return (
                                                        <tr key={order.id}>
                                                            <StyledTableCell>{order.entryId}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {order.type}{' '}
                                                                {order.listService ? `/ ${order.listService}` : ''}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {order.isOverhead ? (
                                                                    <em>Über Betriebskosten abrechnen</em>
                                                                ) : (
                                                                    order.billingDetailsFormatted
                                                                )}
                                                            </StyledTableCell>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </StyledTable>
                                    </StyledTableContainer>
                                </Block>
                            </Collapse>
                        </Block>
                        <Block justify="center">
                            <Block width="100%" maxWidth="400px">
                                <FormInput
                                    layout="white"
                                    ariaLabel="Kostenstelle"
                                    value={costcenterDraft}
                                    placeholder="Neue Kostenstelle ..."
                                    maxLength="128"
                                    onChange={(e) => setCostcenterDraft(e.target.value)}
                                />
                            </Block>
                        </Block>
                        <Group paddingTop="10px" nowrap spacing="10px" justify="center">
                            <FormButton
                                layout="white"
                                label="Abbrechen"
                                icon={IconClose}
                                iconSize="small"
                                onClick={onClickCancel}
                            />
                            <FormButton
                                layout="white"
                                label="Ändern"
                                icon={IconCheck}
                                iconSize="small"
                                disabled={!costcenterDraft}
                                onClick={handleOnClickConfirm}
                            />
                        </Group>
                    </Block>
                </ClickWrapper>
            </StyledDialog>
        </StyledShader>
    );
};

OrdersCostcenterDialog.propTypes = {
    show: PropTypes.bool,
    onClickConfirm: PropTypes.func,
    onClickCancel: PropTypes.func,
    onClickOutside: PropTypes.func
};

OrdersCostcenterDialog.defaultProps = {
    show: false,
    onClickConfirm: () => {},
    onClickCancel: () => {},
    onClickOutside: () => {}
};

export default OrdersCostcenterDialog;

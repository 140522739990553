const claimInitial = {
    id: '',
    type: '',
    location: '',
    problemDescription: '',
    contactPersonList: [
        {
            first_name: '',
            last_name: '',
            email: ''
        }
    ]
};

export default claimInitial;

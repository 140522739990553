import React from 'react';
import { Block } from '@webfox-sc/core';
import PageContentBlock from '../../atoms/PageContentBlock';
import HeadingM from '../../atoms/HeadingM';
import TextM from '../../atoms/TextM';
import TextXL from '../../atoms/TextXL';

const Imprint = () => {
    return (
        <PageContentBlock>
            <Block paddingTop="180px" justify="center">
                <Block maxWidth="800px">
                    <HeadingM>Impressum</HeadingM>

                    <Block paddingTop="L">
                        <TextM>
                            Hier finden Sie die Ansprechpartner für Inhalt, Gestaltung und Technik unseres
                            Internet-Auftritts. Wenn Sie Fragen haben, senden Sie uns bitte ein E-Mail.
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Anschrift</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>
                            Gegenbauer Services GmbH <br />
                            Paul-Robeson-Straße 37
                            <br />
                            10439 Berlin
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Kontakt</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM text="Tel. (030) 44670-0">
                            Tel. (030) 44670-0 <br />
                            Fax (030) 44670-90101 <br />
                            E-Mail info@gegenbauer.de
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Persönlich haftende Gesellschafterin:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>
                            Gegenbauer Services GmbH
                            <br />
                            Sitz: Berlin, Amtsgericht Berlin Charlottenburg HRB 86382
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Vertretungsberechtigte:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>
                            Christian Kloevekorn
                            <br />
                            Kadir Altintas
                            <br />
                            Jürgen Retzlaff
                            <br />
                            Andreas Stipsits
                            <br />
                            Jan Störr
                            <br />
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>DE 813575222</TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Text, Fotos & Grafiken:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>
                            (soweit keine anderen Quellen angegeben)
                            <br />
                            Gegenbauer Services GmbH
                            <br />
                            Die Verwendung zu gewerblichen Zwecken ist untersagt.
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Urheberrechte:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>
                            Für den Inhalt dieser Website besteht Urheberrecht. Alle Rechte bleiben vorbehalten. Ohne
                            vorherige schriftliche Zustimmung dürfen keine Angaben auf der Website, insbesondere keine
                            Texte, Bilder oder Sound, vervielfältigt, verbreitet, öffentlich ausgestellt oder auf
                            sonstige Weise verwertet werden.
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Verbraucherstreitbeilegung / Verbraucherschlichtungsstelle:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>
                            Wir nehmen an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nicht teil.
                            <br />
                            Plattform der EU-Kommission zur Online-Streitbeilegung:
                            <br />
                            www.ec.europa.eu/consumers/odr
                            <br />
                        </TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Konzeption, Programmierung und technische Betreuung:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>AgenturWebfox GmbH</TextM>
                    </Block>

                    <Block paddingTop="L">
                        <TextXL>Haftungshinweis:</TextXL>
                    </Block>
                    <Block paddingTop="S">
                        <TextM>
                            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
                            externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                            verantwortlich.
                        </TextM>
                    </Block>
                </Block>
            </Block>
        </PageContentBlock>
    );
};

Imprint.propTypes = {};
Imprint.defaultProps = {};

export default Imprint;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, InlineSVG } from '@webfox-sc/core';
import IconInfo from '../../assets/icons/IconInfo';
import { STYLE_TEXT_M } from '../../styles/type';

const StyledTableCell = styled.td(({ layout, showBorderRight, color }) => {
    let style = {
        padding: 0,
        verticalAlign: 'top',
        borderTop: `1px solid ${color}`,
        borderBottom: `1px solid ${color}`,
        '&:first-of-type': {
            borderLeft: `1px solid ${color}`
        },
        '&:last-of-type': {
            borderRight: `1px solid ${color}`
        }
    };

    if (layout === 'text') {
        style = {
            ...style,
            padding: '16px 16px',
            ...STYLE_TEXT_M,
            userSelect: 'none'
        };
    }

    if (layout === 'info-icon') {
        style = {
            ...style,
            width: '1%',
            borderLeft: `1px solid ${color}`
        };
    }

    if (showBorderRight) {
        style = {
            ...style,
            borderRight: `1px solid ${color}`
        };
    }

    return style;
});

const TableBodyCell = ({ layout, colSpan, showBorderRight, children, color }) => {
    return (
        <StyledTableCell layout={layout} colSpan={colSpan} showBorderRight={showBorderRight} color={color || 'white'}>
            {layout === 'info-icon' ? (
                <Block width="56px" height="56px" justify="center" alignItems="center">
                    <InlineSVG width="30px" color="white">
                        {IconInfo}
                    </InlineSVG>
                </Block>
            ) : (
                children
            )}
        </StyledTableCell>
    );
};

TableBodyCell.propTypes = {
    layout: PropTypes.string,
    colSpan: PropTypes.any,
    showBorderRight: PropTypes.bool,
    children: PropTypes.any,
    color: PropTypes.string
};

TableBodyCell.defaultProps = {
    layout: null,
    colSpan: null,
    showBorderRight: false,
    children: null,
    color: null
};

export default TableBodyCell;

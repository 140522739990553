/* eslint-disable */
/**
 *
 * CockpitPerformance
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PerformanceItem from '../PerformanceItem';
import moment from 'moment';
import ServiceListEmptyState from '../../MicroComps/ServiceListEmptyState';
import { PieChart, Pie } from 'recharts';

class CockpitPerformance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}
    getLatestOrders(array) {
        let latestOrders = [];
        let i;

        for (i = 0; i < array.length; i++) {
            // Each order we are checking the distance to the current date. We are only adding
            let distanceFromNow = moment.duration(moment().diff(moment(array[i].date_done)));

            if (distanceFromNow > 0) {
                latestOrders.push(array[i]);
            }
        }
        latestOrders = latestOrders
            .sort(function (a, b) {
                return new Date(b.date_done) - new Date(a.date_done);
            })
            .slice(0, 5);
        return latestOrders;
    }
    getNextOrders(array) {
        let nextOrders = [];
        let i;

        let filteredArray = [];

        filteredArray = array.filter((c) => c.status == 'IN ARBEIT' || c.status == 'FREIGEGEBEN');

        for (i = 0; i < filteredArray.length; i++) {
            // Each order we are checking the distance to the current date. We are only adding

            let distanceFromNow = moment.duration(moment().diff(moment(filteredArray[i].date_planned)));
            if (filteredArray[i].date_planned) {
                if (distanceFromNow < 0) {
                    nextOrders.push(filteredArray[i]);
                }
            }
        }
        nextOrders = nextOrders
            .sort(function (a, b) {
                return new Date(a.date_planned) - new Date(b.date_planned);
            })
            .slice(0, 5);
        return nextOrders;
    }
    getCompletedRatio(data) {
        let i;
        let progressCount = 0;
        let doneCount = 0;
        let ratio;
        for (i = 0; i < data.length; i++) {
            if (data[i].order_type == 'order' || data[i].order_type == 'claim') {
                if (data[i].status == 'IN ARBEIT') progressCount++;
                if (data[i].status == 'ERLEDIGT' || data[i].status == 'ABGERECHNET' || data[i].status == 'IN ARBEIT') {
                    doneCount++;
                }
            }
        }

        if (doneCount == 0) {
            if (progressCount == 0) {
                ratio = -1;
            } else {
                ratio = 0;
            }
        } else {
            ratio = 1 - progressCount / doneCount;
        }
        return Math.round(ratio * 100);
    }

    render() {
        let latestOrders = this.getLatestOrders(this.props.data);
        let nextOrders = this.getNextOrders(this.props.data);

        if (latestOrders.length >= 1) {
            latestOrders = latestOrders.map((c) => (
                <PerformanceItem
                    key={c.id}
                    id={c.entry_id}
                    origid={c.id}
                    type={c.type}
                    dateDone={c.date_done != null ? moment(c.date_done).format('DD.MM.YYYY') : c.date_done}
                    rating={c.rating}
                />
            ));
        } else {
            latestOrders = <ServiceListEmptyState />;
        }
        if (nextOrders.length >= 1) {
            nextOrders = nextOrders.map((c) => (
                <PerformanceItem
                    key={c.id}
                    id={c.entry_id}
                    origid={c.id}
                    type={c.type}
                    datePlanned={c.date_planned != null ? moment(c.date_planned).format('DD.MM.YYYY') : c.date_planned}
                    rating={c.rating}
                />
            ));
        } else {
            nextOrders = <ServiceListEmptyState />;
        }

        let completedRatio = this.getCompletedRatio(this.props.data);

        const pieData = [
            {
                name: 'Berechtigt',
                value: 6,
                fill: 'white'
            },
            {
                name: 'Unberechtigt',
                value: 4,
                fill: 'transparent'
            }
        ];

        return (
            <div className="cockpit-sub-base center-flex">
                <Container className="grid-container grid-reset">
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <fieldset>
                                <legend>Performance</legend>

                                <div className="performance-container">
                                    <div className="cockpit-background center-flex">
                                        <h3 className="cockpit-header">Letzte Aktionen</h3>

                                        {latestOrders}
                                    </div>
                                    <div className="cockpit-background center-flex">
                                        <h3 className="cockpit-header">Nächste Aktionen</h3>
                                        {nextOrders}
                                    </div>
                                    <div className="cockpit-background center-flex">
                                        <h3 className="cockpit-header">Erbrachte Leistung</h3>
                                        {completedRatio == -100 ? (
                                            <ServiceListEmptyState />
                                        ) : (
                                            <div>
                                                <PieChart width={200} height={200}>
                                                    <Pie
                                                        data={pieData}
                                                        dataKey="value"
                                                        nameKey="name"
                                                        cx="50%"
                                                        cy="50%"
                                                        outerRadius={100}
                                                        innerRadius={95}
                                                        startAngle={90}
                                                        endAngle={-270}
                                                        fill="transparent"
                                                        stroke="none"
                                                    />
                                                </PieChart>
                                                <div className="performance-percentage">
                                                    <h1>{completedRatio}%</h1>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </fieldset>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CockpitPerformance;

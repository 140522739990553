/**
 * colors
 */
export const COLORS = {
    WHITE: '#ffffff',
    BLUE: '#008cc6',
    DARK_BLUE: '#0364a1',
    DARK_GREY: '#4b4b4b',
    RED: '#ff0000'
};

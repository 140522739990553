import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormCheckbox from '../FormCheckbox';

const StyledSelectBox = styled.div({
    padding: '13px'
});

const TableBodyCellSelect = ({ selected, onChangeSelection }) => {
    return (
        <StyledSelectBox
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <FormCheckbox onChange={onChangeSelection} checked={selected} />
        </StyledSelectBox>
    );
};

TableBodyCellSelect.propTypes = {
    selected: PropTypes.bool,
    onChangeSelection: PropTypes.func
};

TableBodyCellSelect.defaultProps = {
    selected: false,
    onChangeSelection: () => {}
};

export default TableBodyCellSelect;

/* xeslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import { Block, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import TextS from '../../atoms/TextS';
import TextXL from '../../atoms/TextXL';
import FormPanel from '../../atoms/FormPanel';
import FormButton from '../../atoms/FormButton';
import FormLabel from '../../atoms/FormLabel';
import FormNativeSelect from '../../atoms/FormNativeSelect';
import FormInput from '../../atoms/FormInput';
import FormStaticField from '../../atoms/FormStaticField';
import FormTextarea from '../../atoms/FormTextarea';
import FormDatePicker from '../../atoms/FormDatePicker';
import IconArrowRight from '../../assets/icons/IconArrowRight';
import { usePermanentDraft, usePermanentDraftServices } from '../../hooks/permanents';
import { useBuildings } from '../../hooks/buildings';
import FormatUtil from '../../utils/FormatUtil';

const intervalDropdownOptions = [
    {
        value: '',
        label: 'Wiederholen',
        disabled: true
    },
    {
        value: 'day',
        label: 'täglich'
    },
    {
        value: 'week',
        label: 'wöchentlich'
    },
    {
        value: 'month',
        label: 'monatlich'
    },
    {
        value: 'year',
        label: 'jährlich'
    }
];

const PermanentCreateService = ({ onClickNext }) => {
    const { permanentDraft, updatePermanentDraft, permanentDraftMonthlyPrice } = usePermanentDraft();
    const { permanentServicesByType, permanentService } = usePermanentDraftServices();
    const { buildingDropdownOptions } = useBuildings();

    const [isButtonNextClicked, setIsButtonNextClicked] = useState(false);

    let serviceDropdownOptions = permanentServicesByType.map((service) => {
        return {
            value: service.id,
            label: `${service.number} ${service.name}`
        };
    });

    serviceDropdownOptions = _orderBy(serviceDropdownOptions, ['label'], ['asc']);

    serviceDropdownOptions = [
        {
            value: '',
            label: 'Auswählen',
            disabled: true
        },
        ...serviceDropdownOptions
    ];

    const handleSelectService = (e) => {
        updatePermanentDraft({
            serviceId: e.target.value
        });
    };

    return (
        <>
            <FormPanel>
                <Block paddingBottom="20px">
                    <TextXL>
                        <strong>Leistung</strong>
                    </TextXL>
                </Block>
                <Grid spacing="20px">
                    <GridItem cols="12">
                        <FormLabel top bold>
                            Leistungsbeschreibung *
                        </FormLabel>
                        <FormNativeSelect
                            options={serviceDropdownOptions}
                            value={permanentService ? permanentService.id : ''}
                            error={isButtonNextClicked && !permanentService}
                            onChange={handleSelectService}
                        />
                    </GridItem>
                    <GridItem cols="4">
                        <Group nowrap spacing="5px">
                            <GroupItem grow>
                                <FormLabel top bold>
                                    Menge *
                                </FormLabel>
                                <FormInput
                                    ariaLabel="Menge"
                                    type="number"
                                    value={permanentDraft.quantity}
                                    error={isButtonNextClicked && !permanentDraft.quantity}
                                    onChange={(e) => {
                                        updatePermanentDraft({
                                            quantity: e.target.value
                                        });
                                    }}
                                />
                            </GroupItem>
                            <Block width="150px">
                                <FormLabel top bold>
                                    Einheit
                                </FormLabel>
                                <FormStaticField value={permanentService ? permanentService.unit : ''} />
                            </Block>
                        </Group>
                    </GridItem>
                    <GridItem cols="4">
                        <FormLabel top bold>
                            Intervall *
                        </FormLabel>
                        <Group nowrap spacing="5px">
                            <GroupItem grow>
                                <FormInput
                                    ariaLabel="Frequenz"
                                    type="number"
                                    value={permanentDraft.frequency}
                                    error={isButtonNextClicked && !permanentDraft.frequency}
                                    onChange={(e) => {
                                        updatePermanentDraft({
                                            frequency: e.target.value
                                        });
                                    }}
                                />
                            </GroupItem>
                            <FormNativeSelect
                                options={intervalDropdownOptions}
                                value={permanentDraft.interval}
                                error={isButtonNextClicked && !permanentDraft.interval}
                                onChange={(e) => {
                                    updatePermanentDraft({
                                        interval: e.target.value
                                    });
                                }}
                            />
                        </Group>
                    </GridItem>
                    <GridItem cols="4">
                        <FormLabel top bold>
                            Monatspreis EUR
                        </FormLabel>
                        <FormStaticField
                            value={
                                permanentDraftMonthlyPrice
                                    ? FormatUtil.getDecimalFormatted(permanentDraftMonthlyPrice, 2)
                                    : ''
                            }
                        />
                        <Block paddingTop="7px">
                            <TextS>exklusive 7% SRE Aufschlag</TextS>
                        </Block>
                    </GridItem>
                </Grid>
                <Block paddingTop="20px">
                    <Grid spacing="20px">
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Ort *
                            </FormLabel>
                            <FormInput
                                ariaLabel="Ort"
                                placeholder="Gebäude- und Raumnummer"
                                value={permanentDraft.location}
                                error={isButtonNextClicked && !permanentDraft.location}
                                onChange={(e) => {
                                    updatePermanentDraft({
                                        location: e.target.value
                                    });
                                }}
                            />
                        </GridItem>
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Standort *
                            </FormLabel>
                            <FormNativeSelect
                                options={buildingDropdownOptions}
                                value={permanentDraft.buildingId}
                                error={isButtonNextClicked && !permanentDraft.buildingId}
                                onChange={(e) => {
                                    updatePermanentDraft({
                                        buildingId: e.target.value
                                    });
                                }}
                            />
                        </GridItem>
                        <GridItem cols="4">
                            <FormLabel top bold>
                                Startdatum *
                            </FormLabel>
                            <FormDatePicker
                                popperPlacement="top-start"
                                // minDate={moment().add(0, 'days').toDate()}
                                selected={permanentDraft.startDate ? new Date(permanentDraft.startDate) : null}
                                error={isButtonNextClicked && !permanentDraft.startDate}
                                onChange={(date) => {
                                    updatePermanentDraft({
                                        startDate: date
                                    });
                                }}
                                dateFormat="dd.MM.yyyy"
                            />
                        </GridItem>

                        <GridItem cols="8">
                            <FormLabel top bold>
                                Bemerkung
                            </FormLabel>
                            <Block>
                                <FormTextarea
                                    value={permanentDraft.message}
                                    placeholder="Bitte teilen Sie uns Ihre Wünsche mit!"
                                    height="157px"
                                    onChange={(e) => {
                                        updatePermanentDraft({
                                            message: e.target.value
                                        });
                                    }}
                                />
                            </Block>
                        </GridItem>
                    </Grid>
                </Block>
            </FormPanel>
            <Block paddingTop="50px" justify="center">
                <FormButton
                    label="Weiter"
                    icon={IconArrowRight}
                    iconSize="small"
                    onClick={() => {
                        setIsButtonNextClicked(true);
                        onClickNext();
                    }}
                />
            </Block>
        </>
    );
};

PermanentCreateService.propTypes = {
    onClickNext: PropTypes.func
};

PermanentCreateService.defaultProps = {
    onClickNext: () => {}
};

export default PermanentCreateService;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLE_TEXT_XL } from '../../styles/type';

const StyledComponent = styled.div(() => {
    const style = {
        display: 'inline-block',
        ...STYLE_TEXT_XL
    };
    return style;
});

const TextXL = ({ children }) => {
    return <StyledComponent>{children}</StyledComponent>;
};

TextXL.propTypes = {
    children: PropTypes.any
};

TextXL.defaultProps = {
    children: ''
};

export default TextXL;

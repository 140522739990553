/* eslint-disable */
/**
 *
 * ButtonSettings
 *
 */

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import auth from '../../../utils/auth';
import Icon from '../Icon';
import closeIcon from '../../../images/icons/close.svg';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ButtonSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        const user = auth.getUserInfo();
        const { userName, userImage } = this.props;

        let classes;
        let icon;
        let color = 'fff';
        if (this.props.activeSettings) {
            classes = 'comp-button-settings active-settings';
            icon = 'einstellungen';
            color = '008CC6';
        } else {
            classes = 'comp-button-settings';
            icon = 'einstellungen';
            color = '#fff';
        }

        return (
            <div className={classes} onClick={(e) => this.props.onClick(e)}>
                <div className="comp-button-settings-info">
                    <div>
                        <div>
                            {userName} | {user.role.name}
                        </div>
                        <div>{user.email}</div>
                    </div>
                </div>
                <div className="comp-button-settings-icon">
                    {!this.props.activeSettings && <Icon icon={icon} color={color} />}
                    {this.props.activeSettings && <img src={closeIcon} />}
                </div>
            </div>
        );
    }
}

export default ButtonSettings;

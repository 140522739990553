import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block } from '@webfox-sc/core';
import AccordionSection from '../../atoms/AccordionSection';
import PageContentBlock from '../../atoms/PageContentBlock';
import ClaimCreateType from '../ClaimCreateType';
import ClaimCreateData from '../ClaimCreateData';
import ClaimCreateSummary from '../ClaimCreateSummary';
import { useClaimDraft, useClaimActions } from '../../hooks/claims';

const ClaimCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { handleCreateClaimFromDraft } = useClaimActions();
    const { claimDraft, isClaimDraftDataValid, stableResetClaimDraft } = useClaimDraft();

    const [stateAcc, setStateAcc] = useState(1);

    useEffect(() => {
        stableResetClaimDraft();
        return () => {
            stableResetClaimDraft();
        };
    }, [dispatch, stableResetClaimDraft]);

    const handleOnClickDataNext = () => {
        if (isClaimDraftDataValid()) {
            setStateAcc(3);
        }
    };

    const handleOnClickSubmit = async () => {
        await handleCreateClaimFromDraft();
        history.push('/claims');
    };

    return (
        <>
            <AccordionSection
                title={claimDraft.type ? `1. Leistung: ${claimDraft.type}` : '1. Leistung auswählen'}
                expanded={stateAcc === 1}
                onToggleExpanded={(expanded) => setStateAcc(expanded ? 1 : 0)}
            >
                <PageContentBlock>
                    <Block padding="75px 0 40px 0">
                        <ClaimCreateType onClickTypeButton={() => setStateAcc(2)} />
                    </Block>
                </PageContentBlock>
            </AccordionSection>

            <Block paddingTop="40px">
                <AccordionSection
                    title="2. Datenerfassung"
                    expanded={stateAcc === 2}
                    disabled={!claimDraft.type}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 2 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <ClaimCreateData onClickNext={handleOnClickDataNext} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>

            <Block paddingTop="40px">
                <AccordionSection
                    title="3. Zusammenfassung & Bestätigung"
                    expanded={stateAcc === 3}
                    disabled={!isClaimDraftDataValid()}
                    onToggleExpanded={(expanded) => setStateAcc(expanded ? 3 : 0)}
                >
                    <PageContentBlock>
                        <Block padding="75px 0 40px 0">
                            <ClaimCreateSummary onClickSubmit={handleOnClickSubmit} />
                        </Block>
                    </PageContentBlock>
                </AccordionSection>
            </Block>
        </>
    );
};

ClaimCreate.propTypes = {};
ClaimCreate.defaultProps = {};

export default ClaimCreate;

import { REDUX_ACTIONS } from '../../global/constants';

const initialState = {
    isServicesRequest: false,
    servicesSortingKey: 'number',
    servicesSortingOrder: 'asc'
};

function servicesReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_SERVICES:
            return {
                ...state,
                isServicesRequest: true
            };

        case REDUX_ACTIONS.RECIEVE_SERVICES:
        case REDUX_ACTIONS.REQUEST_SERVICES_ERROR:
            return {
                ...state,
                isServicesRequest: false
            };

        case REDUX_ACTIONS.SET_SERVICES_SORTING:
            return {
                ...state,
                servicesSortingKey: action.servicesSortingKey,
                servicesSortingOrder: action.servicesSortingOrder
            };

        /**
         * logout
         */
        case REDUX_ACTIONS.LOGOUT:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default servicesReducer;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Grid, GridItem, Group, InlineSVG } from '@webfox-sc/core';
import FormButton from '../../atoms/FormButton';
import FormLabel from '../../atoms/FormLabel';
import IconTypeBodenreinigung from '../../assets/icons/IconTypeBodenreinigung';
import IconTypeMoebel from '../../assets/icons/IconTypeMoebel';
import IconTypeGlasreinigung from '../../assets/icons/IconTypeGlasreinigung';
import IconTypeKueche from '../../assets/icons/IconTypeKueche';
import IconTypeSonstiges from '../../assets/icons/IconTypeSonstiges';
import IconCheckbox from '../../assets/icons/IconCheckbox';
import { useOrderDraft } from '../../hooks/orders';
import { useUser } from '../../hooks/user';
import { useBilling } from '../../hooks/billing';
import FormatUtil from '../../utils/FormatUtil';
import DateUtil from '../../utils/DateUtil';

const StyledIcon = styled.div({
    position: 'relative',
    top: '-8px'
});

const OrderCreateSummary = ({ onClickSubmit }) => {
    const { username } = useUser();
    const { getBillingFormatted } = useBilling();
    const {
        orderDraft,
        orderDraftService,
        orderDraftBuilding,
        orderDraftAssignee,
        orderDraftMonthlyPrice
    } = useOrderDraft();

    let typeIcon = '';
    switch (orderDraft.type) {
        case 'Teppichböden':
            typeIcon = IconTypeBodenreinigung;
            break;

        case 'Hartbelagböden':
            typeIcon = IconTypeBodenreinigung;
            break;

        case 'Mobiliar':
            typeIcon = IconTypeMoebel;
            break;

        case 'Glas':
            typeIcon = IconTypeGlasreinigung;
            break;

        case 'Küche':
            typeIcon = IconTypeKueche;
            break;

        case 'Sonstiges':
            typeIcon = IconTypeSonstiges;
            break;

        default:
            break;
    }

    return (
        <>
            <Grid spacing="20px">
                <GridItem cols="6">
                    <FormLabel textAlign="right">Leistung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <Group nowrap spacing="15px">
                        {orderDraft.type && (
                            <StyledIcon>
                                <InlineSVG width="30px" height="30px" color="white">
                                    {typeIcon}
                                </InlineSVG>
                            </StyledIcon>
                        )}
                        <FormLabel>{orderDraft.type || '–'}</FormLabel>
                    </Group>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Leistungsbeschreibung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>
                        {orderDraftService ? `${orderDraftService.number} ${orderDraftService.name}` : '–'}
                    </FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Menge:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>
                        {orderDraft.quantity ? FormatUtil.getDecimalFormatted(orderDraft.quantity, 4, 0) : '–'}
                    </FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Einheit:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{orderDraftService ? orderDraftService.unit : '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Gesamtpreis:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>
                        {orderDraftMonthlyPrice ? `${FormatUtil.getDecimalFormatted(orderDraftMonthlyPrice)} EUR` : '–'}
                    </FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Wunschtermin:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{DateUtil.getDateFormatted(orderDraft.datePlanned) || '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Ort:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{orderDraft.location || '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Standort:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{orderDraftBuilding ? orderDraftBuilding.nameCode : '–'}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <FormLabel textAlign="right">Bemerkung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{FormatUtil.nl2br(orderDraft.message) || '–'}</FormLabel>
                </GridItem>

                {orderDraft.isOverhead ? (
                    <GridItem cols="6">
                        <FormLabel textAlign="right">Verrechnung:</FormLabel>
                    </GridItem>
                ) : (
                    <GridItem cols="6">
                        <FormLabel textAlign="right">Verrechnungsdaten:</FormLabel>
                    </GridItem>
                )}
                {orderDraft.isOverhead ? (
                    <GridItem cols="6">
                        <FormLabel>Über Betriebskosten abrechnen</FormLabel>
                    </GridItem>
                ) : (
                    <GridItem cols="6">
                        <FormLabel>{getBillingFormatted(orderDraft.billing) || '–'}</FormLabel>
                    </GridItem>
                )}

                <GridItem cols="6">
                    <FormLabel textAlign="right">Bearbeitung:</FormLabel>
                </GridItem>
                <GridItem cols="6">
                    <FormLabel>{orderDraftAssignee ? orderDraftAssignee.username : username}</FormLabel>
                </GridItem>

                <GridItem cols="6">
                    <Block paddingTop="15px">
                        <FormLabel textAlign="right">
                            <b>Kfm. Ansprechpartner:</b>
                        </FormLabel>
                    </Block>
                </GridItem>
                <GridItem cols="6" />
            </Grid>

            {orderDraft.contactPersonList.map((contactPerson, index) => {
                const { first_name, last_name, email } = contactPerson;
                if (!first_name && !last_name && !email) {
                    return null;
                }

                return (
                    <Block paddingTop="20px" key={String(index)}>
                        <Grid spacing="20px">
                            <GridItem cols="6">
                                <FormLabel textAlign="right">Ansprechpartner {index + 1}:</FormLabel>
                            </GridItem>
                            <GridItem cols="6">
                                <FormLabel>
                                    {contactPerson.first_name} {contactPerson.last_name}{' '}
                                    {contactPerson.email ? `– ${contactPerson.email}` : ''}
                                </FormLabel>
                            </GridItem>
                        </Grid>
                    </Block>
                );
            })}

            <Block paddingTop="35px" justify="center">
                <FormButton label="Zur Freigabe" icon={IconCheckbox} onClick={onClickSubmit} />
            </Block>
        </>
    );
};

OrderCreateSummary.propTypes = {
    onClickSubmit: PropTypes.func
};

OrderCreateSummary.defaultProps = {
    onClickSubmit: () => {}
};

export default OrderCreateSummary;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import ButtonSettings from '../../components/MicroComps/ButtonSettings';
import Logo from '../../components/MicroComps/Logo';
import auth from '../../utils/auth';
import { logout } from '../../redux/actions/authActions';
import DateUtil from '../../utils/DateUtil';
import { useAuth } from '../../hooks/useAuth';

const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const basepath = pathname.split('/')[1];
    const { isLoggedIn } = useAuth();

    const [showHeader, setShowHeader] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowHeader(true);
        }, 300);
    }, []);

    const handleOnClickSettings = () => {
        history.push(basepath === 'settings' ? '/' : '/settings/profile');
    };

    const handleOnClickLogout = () => {
        dispatch(logout());
    };

    const dateToFormat = new Date();
    const user = auth.getUserInfo();

    let renderDate = null;
    let renderUser = null;

    if (isLoggedIn && user != null) {
        renderDate = (
            <div className="header-date">
                <div>
                    {DateUtil.getDateFormatted(dateToFormat)} | KW{DateUtil.getCurrentWeek()}
                </div>
                <NavLink to="/" onClick={handleOnClickLogout}>
                    <div className="btn-logout">Logout</div>
                </NavLink>
            </div>
        );

        renderUser = (
            <div className="header-logout">
                <ButtonSettings
                    activeSettings={basepath === 'settings'}
                    userName={user.username}
                    userImage=""
                    onClick={handleOnClickSettings}
                />
            </div>
        );
    }

    return (
        <div className={`header ${showHeader}`}>
            <NavLink to="/">
                <Logo />
            </NavLink>

            {isLoggedIn && user && (
                <div className="header-info">
                    {renderDate}
                    {renderUser}
                </div>
            )}
        </div>
    );
};

Header.propTypes = {};
Header.defaultProps = {};

export default Header;

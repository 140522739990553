import React from 'react';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import FormInput from '../../../atoms/FormInput';
import FilterCheckbox from '../../../atoms/FilterCheckbox';
import TextM from '../../../atoms/TextM';
import { usePermanentList } from '../PermanentList.hooks';
import { useUser } from '../../../hooks/user';
import { PERMANENT_STATUS } from '../../../global/constants';

const PermanentListFilters = () => {
    const { userIsLoggedIn } = useUser();

    const {
        isPermanentsInitialRequest,
        permanentsSearchText,
        permanentsFilters,
        handleChangePermanentsSearchText,
        togglePermanentsFilter
    } = usePermanentList();

    if (!userIsLoggedIn) {
        return null;
    }

    if (isPermanentsInitialRequest) {
        return null;
    }

    return (
        <>
            <Block maxWidth="750px" margin="0 auto">
                <Group spacing="26px">
                    <GroupItem grow>
                        <Group nowrap spacing="20px" alignItems="center">
                            <Block width="55px">
                                <TextM>
                                    <strong>Suche</strong>
                                </TextM>
                            </Block>
                            <GroupItem grow>
                                <FormInput
                                    size="L"
                                    ariaLabel="Suche"
                                    value={permanentsSearchText}
                                    maxLength="128"
                                    onChange={(e) => handleChangePermanentsSearchText(e.target.value)}
                                />
                            </GroupItem>
                        </Group>
                    </GroupItem>
                </Group>
            </Block>

            <Group paddingTop="40px" spacing="40px" justify="center" alignItems="center">
                <TextM>
                    <strong>Status</strong>
                </TextM>
                <FilterCheckbox
                    label="Zur Freigabe"
                    checked={permanentsFilters.includes(PERMANENT_STATUS.ZUR_FREIGABE)}
                    onChange={() => togglePermanentsFilter(PERMANENT_STATUS.ZUR_FREIGABE)}
                />
                <FilterCheckbox
                    label="Aktiv"
                    checked={permanentsFilters.includes(PERMANENT_STATUS.AKTIV)}
                    onChange={() => togglePermanentsFilter(PERMANENT_STATUS.AKTIV)}
                />
                <FilterCheckbox
                    label="Stornierung"
                    active={permanentsFilters.includes(PERMANENT_STATUS.STORNIERUNG)}
                    onChange={() => togglePermanentsFilter(PERMANENT_STATUS.STORNIERUNG)}
                />
                <FilterCheckbox
                    label="Archiv"
                    checked={permanentsFilters.includes(PERMANENT_STATUS.ARCHIV)}
                    onChange={() => togglePermanentsFilter(PERMANENT_STATUS.ARCHIV)}
                />
            </Group>
        </>
    );
};

PermanentListFilters.propTypes = {};
PermanentListFilters.defaultProps = {};

export default PermanentListFilters;

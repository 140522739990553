import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Block, Collapse } from '@webfox-sc/core';
import IconCreate from '../../assets/icons/IconCreate';
import IconDelete from '../../assets/icons/IconDelete';
import Table from '../../atoms/Table';
import TableBody from '../../atoms/TableBody';
import TableHeader from '../../atoms/TableHeader';
import TableRow from '../../atoms/TableRow';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import TableBodyCell from '../../atoms/TableBodyCell';
import TextM from '../../atoms/TextM';
import FormButton from '../../atoms/FormButton';
import ConfirmDialog from '../../atoms/ConfirmDialog';
import SettingsBuildingsEdit from '../SettingsBuildingsEdit';
import SettingsBuildingsCreate from '../SettingsBuildingsCreate';
import { fetchBuildings, deleteBuilding } from '../../redux/actions/buildingsActions';
import { useSettingsBuildings } from './SettingsBuildings.hooks';
import { useUser } from '../../hooks/user';
import { USER_ROLES } from '../../global/constants';

const SettingsBuildings = () => {
    const dispatch = useDispatch();
    const { userRoleName } = useUser();
    const [activeBuildingId, setActiveBuildingId] = useState('');
    const [showCreatePanel, setShowCreatePanel] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const {
        isBuildingsInitialRequest,
        buildingsSorted,
        buildingsSortingKey,
        buildingsSortingOrder,
        buildingsDisplayMax,
        handleSetBuildingsSortingKey,
        displayFurtherBuildings
    } = useSettingsBuildings();

    useEffect(() => {
        dispatch(fetchBuildings());
    }, [dispatch]);

    if (userRoleName !== USER_ROLES.ADMIN) {
        return null;
    }

    if (isBuildingsInitialRequest) {
        return null;
    }

    const handleOnClickShowCreatePanel = () => {
        setShowCreatePanel(!showCreatePanel);
        setActiveBuildingId('');
    };

    const handleOnClickTableRow = (buildingId) => {
        setActiveBuildingId(buildingId);
        setShowCreatePanel(false);
    };

    const handleOnClickDelete = async () => {
        dispatch(deleteBuilding(activeBuildingId));
    };

    return (
        <>
            <Block justify="center">
                <TextM>
                    <strong>
                        {buildingsSorted.length} {buildingsSorted.length === 1 ? 'Standort' : 'Standorte'}
                    </strong>
                </TextM>
            </Block>

            <Block paddingTop="30px">
                <FormButton
                    label="Neuer Standort"
                    icon={IconCreate}
                    iconSize="small"
                    active={showCreatePanel}
                    onClick={handleOnClickShowCreatePanel}
                />
            </Block>

            <Collapse show={showCreatePanel} keepChildren={false}>
                <Block paddingTop="10px">
                    <SettingsBuildingsCreate
                        onClickCancel={() => setShowCreatePanel(false)}
                        onCreated={() => setShowCreatePanel(false)}
                    />
                </Block>
            </Collapse>

            <Block paddingTop="50px">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell
                                width="50%"
                                label="Name"
                                order={buildingsSortingKey === 'name' ? buildingsSortingOrder : null}
                                onClick={() => handleSetBuildingsSortingKey('name')}
                            />
                            <TableHeaderCell
                                label="Code"
                                order={buildingsSortingKey === 'code' ? buildingsSortingOrder : null}
                                onClick={() => handleSetBuildingsSortingKey('code')}
                            />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {buildingsSorted.slice(0, buildingsDisplayMax).map((building) => {
                            return (
                                <TableRow
                                    key={building.id}
                                    onClick={
                                        activeBuildingId === building.id
                                            ? null
                                            : () => handleOnClickTableRow(building.id)
                                    }
                                >
                                    {activeBuildingId === building.id ? (
                                        <TableBodyCell colSpan="2">
                                            <SettingsBuildingsEdit
                                                buildingId={building.id}
                                                onClickCancel={() => setActiveBuildingId('')}
                                                onUpdated={() => setActiveBuildingId('')}
                                                onClickDelete={() => {
                                                    setShowDeleteDialog(true);
                                                }}
                                            />
                                        </TableBodyCell>
                                    ) : (
                                        <>
                                            <TableBodyCell layout="text">{building.name}</TableBodyCell>
                                            <TableBodyCell layout="text">{building.code}</TableBodyCell>
                                        </>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Block>

            {buildingsDisplayMax < buildingsSorted.length && (
                <Block paddingTop="10px" justify="center">
                    <FormButton label="Mehr anzeigen" onClick={displayFurtherBuildings} />
                </Block>
            )}

            <ConfirmDialog
                show={showDeleteDialog}
                heading="Möchten Sie diesen Eintrag wirklich löschen?"
                text="Diese Aktion kann nicht rückgängig gemacht werden."
                confirmButtonLabel="Löschen"
                confirmButtonIcon={IconDelete}
                onClickConfirm={() => {
                    handleOnClickDelete();
                    setShowDeleteDialog(false);
                }}
                onClickCancel={() => setShowDeleteDialog(false)}
                onClickOutside={() => setShowDeleteDialog(false)}
            />
        </>
    );
};

SettingsBuildings.propTypes = {};
SettingsBuildings.defaultProps = {};

export default SettingsBuildings;

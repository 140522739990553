import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';
import { COLORS } from '../../styles/common';
import { STYLE_TEXT_M } from '../../styles/type';

const theme = {
    root: {
        border: 'none',
        background: 'transparent',
        padding: 0,
        outline: 0,
        span: {
            ...STYLE_TEXT_M,
            color: COLORS.DARK_GREY
        }
    }
};

const TextButtonDialog = (props) => {
    return <Button {...props} theme={theme} />;
};

TextButtonDialog.propTypes = {
    ...Button.propTypes,
    label: PropTypes.string
};

TextButtonDialog.defaultProps = {
    ...Button.defaultProps,
    label: ''
};

export default TextButtonDialog;

import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Block } from '@webfox-sc/core';

import PrivateRoute from '../PrivateRoute';
import LoginForm from '../LoginForm';
import Header from '../Header';
import MainMenu from '../MainMenu';
import Footer from '../../components/Footer';

import Dashboard from '../Dashboard';
import NotFoundPage from '../NotFoundPage';
import AuthResetPage from '../AuthResetPage';
import AuthForgotPage from '../AuthForgotPage';
import Imprint from '../Imprint';
import Privacy from '../Privacy';
import Settings from '../Settings';
import ClaimList from '../ClaimList';
import ClaimCreate from '../ClaimCreate';
import ClaimDetails from '../ClaimDetails';
import OrderList from '../OrderList';
import OrderCreate from '../OrderCreate';
import OrderDetails from '../OrderDetails';
import PermanentList from '../PermanentList';
import PermanentCreate from '../PermanentCreate';
import PermanentDetails from '../PermanentDetails';
import Cockpit from '../Cockpit';

import { useAuth } from '../../hooks/useAuth';

const StyledPage = styled.div(() => {
    const style = {
        display: 'flex',
        flexFlow: 'column',
        minHeight: '100vh'
    };
    return style;
});

const StyledPageContent = styled.div({
    flexGrow: 1,
    paddingBottom: '100px',
    boxSizing: 'border-box'
});

const Page = () => {
    const { isLoggedIn, isRequestingAutoLogin } = useAuth();
    const { pathname } = useLocation();
    const publicPathnames = ['/auth/reset', '/auth/forgot', '/imprint', '/privacy'];

    return (
        <>
            {!isLoggedIn && !isRequestingAutoLogin && !publicPathnames.includes(pathname) && (
                <Block
                    position="absolute"
                    zIndex="1000"
                    top="0"
                    left="0"
                    width="100vw"
                    height="calc(100vh - 80px)"
                    justify="center"
                    alignItems="center"
                >
                    <LoginForm />
                </Block>
            )}
            <StyledPage>
                <Header />
                <MainMenu />
                <StyledPageContent>
                    <Switch>
                        <Route path="/auth/reset/" component={AuthResetPage} />
                        <Route path="/auth/forgot/" component={AuthForgotPage} />
                        <Route path="/imprint" component={Imprint} />
                        <Route path="/privacy" component={Privacy} />

                        <PrivateRoute path="/" exact component={Dashboard} />

                        <PrivateRoute path="/claims" exact component={ClaimList} />
                        <PrivateRoute path="/claims/create" component={ClaimCreate} />
                        <PrivateRoute path="/claims/:claimId" component={ClaimDetails} />

                        <PrivateRoute path="/orders" exact component={OrderList} />
                        <PrivateRoute path="/orders/create" component={OrderCreate} />
                        <PrivateRoute path="/orders/:orderId" component={OrderDetails} />

                        <PrivateRoute path="/permanents" exact component={PermanentList} />
                        <PrivateRoute path="/permanents/create" component={PermanentCreate} />
                        <PrivateRoute path="/permanents/:permanentId" component={PermanentDetails} />

                        <PrivateRoute path="/cockpit" component={Cockpit} />

                        <PrivateRoute path="/settings" component={Settings} />

                        <Route component={NotFoundPage} />
                    </Switch>
                </StyledPageContent>
                <Footer />
            </StyledPage>
        </>
    );
};

export default Page;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem, Group } from '@webfox-sc/core';
import IconCheck from '../../assets/icons/IconCheck';
import IconClose from '../../assets/icons/IconClose';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormStaticField from '../../atoms/FormStaticField';
import FormButton from '../../atoms/FormButton';

const isValid = (contactDraft) =>
    contactDraft?.first_name && contactDraft?.last_name && contactDraft?.email && contactDraft?.department;

const FormPanelContact = ({ contact, isInlineEditing, onSubmit }) => {
    const [contactDraft, setContactDraft] = useState(contact);
    const [isLocalEditMode, setIsLocalEditMode] = useState(false);
    const [isLocalValidationMode, setIsLocalValidationMode] = useState(false);

    useEffect(() => {
        setContactDraft(contact);
    }, [contact]);

    useEffect(() => {
        if (!isLocalEditMode) {
            setContactDraft(contact);
            setIsLocalValidationMode(false);
        }
    }, [isLocalEditMode, contact]);

    const handleChangeContactDraft = (key, value) => {
        const _contactDraft = { ...contactDraft };
        _contactDraft[key] = value;
        setContactDraft(_contactDraft);
    };

    const handleOnClickSubmit = () => {
        if (isValid(contactDraft)) {
            setIsLocalEditMode(false);
            onSubmit(contactDraft);
        } else {
            setIsLocalValidationMode(true);
        }
    };

    return (
        <FormPanel
            title="Kfm. Ansprechpartner"
            showEditButton={isInlineEditing && !isLocalEditMode}
            onClickEditButton={() => setIsLocalEditMode(!isLocalEditMode)}
        >
            <Grid spacing="20px">
                <GridItem cols="6">
                    <FormLabel top bold>
                        Vorname {isLocalEditMode ? '*' : ''}
                    </FormLabel>
                    {isLocalEditMode ? (
                        <FormInput
                            ariaLabel="Vorname"
                            value={contactDraft.first_name}
                            error={isLocalValidationMode && !contactDraft.first_name}
                            onChange={(e) => handleChangeContactDraft('first_name', e.target.value)}
                        />
                    ) : (
                        <FormStaticField value={contactDraft?.first_name || '–'} />
                    )}
                </GridItem>
                <GridItem cols="6">
                    <FormLabel top bold>
                        Nachname {isLocalEditMode ? '*' : ''}
                    </FormLabel>
                    {isLocalEditMode ? (
                        <FormInput
                            ariaLabel="Nachname"
                            value={contactDraft.last_name}
                            error={isLocalValidationMode && !contactDraft.last_name}
                            onChange={(e) => handleChangeContactDraft('last_name', e.target.value)}
                        />
                    ) : (
                        <FormStaticField value={contactDraft?.last_name || '–'} />
                    )}
                </GridItem>
                <GridItem cols="6">
                    <FormLabel top bold>
                        E-Mail {isLocalEditMode ? '*' : ''}
                    </FormLabel>
                    {isLocalEditMode ? (
                        <FormInput
                            ariaLabel="E-Mail"
                            value={contactDraft.email}
                            error={isLocalValidationMode && !contactDraft.email}
                            onChange={(e) => handleChangeContactDraft('email', e.target.value)}
                        />
                    ) : (
                        <FormStaticField value={contactDraft?.email || '–'} />
                    )}
                </GridItem>
                <GridItem cols="6">
                    <FormLabel top bold>
                        Abteilung {isLocalEditMode ? '*' : ''}
                    </FormLabel>
                    {isLocalEditMode ? (
                        <FormInput
                            ariaLabel="Abteilung"
                            value={contactDraft.department}
                            error={isLocalValidationMode && !contactDraft.department}
                            onChange={(e) => handleChangeContactDraft('department', e.target.value)}
                        />
                    ) : (
                        <FormStaticField value={contactDraft?.department || '–'} />
                    )}
                </GridItem>
                {isLocalEditMode && (
                    <GridItem cols="12">
                        <Group justify="right" spacing="5px" alignItems="right">
                            <FormButton icon={IconCheck} iconSize="small" onClick={handleOnClickSubmit} />
                            <FormButton icon={IconClose} iconSize="small" onClick={() => setIsLocalEditMode(false)} />
                        </Group>
                    </GridItem>
                )}
            </Grid>
        </FormPanel>
    );
};

FormPanelContact.propTypes = {
    contact: PropTypes.object,
    isInlineEditing: PropTypes.bool,
    onSubmitBilling: PropTypes.func,
};

FormPanelContact.defaultProps = {
    contact: {},
    isInlineEditing: false,
    onSubmit: () => {},
};

export default FormPanelContact;

export const USER_ROLES = {
    ADMIN: 'Administrator',
    LOCATION_MANAGER: 'LocationManager',
    SERVICE_MANAGER: 'ServiceManager',
    KEY_USER: 'KeyUser'
};

export const REDUX_ACTIONS = {
    /**
     * auth
     */
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',

    REQUEST_LOGIN: 'REQUEST_LOGIN',
    REQUEST_LOGIN_ERROR: 'REQUEST_LOGIN_ERROR',
    RECIEVE_LOGIN: 'RECIEVE_LOGIN',

    REQUEST_AUTO_LOGIN: 'REQUEST_AUTO_LOGIN',
    REQUEST_AUTO_LOGIN_ERROR: 'REQUEST_AUTO_LOGIN_ERROR',
    RECIEVE_AUTO_LOGIN: 'RECIEVE_AUTO_LOGIN',

    REQUEST_ME: 'REQUEST_ME',
    REQUEST_ME_ERROR: 'REQUEST_ME_ERROR',
    RECIEVE_ME: 'RECIEVE_ME',

    /**
     * claims
     */
    REQUEST_CLAIMS: 'REQUEST_CLAIMS',
    REQUEST_CLAIMS_ERROR: 'REQUEST_CLAIMS_ERROR',
    RECIEVE_CLAIMS: 'RECIEVE_CLAIMS',

    REQUEST_CLAIM: 'REQUEST_CLAIM',
    REQUEST_CLAIM_ERROR: 'REQUEST_CLAIM_ERROR',
    RECIEVE_CLAIM: 'RECIEVE_CLAIM',

    REQUEST_CREATE_CLAIM: 'REQUEST_CREATE_CLAIM',
    REQUEST_CREATE_CLAIM_ERROR: 'REQUEST_CREATE_CLAIM_ERROR',
    RECIEVE_CREATE_CLAIM: 'RECIEVE_CREATE_CLAIM',

    REQUEST_UPDATE_CLAIM: 'REQUEST_UPDATE_CLAIM',
    REQUEST_UPDATE_CLAIM_ERROR: 'REQUEST_UPDATE_CLAIM_ERROR',
    RECIEVE_UPDATE_CLAIM: 'RECIEVE_UPDATE_CLAIM',

    REQUEST_DELETE_CLAIM: 'REQUEST_DELETE_CLAIM',
    REQUEST_DELETE_CLAIM_ERROR: 'REQUEST_DELETE_CLAIM_ERROR',
    RECIEVE_DELETE_CLAIM: 'RECIEVE_DELETE_CLAIM',

    SET_CLAIM_DRAFT: 'SET_CLAIM_DRAFT',
    RESET_CLAIM_DRAFT: 'RESET_CLAIM_DRAFT',

    SET_CLAIMS_FILTERS: 'SET_CLAIMS_FILTERS',
    SET_CLAIMS_SORTING: 'SET_CLAIMS_SORTING',

    /**
     * orders
     */
    REQUEST_ORDERS: 'REQUEST_ORDERS',
    REQUEST_ORDERS_ERROR: 'REQUEST_ORDERS_ERROR',
    RECIEVE_ORDERS: 'RECIEVE_ORDERS',

    REQUEST_ORDER: 'REQUEST_ORDER',
    REQUEST_ORDER_ERROR: 'REQUEST_ORDER_ERROR',
    RECIEVE_ORDER: 'RECIEVE_ORDER',

    REQUEST_CREATE_ORDER: 'REQUEST_CREATE_ORDER',
    REQUEST_CREATE_ORDER_ERROR: 'REQUEST_CREATE_ORDER_ERROR',
    RECIEVE_CREATE_ORDER: 'RECIEVE_CREATE_ORDER',

    REQUEST_UPDATE_ORDER: 'REQUEST_UPDATE_ORDER',
    REQUEST_UPDATE_ORDER_ERROR: 'REQUEST_UPDATE_ORDER_ERROR',
    RECIEVE_UPDATE_ORDER: 'RECIEVE_UPDATE_ORDER',

    REQUEST_DELETE_ORDER: 'REQUEST_DELETE_ORDER',
    REQUEST_DELETE_ORDER_ERROR: 'REQUEST_DELETE_ORDER_ERROR',
    RECIEVE_DELETE_ORDER: 'RECIEVE_DELETE_ORDER',

    SET_ORDER_DRAFT: 'SET_ORDER_DRAFT',
    RESET_ORDER_DRAFT: 'RESET_ORDER_DRAFT',

    SET_ORDERS_SEARCH_TEXT: 'SET_ORDERS_SEARCH_TEXT',
    SET_ORDERS_START_DATE: 'SET_ORDERS_START_DATE',
    SET_ORDERS_END_DATE: 'SET_ORDERS_END_DATE',
    SET_ORDERS_FILTERS: 'SET_ORDERS_FILTERS',
    SET_ORDERS_SORTING: 'SET_ORDERS_SORTING',
    SET_SELECTED_ORDER_IDS: 'SET_SELECTED_ORDER_IDS',
    SET_ORDERS_DISPLAY_MAX: 'SET_ORDERS_DISPLAY_MAX',

    /**
     * permanents
     */
    REQUEST_PERMANENTS: 'REQUEST_PERMANENTS',
    REQUEST_PERMANENTS_ERROR: 'REQUEST_PERMANENTS_ERROR',
    RECIEVE_PERMANENTS: 'RECIEVE_PERMANENTS',

    REQUEST_PERMANENT: 'REQUEST_PERMANENT',
    REQUEST_PERMANENT_ERROR: 'REQUEST_PERMANENT_ERROR',
    RECIEVE_PERMANENT: 'RECIEVE_PERMANENT',

    REQUEST_CREATE_PERMANENT: 'REQUEST_CREATE_PERMANENT',
    REQUEST_CREATE_PERMANENT_ERROR: 'REQUEST_CREATE_PERMANENT_ERROR',
    RECIEVE_CREATE_PERMANENT: 'RECIEVE_CREATE_PERMANENT',

    REQUEST_UPDATE_PERMANENT: 'REQUEST_UPDATE_PERMANENT',
    REQUEST_UPDATE_PERMANENT_ERROR: 'REQUEST_UPDATE_PERMANENT_ERROR',
    RECIEVE_UPDATE_PERMANENT: 'RECIEVE_UPDATE_PERMANENT',

    REQUEST_DELETE_PERMANENT: 'REQUEST_DELETE_PERMANENT',
    REQUEST_DELETE_PERMANENT_ERROR: 'REQUEST_DELETE_PERMANENT_ERROR',
    RECIEVE_DELETE_PERMANENT: 'RECIEVE_DELETE_PERMANENT',

    SET_PERMANENT_DRAFT: 'SET_PERMANENT_DRAFT',
    RESET_PERMANENT_DRAFT: 'RESET_PERMANENT_DRAFT',

    SET_PERMANENTS_SEARCH_TEXT: 'SET_PERMANENTS_SEARCH_TEXT',
    SET_PERMANENTS_FILTERS: 'SET_PERMANENTS_FILTERS',
    SET_PERMANENTS_SORTING: 'SET_PERMANENTS_SORTING',
    SET_PERMANENTS_DISPLAY_MAX: 'SET_PERMANENTS_DISPLAY_MAX',

    /**
     * services
     */
    REQUEST_SERVICES: 'REQUEST_SERVICES',
    REQUEST_SERVICES_ERROR: 'REQUEST_SERVICES_ERROR',
    RECIEVE_SERVICES: 'RECIEVE_SERVICES',

    REQUEST_UPDATE_SERVICES: 'REQUEST_UPDATE_SERVICES',
    REQUEST_UPDATE_SERVICES_ERROR: 'REQUEST_UPDATE_SERVICES_ERROR',
    RECIEVE_UPDATE_SERVICES: 'RECIEVE_UPDATE_SERVICES',

    REQUEST_CREATE_SERVICE: 'REQUEST_CREATE_SERVICE',
    REQUEST_CREATE_SERVICE_ERROR: 'REQUEST_CREATE_SERVICE_ERROR',
    RECIEVE_CREATE_SERVICE: 'RECIEVE_CREATE_SERVICE',

    REQUEST_UPDATE_SERVICE: 'REQUEST_UPDATE_SERVICE',
    REQUEST_UPDATE_SERVICE_ERROR: 'REQUEST_UPDATE_SERVICE_ERROR',
    RECIEVE_UPDATE_SERVICE: 'RECIEVE_UPDATE_SERVICE',

    REQUEST_DELETE_SERVICE: 'REQUEST_DELETE_SERVICE',
    REQUEST_DELETE_SERVICE_ERROR: 'REQUEST_DELETE_SERVICE_ERROR',
    RECIEVE_DELETE_SERVICE: 'RECIEVE_DELETE_SERVICE',

    SET_SERVICES_SORTING: 'SET_SERVICES_SORTING',

    /**
     * buildings
     */
    REQUEST_BUILDINGS: 'REQUEST_BUILDINGS',
    REQUEST_BUILDINGS_ERROR: 'REQUEST_BUILDINGS_ERROR',
    RECIEVE_BUILDINGS: 'RECIEVE_BUILDINGS',

    REQUEST_CREATE_BUILDING: 'REQUEST_CREATE_BUILDING',
    REQUEST_CREATE_BUILDING_ERROR: 'REQUEST_CREATE_BUILDING_ERROR',
    RECIEVE_CREATE_BUILDING: 'RECIEVE_CREATE_BUILDING',

    REQUEST_UPDATE_BUILDING: 'REQUEST_UPDATE_BUILDING',
    REQUEST_UPDATE_BUILDING_ERROR: 'REQUEST_UPDATE_BUILDING_ERROR',
    RECIEVE_UPDATE_BUILDING: 'RECIEVE_UPDATE_BUILDING',

    REQUEST_DELETE_BUILDING: 'REQUEST_DELETE_BUILDING',
    REQUEST_DELETE_BUILDING_ERROR: 'REQUEST_DELETE_BUILDING_ERROR',
    RECIEVE_DELETE_BUILDING: 'RECIEVE_DELETE_BUILDING',

    SET_BUILDINGS_SORTING: 'SET_BUILDINGS_SORTING',

    /**
     * users
     */
    REQUEST_USERS: 'REQUEST_USERS',
    REQUEST_USERS_ERROR: 'REQUEST_USERS_ERROR',
    RECIEVE_USERS: 'RECIEVE_USERS',

    REQUEST_USER: 'REQUEST_USER',
    REQUEST_USER_ERROR: 'REQUEST_USER_ERROR',
    RECIEVE_USER: 'RECIEVE_USER',

    REQUEST_CREATE_USER: 'REQUEST_CREATE_USER',
    REQUEST_CREATE_USER_ERROR: 'REQUEST_CREATE_USER_ERROR',
    RECIEVE_CREATE_USER: 'RECIEVE_CREATE_USER',

    REQUEST_UPDATE_USER: 'REQUEST_UPDATE_USER',
    REQUEST_UPDATE_USER_ERROR: 'REQUEST_UPDATE_USER_ERROR',
    RECIEVE_UPDATE_USER: 'RECIEVE_UPDATE_USER',

    REQUEST_DELETE_USER: 'REQUEST_DELETE_USER',
    REQUEST_DELETE_USER_ERROR: 'REQUEST_DELETE_USER_ERROR',
    RECIEVE_DELETE_USER: 'RECIEVE_DELETE_USER',

    SET_USERS_SORTING: 'SET_USERS_SORTING',

    /**
     * roles
     */
    REQUEST_ROLES: 'REQUEST_ROLES',
    REQUEST_ROLES_ERROR: 'REQUEST_ROLES_ERROR',
    RECIEVE_ROLES: 'RECIEVE_ROLES',

    /**
     * configs
     */
    REQUEST_CONFIGS: 'REQUEST_CONFIGS',
    REQUEST_CONFIGS_ERROR: 'REQUEST_CONFIGS_ERROR',
    RECIEVE_CONFIGS: 'RECIEVE_CONFIGS',

    REQUEST_UPDATE_CONFIG: 'REQUEST_UPDATE_CONFIG',
    REQUEST_UPDATE_CONFIG_ERROR: 'REQUEST_UPDATE_CONFIG_ERROR',
    RECIEVE_UPDATE_CONFIG: 'RECIEVE_UPDATE_CONFIG'
};

export const CLAIM_STATUS = {
    OFFEN: 'OFFEN',
    UNBERECHTIGT: 'UNBERECHTIGT',
    IN_ARBEIT: 'IN ARBEIT',
    ERLEDIGT: 'ERLEDIGT'
};

/**
 * use CLAIM_STATUS values !
 */
export const CLAIM_STATUS_LABELS = {
    OFFEN: 'Offen',
    UNBERECHTIGT: 'Unberechtigt',
    'IN ARBEIT': 'In Arbeit',
    ERLEDIGT: 'Erledigt'
};

export const ORDER_STATUS = {
    OFFEN: 'OFFEN',
    ABGELEHNT: 'ABGELEHNT',
    FREIGEGEBEN: 'FREIGEGEBEN',
    IN_ARBEIT: 'IN ARBEIT',
    ERLEDIGT: 'ERLEDIGT',
    ABGERECHNET: 'ABGERECHNET',
    STORNIERT: 'STORNIERT'
};

/**
 * use ORDER_STATUS values !
 */
export const ORDER_STATUS_LABELS = {
    OFFEN: 'Offen',
    ABGELEHNT: 'Abgelehnt',
    FREIGEGEBEN: 'Freigegeben',
    'IN ARBEIT': 'In Arbeit',
    ERLEDIGT: 'Erledigt',
    ABGERECHNET: 'Abgerechnet',
    STORNIERT: 'Storniert'
};

export const PERMANENT_STATUS = {
    ZUR_FREIGABE: 'ZUR FREIGABE',
    AKTIV: 'AKTIV',
    ABGELEHNT: 'ABGELEHNT',
    STORNIERUNG: 'STORNIERUNG',
    ABGELAUFEN: 'ABGELAUFEN',
    ARCHIV: 'ARCHIV'
};

/**
 * use PERMANENT_STATUS values !
 */
export const PERMANENT_STATUS_LABELS = {
    'ZUR FREIGABE': 'Zur Freigabe',
    AKTIV: 'Aktiv',
    ABGELEHNT: 'Abgelehnt',
    STORNIERUNG: 'Stornierung',
    ABGELAUFEN: 'Abgelaufen',
    ARCHIV: 'Archiv'
};

export const INTERVAL_FACTORS = {
    year: 1 / 12,
    quarter: 1 / 3,
    month: 1,
    week: 52 / 12,
    day: 250 / 12
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Block, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import IconClose from '../../assets/icons/IconClose';
import FormPanel from '../../atoms/FormPanel';
import FormLabel from '../../atoms/FormLabel';
import FormInput from '../../atoms/FormInput';
import FormStaticField from '../../atoms/FormStaticField';
import FormButton from '../../atoms/FormButton';
import TextButton from '../../atoms/TextButton';

const FormPanelContactPersonList = ({ contactPersonList, isEditMode, isValidationMode, onChange }) => {
    const [contactPersonListDraft, setContactPersonListDraft] = useState(contactPersonList);
    const [isLocalValidationMode, setIsLocalValidationMode] = useState(isValidationMode);

    useEffect(() => {
        setContactPersonListDraft(contactPersonList);
    }, [contactPersonList]);

    useEffect(() => {
        setIsLocalValidationMode(isValidationMode);
    }, [isValidationMode]);

    const handleChangeContactPersonListDraft = (index, key, value) => {
        const _contactPersonListDraft = [...contactPersonListDraft];
        const _contactPerson = { ..._contactPersonListDraft[index] };
        _contactPerson[key] = value;
        _contactPersonListDraft[index] = _contactPerson;
        setContactPersonListDraft(_contactPersonListDraft);
        onChange(_contactPersonListDraft);
    };

    const handleAddContactPerson = () => {
        const _contactPersonListDraft = [...contactPersonListDraft];
        _contactPersonListDraft.push({
            first_name: '',
            last_name: '',
            email: ''
        });
        setContactPersonListDraft(_contactPersonListDraft);
        onChange(_contactPersonListDraft);
    };

    const handleRemoveContactPerson = (index) => {
        const _contactPersonListDraft = [...contactPersonListDraft];
        _contactPersonListDraft.splice(index, 1);
        setContactPersonListDraft(_contactPersonListDraft);
        onChange(_contactPersonListDraft);
    };

    return (
        <FormPanel title="Kfm. Ansprechpartner">
            <Block>
                {contactPersonListDraft.map((contactPerson, index) => {
                    const { name, first_name, last_name, email } = contactPerson;

                    /**
                     * fallback: do not show empty data
                     */
                    if (!isEditMode && !name && !first_name && !last_name && !email) {
                        return null;
                    }

                    /**
                     * fallback: show legacy name / email
                     */
                    if (!isEditMode && name && !first_name && !last_name) {
                        return (
                            <Block key={String(index)} paddingTop={index === 0 ? null : '10px'}>
                                <Grid spacing="5px">
                                    <GridItem cols="6">
                                        <Block>
                                            <FormLabel top bold>
                                                Name
                                            </FormLabel>
                                            <FormStaticField value={name} />
                                        </Block>
                                    </GridItem>
                                    <GridItem cols="6">
                                        <Block>
                                            <FormLabel top bold>
                                                E-Mail
                                            </FormLabel>
                                            <FormStaticField value={email} />
                                        </Block>
                                    </GridItem>
                                </Grid>
                            </Block>
                        );
                    }

                    return (
                        <Block key={String(index)} paddingTop={index === 0 ? null : '10px'}>
                            <Grid spacing="5px">
                                <GridItem cols="4">
                                    <Block>
                                        <FormLabel top bold>
                                            Vorname {isEditMode ? '*' : ''}
                                        </FormLabel>
                                        {isEditMode ? (
                                            <FormInput
                                                ariaLabel="Vorname"
                                                value={first_name}
                                                error={isLocalValidationMode && !first_name}
                                                onChange={(e) => {
                                                    handleChangeContactPersonListDraft(
                                                        index,
                                                        'first_name',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <FormStaticField value={first_name} />
                                        )}
                                    </Block>
                                </GridItem>
                                <GridItem cols="4">
                                    <Block>
                                        <FormLabel top bold>
                                            Nachname {isEditMode ? '*' : ''}
                                        </FormLabel>
                                        {isEditMode ? (
                                            <FormInput
                                                ariaLabel="Nachname"
                                                value={last_name}
                                                error={isLocalValidationMode && !last_name}
                                                onChange={(e) => {
                                                    handleChangeContactPersonListDraft(
                                                        index,
                                                        'last_name',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <FormStaticField value={last_name} />
                                        )}
                                    </Block>
                                </GridItem>
                                <GridItem cols="4">
                                    <Block>
                                        <FormLabel top bold>
                                            E-Mail {isEditMode ? '*' : ''}
                                        </FormLabel>
                                        <Group nowrap spacing="5px">
                                            <GroupItem grow>
                                                {isEditMode ? (
                                                    <FormInput
                                                        ariaLabel="E-Mail"
                                                        value={email}
                                                        error={isLocalValidationMode && !email}
                                                        onChange={(e) => {
                                                            handleChangeContactPersonListDraft(
                                                                index,
                                                                'email',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <FormStaticField value={email} />
                                                )}
                                            </GroupItem>
                                            {isEditMode && contactPersonListDraft.length > 1 && (
                                                <GroupItem>
                                                    <FormButton
                                                        icon={IconClose}
                                                        onClick={() => handleRemoveContactPerson(index)}
                                                    />
                                                </GroupItem>
                                            )}
                                        </Group>
                                    </Block>
                                </GridItem>
                            </Grid>
                        </Block>
                    );
                })}
            </Block>
            {isEditMode && (
                <Block paddingTop="10px">
                    <TextButton label="Weiterer Ansprechpartner hinzufügen" onClick={handleAddContactPerson} />
                </Block>
            )}
        </FormPanel>
    );
};

FormPanelContactPersonList.propTypes = {
    contactPersonList: PropTypes.array,
    isEditMode: PropTypes.bool,
    isValidationMode: PropTypes.bool,
    onChange: PropTypes.func
};

FormPanelContactPersonList.defaultProps = {
    contactPersonList: [],
    isEditMode: false,
    isValidationMode: false,
    onChange: () => {}
};

export default FormPanelContactPersonList;

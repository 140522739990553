import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import { setServicesSorting, updateServices } from '../../redux/actions/servicesActions';

const dataFields = {
    Unit: {
        type: 'text'
    },
    Price: {
        type: 'number'
    },
    Type: {
        type: 'text'
    },
    Number: {
        type: 'text'
    },
    Name: {
        type: 'text'
    }
};

const identifier = 'Number';

export function useSettingsServices() {
    const dispatch = useDispatch();
    const services = useSelector(({ dataState }) => dataState.services);
    const { isServicesRequest, servicesSortingKey, servicesSortingOrder } = useSelector(
        ({ servicesState }) => servicesState
    );

    const servicesDisplayStep = 50;
    const [servicesDisplayMax, setServicesDisplayMax] = useState(servicesDisplayStep);

    const [servicesSorted, setServicesSorted] = useState([]);

    useEffect(() => {
        /**
         * FILTER
         */
        let _servicesSorted = Object.values(services);

        /**
         * SORT
         */
        _servicesSorted = _orderBy(_servicesSorted, [servicesSortingKey], [servicesSortingOrder]);

        /**
         * SET
         */
        setServicesSorted(_servicesSorted);
    }, [services, servicesSortingKey, servicesSortingOrder]);

    const handleSetServicesSortingKey = (sortingKey) => {
        let sortingOrder = 'asc';
        if (sortingKey === servicesSortingKey) {
            sortingOrder = servicesSortingOrder === 'asc' ? 'desc' : 'asc';
        }
        dispatch(setServicesSorting(sortingKey, sortingOrder));
    };

    const displayFurtherServices = () => {
        setServicesDisplayMax(servicesDisplayMax + servicesDisplayStep);
    };

    const handleUpload = (data) => {
        const fieldKeys = Object.keys(dataFields);
        const fieldCols = {};
        const entries = [];
        if (data && data.length > 1) {
            // determine in which col to find the data
            const [headerRow, ...dataRows] = data;
            if (headerRow.length > 0) {
                headerRow.forEach((headCol, index) => {
                    if (headCol) {
                        const headVal = `${headCol}`.trim().toLowerCase();
                        const fieldKey = fieldKeys.find((key) => key.toLowerCase() === headVal);
                        if (fieldKey) {
                            fieldCols[fieldKey] = index;
                        }
                    }
                });
            }

            dataRows.forEach((row) => {
                const entry = {};
                fieldKeys.forEach((fieldKey) => {
                    const fieldIndex = fieldCols[fieldKey];
                    const value = row[fieldIndex];
                    if (value !== undefined && value !== null) {
                        const fieldType = dataFields[fieldKey].type;
                        if (fieldType === 'number') {
                            entry[fieldKey] = parseFloat(`${value}`.trim().replace('.', '').replace(',', '.'));
                        } else {
                            entry[fieldKey] = `${value}`.trim();
                        }
                    }
                });
                if (entry[identifier]) {
                    entries.push(entry);
                }
            });
        }

        dispatch(updateServices(entries));
    };

    return {
        services,
        isServicesInitialRequest: isServicesRequest && !Object.values(services).length,
        servicesSorted,
        servicesSortingKey,
        servicesSortingOrder,
        servicesDisplayMax,
        handleSetServicesSortingKey,
        displayFurtherServices,
        handleUpload
    };
}

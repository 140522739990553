import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deepmerge from 'deepmerge';
import permanentInitial from '../data/permanentInitial';
import { useUser } from './user';
import { createPermanent, resetPermanentDraft, setPermanentDraft } from '../redux/actions/permanentsActions';
import permanentIntervals from '../data/permanentIntervals';
import { useBilling } from './billing';

const getFrequencyIntervalFormatted = (frequency, interval) => {
    if (!frequency || !interval) {
        return '';
    }
    const permanentInterval = permanentIntervals.find((pi) => pi.value === interval) || {};
    return `${frequency} mal ${permanentInterval.label}`;
};

const monthlyIntervalFactor = {
    day: 250 / 12,
    week: 52 / 12,
    month: 1,
    year: 1 / 12
};

const getMonthlyPrice = (price, quantity, frequency, interval) => {
    if (!price || !quantity || !frequency || !interval) {
        return undefined;
    }
    const monthlyPrice = price * quantity * frequency * monthlyIntervalFactor[interval];
    return monthlyPrice;
};

export function usePermanent(permanentId) {
    const permanent = useSelector(({ dataState }) => dataState.permanents[permanentId]);
    const permanentService = useSelector(({ dataState }) => dataState.services[permanent?.serviceId]);
    const permanentBuilding = useSelector(({ dataState }) => dataState.buildings[permanent?.buildingId]);
    const permanentContactPerson = permanent?.contactPersonList[0] || {};
    const permanentAssignee = useSelector(({ dataState }) => dataState.users[permanent?.assigneeId]);

    return {
        permanent,
        permanentService,
        permanentBuilding,
        permanentContactPerson,
        permanentAssignee,
        permanentMonthlyPrice: getMonthlyPrice(
            permanentService?.price,
            permanent?.quantity,
            permanent?.frequency,
            permanent?.interval
        ),
        frequencyIntervalFormatted: getFrequencyIntervalFormatted(permanent?.frequency, permanent?.interval)
    };
}

export function usePermanentActions() {
    const dispatch = useDispatch();
    const permanentDraft = useSelector(({ permanentsState }) => permanentsState.permanentDraft);

    const handleCreatePermanentFromDraft = async () => {
        /**
         * create permanent
         */
        const createData = {
            type: permanentDraft.type,
            service: permanentDraft.serviceId,
            quantity: permanentDraft.quantity,
            frequency: permanentDraft.frequency,
            interval: permanentDraft.interval,
            location: permanentDraft.location,
            building: permanentDraft.buildingId,
            start_date: permanentDraft.startDate,
            message: permanentDraft.message,
            billing: permanentDraft.billing,
            contact_person_list: permanentDraft.contactPersonList
        };

        return dispatch(createPermanent(createData));
    };

    return {
        handleCreatePermanentFromDraft
    };
}

export function usePermanentDraftServices() {
    const services = useSelector(({ dataState }) => dataState.services);
    const permanentDraft = useSelector(({ permanentsState }) => permanentsState.permanentDraft);
    const { type, serviceId } = permanentDraft;

    const [permanentServicesByType, setPermanentServicesByType] = useState([]);
    const [permanentService, setPermanentService] = useState([]);

    useEffect(() => {
        if (type) {
            const servicesByType = Object.values(services).filter((service) => service.type === type);
            setPermanentServicesByType(servicesByType);
        }
    }, [type, services]);

    useEffect(() => {
        setPermanentService(services[serviceId]);
    }, [serviceId, services]);

    return {
        permanentServicesByType,
        permanentService
    };
}

export function usePermanentDraft() {
    const dispatch = useDispatch();
    const { user } = useUser();
    const { isBillingValid } = useBilling();
    const permanentDraft = useSelector(({ permanentsState }) => permanentsState.permanentDraft);
    const permanentDraftBuilding = useSelector(({ dataState }) => dataState.buildings[permanentDraft.buildingId]);
    const { permanentService } = usePermanentDraftServices();

    const isPermanentDraftServiceDataValid = () => {
        const { serviceId, quantity, frequency, interval, location, startDate } = permanentDraft;
        if (!serviceId || !quantity || !frequency || !interval || !location || !startDate) {
            return false;
        }
        return true;
    };

    const isPermanentDraftDetailsDataValid = () => {
        const { billing, contactPersonList } = permanentDraft;
        const firstContactPerson = contactPersonList[0] || {};
        const { first_name, last_name, email, department } = firstContactPerson;

        if (!isBillingValid(billing) || !first_name || !last_name || !email || !department) {
            return false;
        }

        return true;
    };

    const updatePermanentDraft = (state) => {
        const permanentDraftNew = deepmerge(permanentDraft, state);
        dispatch(setPermanentDraft(permanentDraftNew));
    };

    const updatePermanentDraftType = (type) => {
        if (type !== permanentDraft.type) {
            const permanentDraftNew = {
                ...permanentInitial,
                type,
                billing: user ? user.billing : {}
            };
            dispatch(setPermanentDraft(permanentDraftNew));
        }
    };

    const updatePermanentDraftContactPerson = (contactPerson) => {
        const draftContactPerson = permanentDraft.contactPersonList[0] || {};
        const newContactPerson = { ...draftContactPerson, ...contactPerson };
        const permanentDraftNew = {
            ...permanentDraft,
            contactPersonList: [newContactPerson]
        };

        dispatch(setPermanentDraft(permanentDraftNew));
    };

    const reset = () => {
        dispatch(resetPermanentDraft());
    };

    return {
        permanentDraft,
        permanentDraftBuilding,
        permanentDraftMonthlyPrice: getMonthlyPrice(
            permanentService?.price,
            permanentDraft?.quantity,
            permanentDraft?.frequency,
            permanentDraft?.interval
        ),
        isPermanentDraftServiceDataValid,
        isPermanentDraftDetailsDataValid,
        updatePermanentDraft,
        updatePermanentDraftType,
        updatePermanentDraftContactPerson,
        stableResetPermanentDraft: useCallback(reset, []),
        frequencyIntervalFormatted: getFrequencyIntervalFormatted(permanentDraft?.frequency, permanentDraft?.interval)
    };
}
